import { Col, Input, Row, message, Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../common";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../constants";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

const UserDetailsProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [userData, setuserData] = useState();
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [editBtn, seteditBtn] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("userId");

  useEffect(() => {
    fetchUserDetails();
  }, [paginationParams, search, filterBy, filteredValue, console.log(userData)]);

  const fetchUserDetails = async () => {
    let pageDetails = {
      id: UserId,
      DomainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    try {
      let response = await axios.post(`${REACT_APP_PAM_API}/users/${UserId}`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });

      let userdata = response.data;
      setuserData(userdata);
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.emailAddress);
      setOtp(userData.otpMethod);
      setPhoneNumber(userData.phoneNumber);
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message == "Invalid credentials"
      ) {
        logOutUser();
      } else {
      }
    }
  };

  return (
    <>
      {setNotification}
      <Row justify={"space-between"} className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={16}>
              <Row justify={"space-between"}>
                <Col span={8}>
                  <h2 className="sub-title" style={{ marginTop: "1rem" }}>
                    Attributes{" "}
                  </h2>
                </Col>
                <Col span={8}>
                  <Link>
                    <Button
                      style={{ marginTop: "0.6rem", fontSize: "17px" }}
                      type="link"
                      onClick={() => seteditBtn(!editBtn)}
                    >
                      {editBtn ? "Cancel" : "Edit"}
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {userData && (
                    <>
                      <Row>
                        <Col span={24}>
                          <Row
                            align="middle"
                            justify="space-between"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>First Name</p>
                              </Row>
                              <Row>
                                <Input
                                  // placeholder={userData.firstName}
                                  disabled={!editBtn}
                                  value={firstName}
                                />
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>Last Name</p>
                              </Row>
                              <Row>
                                <Input
                                  // placeholder={userData.lastName}
                                  disabled={!editBtn}
                                  value={lastName}
                                />
                              </Row>
                            </Col>

                            <Col span={4}></Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Row
                            align="middle"
                            justify="space-between"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>Phone Number</p>
                              </Row>
                              <Row>
                                <Input
                                  // placeholder={userData.phoneNumber}
                                  value={phoneNumber}
                                  disabled={!editBtn}
                                />
                              </Row>
                            </Col>
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>Email</p>
                              </Row>
                              <Row>
                                <Input
                                  // placeholder={email}
                                  value={email}
                                  disabled={!editBtn}
                                />
                              </Row>
                            </Col>

                            <Col span={4}></Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Row
                            align="middle"
                            justify="space-between"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>Organization</p>
                              </Row>
                              <Row>
                                <Input placeholder="Enter Organization Name" disabled={!editBtn} />
                              </Row>
                            </Col>

                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>Industry</p>
                              </Row>
                              <Row>
                                <Select
                                  placeholder="Select Industry"
                                  tokenSeparators={[","]}
                                  disabled={!editBtn}
                                  options={[
                                    {
                                      label: "AAA Industry",
                                      value: "AAA Industry",
                                    },
                                    {
                                      label: "BBB Industry",
                                      value: "BBB Industry",
                                    },
                                    {
                                      label: "CCC Industry",
                                      value: "CCC Industry",
                                    },
                                  ]}
                                />
                              </Row>
                            </Col>
                            <Col span={4}></Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Row
                            align="middle"
                            justify="space-between"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>City</p>
                              </Row>
                              <Row>
                                <Select
                                  placeholder="Select City"
                                  tokenSeparators={[","]}
                                  disabled={!editBtn}
                                  options={[
                                    { label: "Chennai", value: "Chennai" },
                                    { label: "Pune", value: "Pune" },
                                    { label: "Mumbai", value: "Mumbai" },
                                  ]}
                                />
                              </Row>
                            </Col>

                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>Country</p>
                              </Row>
                              <Row>
                                <Select
                                  placeholder="Select Country"
                                  tokenSeparators={[","]}
                                  disabled={!editBtn}
                                  options={[
                                    { label: "USA", value: "usa" },
                                    { label: "INDIA", value: "india" },
                                    { label: "UK", value: "uk" },
                                  ]}
                                />
                              </Row>
                            </Col>
                            <Col span={4}></Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Row
                            align="middle"
                            justify="space-between"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>State</p>
                              </Row>
                              <Row>
                                <Select
                                  placeholder="Select State"
                                  tokenSeparators={[","]}
                                  disabled={!editBtn}
                                  options={[
                                    { label: "Tamilnadu", value: "Tamilnadu" },
                                    { label: "Kerela", value: "Kerela" },
                                    { label: "Telangana", value: "Telangana" },
                                  ]}
                                />
                              </Row>
                            </Col>

                            <Col span={8}>
                              <Row>
                                <p style={{ marginBottom: "0.1rem" }}>OTP Method</p>
                              </Row>
                              <Row>
                                <Select
                                  // placeholder={userData.otpMethod}
                                  tokenSeparators={[","]}
                                  disabled={!editBtn}
                                  value={otp}
                                  options={[
                                    { label: "DID", value: "DID" },
                                    { label: "SMS", value: "SMS" },
                                    { label: "Email", value: "Email" },
                                  ]}
                                />
                              </Row>
                            </Col>
                            <Col span={4}></Col>
                          </Row>
                        </Col>
                      </Row>
                      {editBtn && (
                        <Row justify="end" style={{ marginTop: "1rem" }}>
                          <Col span={8}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => navigate(-1)}
                              loading={isLoading}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <div class="group-instruction">
                <h6 className="para-title">Groups</h6>
                <p className="body-text">
                  <Link to={`/directory/groups`}>
                    <span style={{ fontSize: "15px" }}>Groups </span>
                  </Link>
                  allow you to manage app assignments and user profile attributes more efficiently.
                </p>
                <h6 className="para-title">Converting Assignments</h6>
                <p className="body-text">
                  Application access and user profile attributes can be converted from being
                  individually-managed to group-managed. You can convert assignments from an app's
                  Group tab.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserDetailsProfile;
