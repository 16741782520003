import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";
import { ReactComponent as LOGO } from "../../../assets/logo.svg";

function SingleFactorLogin() {
  const [form] = Form.useForm();
  const [IsLoading, setIsLoading] = React.useState(false);

  const handleFinish = (values) => {
    console.log("Received values of form: ", values);
    setIsLoading(true);
  };

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <h2 className="title">
            <LOGO />
          </h2>
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <div className="login-form-button">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={IsLoading}
                >
                  Login
                </Button>
                <br />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default SingleFactorLogin;
