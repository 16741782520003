import { Button, Col, Row, Select, Input, Form } from "antd";
import React, { useEffect, useState } from "react";

const { TextArea } = Input;

const ConditionsTab = ({
  prevTab,
  nextTab,
  selectedType,
  selectedApp,
  selectedEndpoint,
  selectedDeviceType,
  selectedBrowserType,
  selectedDevice,
  selectedBrowser,
  selectedCountry,
  selectedState,
  selectedOU,
  setMfaFailCount,
  setLoginFailCount,
  setTrustedLocation,
  additionalConditions,
  setAdditionalConditions,
  mfaFailCount,
  loginFailCount,
  trustedLocation,
}) => {
  const [form] = Form.useForm();
  const [mfaFailCountValue, setMfaFailCountValue] = useState("N/A");
  const [loginFailCountValue, setLoginFailCountValue] = useState("N/A");
  const [trustedLocationValue, setTrustedLocationValue] = useState("True");

  const handleConditionValueChange = (value, index) => {
    const updatedConditions = [...additionalConditions];
    updatedConditions[index] = {
      ...updatedConditions[index],
      conditionValue: value,
    };
    setAdditionalConditions(updatedConditions);
  };

  const displayValue =
    selectedType === "App" ? selectedApp : selectedType === "Endpoints" ? selectedEndpoint : "";

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setMfaFailCount(mfaFailCountValue);
        setLoginFailCount(loginFailCountValue);
        setTrustedLocation(trustedLocationValue);
        nextTab();
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  useEffect(() => {
    if (selectedOU !== "") {
      form.setFieldsValue({
        mfaFailCount: mfaFailCount ? mfaFailCount : "N/A",
        loginFailCount: loginFailCount ? loginFailCount : "N/A",
        trustedLocation: trustedLocation ? trustedLocation : "N/A",
      });
    }
  }, [selectedOU, selectedEndpoint, form]);

  return (
    <Row>
      <Col span={24} style={{ padding: "20px" }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            mfaFailCount: "N/A",
            loginFailCount: "N/A",
            trustedLocation: "N/A",
          }}
        >
          <Row style={{ marginTop: "1rem" }}>
            <Col
              span={18}
              style={{
                background: "rgb(219, 238, 255)",
                borderRadius: "8px",
                padding: "2rem",
              }}
            >
              {selectedType && displayValue && (
                <Row gutter={20} style={{ marginBottom: "1rem" }}>
                  <Col span={2} style={{ marginTop: "0.3rem" }}>
                    <span>IF</span>
                  </Col>
                  <Col span={11}>
                    <Input value={selectedType} readOnly />
                  </Col>
                  <Col span={11}>
                    <Input value={displayValue} readOnly />
                  </Col>
                </Row>
              )}
              {selectedDeviceType && selectedDevice && (
                <Row gutter={20} style={{ marginBottom: "1rem" }}>
                  <Col span={2} style={{ marginTop: "0.3rem" }}>
                    <span>IF</span>
                  </Col>
                  <Col span={11}>
                    <Input value={selectedDeviceType} readOnly />
                  </Col>
                  <Col span={11}>
                    <Input value={selectedDevice} readOnly />
                  </Col>
                </Row>
              )}
              {selectedBrowserType && selectedBrowser && (
                <Row gutter={20} style={{ marginBottom: "1rem" }}>
                  <Col span={2} style={{ marginTop: "0.3rem" }}>
                    <span>IF</span>
                  </Col>
                  <Col span={11}>
                    <Input value={selectedBrowserType} readOnly />
                  </Col>
                  <Col span={11}>
                    <Input value={selectedBrowser} readOnly />
                  </Col>
                </Row>
              )}
              {selectedCountry && (
                <Row gutter={20} style={{ marginBottom: "1rem" }}>
                  <Col span={2} style={{ marginTop: "0.3rem" }}>
                    <span>IF</span>
                  </Col>
                  <Col span={11}>
                    <Input value={"Country"} readOnly />
                  </Col>
                  <Col span={11}>
                    <Input value={selectedCountry} readOnly />
                  </Col>
                </Row>
              )}
              {selectedState && (
                <Row gutter={20} style={{ marginBottom: "1rem" }}>
                  <Col span={2} style={{ marginTop: "0.3rem" }}>
                    <span>IF</span>
                  </Col>
                  <Col span={11}>
                    <Input value={"State"} readOnly />
                  </Col>
                  <Col span={11}>
                    <Input value={selectedState} readOnly />
                  </Col>
                </Row>
              )}
              <Row gutter={20} style={{ marginBottom: "1rem" }}>
                <Col span={2} style={{ marginTop: "0.3rem" }}>
                  <span>IF</span>
                </Col>
                <Col span={11}>
                  <Input value={"OU"} readOnly />
                </Col>
                <Col span={11}>
                  <TextArea
                    rows={1}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    value={selectedOU}
                    readOnly
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={2} style={{ marginTop: "0.3rem" }}>
                  <span>IF</span>
                </Col>
                <Col span={11}>
                  <Input value={"MFA  Fail Count"} readOnly />
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="mfaFailCount"
                    rules={[
                      {
                        required: true,
                        message: "Please select MFA Fail Count",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select MFA Fail Count"
                      value={mfaFailCountValue}
                      onChange={setMfaFailCountValue}
                      // defaultValue="N/A"
                      options={[
                        { label: "N/A", value: "N/A" },
                        { label: "< 1", value: "< 1" },
                        { label: "< 2", value: "< 2" },
                        { label: "< 3", value: "< 3" },
                        { label: "< 4", value: "< 4" },
                        { label: "< 5", value: "< 5" },
                        { label: "< 6", value: "< 6" },
                        { label: "< 7", value: "< 7" },
                        { label: "< 8", value: "< 8" },
                        { label: "< 9", value: "< 9" },
                        { label: "< 10", value: "< 10" },
                      ]}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={2} style={{ marginTop: "0.3rem" }}>
                  <span>IF</span>
                </Col>
                <Col span={11}>
                  <Input value={"Login  Fail Count"} readOnly />
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="loginFailCount"
                    rules={[
                      {
                        required: true,
                        message: "Please select Login Fail Count",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Login Fail Count"
                      value={loginFailCountValue}
                      onChange={setLoginFailCountValue}
                      options={[
                        { label: "N/A", value: "N/A" },
                        { label: "< 1", value: "< 1" },
                        { label: "< 2", value: "< 2" },
                        { label: "< 3", value: "< 3" },
                        { label: "< 4", value: "< 4" },
                        { label: "< 5", value: "< 5" },
                        { label: "< 6", value: "< 6" },
                        { label: "< 7", value: "< 7" },
                        { label: "< 8", value: "< 8" },
                        { label: "< 9", value: "< 9" },
                        { label: "< 10", value: "< 10" },
                      ]}
                      allowClear
                    />
                  </Form.Item>{" "}
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={2} style={{ marginTop: "0.3rem" }}>
                  <span>IF</span>
                </Col>
                <Col span={11}>
                  <Input value={"Trusted Location"} readOnly />
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="trustedLocation"
                    rules={[
                      {
                        required: true,
                        message: "Please select Trusted Location",
                      },
                    ]}
                  >
                    <Select
                      value={trustedLocationValue}
                      onChange={setTrustedLocationValue}
                      options={[
                        { label: "True", value: "True" },
                        { label: "False", value: "False" },
                      ]}
                      style={{ width: "100%" }}
                      placeholder="Select Trusted Location"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Additional Conditions */}
              {additionalConditions &&
                additionalConditions.slice(0, -1).map((condition, index) => (
                  <Row gutter={20} key={condition.id}>
                    <Col span={2} style={{ marginTop: "0.3rem" }}>
                      <span>IF</span>
                    </Col>
                    <Col span={11}>
                      <Form.Item>
                        <Input value={condition.conditionLabel} readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item>
                        <Select
                          onChange={(value) => {
                            handleConditionValueChange(value, index);
                          }}
                          value={condition.conditionValue}
                          options={
                            condition.conditionName === "trusted_device" ||
                            condition.conditionName === "trusted_network"
                              ? [
                                  { label: "N/A", value: "N/A" },
                                  { label: "True", value: "True" },
                                  { label: "False", value: "False" },
                                ]
                              : [
                                  { label: "N/A", value: "N/A" },
                                  { label: "> 1", value: "> 1" },
                                  { label: "> 2", value: "> 2" },
                                  { label: "> 3", value: "> 3" },
                                  { label: "> 4", value: "> 4" },
                                  { label: "> 5", value: "> 5" },
                                  { label: "> 6", value: "> 6" },
                                  { label: "> 7", value: "> 7" },
                                  { label: "> 8", value: "> 8" },
                                  { label: "> 9", value: "> 9" },
                                  { label: "> 10", value: "> 10" },
                                ]
                          }
                          style={{ width: "100%" }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>

          <Row style={{ marginTop: "1rem" }}>
            <Col span={18}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={() => prevTab()}>Back</Button>
                <Button type="primary" onClick={handleNext}>
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
export default ConditionsTab;
