import React from "react";
import SSOIMAGE from "../../../assets/SSOMFA.svg";
// import SSOIMAGE1 from "../../../assets/SSOMFA1.svg";
import { Button, Card } from "antd";
import { ReactComponent as SSOIMAGE1 } from "../../../assets/SSOMFA1.svg";
import { ReactComponent as ACCESSDENIED } from "../../../assets/access-denied.svg";

const MfaCacheNotification = ({ isLoading }) => {
  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <h2 style={{ textAlign: "center" }} className="title">
            Accept the MFA Prompt on Your Phone
          </h2>

          <span
            style={{
              // height: 500,
              // width: 1080,
              display: "flex",
              // margin: "auto",
            }}
          >
            <SSOIMAGE1 style={{ height: 500 }} />
          </span>

          <p style={{ textAlign: "center" }}>...waiting</p>
        </div>
      </div>
    </>
  );
};

export default MfaCacheNotification;

export const ErrorSSoMFA = () => {
  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>Unable to Verify SSO MFA</h2>
          <span style={{ display: "flex" }}>
            <ACCESSDENIED style={{ height: 250 }} />
          </span>
          <Button
            type="primary"
            onClick={() => {
              window.location.replace(`https://${window.location.host}`);
            }}
            style={{
              display: "flex",
              margin: "auto",
              marginTop: "3rem",
              marginBottom: "1rem",
            }}
          >
            Back to Login
          </Button>
        </div>
      </div>
    </>
  );
};
