import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_API,
  REACT_APP_API_OKTA,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_URL,
} from "../constants";

export const CheckAuthnMethod = () => {
  let CheckUrl = localStorage.getItem("URL");

  const data = {
    url: CheckUrl === "localhost:5173" ? "default.devsetup.authnull.com" : CheckUrl,
  };
  axios.post(`${REACT_APP_API_TENANTS_URL}/getAuthMethod`, data).then((res) => {
    let method = res.data.authenticationmethod;
    if (method.toUpperCase() === "NORMAL") {
      axios
        .get(`${REACT_APP_API}/okta/Logout`, {
          headers: {
            withCredentials: true,
            "X-Authorization": localStorage.getItem("token"),
          },
        })
        .then(() => {
          localStorage.clear();
          window.location.replace(`https://${CheckUrl}/tenant/login`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (method.toUpperCase() === "OKTA") {
      oktaLogout(res.data.ssoUrl);
    } else {
      localStorage.clear();
      window.location.replace(`https://${CheckUrl}/tenant/login`);
    }
  });
};

export const logOutUser = () => {
  let CheckUrl = localStorage.getItem("URL");
  if (CheckUrl === "app.authnull.com" || CheckUrl === "dev.authnull.com") {
    localStorage.clear();
    window.location.replace(`https://${CheckUrl}/org/login`);
  } else {
    CheckAuthnMethod();
  }
};

const oktaLogout = (url) => {
  axios
    .get(`${REACT_APP_API}/okta/Logout`, {
      headers: {
        withCredentials: true,
        "X-Authorization": localStorage.getItem("token"),
      },
    })
    .then(() => {
      localStorage.clear();
      window.location.replace(url);
    })
    .catch((err) => {
      console.log(err);
    });
};
