import { Col, Row, Select, message, Card, Badge, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
} from "../constants";
import { logOutUser } from "../common";
import axios from "axios";
import { Column, Line } from "@ant-design/plots";
import moment from "moment-timezone";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Meta } = Card;

const Dashboard = () => {
  const [notification, setNotification] = message.useMessage();
  const [initialDayVal, setInitialDayVal] = useState("month");
  const [endpointCount, setEndpointCount] = useState(null);
  const [endpointUserCount, setEndpointUserCount] = useState(null);
  const [directoryUsersCount, setDirectoryUsersCount] = useState(null);
  const [tenantData, setTenantData] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [linePolicyData, setLinePolicyData] = useState([]);
  const [policyCount, setPolicyCount] = useState(0);
  const [rejectPolicyCount, setRejectPolicyCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAuthRequest();
    fetchActivePolicy();
    fetchEndponitCount();
    fetchDirectoryUserCount();
    fetchEndponitUserCount();
  }, [initialDayVal]);

  const fetchAuthRequest = () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listAuthRequest`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        fetchAuthApproved();
        let dataRevers = res.data.counts.reverse();
        const originalData = dataRevers.map((val) => ({
          name: "Requested",
          xField: initialDayVal === "hour" ? val.date.split(" ")[1] : val.date,
          yField: val.count,
        }));

        setGraphData((prevData) => {
          const updatedData = prevData?.filter(
            (d) => d?.name !== originalData?.name && d?.xField !== originalData?.xField
          );
          return [...updatedData, ...originalData];
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  const fetchAuthApproved = () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),

      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listApprovedAuthRequest`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        fetchAuthDenied();
        let dataRevers = res.data.counts.reverse();
        const originalData = dataRevers.map((val) => ({
          name: "Accepted",
          xField: initialDayVal === "hour" ? val.date.split(" ")[1] : val.date,
          yField: val.count,
        }));
        setGraphData((prevData) => {
          const updatedData = prevData?.filter(
            (d) => d?.name !== originalData?.name && d?.xField !== originalData?.xField
          );
          return [...updatedData, ...originalData];
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  const fetchAuthDenied = () => {
    let payload = {
      orgId: ORG_ID(),

      tenantId: DOMAIN_ID(),
      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listRejectedAuthRequest`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setRejectPolicyCount(res.data.totalCount);
        let dataRevers = res.data.counts.reverse();
        const originalData = dataRevers.map((val) => ({
          name: "Rejected",
          xField: initialDayVal === "hour" ? val.date.split(" ")[1] : val.date,
          yField: val.count,
        }));
        setGraphData((prevData) => {
          const updatedData = prevData?.filter(
            (d) => d?.name !== originalData?.name && d?.xField !== originalData?.xField
          );
          return [...updatedData, ...originalData];
        });
        setLineData(
          dataRevers.map((data) => {
            const key =
              initialDayVal === "month" ? "Date" : initialDayVal === "day" ? "Day" : "Mins";
            const value = initialDayVal === "hour" ? data.date.split(" ")[1] : data.date;
            return {
              [key]: value,
              Count: data.count,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  const fetchActivePolicy = () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),

      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listActivePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setPolicyCount(res?.data?.totalCount);
        let dataRevers = res?.data?.counts?.reverse();
        setLinePolicyData(
          dataRevers.map((data) => {
            const key =
              initialDayVal === "month" ? "Date" : initialDayVal === "day" ? "Day" : "Mins";
            const value = initialDayVal === "hour" ? data.date.split(" ")[1] : data.date;
            return {
              [key]: value,
              Count: data.count,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logOutUser();
        }
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to fetch new credential shared",
        });
      });
  };

  useEffect(() => {
    fetchTenantDetails();
  }, []);
  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload,{
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const policyMode = res.data.data.authenticationpolicy;
        setTenantData(
          policyMode === 1 ? "Audit" : res.data.data.authenticationpolicy === 2 ? "Policy" : "Live"
        );
        console.log(
          policyMode === 1 ? "Audit" : res.data.data.authenticationpolicy === 2 ? "Policy" : "Live"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchEndponitCount = () => {
    const endpointRequest = {
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),

      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listEndPointCount`, endpointRequest, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setEndpointCount(data?.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDirectoryUserCount = () => {
    const endpointRequest = {
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),

      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listUserCount`, endpointRequest, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setDirectoryUsersCount(data?.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchEndponitUserCount = () => {
    const endpointRequest = {
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),

      period: initialDayVal === "day" ? 7 : initialDayVal === "month" ? 30 : 1,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/dashboardService/listEndPointUserCount`, endpointRequest, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setEndpointUserCount(data?.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const barConfig = {
    data: graphData,
    group: true,
    xField: "xField",
    yField: "yField",
    colorField: "name",
    // color: ["#2389FF", "#0DCCCC", "#FF5722"],
    color: ["#19CDD7", "#DDB27C", "#FF5722"],
    axis: {
      x: {
        labelSpacing: 4,
        style: {
          labelTransform: initialDayVal === "month" && "rotate(270)",
        },
      },
    },
  };
  const config = {
    data: lineData,
    xField: initialDayVal === "month" ? "Date" : initialDayVal === "day" ? "Day" : "Mins",
    yField: "Count",
    axis: {
      x: {
        labelSpacing: 4,
        style: {
          labelTransform: (initialDayVal === "month" || initialDayVal === "day") && "rotate(270)",
        },
      },
    },
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };
  const configPolicy = {
    data: linePolicyData,
    xField: initialDayVal === "month" ? "Date" : initialDayVal === "day" ? "Day" : "Mins",
    yField: "Count",
    axis: {
      x: {
        labelSpacing: 4,
        style: {
          labelTransform: (initialDayVal === "month" || initialDayVal === "day") && "rotate(270)",
        },
      },
    },
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };
  return (
    <Row className="content-conatiner">
      {setNotification}
      {console.log(graphData, "graph")}
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Dashboard</h2>
          </Col>
          <Col span={6}>
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(val) => {
                setGraphData([]);
                setInitialDayVal(val);
              }}
              defaultValue={initialDayVal}
              onClear
              options={[
                {
                  value: "hour",
                  label: "Last Hour",
                },
                {
                  value: "day",
                  label: "Last 7 Days",
                },
                {
                  value: "month",
                  label: "Last 30 Days",
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card title="Authentication requests over time" style={{ marginBottom: "50px" }}>
              {/* <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={320} /> */}
              {isLoading ? (
                <div className="app-loading">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                <Column {...barConfig} />
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              title=""
              style={{
                padding: "3rem 0",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Authentication Logs
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 20, marginTop: 10 }}>
          <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link to={"pam/policy-request"}>View all</Link>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <Card title="Policies active over time">
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <Line {...configPolicy} height={250} />
              )}
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {policyCount}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Unapproved authentication requests">
              <Line {...config} height={250} />
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {rejectPolicyCount}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Tenant Status" className="dashboard-card-container">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                <Badge
                  status={
                    tenantData === "Audit" ? "error" : tenantData === "Live" ? "success" : "warning"
                  }
                  style={{
                    paddingRight: "10px",
                  }}
                />
                {tenantData}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            {/* <Link to="/endpoint"> */}
            <Card title="Total endpoints">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {endpointCount}
              </h3>
            </Card>
            {/* </Link> */}
          </Col>
          <Col span={8}>
            {/* <Link to="/directory/users"> */}
            <Card title="Total Directory Users">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {directoryUsersCount}
              </h3>
            </Card>
            {/* </Link> */}
          </Col>
          <Col span={8}>
            {/* <Link to="/endpointsUsers"> */}
            <Card title="Total Endpoint Users">
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {endpointUserCount}
              </h3>
            </Card>
            {/* </Link> */}
          </Col>
          {/* <Col span={8}> NOTE: SERVICE ACCOUNT WILL BE NEEDED IN FUTURE
            <Card title="Service account users" style={{ height: 450 }}>
              <h3
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  height: 337,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 0,
                }}
              >
                {oldUser ? "0" : "0"}
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Service account auth over time">
              <Line {...config} height={250} />

              <h3
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {oldUser ? "0" : "0"}
              </h3>
            </Card>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
