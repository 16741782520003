import { Card } from "antd";
import React from "react";
import { ReactComponent as LOGOUT } from "../../assets/log-out.svg";

function Logout() {
  return (
    <div className="login-container">
      <Card
        style={{
          width: 500,
        }}
      >
        <h1 style={{ marginBottom: "2rem", textAlign: "center" }}>Logging Out...</h1>

        <span style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
          <LOGOUT style={{ height: 250 }} />
        </span>
      </Card>{" "}
    </div>
  );
}

export default Logout;
