import { Button, Modal, Row, Col, Input, message, Select, Form, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  REACT_APP_API_DID_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";
import { useLocation } from "react-router-dom";

const AssignEndpointUser = ({
  Isassignwallet,
  setIsassignwallet,
  selectedRecords,
  fetchEPMUsers,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [IsBtnLoading, setIsBtnLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    pageSize: 5,
  });
  const [issuer, setIssuer] = useState([]);
  const [walletData, setwalletData] = useState([]);
  const [selectedissuer, setselectedIssuer] = useState([]);
  const [assignedId, setAssignedId] = useState([]);
  const [form] = Form.useForm();
  const debouncedValue = useDebounce(search, 500);
  const location = useLocation();
  const { PublicIp, HostName } = location.state || {};

  const handleEndpointChange = (value) => {
    setselectedIssuer(value);
    form.setFieldsValue({
      IssuerId: value,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchWallets();
  }, [debouncedValue, paginationParams]);

  useEffect(() => {
    fetchIssuerList();
    fetchAssignedListOfUsers();
  }, []);

  const fetchAssignedListOfUsers = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      epmUserId: selectedRecords.userId,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/getAssignedWalletUserByEpmUser`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (!res?.data?.data) {
          return setAssignedId([]);
        }

        setAssignedId(res?.data?.data?.map((data) => data.wallet_id));
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
        } else if (err.response.status === 401) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch issuer list",
          });
        }
      });
  };

  const fetchIssuerList = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIssuer(
          res?.data?.map((data) => ({
            id: data.IssuerId,
            text: data.Name,
          })) || []
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
        } else if (err.response.status === 401) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch issuer list",
          });
        }
      });
  };

  const fetchWallets = async () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageNumber: paginationParams.page + 1,
      pageSize: paginationParams.pageSize,
      filter: search,
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_API_DID_URL}/walletService/walletUserList`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      setTotalCount(res?.data?.count);
      if (res.data.users) {
        const walletemail = res.data.users.map((wemail) => wemail.emailAddress);       
        setwalletData(
          res?.data?.users?.map((user) => ({
            id: user.userId,
            email: user.emailAddress ? user.emailAddress : "-",
            name: user.username ? user.username : "-",
            walletUrl: user.walletUrl,
            walletId: user.walletId,
          }))
        );
      } else {
        setwalletData([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.response.data.message === "Invalid credentials") {
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      }
    }
  };
  const handleWalletassign = () => {
    let walletData = {
      domainId: DOMAIN_ID(),
      issuerId: selectedissuer,
      epmUsers: [
        {
          epmUserId: Number(selectedRecords.userId),
          instanceId: Number(selectedRecords.epmMachineId),
        },
      ],
      users: selectedRowKeys,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/checkouts/assignEpmUserToWallet`, walletData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: "User Assigned to wallet!",
        });
        setTimeout(() => {
          setIsassignwallet(false);
          fetchEPMUsers();
          setIsBtnLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setIsBtnLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid!",
          });
        } else if (err.response.status === 401) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Error Assigning user to wallet!",
          });
        }
        notification.open({
          type: "error",
          content: "Failed to assign User to wallet!",
        });
      });
  };

  // const selectedemail = selectedRecords.wallets.map((email) => email.walletEmail);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email Address", flex: 1 },
    { field: "walletUrl", headerName: "URL", flex: 1 },
  ];

  const handleTableChange = (pagination) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setwalletData([]);
    }
  };

  const handleOk = () => {
    setIsBtnLoading(true);
    setIsLoading(true);
    handleWalletassign();
    setIsLoading(false);
  };
  const handleCancel = () => {
    setIsassignwallet(false);
  };

  return (
    <Modal
      open={Isassignwallet}
      title={
        <span style={{ fontSize: 18 }}>
          Assign To Wallet for <span style={{ color: "#1677FF" }}>{selectedRecords.userName}</span>
        </span>
      }
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1000}
    >
      {setNotification}
      <Spin spinning={isLoading}>
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical" onFinish={handleOk}>
              <Row>
                <Col span={11}>
                  <p className="search-label">Search </p>
                  <Input
                    placeholder="Search Email"
                    allowClear
                    onChange={(e) => {
                      setSearch(e.target.value);
                      if (paginationParams.page !== 0) {
                        setPaginationParams({ page: 0, pageSize: 5 });
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Row
                        style={{ marginTop: "2rem", gap: "1rem" }}
                        className="search-box-container"
                      >
                        <Col span={11}>
                          <Form.Item label="Endpoint">
                            <Input
                              label="Endpoint"
                              disabled
                              value={HostName && PublicIp ? `${HostName} (${PublicIp})` : ''}/>
                          </Form.Item>

                        </Col>
                        <Col span={11}>
                          <Form.Item
                            name="IssuerId"
                            label="Issuer"
                            rules={[
                              {
                                required: true,
                                message: "Select Issuer!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Issuer"
                              showSearch
                              optionFilterProp="label"
                              options={issuer.map((issuer) => ({
                                value: issuer.id,
                                label: issuer.text,
                              }))}
                              onChange={handleEndpointChange}
                            />
                          </Form.Item>


                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="userSelect"
                    rules={[
                      {
                        required: true,
                        message: "Select User to Assign!",
                      },
                    ]}
                  >
                    <Row>
                      <Col span={24}>
                        <DataGrid
                          rows={walletData}
                          columns={columns}
                          paginationMode="server"
                          rowCount={totalCount}
                          style={{ border: "none" }}
                          paginationModel={paginationParams}
                          checkboxSelection
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 5, page: 0 },
                            },
                          }}
                          getRowId={(row) => row.id}
                          isRowSelectable={(params) =>
                            !assignedId.map((id) => id).includes(params.row.walletId)
                          }
                          onPaginationModelChange={(model) => {
                            setIsLoading(true);
                            setPaginationParams({
                              page: model.page,
                              pageSize: model.pageSize,
                            });
                          }}
                          onRowSelectionModelChange={(newSelection) => {
                            setSelectedRowKeys(newSelection);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Row justify={"end"}>
                  <Col span={2} style={{ marginTop: "1rem" }}>
                    <Button key="cancel" type="default" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                  <Col span={2} style={{ marginTop: "1rem" }}>
                    <Button htmlType="submit" type="primary" loading={IsBtnLoading}>
                      Assign
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default AssignEndpointUser;
