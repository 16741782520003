import {
  Col,
  Row,
  Dropdown,
  Button,
  message,
  Input,
  Popconfirm,
  Tag,
  Typography,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { logOutUser } from "../../../common";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  REACT_APP_API_DID_URL,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API,
} from "../../../constants";
import axios from "axios";
import AssignCredentials from "./AssignCredentials";
import AddUser from "./AddUser";
import ImportUsers from "./ImportUsers";
import EditUsers from "./EditUsers";
import { useDebounce } from "../../../common/debounce";
import ReactJoyride from "react-joyride";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const AdUser = () => {
  const [users, setUsers] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignCredentials, setIsAssignCredentials] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [search, setSearch] = useState("");
  const [isAddUser, setIsAddUser] = useState(false);
  const [isImportUser, setIsImportUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [filter, setFilter] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const [paginationParams, setPaginationParams] = useState({
    current: 0,
    pageSize: 10,
  });

  //Tour
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);

  const location = useLocation();
  // const { mfa } = location.state;
  const queryParams = new URLSearchParams(location.search);
  const mfa = queryParams.get("mfa");

  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsOnboarding(mfa ? true : false);
  }, []);

  const [options, setOptions] = React.useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    const filteredOptions = users
      .filter((user) => user.emailId.toLowerCase().includes(value.toLowerCase()))
      .map((user) => ({
        label: user.emailId,
        value: user.emailId,
      }));

    setOptions(filteredOptions);
  };

  const { Text } = Typography;
  const { Option } = Select;

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        // title: <span>Add Users</span>,
        disableBeacon: true,
        content: (
          <span>
            {" "}
            <h4 style={{ marginBottom: "0" }}>Add User or Import the Bulk Users</h4>
            <br />
            Add an indivisual user or add many users in bulk by uploading the CSV file.
          </span>
        ),
        placement: "left",
        hideFooter: true,
      },
      // {
      //   target: "#onboarding_step2",
      //   content: <span>Import the Bulk Users</span>,
      //   disableBeacon: true,
      //   title: "Import Users",
      //   placement: "left",
      // },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAdUser();
  }, [debouncedValue, filter, currentPage]);

  const fetchAdUser = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNo: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      // search,
      filters: {
        emailId: search,
        status: filter,
        logoname: "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API}/ad/GetAllAdUsers`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTotalCount(res?.data?.count);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
        console.log(res);
        const usersList = res?.data?.users;
        setUsers(usersList ? usersList : []);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const handleRevokeWallet = (record) => {
    console.log(record);
    let payload = {
      emailId: record.email,
      orgId: ORG_ID(),
      token: AUTH_TOKEN(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/InvalidateWallet`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        setIsLoading(false);
        fetchAdUser();
        notification.open({
          type: "success",
          content: "Wallet Revoked Successful",
        });
      })
      .catch((err) => {
        console.log(err);
        notification.open({
          type: "error",
          content: "Failed to Revoke wallet",
        });
      });
  };

  const items = [
    {
      key: "1",

      label: (
        <Link
          onClick={() => {
            setIsAddUser(true);
            setIsOnboarding(false);
          }}
        >
          Add User
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          onClick={() => {
            setIsImportUser(true);
            setIsOnboarding(false);
          }}
        >
          Import Users
        </Link>
      ),
    },
  ];

  const handleStatus = async (record) => {
    let data = {
      id: record.id,
      orgId: ORG_ID(),
    };
    let url;
    if (record.status === "Active") {
      url = `${REACT_APP_PAM_API}/users/disableUser`;
    } else {
      url = `${REACT_APP_PAM_API}/users/enableUser`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchAdUser();
      notification.open({
        type: "success",
        content: `${record.email} is ${
          record.status === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };

  const handleResetWallet = (val) => {
    console.log(val);
    let payload = {
      userId: val.id,
      walletId: val.walletId,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_DID_URL}/credential/resetWallet`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification.open({
          type: "success",
          content: "Wallet reset successfully",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to reset wallet",
          });
        }
      });
  };

  const handleUpdateAdUser = async (userId, adId, adMfaFlag) => {
    setIsLoading(true);
    const payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      adId: adId,
      userId: [userId],
      mfaFlag: adMfaFlag,
    };

    try {
      await axios.post(`${REACT_APP_API}/ad/UpdateMfaFlagADUsers`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      fetchAdUser();
      message.success("MFA flag updated successfully");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to update MFA flag.");
      console.log(error);
    }
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {isOnBoarding && startTour()}

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Users</h2>
            </Col>
            <Col id="onboarding_step1">
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
                open={mfa}
              >
                <Button type="primary">Add Users</Button>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row className="search-box-container">
                    <Col span={4}>
                      <p className="search-label">Search</p>
                      <div>
                        {/* <Input
                          placeholder="Search email"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        /> */}

                        <AutoComplete
                          placeholder="Search E-mail"
                          allowClear
                          onSearch={handleSearch}
                          options={options}
                          onChange={(value) => {
                            setSearch(value);
                            if (paginationParams.current !== 1) {
                              setPaginationParams({ current: 0, pageSize: 10 });
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Status</p>
                      <div>
                        <Select
                          style={{ width: 200 }}
                          allowClear
                          placeholder="Search status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          // value={getCurrentOption("policyType")}
                          onChange={(e) => {
                            console.log(e);
                            setFilter(e);
                          }}
                        >
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                          <Option value="Pending">Pending</Option>
                        </Select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <DataGrid
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                          noResultsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={users}
                        columns={[
                          // {
                          //   headerName: "", // Empty header for the expand/collapse column
                          //   field: "expand",
                          //   width: 10,
                          //   sortable: false,
                          //   disableColumnMenu: true,
                          //   renderCell: (val) => (
                          //     <Link
                          //       style={{ cursor: "pointer" }}
                          //       onClick={() => {
                          //         setIsEditUser(true);
                          //         setSelectedUserData(val.row);
                          //       }}
                          //     >
                          //       <EditOutlined />
                          //     </Link>
                          //   ),
                          // },
                          {
                            headerName: "Email",
                            field: "emailId",
                            key: "emailId",
                            flex: 1.5,
                            renderCell: (val) =>
                              // <Link to={`/directory/userDetails?userId=${val.row.id}`}>
                              val.row.emailId,
                            // </Link>
                          },
                          // {
                          //   headerName: "User Name",
                          //   field: "username",
                          //   key: "username",
                          //   flex: 1,
                          // },
                          {
                            headerName: "Active Directory Name",
                            field: "activeDirectoryName",
                            key: "activeDirectoryName",
                            flex: 1.5,
                          },
                          {
                            headerName: "Logon Name",
                            field: "logoname",
                            key: "logoname",
                            flex: 1,
                          },

                          {
                            headerName: "OU",
                            field: "ou",
                            key: "ou",
                            flex: 2.5,
                          },
                          {
                            headerName: "Account Type",
                            field: "userType",
                            key: "userType",
                            flex: 1,
                            renderCell: (val) => {
                              return val?.row?.userType?.toLowerCase() === "normaluser"
                                ? "User"
                                : "Service Account";
                            },
                          },
                          {
                            headerName: "Status",
                            field: "status",
                            key: "status",
                            flex: 0.5,
                            renderCell: (val) => {
                              const statusVal = val?.row?.status?.toLowerCase();
                              return (
                                <div>
                                  <Text
                                    type={
                                      val.row.firstLogin === "0"
                                        ? "warning"
                                        : statusVal === "active"
                                        ? "success"
                                        : "danger"
                                    }
                                  >
                                    {val.row.firstLogin === "0"
                                      ? "Pending"
                                      : statusVal === "active"
                                      ? "Active"
                                      : "Inactive"}
                                  </Text>
                                </div>
                              );
                            },
                          },

                          {
                            headerName: "Action",
                            field: "action",
                            key: "action",
                            flex: 0.5,
                            renderCell: (val) => (
                              <Dropdown
                                placement="bottomRight"
                                menu={{
                                  items: [
                                    {
                                      key: "assign",
                                      label: (
                                        <Link
                                          onClick={() => {
                                            setSelectedUserData(val.row);
                                            setIsAssignCredentials(true);
                                          }}
                                        >
                                          Assign Credentials
                                        </Link>
                                      ),
                                    },
                                    {
                                      key: "toggleMfa",
                                      label: (
                                        <Link
                                          onClick={() => {
                                            handleUpdateAdUser(
                                              val?.row?.id,
                                              val?.row?.domainId,
                                              val?.row?.adMfaFlag === 0 ? 1 : 0
                                            );
                                          }}
                                        >
                                          {val?.row?.adMfaFlag === 0 ? "Enable MFA" : "Disable MFA"}
                                        </Link>
                                      ),
                                    },
                                    // {  NOTE: UNDER DEVELOPMENT TODO: NEED TO UPDATE THIS API FOR AD USER
                                    //   key: "revokeWallet",
                                    //   label: (
                                    //     <Popconfirm
                                    //       title={`Sure to Revoke Wallet ${val.row.emailId}?`}
                                    //       onConfirm={() => handleRevokeWallet(val.row)}
                                    //     >
                                    //       <Link>Revoke Wallet</Link>
                                    //     </Popconfirm>
                                    //   ),

                                    //   // danger: true,
                                    // },
                                    // {
                                    //   key: "resetWallet",
                                    //   label: (
                                    //     <Popconfirm
                                    //       title={`Are you sure want to reset the wallet for ${val.row.emailId}?`}
                                    //       onConfirm={() => handleResetWallet(val.row)}
                                    //       loading={isLoading}
                                    //     >
                                    //       <Link loading={isLoading}>Reset Wallet</Link>
                                    //     </Popconfirm>
                                    //   ),
                                    // },
                                    // {
                                    //   key: "status",
                                    //   label: (
                                    //     <Link onClick={() => handleStatus(val.row)}>
                                    //       {val?.row?.status?.toLowerCase() === "active"
                                    //         ? "Deactivate"
                                    //         : "Activate"}
                                    //     </Link>
                                    //   ),
                                    //   danger:
                                    //     val?.row?.status?.toLowerCase() === "active" ? true : false,
                                    // },
                                  ],
                                }}
                              >
                                <Button type="text" shape="circle" icon={<MoreOutlined />} />
                              </Dropdown>
                            ),
                          },
                        ]}
                        rowCount={totalCount}
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        style={{ border: "none" }}
                        loading={isLoading}
                        paginationMode="server"
                        page={currentPage}
                        paginationModel={currentPageModel}
                        onPaginationModelChange={(params) => {
                          setCurrentPage(params.page);
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {isAssignCredentials && (
        <AssignCredentials
          setIsAssignCredentials={setIsAssignCredentials}
          isAssignCredentials={isAssignCredentials}
          selectedUserData={selectedUserData}
          fetchAdUser={fetchAdUser}
          notification={notification}
        />
      )}
      {isAddUser && (
        <AddUser
          isAddUser={isAddUser}
          setIsAddUser={setIsAddUser}
          notification={notification}
          fetchUsers={fetchAdUser}
          run={run}
          setRun={setRun}
        />
      )}
      {isImportUser && (
        <ImportUsers
          isImportUser={isImportUser}
          setIsImportUser={setIsImportUser}
          notification={notification}
          fetchUsers={fetchAdUser}
          run={run}
          setRun={setRun}
        />
      )}
      {isEditUser && (
        <EditUsers
          isEditUser={isEditUser}
          setIsEditUser={setIsEditUser}
          selectedUserData={selectedUserData}
          fetchUsers={fetchAdUser}
          notification={notification}
        />
      )}
    </>
  );
};

export default AdUser;
