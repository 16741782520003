import { Button, Col, Form, Modal, Row, Select, message } from "antd";
import { first } from "lodash";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import TextArea from "antd/es/input/TextArea";
import { CopyOutlined } from "@ant-design/icons/lib/icons";


const AuthFlow = ({ isAuthFlow, setIsAuthFlow, selectedEndpoints }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [url, setCodeData] = useState("");

  const [initialValues, setInitialValues] = useState({
    first: "Password",
    second: "SSH",
    third: "DID",
  });

  const [form] = Form.useForm();
  useEffect(() => {
    fetchPamCode();
  }, []);

  const handleCancel = () => {
    setIsAuthFlow(false);
  };

  const firstFactor = [
    {
      value: "Password",
      label: "Password",
    },
    {
      value: "SSH",
      label: "SSH Key",
    },
    {
      value: "DID",
      label: "DID",
    },
  ];

  const secondFactor = [
    {
      value: "Password",
      label: "Password",
    },
    {
      value: "SSH",
      label: "SSH Key",
    },
    {
      value: "DID",
      label: "DID",
    },
    {
      value: "none",
      label: "None",
    },
  ];
  const handleChange = (val, data, dd) => {
    form.setFieldsValue({
      first: val,
    });
    setInitialValues({ ...initialValues, first: val });
  };
  const handleSecond = (val) => {
    form.setFieldsValue({
      second: val,
    });
    setInitialValues({ ...initialValues, second: val });
  };

  const handleThird = (val) => {
    form.setFieldsValue({
      third: val,
    });
    setInitialValues({ ...initialValues, third: val });
  };
  const fetchPamCode = () => {
    let requestData = {
      instanceId: Number(selectedEndpoints?.instanceId),
      domainId: DOMAIN_ID(),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/viewGeneratedPamCode`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let data = res?.data?.Code ? res?.data?.Code : "";
        setCodeData(data?.split("\n").join("\n").replace(/\t/g, " ").replace(/ {2,}/g, " "));
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        }
        notification.open({
          type: "error",
          content: "Unable to fetch PAM Code",
        });
      });
  };

  const handleFinish = (e) => {
    const instanceAuthdata = {
      instanceId: selectedEndpoints?.instanceId,
      domainId: DOMAIN_ID(),
      authType:
        e.second === "none"
          ? `${e.first},${e.third}`
          : e.third === "none"
          ? `${e.first},${e.second}`
          : `${e.first},${e.second},${e.third}`,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    isLoading(true);
    axios
      .put(`${REACT_APP_API_PAM_URL}/instances/updateAuthType`, instanceAuthdata, {
        // .put("https://api.authnull.authnull.com/api/v1/instances/updateAuthType", instanceAuthdata, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        isLoading(false);
        let data = res?.data ? res?.data?.Code : null;
        let code = data?.split("\n").join("\n").replace(/\t/g, " ").replace(/ {2,}/g, " ");
        setCodeData(code);
        notification.open({
          type: "Success",
          content: "Auth Flow updated successful",
        });
      })
      .catch((err) => {
        isLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
        } else if (err.response.status === 401) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };
  const downloadPamCode = (text) => {
    var fileName = "PAM.SO";
    var fileContent = text;
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  const handleOk = () => {
    form.submit();
  };
  return (
    <>
      {setNotification}
      <Modal
        title={
          <span style={{ fontSize: 18 }}>
            Authentication Flow for{" "}
            <span style={{ color: "#1677FF" }}>{` ${selectedEndpoints?.instanceName}`}</span>
          </span>
        }
        centered
        open={isAuthFlow}
        onOk={handleOk}
        onCancel={handleCancel}
        width={950}
        footer={[
          <Button form="myForm" key="submit" htmlType="submit" type="primary" loading={isLoading}>
            {url ? "Re-Generate" : "Generate"}
          </Button>,
        ]}
      >
        <Form
          id="myForm"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <a
                    style={{ color: "#d32626" }}
                    target="_blank"
                    href="https://help.authnull.com/docs/pam/pluggableauthmodule/"
                  >
                    This section requires changes to linux pluggable Authentication Modules. Click
                    here to learn more
                  </a>
                  <br />
                  <br />
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name="first"
                    label="First Factor"
                    rules={[
                      {
                        required: true,
                        message: "Select First Factor",
                      },
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            if (initialValues.second && initialValues.third.includes(value)) {
                              reject("Already exist");
                            } else {
                              resolve();
                            }
                          });
                        },
                      },
                    ]}
                  >
                    <Select
                      value={initialValues.first}
                      placeholder="Select Hostname"
                      onChange={handleChange}
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={firstFactor}
                    />{" "}
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    name="second"
                    label="Second Factor"
                    rules={[
                      {
                        required: true,
                        message: "Select Second Factor",
                      },
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            if (initialValues.third && initialValues.first.includes(value)) {
                              reject("Already exist");
                            } else {
                              resolve();
                            }
                          });
                        },
                      },
                    ]}
                  >
                    <Select
                      value={initialValues?.second}
                      onChange={handleSecond}
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={secondFactor}
                    />{" "}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item
                name="third"
                label="Third Factor"
                rules={[
                  {
                    required: true,
                    message: "Select Third Factor",
                  },
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (initialValues.first && initialValues.second.includes(value)) {
                          reject("Already exist");
                        } else {
                          resolve();
                        }
                      });
                    },
                  },
                ]}
              >
                <Select
                  value={initialValues?.third}
                  onChange={handleThird}
                  tokenSeparators={[","]}
                  showSearch
                  optionFilterProp="label"
                  options={secondFactor}
                />{" "}
              </Form.Item>
            </Col>
          </Row>
          {url && (
            <Row>
              <Col span={24}>
                <Form.Item label="View Generated PAM Code">
                  <div
                    style={{
                      width: "650px",
                      overflow: "auto",
                    }}
                  >
                    <TextArea
                      prefix={<CopyOutlined />}
                      style={{
                        background: "#fff",
                        color: "#000",
                        padding: "20px 10px",
                      }}
                      disabled
                      value={url}
                      autoSize={{
                        minRows: 3,
                        maxRows: 8,
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col>
                <a class="link2" onClick={() => downloadPamCode(url)}>
                  {" "}
                  Download PAM.SO files.{" "}
                </a>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>{" "}
    </>
  );
};

export default AuthFlow;
