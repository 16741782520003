import { Button, Col, Form, Input, Row, Select, message } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { REACT_APP_API, ORG_ID, DOMAIN_ID, AUTH_TOKEN } from "../../../../constants.js";

const { Option } = Select;

export default function UsersTab({
  setCurrentTab,
  prevTab,
  nextTab,
  selectedType,
  selectedOU,
  setSelectedOU,
}) {
  const [appName, setAppName] = useState();
  const [allGroups, setAllGroups] = useState([]);
  const [allOU, setAllOU] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedType === "App") {
      // fetchGroups();
    } else if (selectedType === "Endpoints") {
      fetchOU();
    }
  }, [selectedType]);

  // const fetchGroups = async (val) => {
  //   let data = {
  //     appname: val ? val.value : appName,
  //   };
  //   try {
  //     const response = await axios.post(
  //       `${REACT_APP_API_GOOGLEWORKSPACE}/getGroups`,
  //       data,
  //       {
  //         headers: {
  //           Accept: "*/*",
  //           "User-Agent": "Thunder Client (https://www.thunderclient.com)",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     let tempData = response?.data?.groups;
  //     setAllGroups(tempData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchOU = async () => {
    let data = {
      org_Id: ORG_ID(),
      tenant_Id: DOMAIN_ID(),
    };
    try {
      const response = await axios.post(`${REACT_APP_API}/api/v1/policyService/listOU`, data, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const fetchedOUs = response.data.data;

      if (Array.isArray(fetchedOUs)) {
        setAllOU(fetchedOUs);
      } else {
        setAllOU([]);
        console.error("Fetched OUs is not an array:", fetchedOUs);
      }
    } catch (error) {
      console.error("Error fetching OUs:", error);
    }
  };

  const handleOUChange = (value) => {
    setSelectedOU(value);
    console.log("Selected OU:", value);
  };

  const handleNextClick = () => {
    form
      .validateFields()
      .then((values) => {
        nextTab();
      })
      .catch(() => {
        message.error("Please fill in the required fields before proceeding");
      });
  };
  return (
    <Row>
      <Col span={24} style={{ padding: "20px" }}>
        <Row>
          <Col
            span={18}
            style={{
              background: "rgb(219, 238, 255)",
              borderRadius: "8px",
              padding: "2rem",
            }}
          >
            <Form form={form} onFinish={handleNextClick} layout="vertical">
              {selectedType === "App" && (
                <Form.Item
                  name="app"
                  rules={[{ required: true, message: "Please select an app" }]}
                  label="Select App Group"
                >
                  <Select placeholder="Select App">
                    {/* {allGroups.map((allGroup) => (
                      <Option key={allGroup.GroupID}>
                        {allGroup.GroupName}
                      </Option>
                    ))} */}
                    {allOU.map((allGroup) => (
                      <Option key={allGroup.GroupID}>{allGroup.GroupName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {selectedType === "Endpoints" && (
                <Form.Item
                  name="endpoint"
                  rules={[{ required: true, message: "Please select an endpoint" }]}
                  label="Select Endpoint Groups"
                >
                  <Select
                    placeholder="Select Endpoint"
                    mode="multiple"
                    onChange={(values) => handleOUChange(values.join(", "))} // Convert array to string
                  >
                    {allOU.map((ou) => (
                      <Option key={ou.id} value={ou.ou_name}>
                        {ou.ou_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>

        <Row style={{ marginTop: "1rem" }}>
          <Col span={18}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => prevTab()}>Back</Button>

              <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
