import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Input, Form, message, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { AUTH_TOKEN, ORG_ID, REACT_APP_PAM_API, DOMAIN_ID } from "../../../constants";
import Instruction from "./Instruction";

const AddJumpServer = () => {
  const [serverHostName, setServerHostName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [instruction, setInstruction] = useState(false);
  const [form] = Form.useForm();
  const [notification, setNotification] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    fetchInstance();
  }, []);

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setServerHostName(
          res.data.instances.map((ins) => ({
            value: ins.instanceId,
            label: (
              <div>
                {ins.hostName} <b>{`(${ins.publicIp})`}</b>
              </div>
            ),
            hostName: ins.hostName,
            ipAddress: ins.publicIp,
          }))
        );
      });
  };

  const handleChange = (value, data) => {
    console.log(value, data);

    form.setFieldsValue({
      serverName: data[0]?.hostName,
      publicIpAddress: data[0]?.ipAddress,
      endpoints: value,
    });
  };

  const handleInstall = (e) => {
    console.log(e);
    let payload = {
      ...e,
      domainId: DOMAIN_ID(),
      domains: [],
      serverId: "KLOUDONE202",
      region: "US West",
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/jumpserver/addJumpServer`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification.open({
          type: "success",
          content: "Jump Server added successfully",
        });
        navigate(-1);
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to add jump server",
        });
      });
  };
  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">Add Jump Server</h2>
          </Col>
        </Row>
        <Form form={form} layout="vertical" onFinish={handleInstall}>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name="endpoints"
                    label="Server Host Name"
                    rules={[
                      {
                        required: true,
                        message: "Select Server Host Name",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Hostname"
                      onChange={handleChange}
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={serverHostName}
                    />{" "}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="serverName" label="Server Name">
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                  <Form.Item
                    name="domains"
                    label="Domains"
                    rules={[
                      {
                        required: true,
                        message: "Select Domains!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Domains"
                      tokenSeparators={[","]}
                      options={[
                        {
                          value: 1,
                          label: "Domain 1",
                        },
                        {
                          value: 2,
                          label: "Domain 2",
                        },
                        {
                          value: 3,
                          label: "Domain 3",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    name="publicIpAddress"
                    label="Server IP Address"
                    rules={[
                      {
                        required: false,
                        message: "Server IP Address is required",
                      },
                    ]}
                  >
                    <Input disabled style={{ background: "#ffffff" }} />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h5>View Instructions</h5>
            </Col>
            <Col>
              <Link onClick={() => setInstruction(true)}>
                Click to view instructions to setup server
              </Link>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "1rem" }}>
            <Col span={8}>
              <Space>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  htmlType="submit"
                  loading={isLoading}
                >
                  Install
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
      {instruction && <Instruction instruction={instruction} setInstruction={setInstruction} />}
    </Row>
  );
};

export default AddJumpServer;
