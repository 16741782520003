import { Col, Row, Select, Button, Input, message, Modal, Checkbox } from "antd";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_API_PAM_URL,
} from "../../../constants";
import { logOutUser } from "../../../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
const { Option } = Select;

const CreatePolicyV2 = () => {
  const [type3, setType3] = useState("option1");
  const navigate = useNavigate();
  const [policyName, setPolicyName] = useState();
  const [ou, setOu] = useState(null);
  const [type, setType] = useState("option1");
  const [type2, setType2] = useState("option1");
  const [adUser, setAdUser] = useState([]);
  const [epmGroups, setEpmGroups] = useState([]);
  const [epmUsers, setEpmUsers] = useState([]);
  const [epm, setEpm] = useState([]);
  const [dom, setDom] = useState([]);
  const [directoryUser, setDirectoryUser] = useState([]);
  const [AdUsersVal, setAdUsersVal] = useState();
  const [directoryGroups, setDirectoryGroups] = useState();
  const [endpoints, setEndpoints] = useState();
  const [endpointGroups, setEndpointGroups] = useState();
  const [userWildCard, setUserWildCard] = useState();
  const [endpointWildCard, setEndpointWildCard] = useState();
  const [endpointUserWildCard, setEndpointUserWildCard] = useState();
  const [endpointUser, setEndpointUser] = useState();
  const [credentialData, setCredentialData] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [userType, setUserType] = useState("ad");
  const [domainData, setDomainData] = useState([]);
  const [domainVal, setDomainVal] = useState("");
  const [os, setOs] = useState("");
  const [protocol, setProtocol] = useState("");
  const [actionVal, setActionVal] = useState("Allow");
  const [checkPolicyNotification, setCheckPolicyNotification] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [ouData, setOuData] = useState([]);
  const [walletUserVal, setWalletUserVal] = useState([]);
  const [walletData, setwalletData] = useState([]);
  useEffect(() => {
    fetchEndpointUser();
    fetchAdUser();
    fetchEndpoints();
    fetchEndpointGroups();
    fetchDomains();
    fetchGrp();
    listDomain();
    generateDynamicPolicyName("", 36);
    fetchWalletUser();
  }, []);
  const handleADUser = (val) => {
    if (directoryUser.length > 0 && directoryUser.includes("all")) {
      // If "all" was previously selected and is now deselected, remove it from the array
      const filteredValues = val.filter((item) => item !== "all");
      setDirectoryUser(filteredValues);
    } else if (val.includes("all")) {
      // If "all" is selected, reset the directoryUser array
      setDirectoryUser(["all"]);
    } else {
      setDirectoryUser(val);
    }
  };
  const validateForm = () => {
    let checkEndpointVal =
      type2 === "option1"
        ? !endpoints?.length
        : type2 === "option2"
        ? !endpointGroups?.length
        : (type2 === "option3" || type2 === "option4") && !endpointWildCard;
    let checkdirectoryUserVal =
      type === "option1"
        ? !directoryUser?.length
        : type === "option2"
        ? !directoryGroups?.length
        : (type === "option3" || type === "option4") && !userWildCard;

    let checkDirectoryUserForLocal =
      type === "option1"
        ? !AdUsersVal
        : (type2 === "option3" || type2 === "option4") && !endpointWildCard;

    let checkEPMUser =
      (type3 === "option1" && !endpointUser?.length) ||
      (type3 === "option3" && !endpointUserWildCard);

    if (userType.toLowerCase() === "ad") {
      if (checkEndpointVal || checkdirectoryUserVal || !domainVal || !ou || !walletUserVal.length) {
        message.error("Please fill in all the fields.");
        return false;
      }
      return true;
    }
    if (userType.toLowerCase() === "local") {
      if (checkEndpointVal || checkEPMUser || !walletUserVal.length) {
        message.error("Please fill in all the fields.");
        return false;
      }
      return true;
    }
  };
  const handleFinish = () => {
    if (!validateForm()) {
      return;
    }
    console.log(policyName);
    let tempUserSelectionType;
    let tempMatch;
    let tempSelection;
    let tempEndpointSelectionType;
    let tempMatch2;
    let tempSelection2;
    let tempEndpointUserSelectionType = "user";
    let tempMatch3;
    let tempSelection3;

    if (type === "option1" || type === "option4") {
      tempUserSelectionType = "user";
    } else if (type === "option2" || type === "option3") {
      tempUserSelectionType = "groups";
    } else {
      tempUserSelectionType = "tenant";
    }
    if (type === "option1" || type === "option2" || type === "option5") {
      tempMatch = "exact";
    } else {
      tempMatch = "wild";
    }
    if (type === "option1") {
      tempSelection = AdUsersVal ? [AdUsersVal] : directoryUser;
    } else if (type === "option2") {
      tempSelection = directoryGroups;
    } else if (type === "option5") {
      tempSelection = ["all"];
    } else {
      tempSelection = userWildCard;
    }

    if (type2 === "option1" || type2 === "option4") {
      tempEndpointSelectionType = "endpoint";
    } else if (type2 === "option2" || type2 === "option3") {
      tempEndpointSelectionType = "group";
    } else {
      tempEndpointSelectionType = "tenant";
    }

    if (type2 === "option1" || type2 === "option2" || type2 === "option5") {
      tempMatch2 = "exact";
    } else {
      tempMatch2 = "wild";
    }
    if (type2 === "option1") {
      tempSelection2 = endpoints;
    } else if (type2 === "option2") {
      tempSelection2 = endpointGroups;
    } else if (type === "option5") {
      tempSelection2 = ["all"];
    } else {
      tempSelection2 = endpointWildCard;
    }

    if (type3 === "option5") {
      tempEndpointUserSelectionType = "tenant";
      tempSelection3 = ["all"];
    }

    if (type3 === "option1" || type3 === "option5") {
      tempMatch3 = "exact";
    } else {
      tempMatch3 = "wild";
    }
    if (type3 === "option1") {
      tempSelection3 = endpointUser;
    } else if (type === "option5") {
      tempSelection3 = ["all"];
    } else {
      tempSelection3 = endpointUserWildCard;
    }

    let data = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      action: actionVal,
      policyName: policyName,
      policyType: userType,
      protocol: [protocol],
      os: os,
      adOu: ou?.children,
      adDomain: domainVal?.children,
      sAMAccountName: false,
      userOrGroups: {
        selectionType: tempUserSelectionType,
        selectionValue: tempSelection,
        match: tempMatch,
      },
      endpointOrGroups: {
        // source: {
        //   match: "exact",
        //   selectionType: "",
        //   selectionValue: [],
        // },
        destination: {
          selectionType: tempEndpointSelectionType,
          selectionValue: tempSelection2,
          match: tempMatch2,
        },
      },
      endpointUsers: {
        selectionType: tempEndpointUserSelectionType,
        selectionValue: tempSelection3,
        match: tempMatch3,
      },
      walletUsers: walletUserVal,
      adDomainId: domainVal?.value,
      adOuId: ou?.value,
    };

    setBtnLoading(true);
    axios
      .post(
        `${REACT_APP_API_DID_URL}/policyService/IsPolicyExists`,
        { ...data, update: 0 },
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setBtnLoading(false);
        console.log(res);
        if (res.data.isPolicyExists == true) {
          setCheckPolicyNotification(true);
        } else {
          createPolicy(data);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to create now, PLease try again",
          });
        }
      });
  };

  const createPolicy = (payload) => {
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/CreatePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: `${policyName} policy created successfully`,
        });
        setTimeout(() => {
          navigate("/pam/policy");
        }, 3000);
      })

      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: `Unable to create ${policyName} policies`,
        });
      });
  };

  const fetchWalletUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: 1,
      pageSize: 100,
      search: "",

      filter: "",
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setwalletData(res.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            name: grp.groupName,

            ...grp,
          }));
        }
        setCredentialData(Data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpointUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmUsers(data.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain, ou: item.ous };
        });

        setDomainData(inputArray);
        console.log({ outputArray });
      });
  };
  const fetchAdUser = (ouDn) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-users",
      ...ouDn,
      // adDomainId: 4,
      // adOuId: 12,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data.data.adUsers);
        setAdUser(data.data.adUsers);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  console.log(adUser);
  const fetchEndpointGroups = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-groups",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmGroups(data.data.groups);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchDomains = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setDom(data.data.adDomains);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpoints = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpm(data.data.endpoints);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  // Function to generate a dynamic name based on a template
  const generateDynamicPolicyName = (prefix, length) => {
    const characters = "0123456789";
    let result = prefix; // Fixed part

    const sections = [7, 8]; // Length of each hyphen-separated section
    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      if (sectionLength !== 8) {
        // Add hyphen except for the last section
        result += "-";
      }
    }
    return setPolicyName(result);
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {checkPolicyNotification && (
          <Modal
            title={`Policy already exists for this combination, Please Create with some other combination`}
            open={checkPolicyNotification}
            onOk={() => {
              setCheckPolicyNotification(false);
            }}
            centered
            closeIcon={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                loading={btnLoading}
                onClick={() => setCheckPolicyNotification(false)}
              >
                Ok
              </Button>,
            ]}
          ></Modal>
        )}
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Create Policy</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <p className="search-label">Policy Name</p>
              <Input
                placeholder="Enter Policy Name"
                value={policyName}
                onChange={(e) => setPolicyName(e.target.value)}
                disabled
              />
            </Col>
            <Col span={8}>
              <p className="search-label">Policy Type</p>
              <Select
                showSearch
                placeholder="Search  Policy Type"
                optionFilterProp="children"
                defaultValue={userType}
                onChange={(e) => {
                  setUserType(e);
                  console.log(e);
                }}
              >
                <Option value="ad">Active Directory</Option>
                <Option value="local">Local Users</Option>
                {/* <Option value="others">Others</Option> */}
              </Select>
            </Col>
          </Row>

          <h3 style={{ color: "blue", marginTop: "2rem" }}>Resources that can be accessed</h3>

          <>
            <Row gutter={16}>
              <Col span={8}>
                <p className="search-label">Endpoints and Endpoint Groups</p>
                <Select
                  placeholder="Select Endpoint And Endpoint Groups"
                  onChange={(e) => setType2(e)}
                  defaultValue={type2}
                >
                  <Option value="option1">Endpoints</Option>
                  <Option value="option2">Endpoint Groups</Option>
                  <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                  <Option value="option4">Endpoint Matching Wild Card</Option>
                  <Option value="option5">Apply To Whole Tenant</Option>
                </Select>
              </Col>
              {type2 === "option1" ? (
                <Col span={8}>
                  <p className="search-label">Endpoints List</p>
                  <Select
                    mode="multiple"
                    onChange={(e) => setEndpoints(e)}
                    placeholder="Select endpoints"
                  >
                    {epm &&
                      epm.length > 0 &&
                      epm.map((endpoint) => <Option value={endpoint.name}>{endpoint.name}</Option>)}
                  </Select>
                </Col>
              ) : (
                type2 === "option2" && (
                  <Col span={8}>
                    <p className="search-label">Endpoints Groups List</p>
                    <Select mode="multiple" onChange={(e) => setEndpointGroups(e)}>
                      {epmGroups &&
                        epmGroups.length > 0 &&
                        epmGroups.map((group) => (
                          <Option value={group.groupName}>{group.groupName}</Option>
                        ))}
                    </Select>
                  </Col>
                )
              )}
              {(type2 == "option3" || type2 == "option4") && (
                <Col span={8}>
                  <p className="search-label">Wild Card</p>
                  <Input
                    placeholder="prefix*"
                    onSearch={(value) => console.log(value)}
                    onChange={(e) => setEndpointWildCard(e.target.value)}
                  />
                </Col>
              )}
            </Row>
          </>

          {userType === "local" && (
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={8}>
                <p className="search-label">Endpoints Users</p>
                <Select
                  placeholder="Select Endpoint Users"
                  onChange={(e) => setType3(e)}
                  defaultValue={type3}
                >
                  <Option value="option1">Endpoint Users</Option>

                  <Option value="option3">Endpoint Users Matching Wild Card</Option>

                  <Option value="option5">Apply To Whole Tenant</Option>
                </Select>
              </Col>
              {type3 === "option1" ? (
                <Col span={8}>
                  <p className="search-label">Endpoint User List</p>
                  <Select
                    mode="multiple"
                    onChange={(e) => setEndpointUser(e)}
                    placeholder="Select endpoint users"
                  >
                    {epmUsers &&
                      epmUsers.length > 0 &&
                      epmUsers.map((user) => <Option value={user.user}>{user.user}</Option>)}
                  </Select>
                </Col>
              ) : (
                type3 === "option2" && (
                  <Col span={8}>
                    <p className="search-label">Endpoints Groups List</p>
                    <Select mode="multiple">
                      <Option value="option1">Label 1</Option>
                      <Option value="option2">Label 2</Option>
                      <Option value="option3">Label 3</Option>
                    </Select>
                  </Col>
                )
              )}
              {(type3 == "option3" || type3 == "option4") && (
                <Col span={8}>
                  <p className="search-label">Wild Card</p>
                  <Input
                    placeholder="prefix*"
                    onSearch={(value) => console.log(value)}
                    onChange={(e) => setEndpointUserWildCard(e.target.value)}
                  />
                </Col>
              )}
            </Row>
          )}
          {userType === "ad" && (
            <>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">Domain</p>
                  <Select
                    showSearch
                    placeholder="Search Domain"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e, val) => {
                      console.log(val);

                      setDomainVal(val);
                      const selectedDomain = domainData.find((domain) => domain.id === e);
                      if (selectedDomain) {
                        setOuData(
                          selectedDomain?.ous?.map((data) => {
                            let ouVal = data.OuName.split(",")[0];
                            let nameOu = ouVal.substring(3);
                            return data;
                          })
                        );

                        fetchAdUser({ adDomainId: parseInt(e) });
                      } else {
                        setOuData([]);
                      }
                      // console.log(e, val);
                    }}
                  >
                    {domainData?.map((dom) => (
                      <Option key={dom.id} value={dom.id}>
                        {dom.directoryName}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={8}>
                  <p className="search-label">Ou</p>
                  <Select
                    showSearch
                    placeholder="Search OU"
                    optionFilterProp="children"
                    onChange={(e, val) => {
                      setOu(val);
                      console.log(val);
                      let ouDn = {
                        adDomainId: parseInt(val.key),
                        adOuId: e,
                      };
                      fetchAdUser(ouDn);
                    }}
                  >
                    {ouData?.map((ou) => (
                      <Option key={ou.AdId} value={ou.Id}>
                        {ou.OuName}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">
                    {userType === "local" ? "Directory Users" : "Directory Users and Groups"}
                  </p>
                  {userType === "ad" ? (
                    <Select
                      placeholder="Select User And Groups"
                      onChange={(e) => setType(e)}
                      defaultValue={type}
                    >
                      <Option value="option1">Directory Users </Option>
                      <Option value="option2">Directory Groups</Option>
                      <Option value="option3">Groups Matching Wild Card</Option>
                      <Option value="option4">Users Matching Wild Card</Option>
                      <Option value="option5">Apply To Whole Tenant</Option>
                    </Select>
                  ) : (
                    <Select
                      placeholder="Select User"
                      onChange={(e) => setType(e)}
                      defaultValue={type}
                    >
                      <Option value="option1">Directory Users </Option>
                      <Option value="option4">Users Matching Wild Card</Option>
                    </Select>
                  )}
                </Col>
                {type === "option1" ? (
                  <Col span={8}>
                    <p className="search-label">Directory User List</p>
                    <Select
                      showSearch
                      placeholder="Select directory user"
                      mode={userType === "ad" && "multiple"}
                      onChange={(e) => {
                        if (userType === "ad") {
                          handleADUser(e);
                        } else {
                          setAdUsersVal(e);
                        }
                      }}
                      value={userType === "ad" ? directoryUser : AdUsersVal}
                    >
                      {adUser && adUser.length > 0 && (
                        <>
                          {userType !== "ad" ? (
                            <>
                              {" "}
                              {adUser.map((user) => (
                                <Option key={user.Id} value={user.UserName}>
                                  {user.UserName}
                                </Option>
                              ))}
                            </>
                          ) : (
                            <>
                              <Option value="all">Select All</Option>
                              {adUser.map((user) => (
                                <Option key={user.Id} value={user.UserName}>
                                  {user.UserName}
                                </Option>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </Select>
                  </Col>
                ) : (
                  type === "option2" && (
                    <Col span={8}>
                      <p className="search-label"> Directory Groups List</p>
                      <Select mode="multiple" onChange={(e) => setDirectoryGroups(e)}>
                        {credentialData &&
                          credentialData.length > 0 &&
                          credentialData.map((user) => (
                            <Option value={user.name}>{user.name}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type == "option3" || type == "option4") && (
                  <Col span={8}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setUserWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
          <h3 style={{ color: "blue", marginTop: "2rem" }}>Policy applicable to</h3>

          <Row gutter={16} style={{ marginTop: "1rem" }}>
            <Col span={8}>
              {/* <p className="search-label">Wallet Users</p> */}
              <Select
                mode="multiple"
                onChange={(e) => setWalletUserVal(e)}
                placeholder="Select wallet users"
              >
                {walletData?.map((user) => (
                  <Option value={user.emailAddress}>{user.emailAddress}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <h3 style={{ color: "blue", marginTop: "2rem" }}>Select the policy action</h3>
          <Row>
            <Col span={8}>
              {/* <p className="search-label">Policy Action</p> */}
              <Select onChange={(e) => setActionVal(e)} defaultValue={actionVal}>
                <Option value="Allow" success>
                  Allow
                </Option>
                <Option value="Deny" danger>
                  Deny
                </Option>
              </Select>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: "1rem" }}>
            <Col>
              <Checkbox onChange={(e) => console.log(e)}>
                Users can login with their SAM accountname
              </Checkbox>
            </Col>
          </Row> */}
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2rem",
              }}
              span={16}
            >
              <Button type="primary" onClick={() => handleFinish()}>
                Create Policy
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreatePolicyV2;
