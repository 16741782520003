import {
  LoadingOutlined,
  LockOutlined,
  PlusOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateApp = () => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const CheckboxGroup = Checkbox.Group;

  const handleFinish = (values) => {
    console.log("Received values of form: ", values);
    const loginData = {
      ...values,
    };
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  useEffect(() => {
    form.setFieldsValue({
      auth_type: "One-Factor Authentication",
    });
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <h2 className="title">Create App</h2>

          <Form
            form={form}
            name="create_app"
            className="login-forms"
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            onFinish={handleFinish}
          >
            <Form.Item
              name="appname"
              label="App Name"
              rules={[
                {
                  required: true,
                  message: "Please input your App name",
                },
              ]}
            >
              <Input size="large" placeholder="appname" />
            </Form.Item>

            <Form.Item
              name="url"
              label="Redirect URL"
              rules={[
                {
                  required: true,
                  message: "Please input your redirect URL",
                },
              ]}
            >
              <Input size="large" placeholder="https://www.example.com/" />
            </Form.Item>
            <Form.Item
              label="Authentication Type"
              name="auth_type"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Select Authentication type",
                },
              ]}
            >
              <CheckboxGroup options={["One-Factor Authentication", "Two-Factor Authentication"]} />
            </Form.Item>
            <Form.Item
              name="upload"
              label="Upload Logo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please input your App name",
                },
              ]}
            >
              <Upload name="logo" listType="picture-card" maxCount={1} onPreview={handlePreview}>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}{" "}
              </Upload>
            </Form.Item>
            {/* <Form.Item label="Radio">
          <Radio.Group>
            <Radio value="apple"> Apple </Radio>
            <Radio value="pear"> Pear </Radio>
          </Radio.Group>
        </Form.Item> */}

            <div className="login-form-button">
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                  Create App
                </Button>
                <br />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateApp;
