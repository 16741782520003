import { Col, Row, Table, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const EndpointDetailsAuthFlow = ({ stateHostAddress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isInstanceId = queryParams.get("instanceId");
  const [notification, setNotification] = message.useMessage();
  const [url, setCodeData] = useState("");
  const [factors, setFactors] = useState([]);
  const [isOpenPamCode, setIsOpenPamCode] = useState(false);
  const [sortModel, setSortModel] = useState();

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const { instanceId } = location?.state || {};

  useEffect(() => {
    fetchPamCode();
  }, []);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const tab3columns = [
    {
      headerName: "First Factor",
      field: "first",
      key: "first",
      flex: 1,
    },
    {
      headerName: "Second Factor",
      field: "secound",
      key: "secound",
      flex: 1,
    },
    {
      headerName: "Third Factor",
      field: "third",
      key: "third",
      flex: 1,
    },

    {
      headerName: "PAM code",
      field: "pamCode",
      key: "View",
      flex: 1,
      renderCell: (data, record) =>
        record && record.data && <Link onClick={() => setIsOpenPamCode(true)}>View</Link>,
    },
  ];

  const fetchPamCode = () => {
    let requestData = {
      instanceId: Number(instanceId ? instanceId : stateHostAddress.instanceId),
      domainId: DOMAIN_ID(),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/viewGeneratedPamCode`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let dataFac = res.data.Factors;
        // Filter out rows with null or empty values
        const filteredData = dataFac.filter((data) => data.first || data.secound || data.third);

        setFactors(filteredData.map((data, i) => ({ id: i + 1, ...data })));

        // let orgData = [];

        // orgData.push({
        //   first: dataFac[0],
        //   secound: dataFac[1],
        //   third: dataFac[2] ? dataFac[2] : "",
        // });
        // setFactors(orgData.map((data, i) => ({ id: i + 1, ...data })));

        let data = res?.data?.Code ? res?.data?.Code : "";
        setCodeData(data?.split("\n").join("\n").replace(/\t/g, " ").replace(/ {2,}/g, " "));
        setTotalCount(res?.data?.totalGroups);
        setIsLoading(false);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        }
        notification.open({
          type: "error",
          content: "Unable to fetch PAM Code",
        });
      });
  };

  const downloadPamCode = (text) => {
    var fileName = "PAM.SO";
    var fileContent = text;
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={factors}
                columns={tab3columns}
                paginationMode="server"
                rowCount={totalCount}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpenPamCode && (
        <div className="video-container">
          <Modal
            headerName="View PAM Code"
            centered
            open={isOpenPamCode}
            onOk={() => setIsOpenPamCode(false)}
            onCancel={() => setIsOpenPamCode(false)}
            width={950}
            footer={null}
          >
            <Row style={{ marginTop: "30px" }}>
              <Col span={24}>
                <TextArea
                  prefix={<CopyOutlined />}
                  style={{
                    background: "#fff",
                    color: "#000",
                    padding: "20px 10px",
                  }}
                  disabled
                  value={url}
                  autoSize={{
                    minRows: 3,
                    maxRows: 8,
                  }}
                />
              </Col>

              <Col style={{ marginTop: "50px" }}>
                <a class="link2" onClick={() => downloadPamCode(url)}>
                  Download PAM.SO files.
                </a>
              </Col>
            </Row>
          </Modal>
        </div>
      )}
    </>
  );
};

export default EndpointDetailsAuthFlow;
