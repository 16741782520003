import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Tabs,
  Breadcrumb,
} from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ServiceAssignPermissions from "./ServiceAssignPermissions";
import ServiceWalletAssignment from "./ServiceWalletAssignment";
import ReactJoyride from "react-joyride";

const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;
const AddServiceAccount = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState("1");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ishistory = queryParams.get("history");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [assignData, setAssignData] = useState();
  const onBoardType = queryParams.get("type");
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);
  const handleAssignPermissionData = (data) => {
    console.log(data);
    // Do something with the data in the parent component
    handleTab("2");
    setAssignData(data);
  };
  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsOnboarding(onBoardType ? true : false);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: <span>Enter a unique policy name</span>,
        disableBeacon: true,
        // title: "MFA Device",
      },
      {
        target: "#onboarding_step2",
        content: <span>Select either multiple Endpoints or Endpoint Groups</span>,
        disableBeacon: true,
        // title: "Endpoint Authentication MFA",
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };
  useEffect(() => {
    handleTab();
  }, []);

  const handleTab = (key) => {
    if (ishistory) {
      setCurrentTab("3");
    } else {
      setCurrentTab(key);
    }
  };

  const items = [
    {
      key: "1",
      label: "Assign Permissions",

      children: (
        <ServiceAssignPermissions
          id="onboarding_step1"
          handleAssignPermissionData={handleAssignPermissionData}
        />
      ),
    },
    {
      key: "2",
      label: "Wallet Assignment",
      children: <ServiceWalletAssignment id="onboarding_step2" assignData={assignData} />,
    },
  ];

  return (
    <>
      {/* {isOnBoarding && startTour()} */}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  href: "",
                  title: <HomeOutlined />,
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Service Account</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Add Service Account",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`Add Service Account`} </h2>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                activeKey={currentTab}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddServiceAccount;
