import {
  Button,
  Col,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { Link } from "react-router-dom";

const { Option } = Select;
const ApproveRequest = ({
  selectedRow,
  setIsModalVisible,
  isModalVisible,
  epm,
  domainData,
  epmGroups,
  endpointGroups,
  notification,
  requestData,
  fetchRequest,
}) => {
  const [type, setType] = useState("option1");
  const [type2, setType2] = useState("option1");
  const [type3, setType3] = useState("option1");
  const [policyType, setPolicyType] = useState(
    (selectedRow?.logType.toLowerCase() === "ad" && "ad") ||
      (selectedRow?.logType.toLowerCase() === "epm" && "local")
  );
  const [endpointsval, setEndpointsval] = useState([selectedRow?.destinationEndpoint]);
  const [epmGroupVal, setEpmGroupVal] = useState([]);
  const [endpointWildCard, setEndpointWildCard] = useState();
  const [endpointUser, setEndpointUser] = useState([selectedRow?.username]);
  const [endpointUserWildCard, setEndpointUserWildCard] = useState();
  const [adUsersVal, setAdUsersVal] = useState(
    selectedRow?.logType === "EPM" && selectedRow?.walletUser
  );
  const [os, setOs] = useState(
    (selectedRow?.os.toLowerCase() === "windows" && "Windows") ||
      (selectedRow?.os.toLowerCase() === "linux" && "Linux")
  );
  const [protocol, setProtocol] = useState(selectedRow?.protocol);
  const [domainVal, setDomainVal] = useState(selectedRow?.adDomain);
  const [ou, setOu] = useState(selectedRow?.adOu);
  const [directoryGroups, setDirectoryGroups] = useState([]);
  const [directoryUser, setDirectoryUser] = useState(selectedRow?.adUser?.split(","));
  const [userWildCard, setUserWildCard] = useState();
  const [policyName, setPolicyName] = useState(selectedRow?.policyName);
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkPolicyNotification, setCheckPolicyNotification] = useState(false);
  const [actionVal, setActionVal] = useState(selectedRow?.action ? selectedRow?.action : "Allow");
  const [WalletData, setWalletData] = useState([]);
  const [walletUserVal, setWalletUserVal] = useState([selectedRow?.walletUser]);
  const [adUser, setAdUser] = useState([]);
  const [ouData, setOuData] = useState([]);

  const handleADUser = (val) => {
    if (directoryUser.length > 0 && directoryUser.includes("all")) {
      // If "all" was previously selected and is now deselected, remove it from the array
      const filteredValues = val.filter((item) => item !== "all");
      setDirectoryUser(filteredValues);
    } else if (val.includes("all")) {
      // If "all" is selected, reset the directoryUser array
      setDirectoryUser(["all"]);
    } else {
      setDirectoryUser(val);
    }
  };

  useEffect(() => {
    fetchGrp();
    fetchEndpointUser();
    fetchWallets();
    generateDynamicPolicyName("", 36);
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const validateForm = () => {
    let checkEndpointVal =
      type2 === "option1"
        ? !endpointsval.length
        : type2 === "option2"
        ? !epmGroupVal.length
        : (type2 === "option3" || type2 === "option4") && !endpointWildCard;
    let checkEndPointUserVal =
      type3 === "option1"
        ? !endpointUser.length
        : type3 === "option2"
        ? !epmGroupVal.length
        : (type3 === "option3" || type3 === "option4") && !endpointUserWildCard;
    if (selectedRow?.logType.toLowerCase() === "ad") {
      if (checkEndpointVal || !directoryUser.length) {
        message.error("Please fill in all the fields.");
        return false;
      }
      return true;
    }
    if (selectedRow?.logType.toLowerCase() === "epm") {
      if (checkEndpointVal || checkEndPointUserVal) {
        console.log("akjn1");
        message.error("Please fill in all the fields.");
        return false;
      }

      // Additional validation checks based on your specific requirements

      return true;
    }
  };
  const [adGroups, setAdGroups] = useState([]);
  const [epmUsers, setEpmUsers] = useState([]);
  const fetchEndpointUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setEpmUsers(data.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            name: grp.groupName,

            ...grp,
          }));
        }
        setAdGroups(Data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const fetchWallets = async () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_API_DID_URL}/walletService/walletUserList`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      let walletData = res?.data?.users?.map((user, i) => ({
        id: user.walletId,
        text: user.emailAddress,
      }));
      setWalletData(res?.data?.users);
    } catch (err) {
      if (err.response.status == 401) {
        logOutUser();
      }
    }
  };
  const handleReject = () => {
    if (!validateForm()) {
      return;
    }
    notification.open({
      type: "success",
      content: `${
        selectedRow?.logType.toLowerCase() === "ad" ? selectedRow?.adUser : selectedRow?.walletUser
      } policy rejected successfully`,
    });
    setTimeout(() => {
      setIsModalVisible(false);
    }, 3000);
  };
  const handleInreview = () => {
    if (!validateForm()) {
      return;
    }
    notification.open({
      type: "success",
      content: `${
        selectedRow?.logType.toLowerCase() === "ad" ? selectedRow?.adUser : selectedRow?.walletUser
      } policy inreview successfully`,
    });
    setTimeout(() => {
      setIsModalVisible(false);
    }, 3000);
  };
  const handleFinish = () => {
    if (!validateForm()) {
      return;
    }
    let tempUserSelectionType;
    let tempMatch;
    let tempSelection;
    let tempEndpointSelectionType;
    let tempMatch2;
    let tempSelection2;
    let tempEndpointUserSelectionType = "user";
    let tempMatch3;
    let tempSelection3;
    if (type === "option1" || type === "option4") {
      tempUserSelectionType = "user";
    } else if (type === "option2" || type === "option3") {
      tempUserSelectionType = "groups";
    } else {
      tempUserSelectionType = "tenant";
    }
    if (type === "option1" || type === "option2" || type === "option5") {
      tempMatch = "exact";
    } else {
      tempMatch = "wild";
    }
    if (type === "option1") {
      tempSelection = adUsersVal ? [adUsersVal] : directoryUser;
    } else if (type === "option2") {
      tempSelection = directoryGroups;
    } else if (type === "option5") {
      tempSelection = ["all"];
    } else {
      tempSelection = userWildCard;
    }

    if (type2 === "option1" || type2 === "option4") {
      tempEndpointSelectionType = "endpoint";
    } else if (type2 === "option2" || type2 === "option3") {
      tempEndpointSelectionType = "group";
    } else {
      tempEndpointSelectionType = "tenant";
    }

    if (type2 === "option1" || type2 === "option2" || type2 === "option5") {
      tempMatch2 = "exact";
    } else {
      tempMatch2 = "wild";
    }
    if (type2 === "option1") {
      tempSelection2 = endpointsval;
    } else if (type2 === "option2") {
      tempSelection2 = epmGroupVal;
    } else if (type === "option5") {
      tempSelection2 = ["all"];
    } else {
      tempSelection2 = endpointWildCard;
    }

    if (type3 === "option5") {
      tempEndpointUserSelectionType = "tenant";
      tempSelection3 = ["all"];
    }

    if (type3 === "option1" || type3 === "option5") {
      tempMatch3 = "exact";
    } else {
      tempMatch3 = "wild";
    }
    if (type3 === "option1") {
      tempSelection3 = endpointUser;
    } else if (type === "option5") {
      tempSelection3 = ["all"];
    } else {
      tempSelection3 = endpointUserWildCard;
    }

    let data = {
      orgId: ORG_ID(),
      action: actionVal,
      tenantId: DOMAIN_ID(),
      policyName: policyName,
      policyType: policyType === "Active Directory" ? "ad" : policyType,
      protocol: [protocol],
      os: "",
      adOu: ou?.children,
      adDomain: domainVal?.children,
      sAMAccountName: false,
      userOrGroups: {
        selectionType: tempUserSelectionType,
        selectionValue: tempSelection,
        match: tempMatch,
      },
      endpointOrGroups: {
        source: {
          match: "exact",
          selectionType: "",
          selectionValue: [],
        },
        destination: {
          selectionType: tempEndpointSelectionType,
          selectionValue: tempSelection2,
          match: tempMatch2,
        },
      },
      endpointUsers: {
        selectionType: tempEndpointUserSelectionType,
        selectionValue: tempSelection3,
        match: tempMatch3,
      },
      walletUsers: walletUserVal,
      adDomainId: domainVal?.value,
      adOuId: ou?.value,
    };

    setBtnLoading(true);
    axios
      .post(
        `${REACT_APP_API_DID_URL}/policyService/IsPolicyExists`,
        { ...data, update: 1 },
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setBtnLoading(false);
        console.log(res);
        if (res.data.isPolicyExists == true) {
          setCheckPolicyNotification(true);
        } else {
          handleApprovePolicy(data);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to create now, PLease try again",
          });
        }
      });
  };

  const fetchAdUser = (ouDn) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-users",
      ...ouDn,
      // adDomainId: 4,
      // adOuId: 12,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data.data.adUsers);
        setAdUser(data.data.adUsers);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };

  const handleApprovePolicy = (data) => {
    console.log(selectedRow);
    let archive = {
      orgId: selectedRow?.orgId,
      tenantId: selectedRow?.tenantId,
      logType: selectedRow?.logType,
      os: selectedRow?.os,
      adUser: selectedRow?.adUser,
      adDomain: selectedRow?.adDomain,
      adOu: selectedRow?.adOu,
      sourceEndpoint: selectedRow?.sourceEndpoint,
      destinationEndpointIp: selectedRow?.destinationEndpointIp,
      username: selectedRow?.username,
      accessMode: selectedRow?.accessMode,
      protocol: selectedRow?.protocol,
      timestamp: selectedRow?.timestamp,
      loginStatus: selectedRow?.loginStatus,
      samAccountName: selectedRow?.samAccountName,
      destinationEndpoint: selectedRow?.destinationEndpoint,
      platformUser: selectedRow?.walletUser,
      requestCount: selectedRow?.requestCount,
      walletUser: selectedRow?.walletUser,
    };
    console.log(archive);
    let payload = {
      ...data,
      source: "request",
      archive: archive,
    };

    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/CreatePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: `${
            selectedRow?.logType.toLowerCase() === "ad"
              ? selectedRow?.adUser
              : selectedRow?.walletUser
          } policy approved successfully`,
        });
        setTimeout(() => {
          setIsModalVisible(false);
          fetchRequest();
        }, 3000);
        requestData();
      })

      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: `Unable to approve ${policyName} policies`,
        });
      });
  };
  // Function to generate a dynamic name based on a template
  const generateDynamicPolicyName = (prefix, length) => {
    const characters = "0123456789";
    let result = prefix; // Fixed part

    const sections = [7, 8]; // Length of each hyphen-separated section
    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      if (sectionLength !== 8) {
        // Add hyphen except for the last section
        result += "-";
      }
    }
    return setPolicyName(result);
  };
  return (
    <>
      <Modal
        title={
          <span style={{ fontSize: 18 }}>
            Creating new policy for{" "}
            <span style={{ color: "#1677FF" }}>
              {` ${
                selectedRow?.logType.toLowerCase() === "ad"
                  ? selectedRow?.adUser
                  : selectedRow?.walletUser
              }`}
            </span>
          </span>
        }
        footer={[
          <Popconfirm
            placement="topRight"
            title={
              <span>
                Are you sure to in review this request <br /> for{" "}
                {`${
                  selectedRow?.logType.toLowerCase() === "ad"
                    ? selectedRow?.adUser
                    : selectedRow?.walletUser
                } ?`}
              </span>
            }
            onConfirm={handleInreview}
            onCancel={(e) => console.log(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button key="submit" type="default">
              Policy In Review
            </Button>
          </Popconfirm>,
          <Popconfirm
            placement="topRight"
            title={
              <span>
                Are you sure to approve this request <br /> for{" "}
                {`${
                  selectedRow?.logType.toLowerCase() === "ad"
                    ? selectedRow?.adUser
                    : selectedRow?.walletUser
                } ?`}
              </span>
            }
            onConfirm={handleFinish}
            onCancel={(e) => console.log(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              key="submit"
              type="primary"
              // onClick={handleExpandSave}
              // style={{ background: "#52C41A" }}
              loading={btnLoading}
            >
              Activate
            </Button>
          </Popconfirm>,
        ]}
        width={900}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="authModal"
      >
        <Row className="content-conatiner">
          {checkPolicyNotification && (
            <Modal
              title={`Policy already exists for this combination, Please Create with some other combination`}
              open={checkPolicyNotification}
              onOk={() => {
                // handleApprovePolicy();
                setCheckPolicyNotification(false);
              }}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  loading={btnLoading}
                  onClick={() => setCheckPolicyNotification(false)}
                >
                  Ok
                </Button>,
              ]}
              centered
              closeIcon={false}
            ></Modal>
          )}

          <Col span={24}>
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={12}>
                <p className="search-label">Policy Name</p>
                <Input value={policyName} onChange={(e) => setPolicyName(e.target.value)} />
              </Col>
              <Col span={12}>
                <p className="search-label">Policy Type</p>
                <Select
                  showSearch
                  placeholder="Search Policy Type"
                  optionFilterProp="children"
                  defaultValue={policyType}
                  onChange={(e) => {
                    setPolicyType(e);
                  }}
                  disabled
                >
                  <Option value="ad">Active Directory</Option>
                  <Option value="local">Local Users</Option>
                </Select>
              </Col>
            </Row>
            <h3 style={{ color: "blue", marginTop: "2rem" }}>Resources that can be accessed</h3>

            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={12}>
                <p className="search-label">Endpoints and Endpoint Groups</p>
                <Select
                  placeholder="Select Endpoint And Endpoint Groups"
                  onChange={(e) => setType2(e)}
                  defaultValue={type2}
                >
                  <Option value="option1">Endpoints</Option>
                  <Option value="option2">Endpoint Groups</Option>
                  <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                  <Option value="option4">Endpoint Matching Wild Card</Option>
                  <Option value="option5">Apply To Whole Tenant</Option>
                </Select>
              </Col>
              {type2 === "option1" ? (
                <Col span={12}>
                  <p className="search-label">Endpoints List</p>
                  <Select
                    mode="multiple"
                    onChange={(e) => setEndpointsval(e)}
                    defaultValue={endpointsval}
                  >
                    {epm &&
                      epm.length > 0 &&
                      epm.map((endpoint) => (
                        <Option value={endpoint.hostName}>
                          {/* {endpoint.name} */}
                          {endpoint?.hostName} <b>{`(${endpoint?.publicIp})`}</b>
                        </Option>
                      ))}
                  </Select>
                </Col>
              ) : (
                type2 === "option2" && (
                  <Col span={12}>
                    <p className="search-label">Endpoints Groups List</p>
                    <Select mode="multiple" onChange={(e) => setEpmGroupVal(e)}>
                      {endpointGroups &&
                        endpointGroups.length > 0 &&
                        endpointGroups.map((group) => (
                          <Option value={group.groupName}>{group.groupName}</Option>
                        ))}
                    </Select>
                  </Col>
                )
              )}
              {(type2 == "option3" || type2 == "option4") && (
                <Col span={12}>
                  <p className="search-label">Wild Card</p>
                  <Input
                    placeholder="prefix*"
                    onSearch={(value) => console.log(value)}
                    onChange={(e) => setEndpointWildCard(e.target.value)}
                  />
                </Col>
              )}
            </Row>

            {policyType === "local" && (
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <p className="search-label">Endpoints Users</p>
                  <Select
                    placeholder="Select Endpoint Users"
                    onChange={(e) => setType3(e)}
                    defaultValue={type3}
                  >
                    <Option value="option1">Endpoint Users</Option>

                    <Option value="option3">Endpoint Users Matching Wild Card</Option>

                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type3 === "option1" ? (
                  <Col span={12}>
                    <p className="search-label">Endpoint User List</p>
                    <Select
                      mode="multiple"
                      onChange={(e) => setEndpointUser(e)}
                      defaultValue={endpointUser}
                    >
                      {epmUsers &&
                        epmUsers.length > 0 &&
                        epmUsers.map((user) => <Option value={user.user}>{user.user}</Option>)}
                    </Select>
                  </Col>
                ) : (
                  type3 === "option2" && (
                    <Col span={12}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple" onChange={(e) => setEpmGroupVal(e)}>
                        {epmGroups &&
                          epmGroups.length > 0 &&
                          epmGroups.map((group) => (
                            <Option value={group.groupName}>{group.groupName}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type3 == "option3" || type3 == "option4") && (
                  <Col span={12}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setEndpointUserWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            )}
            {/* <h3 style={{ color: "blue", marginTop: "2rem" }}>User Information</h3> */}
            {policyType === "ad" && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <p className="search-label">Domain</p>
                    <Select
                      showSearch
                      placeholder="Search Domain"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      defaultValue={domainVal}
                      onChange={(e, val) => {
                        setDomainVal(val);
                        const selectedDomain = domainData.find((domain) => domain.id === e);
                        if (selectedDomain) {
                          setOuData(
                            selectedDomain?.ous?.map((data) => {
                              let ouVal = data.OuName.split(",")[0];
                              let nameOu = ouVal.substring(3);
                              return data;
                            })
                          );
                          fetchAdUser({ adDomainId: parseInt(e) });
                        } else {
                          setOuData([]);
                        }
                        // console.log(e, val);
                      }}
                    >
                      {domainData?.map((dom) => (
                        <Option key={dom.id} value={dom.id}>
                          {dom.directoryName}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <p className="search-label">Ou</p>
                    <Select
                      showSearch
                      placeholder="Search OU "
                      optionFilterProp="children"
                      onChange={(e, val) => {
                        setOu(val);
                        console.log(val);
                        let ouDn = {
                          adDomainId: parseInt(val.key),
                          adOuId: e,
                        };
                        fetchAdUser(ouDn);
                      }}
                      defaultValue={ou}
                    >
                      {ouData?.map((ou) => (
                        <Option key={ou.AdId} value={ou.Id}>
                          {ou.OuName}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <p className="search-label">Directory Users and Groups</p>
                    <Select
                      placeholder="Select User And Groups"
                      onChange={(e) => setType(e)}
                      defaultValue={type}
                    >
                      <Option value="option1">Directory Users </Option>
                      {policyType !== "local" && (
                        <>
                          <Option value="option2">Directory Groups</Option>
                          <Option value="option3">Groups Matching Wild Card</Option>
                          <Option value="option4">Users Matching Wild Card</Option>
                        </>
                      )}
                      <Option value="option5">Apply To Whole Tenant</Option>
                    </Select>
                  </Col>
                  {type === "option1" ? (
                    <Col span={12}>
                      <p className="search-label">Directory User List</p>
                      {policyType === "local" ? (
                        <Select
                          placeholder="Select directory user"
                          onChange={(e) => setAdUsersVal(e)}
                          defaultValue={adUsersVal}
                          showSearch
                        >
                          {adUser && adUser.length > 0 && (
                            <>
                              {adUser.map((user) => (
                                <Option key={user.UserName} value={user.UserName}>
                                  {user.UserName}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      ) : (
                        <Select
                          placeholder="Select directory user"
                          mode="multiple"
                          onChange={handleADUser}
                          defaultValue={directoryUser}
                        >
                          {adUser && adUser.length > 0 && (
                            <>
                              <Option value="all">Select All</Option>
                              {adUser.map((user) => (
                                <Option key={user.UserName} value={user.UserName}>
                                  {user.UserName}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      )}
                    </Col>
                  ) : (
                    type === "option2" && (
                      <Col span={12}>
                        <p className="search-label"> Directory Groups List</p>
                        <Select onChange={(e) => setDirectoryGroups(e)} mode="multiple">
                          {adGroups &&
                            adGroups.length > 0 &&
                            adGroups.map((user) => <Option value={user.name}>{user.name}</Option>)}
                        </Select>
                      </Col>
                    )
                  )}
                  {(type == "option3" || type == "option4") && (
                    <Col span={12}>
                      <p className="search-label">Wild Card</p>
                      <Input
                        placeholder="prefix*"
                        onSearch={(value) => console.log(value)}
                        onChange={(e) => setUserWildCard(e.target.value)}
                      />
                    </Col>
                  )}
                </Row>

                {/* <Row gutter={16}>
              <Col span={12}>
                {policyType === "ad" && (
                  <>
                    <p className="search-label">Directory Users</p>

                    <Select
                      placeholder="Select directory user"
                      mode="multiple"
                      onChange={handleADUser}
                      defaultValue={selectedRow?.adUser}
                    >
                      {adUser && adUser.length > 0 && (
                        <>
                          <Option value="all">Select All</Option>
                          {adUser.map((user) => (
                            <Option key={user.emailAddress} value={user.emailAddress}>
                              {user.emailAddress}
                            </Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </>
                )}
                {policyType === "local" && (
                  <>
                    <p className="search-label">Wallet Users</p>
                    <Select
                      placeholder="Select User"
                      onChange={(e) => setWalletUserVal(e)}
                      defaultValue={walletUserVal}
                      mode="multiple"
                    >
                      {WalletData && WalletData.length > 0 && (
                        <>
                          {WalletData.map((user) => (
                            <Option key={user.text} value={user.text}>
                              {user.text}
                            </Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </>
                )}
              </Col>{" "}
              {policyType === "ad" && (
                <Col span={12}>
                  <p className="search-label">Wallet Users</p>
                  <Select
                    placeholder="Select User"
                    onChange={(e) => setWalletUserVal(e)}
                    defaultValue={walletUserVal}
                    mode="multiple"
                  >
                    {WalletData && WalletData.length > 0 && (
                      <>
                        {WalletData.map((user) => (
                          <Option key={user.text} value={user.text}>
                            {user.text}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Col>
              )}
            </Row> */}
              </>
            )}
            <h3 style={{ color: "blue", marginTop: "2rem" }}>Policy applicable to</h3>

            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={12}>
                {/* <p className="search-label">Wallet Users</p> */}
                <Select
                  mode="multiple"
                  onChange={(e) => setWalletUserVal(e)}
                  placeholder="Select wallet users"
                  defaultValue={walletUserVal}
                >
                  {WalletData?.map((user) => (
                    <Option value={user?.emailAddress}>{user?.emailAddress}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <h3 style={{ color: "blue", marginTop: "2rem" }}>Select the policy action</h3>

            <Row>
              <Col span={12}>
                {/* <p className="search-label">Policy Action</p> */}
                <Select onChange={(e) => setActionVal(e)} defaultValue={actionVal}>
                  <Option value="Allow">Allow</Option>
                  <Option value="Deny">Deny</Option>
                </Select>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col span={12}>
                <p className="search-label">Policy Action</p>
                <Select onChange={(e) => setActionVal(e)} defaultValue={actionVal}>
                  <Option value="Allow">Allow</Option>
                  <Option value="Deny">Deny</Option>
                </Select>
              </Col>
            </Row> */}

            {/* <Row justify="end" style={{ marginTop: "1rem" }}>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
                span={4}
                // offset={22}
              >
                
                
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ApproveRequest;
