import React, { useEffect, useState } from "react";
import { Col, Row, Tag, Select, notification, message, Typography } from "antd";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../constants";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";

const { Option } = Select;
const { Text } = Typography;

const Request = () => {
  const [sortModel, setSortModel] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 100,
    page: 0,
  });
  const [appliedFilters, setAppliedFilters] = useState([
    { filterParameter: "logType", filterValue: "AD" },
    { filterParameter: "walletUser", filterValue: localStorage.getItem("UserName") },
  ]);
  const [endpointData, setEndpointData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [ouData, setOuData] = useState([]);
  const [isNotification, setNotification] = message.useMessage();
  const [userType, setUserType] = useState("AD");
  useEffect(() => {
    fetchRequest();
  }, [appliedFilters, sortModel, userType]);
  useEffect(() => {
    listDomain();
    fetchTenantDetails();

    // Clean up the message when the component unmounts
    return () => {
      message.destroy("permanentMessage");
    };
  }, []);
  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    }).then((res) => {
      const PolicyType = res.data.data.authenticationpolicy;
      message.open({
        type: PolicyType === 1 ? "info" : PolicyType === 2 ? "warning" : "success",
        content: `Tenant has policies in ${
          PolicyType === 1 ? "audit" : PolicyType === 2 ? "policy" : "live"
        } mode`,
        key: "permanentMessage",
        duration: 0, // Set duration to 0 for a permanent message
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        },
      });
    });
  };
  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        setDomainData(inputArray);
      });
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpointData(res?.data?.instances);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          // logOutUser();
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchRequest = (page) => {
    let REQUEST_HEADER;
    let requestPayload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyType: userType ? userType : "AD",
      filters: appliedFilters ? appliedFilters : [],
      requestId: "",
      page: page,
      limit: currentPageModal.pageSize,
      sort: {
        field: sortModel ? sortModel[0]?.field : "",
        order: sortModel ? sortModel[0]?.sort : "",
      },
    };
    if (appliedFilters.length !== 0) {
      REQUEST_HEADER = `${REACT_APP_API_DID_URL}/policyService/FilterAccessRequest`;
    } else {
      REQUEST_HEADER = `${REACT_APP_API_DID_URL}/policyService/AccessRequest`;
    }
    axios
      .post(REQUEST_HEADER, requestPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then(({ data }) => {
        setRequestData(
          data?.data?.map((res, i) => ({
            id: i + 1,
            ...res,
          }))
        );
        setTotalCount(data.total_pages * 100);
        setCurrentPageModal({
          pageSize: 100,
          page: data.page - 1,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCurrentOption = (type) => {
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const handleApplyFilter = (filter, value) => {
    let filterParameter = filter;
    let filterValue = value;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    setAppliedFilters(uniqueFilters);
  };

  const handleRemoveFilter = (filter, value) => {
    if (filter === "logType") {
      setUserType("AD");
    }

    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };
  const columns = [
    userType === "AD"
      ? {
          headerName: "Directory User",
          field: "adUser",
          flex: 2,

          sortable: true,
          renderCell: (val) => {
            const tagsArray = val?.row?.adUser?.split(",");

            return (
              <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
                {tagsArray?.map((tag, index) => (
                  <Tag key={index} onClick={() => handleApplyFilter("username", tag)}>
                    {tag.trim()}
                  </Tag>
                ))}
              </div>
            );
          },
        }
      : {
          headerName: "Login User",
          field: "username",
          flex: 1.6,

          sortable: true,
          renderCell: (val) => {
            const tagsArray = val?.row?.username?.split(",");

            return (
              <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
                {tagsArray?.map((tag, index) => (
                  <Tag key={index} onClick={() => handleApplyFilter("username", tag)}>
                    {tag.trim()}
                  </Tag>
                ))}
              </div>
            );
          },
        },

    {
      headerName: "Destination Hostname",
      field: "destinationEndpoint",

      flex: 2.5,

      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.destinationEndpoint?.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray?.map((tag, index) => (
              <Tag
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => handleApplyFilter("destinationEndpoint", tag)}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Destination IP",
      field: "destinationEndpointIp",
      flex: 2,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.destinationEndpointIp?.split(",");
        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray?.map((tag, index) => (
              <Tag
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => handleApplyFilter("destinationEndpointIp", tag)}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },

    {
      headerName: "Policy Action",
      field: "loginStatus",
      flex: 1.6,

      renderCell: (value) => {
        let data = value.row.loginStatus.toLowerCase();
        return (
          <>
            <Text type={data === "success" ? "success" : data === "failure" ? "danger" : ""}>
              {" "}
              {data === "success" ? "Approved" : data === "failure" ? "Denied" : ""}
            </Text>
          </>
        );
      },
    },
    {
      headerName: "Auth Count",
      field: "requestCount",
      flex: 1.6,

      sortable: true,
      renderCell: (val) => (
        <>
          <span>{val?.row?.requestCount}</span>
        </>
      ),
    },
    // {
    //   headerName: "Policy Search",
    //   field: "accessMode",
    //   width: 150,
    //   minWidth: 170,
    //   maxWidth: 500,
    //   sortable: true,
    //   renderCell: (val) => (
    //     <>
    //       <Link
    //         to={`/policy`}
    //         state={{
    //           destinationEndpointIp: val?.row?.destinationEndpointIp,
    //           destinationEndpoint: val?.row?.destinationEndpoint,
    //           walletUser: val?.row?.walletUser,
    //           adUser: val?.row?.adUser,
    //           logType: val?.row?.logType.toLowerCase() === "epm" ? "local" : "ad",
    //           endpointUser: val?.row?.username,
    //         }}
    //       >
    //         View
    //       </Link>
    //     </>
    //   ),
    // },
  ];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Requests</h2>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Access Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Access Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          handleApplyFilter("logType", e);
                          setUserType(e);
                        }}
                        value={getCurrentOption("logType")}
                        options={[
                          { value: "EPM", label: "Endpoint User" },
                          {
                            value: "AD",
                            label: "Active Directory",
                          },
                        ]}
                      />
                    </Col>

                    {userType === "AD" && (
                      <>
                        <Col span={4}>
                          <p className="search-label">Domain</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={getCurrentOption("adDomain")}
                            onChange={(e, val) => {
                              handleApplyFilter("adDomain", val?.children);
                              const selectedDomain = domainData.find((domain) => domain.id === e);
                              if (selectedDomain) {
                                console.log(selectedDomain.ou);
                                setOuData(
                                  selectedDomain?.ous?.map((data) => {
                                    let ouVal = data.OuName.split(",")[0];
                                    let nameOu = ouVal.substring(3);
                                    return { ...data, ouNames: nameOu };
                                  })
                                );
                              } else {
                                setOuData([]);
                              }
                              // console.log(e, val);
                            }}
                          >
                            {domainData?.map((dom) => (
                              <Option key={dom.id} value={dom.id}>
                                {dom.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <p className="search-label">Ou</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search OU"
                            optionFilterProp="children"
                            onChange={(e, val) => handleApplyFilter("adOu", val?.children)}
                            value={getCurrentOption("adOu")}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {ouData?.map((ou) => (
                              <Option key={ou.AdId} value={ou.Id}>
                                {ou.OuName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </>
                    )}
                    {/* <Col span={4}>
                      <p className="search-label">Endpoint</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => handleApplyFilter("destinationEndpoint", e)}
                        value={getCurrentOption("destinationEndpoint")}
                      >
                        {endpointData &&
                          endpointData.length > 0 &&
                          endpointData.map((endpoint) => (
                            <Option value={endpoint.hostName || endpoint.instanceId}>
                              {endpoint?.hostName} <b>{`(${endpoint?.publicIp})`}</b>
                            </Option>
                          ))}
                      </Select>
                    </Col> */}
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters:
                      </h4>
                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          {filterParameter !== "walletUser" && (
                            <Tag
                              key={`${filterParameter}:${filterValue}`}
                              closable
                              onClose={() => handleRemoveFilter(filterParameter, filterValue)}
                            >
                              {`${filterParameter}: ${filterValue}`}
                            </Tag>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DataGrid
                    rows={requestData}
                    columns={columns}
                    rowCount={totalCount}
                    sortModel={sortModel}
                    onSortModelChange={(val) => {
                      if (val?.length > 0) {
                        setSortModel(val);
                      }
                    }}
                    // isRowExpandable={isRowExpandable}
                    getRowId={(row) => row?.id?.toString()}
                    // expandedRows={expandedRows}
                    // onRowExpand={handleRowExpand}
                    paginationMode="server"
                    page={currentPageModal.current}
                    pageSize={currentPageModal.pageSize}
                    pageSizeOptions={[10]}
                    // onPageChange={(newPage) => {
                    //   setPaginationParams({
                    //     ...paginationParams,
                    //     current: newPage.page,
                    //   });
                    // }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 100, page: 0 },
                      },
                    }}
                    paginationModel={currentPageModal}
                    onPaginationModelChange={(params) => {
                      fetchRequest(params.page + 1);
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) =>
                      handleApplyFilter(e.items[0].field, e.items[0].value)
                    }
                    disableRowSelectionOnClick
                    style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                    rowThreshold={0}
                    pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                    sx={{
                      "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                      },
                    }}
                    // components={{
                    //   NoRowsOverlay: CustomNoRowsOverlay,
                    //   noResultsOverlay: CustomNoRowsOverlay,
                    // }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Request;
