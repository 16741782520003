import React, { useState } from "react";
import { Col, Row, Tag, Select, Button, Menu, Dropdown } from "antd";
import { MoreOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';


const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;

const WindowsEndpoints = () => {
  const [searchText, setSearchText] = useState("");
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const endpoints = Array.from({ length: 100 }, (_, index) => ({
    id: index + 1,
    endpoint: `Endpoint ${index + 1}`,
    ip: `192.168.1.${index % 256}.${index % 256}`,
    mode: index % 2 === 0 ? "audit" : "normal",
    users: [
      {
        userName: `User ${index % 10}`,
        id: index + 1,
      },
      {
        userName: `User ${(index % 10) + 1}`,
        id: index + 2,
      },
    ],
    groups: [
      {
        groupName: `Group ${index % 10}`,
        id: index + 1,
      },
      {
        groupName: `Group ${(index % 10) + 1}`,
        id: index + 2,
      },
    ],
    labels: [
      {
        labelName: `Label ${index % 10}`,
        id: index + 1,
      },
      {
        labelName: `Label ${(index % 10) + 1}`,
        id: index + 2,
      },
    ],
    uac: index % 2 === 0 ? "On" : "Off",
    admin: index % 2 === 0 ? "On" : "Off",
  }));
  const [filteredEndpoints, setFilteredEndpoints] = useState(
    endpoints.filter((endpoint) => {
      console.log(endpoint);
      return (
        endpoint.endpoint.toLowerCase().includes(searchText.toLowerCase()) &&
        appliedFilters.every(({ filter, value }) =>
          endpoint.endpoint.toLowerCase().includes(value.toLowerCase())
        )
      );
    })
  );

  const handleOptionSelect = (value) => {
    // Update the data for the selected rows
    console.log(value, selectedRows);
    const updatedEndpoints = filteredEndpoints.map((endpoint) => {
      if (selectedRows.includes(endpoint.id)) {
        console.log(endpoint, value);
        return {
          ...endpoint,
          mode:
            value == 1
              ? `audit`
              : value == 2
              ? "policy"
              : value == 3
              ? "live"
              : value == 4
              ? "IT Admin"
              : endpoint.mode,
          uac: value === 5 ? "On" : value === 6 ? "Off" : endpoint.uac,
          admin: value === 7 ? "On" : value === 8 ? "Off" : endpoint.admin,
        };
      }
      return endpoint;
    });

    // Set the updated data
    setFilteredEndpoints(updatedEndpoints);
  };

  const menu = (rowId) => (
    <Menu style={{ padding: "1rem" }}>
      <h5>Status</h5>
      <Menu.Item key="1" onClick={() => handleOptionSelect(1, rowId)}>
        Set to audit
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleOptionSelect(2, rowId)}>
        Set to policy
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleOptionSelect(3, rowId)}>
        Set to live
      </Menu.Item>
      <Menu.Item key="4" onClick={() => handleOptionSelect(4, rowId)}>
        Set to IT Admin
      </Menu.Item>
      <Menu.Divider />
      <h5>UAC Settings</h5>
      <Menu.Item key="5" onClick={() => handleOptionSelect(5, rowId)}>
        Set to On
      </Menu.Item>
      <Menu.Item key="6" onClick={() => handleOptionSelect(6, rowId)}>
        Set to Off
      </Menu.Item>
      <Menu.Divider />
      <h5>Admin Priviledge Revokation</h5>
      <Menu.Item key="7" onClick={() => handleOptionSelect(7, rowId)}>
        Set to On
      </Menu.Item>
      <Menu.Item key="8" onClick={() => handleOptionSelect(8, rowId)}>
        Set to Off
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      field: "endpoint",
      headerName: "Endpoint",
      flex: 2,
      sortable: true,
      maxWidth: 170,
    },
    {
      field: "users",
      headerName: "Endpoint Users",
      flex: 2,
      sortable: true,
      maxWidth: 170,
      renderCell: (val) => (
        <>
          {val.row.users.map((user) => (
            <Tag color="blue" key={user.id}>
              {user.userName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      field: "groups",
      headerName: "Endpoint Groups",
      flex: 2,
      sortable: true,
      maxWidth: 170,
      renderCell: (val) => (
        <>
          {val.row.groups.map((group) => (
            <Tag color="blue" key={group.id}>
              {group.groupName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      field: "ip",
      headerName: "Ip Address",
      flex: 2,
      sortable: true,
      maxWidth: 170,
    },

    {
      field: "labels",
      headerName: "Labels",
      flex: 2,
      sortable: true,
      maxWidth: 170,
      renderCell: (val) => (
        <>
          {val.row.labels.map((label) => (
            <Tag color="blue" key={label.id} onClick={()=>handleApplyFilter("labels", label.labelName)}>
              {label.labelName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      field: "mode",
      headerName: "Mode",
      flex: 1,
      sortable: true,
      maxWidth: 170,
    },
    {
      field: "uac",
      headerName: "UAC",
      flex: 1,
      sortable: true,
      maxWidth: 170,
      renderCell: (val) => (
        <>
          {val.row.uac === "On" ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              On
            </Tag>
          ) : (
            <Tag icon={<CheckCircleOutlined />} color="error">
              Off
            </Tag>
          )}
        </>
      ),
    },
    {
      field: "admin",
      headerName: "Admin Priviledges",
      flex: 3,
      sortable: true,
      maxWidth: 170,
      renderCell: (val) => (
        <>
          {val.row.admin === "On" ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              On
            </Tag>
          ) : (
            <Tag icon={<CheckCircleOutlined />} color="error">
              Off
            </Tag>
          )}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      maxWidth: 170,
      renderCell: (params) => (
        <Dropdown overlay={() => menu(params.id)} placement="bottomLeft">
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleApplyFilter = (filter, value) => {
    if (value) {
      const newFilter = { filter, value };
      setAppliedFilters([...appliedFilters, newFilter]);

      // Update searchText immediately
      setSearchText(value);

      // Update filteredEndpoints based on searchText and appliedFilters
      setFilteredEndpoints((prevFilteredEndpoints) => {
        return prevFilteredEndpoints.filter((endpoint) => {
          const searchTextMatch = endpoint.endpoint
            .toLowerCase()
            .includes(value.toLowerCase()); // Use the current value

          const filtersMatch = appliedFilters.every(({ filter, value }) =>
            endpoint[filter].toLowerCase().includes(value.toLowerCase())
          );

          return searchTextMatch && filtersMatch;
        });
      });
    }
  };

  const handleRemoveFilter = (filter, value) => {
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) =>
          filterItem.filter !== filter || filterItem.value !== value
      )
    );
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const handleSelectionModelChange = (newSelection) => {
    console.log(newSelection);
    setSelectedRows(newSelection);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Endpoints</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row
                style={{ marginBottom: "2rem", gap: "2rem" }}
                className="search-box-container"
              >
                <Col span={4}>
                  <p className="search-label">Tenant</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select Tenant"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={"Authnull"} // Set the default value
                  >
                    <Option value="Authnull">Authnull</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>
                <Col span={4}>
                  <p className="search-label">Endpoint Groups</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search Endpoint Group"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("groups", e)}
                    // Set the default value
                  >
                    <Option value="Group 1">Group 1</Option>
                    <Option value="Group 2">Group 2</Option>
                    <Option value="Group 3">Group 3</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>
                <Col span={4}>
                  <p className="search-label">Endpoint</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search Endpoint "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("groups", e)}
                    // Set the default value
                  >
                    <Option value="Endpoint 1">Endpoint 1</Option>
                    <Option value="Endpoint 2">Endpoint 2</Option>
                    <Option value="Endpoint 3">Endpoint 3</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>

                <Col offset={8} style={{ marginTop: "2rem" }}>
                  <Dropdown
                    disabled={selectedRows.length === 0}
                    overlay={() => menu()}
                    placement="bottomLeft"
                  >
                    <Button type="primary" disabled={selectedRows.length === 0}>
                      Action
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {appliedFilters.length > 0 && (
                <div className="filter-tags">
                  <h4
                    style={{
                      marginTop: "0rem",
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                    }}
                  >
                    Applied Filters:
                  </h4>
                  {appliedFilters.map(({ filter, value }) => (
                    <Tag
                      key={`${filter}:${value}`}
                      closable
                      onClose={() => handleRemoveFilter(filter, value)}
                    >
                      {`${filter}: ${value}`}
                    </Tag>
                  ))}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={filteredEndpoints}
                // paginationMode='server'
                columns={columns}
                pageSize={10}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                selectionModel={selectedRows} // Change this line
                onRowSelectionModelChange={(newSelection) => {
                  setSelectedRows(newSelection);
                }}
                style={{ border: "none" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default WindowsEndpoints;
