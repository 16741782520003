import React from "react";
import { Select } from "antd";

const { Option } = Select;

const InfiniteScrollDropdown = ({
  fetchData,
  loading,
  options,
  onSelect,
  placeholder,
  hasMore,
  onSearch,
  value,
}) => {
  const handleScroll = (event) => {
    const { target } = event;

    if (
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 10 &&
      !loading &&
      hasMore
    ) {
      fetchData();
    }
  };

  return (
    <Select
      showSearch
      placeholder={placeholder}
      onPopupScroll={handleScroll}
      onChange={onSelect}
      onSearch={onSearch}
      value={value}
      style={{ width: "200" }}
    >
      {options.map((item) => (
        <Option key={item.id} value={item.value} label={item.label}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default InfiniteScrollDropdown;
