import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Row, Col, message, Spin } from "antd";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

const { TextArea } = Input;
const { Option } = Select;

const EditEpmGroupModal = ({
  isEditgroup,
  setIsEditgroup,
  selectedRecords,
  fetchepmGroups,
  endpoints,
}) => {
  const [groupName, setGroupName] = useState("");
  const [baseDN, setBaseDN] = useState("");
  const [nameFormat, setNameFormat] = useState("");
  const [groupFormat, setGroupFormat] = useState("");
  const [metaData, setMetaData] = useState("");
  const [validMetaData, setValidMetaData] = useState(true);
  const [instanceData, setInstanceData] = useState([]);
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();

  useEffect(() => {
    setIsLoading(true);

    const filteredEndpoints = endpoints
      .filter((ins) => selectedRecords.hostName.includes(ins.hostName))
      .map((ins) => ins.value);

    setSelectedInstances(filteredEndpoints);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchEndpoints = () => {
      setIsLoading(true);

      let payload = {
        domainId: DOMAIN_ID(),
        pageId: 1,
        pageSize: 100,
        search: "",
        filter: {
          filterBy: "",
          value: "",
        },
        token: AUTH_TOKEN(),
        orgId: ORG_ID(),
      };
      axios
        .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        })
        .then((res) => {
          setInstanceData(
            res?.data?.instances.map((instance) => ({
              id: instance.instanceId,
              text: instance.hostName,
              publicIp: instance.publicIp,
            }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err.response.data.message === "Invalid credentials" ||
            err?.response?.status === 401
          ) {
            logOutUser();
            notification.open({
              type: "error",
              content: "Credentials are invalid",
            });
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch endpoints",
            });
          }
        });
    };
    fetchEndpoints();
  }, []);

  const handleOk = (e) => {
    form
      .validateFields()
      .then((values) => {
        let data = {
          domainId: DOMAIN_ID(),
          groupId: selectedRecords.groupId,
          groupName: values.groupName,
          instanceIds: selectedInstances ?? [],
          metadata: values.metadata,
          ou: groupFormat ?? "",
          cn: nameFormat ?? "",
          dc: baseDN ?? "",
          token: AUTH_TOKEN(),
          orgId: ORG_ID(),
        };

        axios
          .put(`${REACT_APP_API_PAM_URL}/instanceGroup/editInstanceGroup`, data, {
            headers: {
              "X-Authorization": AUTH_TOKEN(),
              withCredentials: true,
            },
          })
          .then(() => {
            notification.open({
              type: "success",
              content: `Group ${values.groupName} Edited Successfully`,
            });
            setTimeout(() => {
              setIsEditgroup(false);
              fetchepmGroups();
            }, 1000);
          })
          .catch((err) => {
            if (err.response.data.message == "Invalid credentials") {
              logOutUser();
              notification.open({
                type: "error",
                content: "Credentials are invalid",
              });
            } else {
              notification.open({
                type: "error",
                content: "Unable to edit group",
              });
            }
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsEditgroup(false);
  };

  return (
    <Modal
      open={isEditgroup}
      title={`Edit Group`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Save
        </Button>,
      ]}
      width={800}
    >
      {setNotification}
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            groupName: selectedRecords?.groupName,
            endpoints: selectedRecords?.hostName,
            // endpoints: endpoints
            //   .filter((ins) => selectedRecords.hostName.includes(ins.hostName))
            //   .map((ins) => ins.value),
          }}
        >
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={24}>
                  <Form.Item
                    label="Group Name"
                    name="groupName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the group name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Group Name"
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: "2rem", gap: "1.5rem" }} className="search-box-container">
                <Col span={24}>
                  <Form.Item
                    label="Endpoints"
                    name="endpoints"
                    rules={[
                      {
                        required: true,
                        message: "Please select endpoint!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      mode="multiple"
                      placeholder="Search Endpoints"
                      allowClear
                      onChange={(value) => setSelectedInstances(value)}
                      options={instanceData.map((instanceData) => ({
                        value: instanceData.id,
                        label: instanceData.text,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Other Metadata"
                    name="metadata"
                    rules={[
                      {
                        required: true,
                        message: "Please enter metadata!",
                      },
                    ]}
                  >
                    <TextArea
                      style={{ width: "100%" }}
                      label="Other Metadata"
                      validateStatus={validMetaData ? "" : "error"}
                      help={validMetaData ? "" : "Invalid Other Metadata"}
                      rows={8}
                      value={metaData}
                      onChange={(e) => {
                        setMetaData(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditEpmGroupModal;
