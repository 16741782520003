import {
  Col,
  Row,
  Select,
  Button,
  Input,
  Tag,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';


const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const JitConnections = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const endpoints = [
    {
      id: 1,
      endpoint: "MYSQL*.*",
      platform: "AWS",
      count: "5",
      status: "active",
    },
    {
      id: 2,
      endpoint: "PostgreSQL*.*",
      platform: "Azure",
      count: "8",
      status: "active",
    },
    {
      id: 3,
      endpoint: "SQL Server*.*",
      platform: "AWS",
      count: "12",
      status: "inactive",
    },
    {
      id: 4,
      endpoint: "MongoDB*.*",
      platform: "GCP",
      count: "3",
      status: "active",
    },
    {
      id: 5,
      endpoint: "Oracle*.*",
      platform: "Azure",
      count: "6",
      status: "inactive",
    },
    {
      id: 6,
      endpoint: "Redis*.*",
      platform: "AWS",
      count: "9",
      status: "active",
    },
    {
      id: 7,
      endpoint: "Cassandra*.*",
      platform: "GCP",
      count: "7",
      status: "active",
    },
    {
      id: 8,
      endpoint: "Elasticsearch*.*",
      platform: "Azure",
      count: "4",
      status: "inactive",
    },
    {
      id: 9,
      endpoint: "DynamoDB*.*",
      platform: "AWS",
      count: "11",
      status: "active",
    },
    {
      id: 10,
      endpoint: "MariaDB*.*",
      platform: "GCP",
      count: "10",
      status: "active",
    },
  ];

  const columns = [
    {
      headerName: "Endpoint Name",
      field: "endpoint",
      key: "endpoint",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "File",
      field: "file",
      flex: 1,
      sortable: true,
      renderCell: (_value, record) => <a>Download</a>,
    },

    {
      headerName: "Platform",
      field: "platform",

      flex: 1,
      sortable: true,
    },
    {
      headerName: "Rule Count",
      field: "count",

      flex: 1,
      sortable: true,
    },
    {
      headerName: "Connect",
      field: "connect",
      flex: 1,
      sortable: true,
      renderCell: (_value, record) => <a>Connect</a>,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      sortable: true,
      render: (tags, record) => (
        <span>
          {record.status == "active" ? (
            <Tag color={"green"}>Live</Tag>
          ) : (
            <Tag color={"black"}>Audit</Tag>
          )}
        </span>
      ),
    },

    {
      headerName: "Logs",
      field: "logs",
      flex: 1,
      sortable: true,
      renderCell: (_value, record) => <a>View Logs</a>,
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">JIT Connections</h2>
            </Col>
            <Col>
              <Link to={"add"}>
                <Button type="primary">Add Connections</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Tenant</p>
                      <Input placeholder="Tenant Name" value={"Authnull"} />
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Range</p>
                      <RangePicker />
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Endpoint Name</p>
                      <Input
                        placeholder="Search..."
                        onSearch={(value) => console.log(value)}
                        addonAfter={null}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={endpoints}
                paginationMode='server'
                columns={columns}
                pageSize={10}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default JitConnections;
