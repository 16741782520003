import { Button, Modal, Row, Col, Input, Tooltip, Form, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons/lib/icons";
import React, { useState, useEffect } from "react";
import ReactJoyride from "react-joyride";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

const cntoolTip = <span>Name format should be defined for with prefix "cn" ,Eg:cn</span>;

const dntoolTip = <span>Distinguish Name must be dc=example,dc=com format</span>;

const AddUser = ({ isAddUser, setIsAddUser, notification, fetchUsers, run, setRun }) => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [notification, setNotification] = message.useMessage();
  const [form] = Form.useForm();
  const [isAddUserOnboarding, setIsAddUserOnboarding] = useState(false);

  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsAddUserOnboarding(false);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#fullName",
        content: <span>Enter the first name and last name.</span>,
        disableBeacon: true,
      },
      {
        target: "#phoneOnboard",
        content: <span>Enter a valid phone number.</span>,
        disableBeacon: true,
      },
      {
        target: "#emailOnboard",
        content: <span>Enter a valid email.</span>,
        disableBeacon: true,
      },
      {
        target: "#cNameOnboard",
        content: <span>Enter common name</span>,
        disableBeacon: true,
      },

      {
        target: "#dNameOnboard",
        content: <span>Enter your distinguish name</span>,
        disableBeacon: true,
      },
      {
        target: "#issuerDIDboard",
        content: <span>Select any Issuer DID</span>,
        disableBeacon: true,
      },
      {
        target: "#addBtnOnboard",
        content: (
          <span>
            Once you click the add button you'll receive a wallet key via mail which will help you
            to register the wallet.
          </span>
        ),
        disableBeacon: true,
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };

    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          options: {
            primaryColor: "#1677ff",
            width: 500,
            zIndex: 1000,
          },
          spotlight: {
            padding: 0,
          },
        }}
      />
    );
  };

  useEffect(() => {
    fetchIssue();
  }, []);

  const handleOk = (e) => {
    console.log(e);
    let payload = {
      ...e,
      phone: "",
      userRoleId: 3,
      filter: "",
      DomainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      password: "",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/onboardUser`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification?.open({
          type: "success",
          content: "User onboarded Successful!",
        });
        setIsAddUser(false);
        fetchUsers();
      })
      .catch((err) => {
        setIsLoading(false);
        let errMgs = err.response.data.message;
        notification.open({
          type: "error",
          content:
            errMgs === "User already exists"
              ? "User already exists"
              : errMgs === "Invalid credentials"
              ? "Credentials are invalid"
              : "Unable to unboard User",
        });
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const handleCancel = () => {
    setIsAddUser(false);
  };

  const fetchIssue = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          })) || []
        );
      });
  };

  const handleRole = (_, data) => {
    form.setFieldsValue({
      userRoleId: data.role,
    });
  };

  const handleIssuer = (value) => {
    form.setFieldsValue({
      issuerId: value,
    });
  };

  return (
    <Modal
      open={isAddUser}
      title="Add User"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={900}
      zIndex={900}
    >
      {isAddUserOnboarding && startTour()}
      <div style={{ marginTop: "2rem" }}>
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical" onFinish={handleOk}>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <Row align="middle" justify="space-between" id="fullName">
                    <Col span={11}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "First name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter first name" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="LastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Last name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Last name" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        // name="userRole"
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: "First name is required",
                          },
                        ]}
                      >
                        <Input defaultValue={"Enduser"} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={11} id="emailOnboard">
                      <Form.Item
                        name="email"
                        label="Email ID"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Email ID is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:abc@gmail.com" />
                      </Form.Item>
                    </Col>

                    {/* <Col span={11} id="phoneOnboard">
                      <Form.Item
                        name="phone"
                        label="User Phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone number is required",
                          },
                          {
                            pattern: /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                            message: "Please enter a valid phone number",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Phone number" />
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11} id="cNameOnboard">
                      <Form.Item
                        name="cn"
                        label={
                          <span>
                            Common Name&nbsp;
                            <Tooltip title={cntoolTip}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Common Name is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:cn" />
                      </Form.Item>
                    </Col>
                    <Col span={11} id="dNameOnboard">
                      <Form.Item
                        name="dn"
                        label={
                          <span>
                            Distinguish Name&nbsp;
                            <Tooltip title={dntoolTip}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Distinguish Name is required",
                          },
                          {
                            pattern: /^(dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message: "Distinguish Name must be dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11} id="issuerDIDboard">
                      <Form.Item
                        name="issuerId"
                        label="Issuer DID"
                        rules={[
                          {
                            required: true,
                            message: "Select issuer DID",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleIssuer}
                          placeholder="Select issuer DID"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={issuerData}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col offset={22} span={2}>
                  <Form.Item style={{ textAlign: "center" }}>
                    <span id="addBtnOnboard" style={{ paddingBottom: "5px" }}>
                      <Button htmlType="submit" type="primary" loading={isLoading}>
                        Add
                      </Button>
                    </span>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddUser;
