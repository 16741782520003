import { Col, Input, Row, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


const EndpointDetailsServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const PAGESIZE = 10;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [totalCount, setTotalCount] = useState(1);
  useEffect(() => {}, [paginationParams, search, filterBy, filteredValue]);

  useEffect(() => {}, [search, currentPage, paginationParams]);

  const tab4columns = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Protocol",
      dataIndex: "protocol",
      key: "protocol",
    },
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
    },
  ];
  const data1source = [
    {
      serviceName: "RDP",
      protocol: "TCP/CP",
      port: "3389",
    },
    {
      serviceName: "VNC",
      protocol: "UDP",
      port: "5900",
    },
    {
      serviceName: "SSH",
      protocol: "TCP/IP",
      port: "22",
    },
    {
      serviceName: "WEB",
      protocol: "TCP/IP",
      port: "22",
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={tab4columns}
                dataSource={data1source}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointDetailsServices;
