import { Col, Row, Select, Button, Input, message, Tag } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_SAM_URL, DOMAIN_ID, ORG_ID } from "../../constants";
import axios from "axios";
import ServiceAccount from "./ServiceAccount";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const ServiceCredentials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [Data, setData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [sortModel, setSortModel] = useState();
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  useEffect(() => {
    fetchAssignEndpoint();
  }, [paginationParams, debouncedValue]);

  const fetchAssignEndpoint = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNo: paginationParams.currentPage,
      pageSize: paginationParams.pageSize,
      search: search,
      filter: {
        filterBy: "",
        value: "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_SAM_URL}/listAssignedEndpoints`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setData(res.data.endpoints);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      headerName: "Service Account Name",
      field: "serviceAccount",
      key: "serviceAccountName",
      flex: 1,

      renderCell: (data, record) => (
        <>{data.row.serviceAccount && data.row.serviceAccount.username}</>
      ),
    },
    {
      headerName: "Created By",
      field: "createdBy",
      key: "createdBy",
      flex: 1,

      renderCell: (data, record) => (
        <>
          <span>{data.row.createdBy}</span>
        </>
      ),
    },
    {
      headerName: "Created At",
      field: "createdAt",
      key: "createdAt",
      flex: 1,

      renderCell: (data, record) => (
        <>
          {moment.utc(data.row.createdAt).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss")}
        </>
      ),
    },
    {
      headerName: "Source",
      field: "source",
      key: "source",
      flex: 1,

      renderCell: (data, record) => (
        <>
          {data.row.source &&
            data.row.source.length > 0 &&
            data.row.source.map((dat, index) => <Tag>{dat.hostname}</Tag>)}
        </>
      ),
    },
    {
      headerName: "Destination",
      field: "destination",
      key: "destination",
      flex: 1,

      renderCell: (data, record) => (
        <>
          {data.row.destination &&
            data.row.destination.length > 0 &&
            data.row.destination.map((data, index) => <Tag>{data.hostname}</Tag>)}
        </>
      ),
    },
    {
      headerName: "Access",
      field: "access",
      key: "access",
      flex: 1,

      renderCell: (data, record) => (
        <>
          {data.row.access === true ? (
            <Tag color="green">Allowed</Tag>
          ) : (
            <Tag color="danger">Denied</Tag>
          )}
        </>
      ),
    },
    {
      headerName: "Assigned to Wallet",
      field: "assignedWallet",
      key: "assignedWallet",
      flex: 1,
    },
  ];

  const filterOption = [
    {
      label: "Endpoint Groups",
      value: "endpointGroups",
    },
    {
      label: "Service Account Name",
      value: "serviceAccountName",
    },
  ];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
            {/* <Col>
              <Link to={"/serviceAccount/addServiceAccount"}>
                <Button type="primary">Add Service Account Policy</Button>
              </Link>
            </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      <div>
                        <Select
                          showSearch
                          allowClear
                          placeholder="Filter by"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(_, data) => {
                            setFilter(data ? data?.value : "");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          options={filterOption}
                        />
                      </div>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={Data}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              /> */}

              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={Data}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ServiceCredentials;
