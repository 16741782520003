import { Col, Row, Table, Button, Input, Card, Tag } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { AUTH_TOKEN, AUTH_TOKEN_ORG, REACT_APP_API_TENANTS_URL } from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const Dashboard = () => {
  const [noOfTenant, setNoOfTenant] = useState(null);
  const [noOfUser, setNoOfUser] = useState(null);
  const [noOfEndpoint, setNoOfEndpoint] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tenantList, setTenantList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTotalTenent();
    fetchTOtalEndpoint();
    fetchTOtalUser();
    fetchTenent();
  }, []);
  const fetchTenent = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/tenantlist`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTenantList(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const tenantColumns = [
    { title: "Name", dataIndex: "tenant_name", key: "tenant_name" },
    {
      title: "Admin Email",
      dataIndex: "admin_email",
      key: "admin_email",
      render: (tags, record) => (
        <span>
          <Tag color={record.status.toLowerCase() === "active" ? "green" : "red"} key={tags}>
            {tags}
          </Tag>
        </span>
      ),
    },
    {
      title: "Tenant URL",
      dataIndex: "site_url",
      key: "site_url",
      render: (tags, record) => (
        <span>
          <a href={`https://${tags}`} target="_blank">
            {tags}
          </a>
        </span>
      ),
    },
    {
      title: "Authentication Method",
      dataIndex: "authentication_method",
      key: "authentication_method",
      render: (val) =>
        val.toLowerCase() === "normal"
          ? "Basic Password Method"
          : val.toLowerCase() === "okta"
          ? "SAML 2.0"
          : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => (val.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <span>
          {record && moment(record).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm [PST]")}
        </span>
      ),
    },
    {
      title: "Modified At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => (
        <span>
          {record && moment(record).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm [PST]")}
        </span>
      ),
    },
  ];

  const fetchTotalTenent = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/dashboardnooftenant`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        console.log(res.data);
        setNoOfTenant(res.data.data);
      });
  };
  const fetchTOtalUser = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/dashboardnoofuser`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        console.log(res.data);
        setNoOfUser(res.data.data);
      });
  };
  const fetchTOtalEndpoint = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/dashboardnoofendpoints`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        console.log(res.data);
        setNoOfEndpoint(res.data.data);
      });
  };

  return (
    <>
      {isLoading}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Dashboard</h2>
            </Col>
          </Row>
          <Row justify="space-between" style={{ alignItems: "baseline" }}>
            <Col>
              <h4 className="sub-title">Tenant Available</h4>
            </Col>
            <Col>
              <Link to={"/tenant/add"}>
                <Button type="primary">Create Tenant</Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: "1rem" }}>
            <Col span={8}>
              <Card
                hoverable
                onClick={() =>
                  navigate(`/org/dashboard/details`, { state: { dataType: "TENANT" } })
                }
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "600",
                    marginBottom: "0.5rem",
                  }}
                >
                  {noOfTenant}
                </h3>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "600",
                    marginTop: "0.5rem",
                  }}
                >
                  Total Tenants
                </h4>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                hoverable
                onClick={() => navigate(`/org/dashboard/details`, { state: { dataType: "USER" } })}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "600",
                    marginBottom: "0.5rem",
                  }}
                >
                  {noOfUser}
                </h3>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "600",
                    marginTop: "0.5rem",
                  }}
                >
                  Total Users
                </h4>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                hoverable
                onClick={() =>
                  navigate(`/org/dashboard/details`, { state: { dataType: "ENDPOINT" } })
                }
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "600",
                    marginBottom: "0.5rem",
                  }}
                >
                  {noOfEndpoint}
                </h3>
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "600",
                    marginTop: "0.5rem",
                  }}
                >
                  Total Endpoints
                </h4>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input placeholder="Search" allowClear />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table loading={isLoading} columns={tenantColumns} dataSource={tenantList} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
