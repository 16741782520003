import { Button, Col, Form, Input, Row, Space, Tooltip, Typography, message, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../../../constants";
import { logOutUser } from "../../../../common";
import { CopyTwoTone, DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import JSZip from "jszip";

export const OnboardWindowV2 = () => {
  const [notification, setNotification] = message.useMessage();
  const navigate = useNavigate();
  const { Text } = Typography;
  const [form] = Form.useForm();

  const [code, setCode] = useState("");
  const [publicIp, setPublicIp] = useState("");
  const [uuid, setUuid] = useState("");
  const [aDData, setADData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadEnv, setIsDownloadEnv] = useState(false);
  const [formData, setFormData] = useState();
  const [aDInfo, setADInfo] = useState(null);
  const [adMfaMode, setAdMfaMode] = useState(null);
  const [domainGroups, setDomainGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [adId, setAdId] = useState(null);

  useEffect(() => {
    generateDynamicUuid();
    fetchADMachine();
  }, []);

  useEffect(() => {
    if (adId !== null) {
      fetchGroupsByDomain();
    }
  }, [adId]);

  useEffect(() => {
    fetchAgentKey();
    setFormData([{ name: ["token"], value: code }]);
  }, [code]);

  const createZipAndDownload = async () => {
    const zip = new JSZip();
    const url =
      "https://raw.githubusercontent.com/authnull0/windows-endpoint/refs/heads/main/windows-agent-install.ps1";

    // Add text content as a separate file to the ZIP
    zip.file("app.env", textContent);

    // Fetch the file from the provided URL
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");

      const fileBlob = await response.blob();

      // Add the downloaded file to the ZIP
      zip.file("windows-agent-install.ps1", fileBlob);

      // Generate the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a download link for the ZIP file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "windows-agent-install.zip";
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const fetchADMachine = () => {
    let pageDetails = {
      tenantId: DOMAIN_ID(),
      filter: "",
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API}/ad/GetAllDomains`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        const ADData = res?.data?.domains?.map((ad, i) => ({
          id: i + 1,
          value: ad.id,
          label: ad.directoryName,
          ...ad,
        }));

        setADData(ADData ? ADData : []);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const fetchGroupsByDomain = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      pageNo: 1,
      pageSize: 100,
      adId: adId,
    };

    try {
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllGroupsByDomain`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempGroups = res?.data?.groups?.map((g) => ({
        id: g.id,
        value: g.groupName,
        label: g.groupName,
      }));

      setDomainGroups(tempGroups);
    } catch (error) {
      console.log(error);
    }
  };

  const textContent = `KEY=KL01
PUBLIC_IP=${publicIp}
MACHINE_KEY=MACKL01
AGENT_TOKEN=${code}
TENANT_ID=${localStorage.getItem("DomainId")}
ORG_ID=${localStorage.getItem("OrgId")}
USER_ID=${localStorage.getItem("userId")}
AWS_ACCESS_KEY_ID=AKIAUJV77EJND3TKWHXI
AWS_SECRET_ACCESS_KEY=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
AWS_REGION=us-west-1
BUCKET_NAME=guacwest
RECORDING_DIR=/anchor_dvr/
TEXT_RECORDING_DIR=/text_recordings/
FILE_NAME=test.guac
BUCKET_NAME_GCS=gto-did-app-dev
STORAGE_AWS_FLAG=true
INSTALL_SERVICES=false # 
UUID=${uuid}
LDAP_HOST=${aDInfo?.adHost}
LDAP_PORT=${aDInfo?.port}
SEARCH_DN=${aDInfo?.dc}
GROUP_DN_PATTERN=${aDInfo?.dc}
USER_DN_PATTERN=CN=%u,${aDInfo?.dc}
PASSWORD=${aDInfo?.password}
PreferredDNS=${aDInfo?.adHost}
DomainAdmin=${aDInfo?.adAdminUsername}
DomainName=${aDInfo?.dc1}.${aDInfo?.dc2}
GROUPS=${selectedGroups.map((g) => g).join(",")}
ADMFA=${adMfaMode}
`;

  const generateDynamicUuid = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    // Define the format for each section
    const sections = [8, 4, 4, 4, 12];

    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      result += "-";
    }

    // Remove the trailing hyphen
    result = result.slice(0, -1);

    return setUuid(result);
  };

  const downloadPamCode = () => {
    let fileName = "app.env";
    let fileContent = textContent;
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const fetchAgentKey = () => {
    let keyData = {
      domainId: DOMAIN_ID(),
      expired: false,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setCode(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (
          err.response.data.message === "Credentials are invalid" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  const validateIpAddress = (ip) => {
    const validationData = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      publicIp: ip,
    };

    return axios.post(`${REACT_APP_API_TENANTS_URL}/IsIPExists`, validationData, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Add Windows</h2>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col span={20}>
            <Row>
              <p>
                <b>Step1: </b> Download and extract the installation file and copy it to the target
                machine.
              </p>
            </Row>
            <Form
              form={form}
              layout="vertical"
              fields={formData}
              onFieldsChange={(_, allFields) => {
                setFormData(allFields);
              }}
              onFinish={() => {
                createZipAndDownload();
              }}
            >
              <Row>
                <Col span={24}>
                  <Row align="middle" gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="token"
                        label={
                          <span>
                            Agent Key{" "}
                            <Tooltip title="Agent Key to be used for registering">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                }}
                              />
                            </Tooltip>{" "}
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: "Key is required",
                          },
                        ]}
                      >
                        <Input
                          value={code}
                          disabled
                          suffix={
                            <Text
                              style={{ color: "GrayText" }}
                              copyable={{
                                text: code,
                              }}
                            ></Text>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Form.Item
                        name="ip"
                        label="Public IP"
                        rules={[
                          {
                            required: true,
                            message: "Public IP is required",
                          },
                          {
                            pattern: /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/,
                            message: "IP address range must be 0.0.0.0 to 255.255.255.255",
                          },
                          {
                            validator(rule, value) {
                              return new Promise(async (resolve, reject) => {
                                if (value) {
                                  try {
                                    const resEmail = await validateIpAddress(value);
                                    resEmail.data.isExists === false && setPublicIp(value);
                                    resEmail?.data?.isExists
                                      ? reject("Public IP already exist")
                                      : resolve();
                                  } catch {}
                                } else {
                                  reject();
                                }
                              });
                            },
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row align="middle" gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="AdMachine"
                        label={
                          <span>
                            Active Directory{" "}
                            <Tooltip title="Select the appropriate Active Directory for user authentication">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                }}
                              />
                            </Tooltip>{" "}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Active Directory is required",
                          },
                        ]}
                      >
                        <Select
                          onChange={(e, val) => {
                            console.log(e);
                            setADInfo(val);
                            setAdMfaMode(val.adMfa);
                            setAdId(val.id);
                          }}
                          options={aDData}
                          placeholder="Select Active Directory"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {adMfaMode === 1 && aDInfo !== null && (
                        <Form.Item
                          name="adGroups"
                          label={
                            <span>
                              Select Groups{" "}
                              <Tooltip title="Select the groups that are needed to be added in this is machine for remote access.">
                                <InfoCircleOutlined
                                  style={{
                                    color: "rgba(0,0,0,.45)",
                                  }}
                                />
                              </Tooltip>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Select groups",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select groups"
                            options={domainGroups}
                            onChange={(val) => {
                              setSelectedGroups(val);
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    {aDInfo !== null && (
                      <Col span={12}>
                        <Button type="primary" htmlType="submit">
                          Download <DownloadOutlined />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              {/* <Row>
                <Col span={20}>
                  <p>
                    {" "}
                    <b>Step2 : </b> Download the installation file and copy to target machine
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      htmlType="submit"
                      style={{ marginLeft: "1rem" }}
                      icon={<DownloadOutlined />}
                    />
                  </p>
                </Col>
              </Row> */}
            </Form>

            <Row>
              <Col span={24}>
                <p>
                  <b>Step2 : </b> In the target machine, open the PowerShell window as an
                  administrator privilege and execute the following command
                </p>
              </Col>
              <Col span={20}>
                <Input
                  style={{ border: "none", padding: "1rem" }}
                  size="large"
                  value=".\windows-agent-install.ps1 -OutputPath C:\authnull-agent"
                  disabled
                  suffix={
                    <Text
                      style={{ color: "GrayText" }}
                      copyable={{
                        text: ".\\windows-agent-install.ps1 -OutputPath C:\\authnull-agent",
                        tooltips: ["Copy Command", "Copied"],
                      }}
                    ></Text>
                  }
                />
              </Col>
            </Row>
            {/* <Row>
              <Col span={20}>
                <p>
                  <b>Step4 : </b> Copy the content of the .env file and paste it when the
                  installation prompt for configuration.
                </p>
              </Col>
            </Row> */}

            <Row justify="end" style={{ marginTop: "2rem" }}>
              <Col>
                <Space>
                  <Button onClick={() => navigate(-1)}>Cancel</Button>
                  <Button type="primary" htmlType="submit" onClick={() => navigate(-1)}>
                    Finish
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
};
