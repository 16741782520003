import { Col, Row, Select, Table, Button, message, DatePicker, TimePicker, Tag } from "antd";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TRANS_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../constants";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";
import { Typography } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const { Text } = Typography;
const Log = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({});
  const [userData, setUserData] = useState([]);
  const [endpointUser, setEndpointUser] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [filterEpmUser, setFilterEpmUser] = useState(null);
  const [filterLDAPUser, setFilterLDAPUser] = useState(null);
  const [filterTransStatus, setFilterTransStatus] = useState(null);
  const [filterTransType, setFilterTransType] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterTime, setFilterTime] = useState(null);

  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();

  dayjs.extend(customParseFormat);

  useEffect(() => {
    fetchLog();
  }, [
    filterEpmUser,
    filterLDAPUser,
    filterTransStatus,
    filterTransType,
    filterDate,
    filterTime,
    currentPageModal,
  ]);

  useEffect(() => {
    fetchUser();
    fetchEndpointUser();
  }, []);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const fetchLog = () => {
    let currentOffset = 0;

    let pageDetails = {
      domainId: DOMAIN_ID().toString(),
      orgId: ORG_ID(),
      filter: {
        ldapUser: filterLDAPUser ?? "",
        LocalUser: filterEpmUser ?? "",
        transactionType: filterTransType ?? "",
        resourceIP: "",
        userIP: "",
        transactionStatus: filterLDAPUser ?? "",
        date: filterDate?.format("DD-MM-YYYY") ?? "",
        hour: filterTime?.format("HH") ?? "",
      },

      limit: currentPageModal?.pageSize,
      offset: currentPageModal?.page + 1,

      // limit: paginationParams?.pageSize,
      // offset: paginationParams.current
      //   ? (paginationParams.current - 1) * paginationParams?.pageSize
      //   : currentOffset,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TRANS_URL}/GetTransactions`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data = [];
        if (res.data.transactions) {
          Data = res.data.transactions.map((data, i) => ({
            id: i,
            transactionId: data.ID,
            LdapUser: data.LdapUser,
            CID: data.CID,
            ChainAddress: data.ChainAddress,
            TransactionType: data.TransactionType,
            TransactionMessage: data.TransactionMessage,
            LocalUser: data.LocalUser,
            ResourceType: data.ResourceType,
            ResourceIP: data.ResourceIP,
            UserIP: data.UserIP,
            DateTime: moment(data.DateTime, "MM-DD-YYYY H[h]").format("DD-MM-YYYY H[h]"),
            TransactionStatus: data.TransactionStatus,
            MerkleHash: data.MerkleHash,
          }));
        }
        console.log(Data);
        setCredentialData(Data);
        setTotalCount(res?.data?.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const toCamelCase = (str) => {
    const s =
      str &&
      str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
        .join("");
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const columns = [
    {
      key: "LdapUser",
      headerName: "Directory User",
      field: "LdapUser",
      width: 210,
      renderCell: (params) => (
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{params.value}</span>
      ),
    },

    {
      key: "LocalUser",
      headerName: "Endpoint User",
      field: "LocalUser",
      width: 180,
    },
    {
      key: "MerkleHash",
      headerName: "Merkle Hash",
      field: "MerkleHash",
      width: 310,
      renderCell: (params) => (
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{params.value}</span>
      ),
    },
    {
      key: "ResourceType",
      headerName: "Resource Type",
      field: "ResourceType",
      width: 180,
    },
    {
      key: "TransactionType",
      headerName: "Transaction Type",
      field: "TransactionType",
      width: 180,
      renderCell: (params) => (
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{params.value}</span>
      ),
    },
    {
      key: "TransactionStatus",
      headerName: "Transaction Status",
      field: "TransactionStatus",
      width: 180,
      renderCell: (params) => (
        <Text type={params.value === "Success" ? "success" : "danger"}>
          {toCamelCase(params.value)}
        </Text>
      ),
    },
    { key: "CID", headerName: "CID", field: "CID", width: 120 },
    // { key: "ChainAddress", headerName: "Chain Address", field: "ChainAddress" },
    // { key: "domainID", headerName: "Domain ID", field: "domainID" },
    { key: "DateTime", headerName: "Date & Hours", field: "DateTime", width: 180 },

    // Add field for other columns if needed
  ];

  const onFilterChange = (_, data) => {
    console.log(data);
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  const fetchUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })

      .then((res) => {
        setUserData(
          res?.data?.users?.map((data) => ({
            value: data.id,
            label: data.emailAddress,
          }))
        );
      });
  };

  const fetchEndpointUser = async () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      filter: {
        filterBy: "",
        value: "",
      },
      instanceIds: [],
      search: "",
      orgId: ORG_ID(),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);

        setEndpointUser(
          res?.data?.epmUsers?.map((user) => ({
            value: user.userId,
            label: user.userName,
          }))
        );
      })

      .catch((err) => {
        setIsLoading(false);

        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints groups",
          });
        }
      });
  };

  const handleClearFilter = () => {
    setFilterEpmUser(null);
    setFilterLDAPUser(null);
    setFilterTransStatus(null);
    setFilterTransType(null);
    setFilterDate(null);
    setFilterTime(null);
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row
                style={{
                  marginBottom: "1rem",
                  gap: "1rem",
                }}
                className="search-box-container"
              >
                {/* <p className="search-label">Filter by</p> */}
                <Col span={3}>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Transaction Type"
                      labelInValue={true}
                      onChange={(_, data) => setFilterTransType(data?.children)}
                      value={filterTransType}
                    >
                      <Option value="1">Credential</Option>
                      <Option value="2"> Presentation Request</Option>
                      <Option value="3">DID </Option>
                    </Select>
                  </div>
                </Col>
                <Col span={4}>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Transaction Status"
                    labelInValue={true}
                    onChange={(_, data) => setFilterTransStatus(data?.children)}
                    value={filterTransStatus}
                  >
                    <Option value="1">Success</Option>
                    <Option value="2">Failed</Option>
                  </Select>
                </Col>
                <Col span={3}>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Directory Users"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={(_, data) => {
                        setFilterLDAPUser(data ? data?.label : "");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      value={filterLDAPUser}
                      options={userData}
                    />
                  </div>
                </Col>
                <Col span={3}>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Endpoint Users"
                    labelInValue={true}
                    optionFilterProp="children"
                    onChange={(_, data) => {
                      setFilterEpmUser(data ? data?.label : "");
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    value={filterEpmUser}
                    options={endpointUser}
                  />
                </Col>
                <Col span={3}>
                  <DatePicker
                    placeholder="Date"
                    value={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    format="DD-MM-YYYY"
                  />
                </Col>
                <Col span={3}>
                  <TimePicker
                    placeholder="Hours"
                    value={filterTime}
                    defaultOpenValue={dayjs("00", "HH")}
                    onChange={(time, timeString) => setFilterTime(time)}
                    format="HH"
                  />
                </Col>
                {(filterEpmUser ||
                  filterLDAPUser ||
                  filterTransStatus ||
                  filterTransType ||
                  filterDate ||
                  filterTime) && (
                  <Col>
                    <Button type="primary" onClick={handleClearFilter}>
                      Reset Filter
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              ...paginationParams,
              total: totalCount,
              showTotal: (total) => `Total ${total} items`,
              onChange: (page, pageSize) => {
                setPaginationParams({
                  current: page,
                  pageSize: pageSize,
                });
              },
            }}
          /> */}

          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            rows={credentialData}
            columns={columns}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPageModal.page}
            pageSize={currentPageModal.pageSize}
            onPaginationModelChange={(params) => {
              setCurrentPageModal({
                page: params.page,
                pageSize: 10,
              });
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default Log;
