import React, { useState, useEffect } from "react";
import { Col, Row, Tabs, Breadcrumb } from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import EndpointDetailsUser from "./EndpointDetailsUser";
import EndpointDetailsGroup from "./EndpointDetailsGroup";
import EndpointDetailsAuthFlow from "./EndpointDetailsAuthFlow";
import EndpointDetailsServices from "./EndpointDetailsServices";

const EndpointDetails = ({ selectedEndpoints }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const defaultTab = queryParams.get("defaultTab");
  const [selectedTab, setSelectedTab] = useState("1");
  const navigate = useNavigate();
  const { hostAddress } = location.state || {};

  useEffect(() => {
    handleQueryParam();
  }, []);

  const Storage = localStorage.getItem("details");
  const stateHostAddress = JSON.parse(Storage);

  const items = [
    {
      key: "1",
      label: "Endpoint User",
      children: <EndpointDetailsUser stateHostAddress={stateHostAddress} />,
    },
    {
      key: "2",
      label: "Local User Group",
      children: <EndpointDetailsGroup stateHostAddress={stateHostAddress} />,
    },
    {
      key: "3",
      label: "Auth Flow",
      children: <EndpointDetailsAuthFlow stateHostAddress={stateHostAddress} />,
    },
    {
      key: "4",
      label: "Services",
      children: <EndpointDetailsServices />,
    },
  ];

  const handleTab = (key) => {
    console.log(key);
    setSelectedTab(key);
  };
  const handleQueryParam = (key) => {
    console.log(defaultTab);

    if (defaultTab === "hostname") {
      setSelectedTab("1");
    } else if (defaultTab === "usercount") {
      setSelectedTab("1");
      console.log(defaultTab);
    } else if (defaultTab === "groupcount") {
      setSelectedTab("2");
      console.log(defaultTab);
    }
  };

  return (
    <>
      <Row style={{ marginBottom: "1rem" }}>
        <Breadcrumb
          items={[
            {
              title: (
                <>
                  <Link to="/">
                    {" "}
                    <HomeOutlined />
                  </Link>
                </>
              ),
            },
            {
              title: (
                <>
                  <UserOutlined />
                  <Link onClick={() => navigate(-1)}>
                    <span>Endpoints</span>
                  </Link>
                </>
              ),
            },
            {
              title: "Endpoint Details",
            },
          ]}
        />
      </Row>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">
                Endpoint Details -{" "}
                <span
                  style={{ color: "rgb(22, 119, 255)", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >{`${hostAddress ? hostAddress : stateHostAddress.hostAddress}`}</span>
              </h2>
            </Col>
          </Row>
          <Row className="tab-container">
            <Col span={24}>
              <Tabs activeKey={selectedTab} onChange={handleTab} items={items} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointDetails;
