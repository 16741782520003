import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Tabs,
  Breadcrumb,
} from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../constants";
import { logOutUser } from "../../common";
import axios from "axios";
import {
  HomeOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import UserDetailsGroups from "./UserDetailsGroups";
import UserDetailsProfile from "./UserDetailsProfile";

const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;

const EndpointUserDetails = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setuserData] = useState([]);
  const [appData, setappData] = useState([]);
  const [groupData, setgroupData] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const epmUsername = queryParams.get("epmUsername");
  const UserId = queryParams.get("userId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchUserDetails();
  }, [search, currentPage]);

  const fetchUserDetails = async () => {
    let pageDetails = {
      id: UserId,
      DomainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    try {
      let response = await axios.post(`${REACT_APP_PAM_API}/users/${UserId}`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });
      let data = response.data;
      let AppData = data.applications.map((app, i) => ({
        id: i,
        appid: app.id,
        appname: app.appName,
        type: app.typeOfRegn,
      }));

      let GroupData = data.groups.map((grp, i) => ({
        id: i,
        groupid: grp.id,
        groupname: grp.groupName,
      }));
      setuserData(data);
      setappData(AppData);
      setgroupData(GroupData);
    } catch (err) {
      if (err.response.data.message == "Invalid credentials") {
        logOutUser();
      } else {
      }
    }
  };

  const items = [
    { key: "1", label: "Groups", children: <UserDetailsGroups UserId={UserId} /> },
    {
      key: "2",
      label: "Profile",
      children: <UserDetailsProfile UserId={UserId} />,
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  href: "",
                  title: <HomeOutlined />,
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Users</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Users Details",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`${userData.firstName} ${userData.lastName}`} </h2>
              <p>{`${userData.emailAddress}`}</p>
            </Col>
          </Row>
          <Row>
            <UsergroupAddOutlined />
            <span style={{ marginLeft: "5px", marginRight: "10px" }}>User</span>
            <SwapRightOutlined />
            <span style={{ marginLeft: "5px", marginRight: "10px" }}>
              Password reset. User is now in one-time password mode.
            </span>
            <span>
              <Link>View Logs</Link>
            </span>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointUserDetails;
