import { Col, Row, Button, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListworkloadIdentites from "./ListworkloadIdentites";
import ListworkloadGroups from "./ListworkloadGroups";

const WorkloadIdentity = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ishistory = queryParams.get("history");

  const items = [
    {
      key: 1,
      label: "Identities",
      children: <ListworkloadIdentites />,
    },
    {
      key: 2,
      label: "Workload Groups",
      children: <ListworkloadGroups />,
    },
  ];
  const handleAddPolicy = () => {
    if (ishistory === true) {
      navigate("/pam/credentialRotation/addPassword");
    } else {
      navigate("/pam/credentialRotation/addSshKeys");
    }
  };

  useEffect(() => {
    console.log(ishistory);
  }, []);

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Workload Identity</h2>
            </Col>
            <Col>
              {currentTab === 1 && (
                <Button
                  type="primary"
                  onClick={() => navigate("/workloadIdentity/addWorkloadIdentity")}
                >
                  Add Workload Identity
                </Button>
              )}
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default WorkloadIdentity;
