import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDID from "./userDid";
import IssuerDID from "./IssuerDid";

const DIDS = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "User DID",
      children: <UserDID />,
    },
    {
      key: "2",
      label: "Issuer DID",
      children: <IssuerDID />,
    },
  ];
  const handleAddPolicy = () => {
    if (currentTab === "1") {
      navigate("/directory/did/addHolderDID");
    } else {
      navigate("/directory/did/addIssuerDID");
    }
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">DID</h2>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleAddPolicy()}>
                Add {currentTab === "1" ? "User DID" : "Issuer DID"}
              </Button>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DIDS;
