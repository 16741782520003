import { brown } from "@mui/material/colors";
import { Button, Col, Row, Select, Form, message } from "antd";
import React, { useEffect, useState } from "react";

export default function DevicesTab({
  prevTab,
  nextTab,
  setSelectedDeviceType,
  setSelectedBrowserType,
  // setSelectedDevice,
  // setSelectedBrowser,
  additionalConditions,
  setAdditionalConditions,
}) {
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  // const [selectedDeviceLocal, setSelectedDeviceLocal] = useState(null);
  // const [selectedBrowserLocal, setSelectedBrowserLocal] = useState(null);

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleNextClick = () => {
    form.validateFields().then(() => {
      if (selectedType === "Device") {
        setSelectedDeviceType(selectedType);
        // setSelectedDevice(selectedDeviceLocal);
      } else if (selectedType === "Browser") {
        setSelectedBrowserType(selectedType);
        // setSelectedBrowser(selectedBrowserLocal);
      }
      nextTab();
    });
  };

  const handleConditionValueChange = (value, index) => {
    const updatedConditions = [...additionalConditions];
    updatedConditions[index] = {
      ...updatedConditions[index],
      conditionValue: value,
    };
    setAdditionalConditions(updatedConditions);
  };

  useEffect(() => {
    setSelectedType("Browser");
    form.setFieldsValue({
      BrowserType: additionalConditions[5].conditionLabel,
      Browser: additionalConditions[5].conditionValue,
    });
  }, []);

  return (
    <Row>
      <Col span={24} style={{ padding: "20px" }}>
        <Form form={form} layout="vertical">
          <Row>
            <Col
              span={18}
              style={{
                background: "rgb(219, 238, 255)",
                borderRadius: "8px",
                padding: "2rem",
              }}
            >
              <Row gutter={20} style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <Form.Item
                    label="Select Browser"
                    name="BrowserType"
                    rules={[
                      {
                        required: true,
                        message: "Select Browser Type",
                      },
                    ]}
                  >
                    <Select
                      options={[{ label: "Browser", value: "Browser" }]}
                      style={{ width: "100%" }}
                      onChange={handleTypeChange}
                      placeholder="Select Browser"
                    />
                  </Form.Item>
                </Col>
                {selectedType === "Browser" && (
                  <Col span={12}>
                    <Form.Item
                      label="Select Browser Type"
                      name="Browser"
                      rules={[
                        {
                          required: true,
                          message: "Select Browser",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: "Google Chrome", value: "Google Chrome" },
                          { label: "Microsoft Edge", value: "Microsoft Edge" },

                          { label: "Firefox", value: "Firefox" },
                        ]}
                        style={{ width: "100%" }}
                        placeholder="Select Browser Type"
                        // onChange={handleBrowserChange}
                        onChange={(value) => {
                          handleConditionValueChange(value, 5);
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: "1rem" }}>
            <Col span={18}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={() => prevTab()}>Back</Button>
                <Button type="primary" htmlType="submit" onClick={handleNextClick}>
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
