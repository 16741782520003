import { Button, Col, Form, Input, Modal, Row, Select, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_SAM_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { InfoCircleOutlined } from "@ant-design/icons/lib/icons";

const { Option } = Select;

const UpdatePolicyV2 = ({
  selectedRow,
  notification,
  setIsModalVisible,
  isModalVisible,
  endpointGroups,
  domainData,
  epmUsers,
  epmGroups,
  adGroups,
  epm,
  fetchPolicy,
}) => {
  const [walletData, setwalletData] = useState([]);
  const [adUser, setAdUser] = useState([]);
  const [formData, setFormData] = useState();
  const [checkPolicyNotification, setCheckPolicyNotification] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [type, setType] = useState(selectedRow.adUser == "all" ? "option5" : "option1");
  const [type2, setType2] = useState(
    selectedRow.destinationEndpoint == "all" ? "option5" : "option1"
  );
  const [type3, setType3] = useState(selectedRow.endpointUser == "all" ? "option5" : "option1");
  const [policyType, setPolicyType] = useState(selectedRow?.policyType);
  const [serviceAccountType, setServiceAccountType] = useState(
    selectedRow?.endpointUser === "" ? "ad" : "local"
  );
  const [ouData, setOuData] = useState([]);
  const [directoryUser, setDirectoryUser] = useState([]);
  const [adDomainName, setAdDomainName] = useState(
    selectedRow?.adDomain ? selectedRow?.adDomain : null
  );
  const [adDomainId, setAdDomainId] = useState(null);
  const [ouName, setOuName] = useState(selectedRow?.adOu ? selectedRow?.adOu : null);
  const [serviceAccountNameList, setServiceAccountNameList] = useState([]);
  const [ouId, setouId] = useState(null);
  const [serviceAccountADUserList, setServiceAccountADUserList] = useState([]);
  const [serviceAccountADOuList, setServiceAccountADOuList] = useState([]);
  const [credentialData, setCredentialData] = useState([]);

  const [form] = Form.useForm();
  const handleADUser = (val) => {
    if (directoryUser.length > 0 && directoryUser.includes("all")) {
      // If "all" was previously selected and is now deselected, remove it from the array
      const filteredValues = val.filter((item) => item !== "all");
      setDirectoryUser(filteredValues);
    } else if (val.includes("all")) {
      // If "all" is selected, reset the directoryUser array
      setDirectoryUser(["all"]);
    } else {
      setDirectoryUser(val);
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    fetchWalletUser();
    fetchServiceAccount();
    fetchServiceAccountADDomain();
    setFormData([
      { name: ["type"], value: selectedRow.adUser == "all" ? "option5" : "option1" },
      { name: ["type2"], value: selectedRow.destinationEndpoint == "all" ? "option5" : "option1" },
      { name: ["type3"], value: selectedRow.endpointUser == "all" ? "option5" : "option1" },
      { name: ["policyName"], value: selectedRow?.policyName },
      { name: ["policyType"], value: selectedRow?.policyType },
      { name: ["serviceAccountType"], value: selectedRow?.endpointUser === "" ? "ad" : "local" },
      {
        name: ["endpointsval"],
        value:
          selectedRow.destinationEndpointType === "endpoint"
            ? selectedRow?.destinationEndpoint === ""
              ? null
              : selectedRow?.destinationEndpoint?.split(",")
            : null,
      },
      {
        name: ["endpointUser"],
        value:
          selectedRow.endpointUserType === "user"
            ? selectedRow?.endpointUser === ""
              ? null
              : selectedRow?.endpointUser?.split(",")
            : null,
      },
      { name: ["domainVal"], value: selectedRow?.adDomain ? selectedRow?.adDomain : null },
      { name: ["ou"], value: selectedRow?.adOu ? selectedRow?.adOu : null },
      {
        name: ["directoryUser"],
        value: selectedRow?.adUser === "" ? null : selectedRow?.adUser.split(","),
      },
      {
        name: ["actionVal"],
        value: selectedRow?.action,
      },
      {
        name: ["walletUserVal"],
        value: selectedRow?.walletUsers === "" ? null : selectedRow?.walletUsers?.split(","),
      },
      {
        name: ["directoryType"],
        value: "option1",
      },
      {
        name: ["sourceEndpoint"],
        value: selectedRow?.sourceEndpoint === "" ? null : selectedRow?.sourceEndpoint?.split(","),
      },
      {
        name: ["serviceAccountName"],
        value:
          selectedRow?.endpointUser === "" && selectedRow?.policyType === "ServiceAccount"
            ? selectedRow?.adUser?.split(",")
            : selectedRow?.endpointUser === ""
            ? null
            : selectedRow?.endpointUser?.split(","),
      },
      {
        name: ["authorizationRequired"],
        value: selectedRow?.authorizationRequired ? selectedRow?.authorizationRequired : 1,
      },
    ]);
  }, []);

  useEffect(() => {
    const findInitialADDomainId = domainData?.find(
      (domain) => domain?.directoryName === selectedRow?.adDomain
    );
    if (findInitialADDomainId) {
      fetchAdUser({ adDomainId: findInitialADDomainId?.id ? findInitialADDomainId?.id : null });
      setAdDomainName(findInitialADDomainId.directoryName);
      setAdDomainId(findInitialADDomainId?.id);

      // Find the object where OuName contains ouName
      const matchingOU = findInitialADDomainId.ous.find((ouObject) =>
        ouObject.OuName.includes(selectedRow?.adOu)
      );
      // Extract and log the Id if a match is found
      if (matchingOU) {
        const id = matchingOU.Id;
        setouId(id);
      } else {
        setouId(null);
      }
      setOuData(
        findInitialADDomainId?.ous?.map((data) => {
          return data;
        })
      );
    }
  }, []);

  const fetchServiceAccountADDomain = () => {
    let pageDetails = {
      tenantId: DOMAIN_ID(),
      filter: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API}/ad/GetAllDomains`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        const ADData = res?.data?.domains?.map((ad, i) => ({
          // id: i + 1,
          ...ad,
        }));

        setCredentialData(ADData ? ADData : []);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const fetchServiceAccount = (insId) => {
    let data = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 100,
      instanceIds: insId ? insId : [],
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const serviceAccountName = res?.data?.epmUsers
          ?.filter((user) => user.userType.toLowerCase() === "service")
          .map((user) => ({ ...user }));
        if (serviceAccountName.length === 0) {
          // serviceAccountNameInfo(val.map((item) => item.value));
        }
        setServiceAccountNameList(serviceAccountName);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const fetchWalletUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: 1,
      pageSize: 100,
      search: "",

      filter: "",
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setwalletData(res.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const fetchAdUser = (ouDn) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-users",
      adDomainId: ouDn.adDomainId,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setAdUser(data.data.adUsers);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchServiceAccountUser = (val) => {
    let pageDetails = {
      adId: val.id,
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API}/ad/GetServiceAccountUserByDomain`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setServiceAccountADUserList(res.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const checkOldwithCurrentData = (e) => {
    let newData = {
      policyName: e?.policyName,
      action: e?.actionVal,
      adDomain: adDomainName,
      adOu: ouName,
      adUser: e?.directoryUser ? e?.directoryUser?.join(",") : "",
      destinationEndpoint: e?.endpointsval ? e.endpointsval?.join(",") : "",
      endpointUser: e?.endpointUser ? e?.endpointUser?.join(",") : "",
      os: e?.os ? e.os : "",
      policyType: e?.policyType,
      // protocol: e.protocol,
      walletUsers: e?.walletUserVal ? e?.walletUserVal?.join(",") : "",
    };

    const relevantFields = [
      "policyName",
      "action",
      "adDomain",
      "adOu",
      "os",
      "policyType",
      // "protocol",
      "adUser",
      "destinationEndpoint",
      "endpointUser",
      "walletUsers",
    ];
    let relevantFieldsMatch = true;

    for (const field of relevantFields) {
      if (selectedRow[field] !== newData[field]) {
        relevantFieldsMatch = false;
        break;
      }
    }
    if (relevantFieldsMatch) {
      console.log("JSON objects match on relevant fields.");
      setIsModalVisible(false);
    } else {
      console.log("JSON objects do not match on relevant fields.");
      handleFinish(e);
    }
  };

  const handleFinish = (e) => {
    let tempUserSelectionType;
    let tempMatch;
    let tempSelection;
    let tempEndpointSelectionType;
    let tempMatch2;
    let tempSelection2;
    let tempEndpointUserSelectionType = "user";
    let tempMatch3;
    let tempSelection3;
    if (type === "option1" || type === "option4") {
      tempUserSelectionType = "user";
    } else if (type === "option2" || type === "option3") {
      tempUserSelectionType = "groups";
    } else {
      tempUserSelectionType = "tenant";
    }
    if (type === "option1" || type === "option2" || type === "option5") {
      tempMatch = "exact";
    } else {
      tempMatch = "wild";
    }
    if (type === "option1") {
      tempSelection = e.directoryUser ? e.directoryUser : [];
    } else if (type === "option2") {
      tempSelection = e.directoryGroups;
    } else if (type === "option5") {
      tempSelection = ["all"];
    } else {
      tempSelection = [e.userWildCard];
    }

    if (type2 === "option1" || type2 === "option4") {
      tempEndpointSelectionType = "endpoint";
    } else if (type2 === "option2" || type2 === "option3") {
      tempEndpointSelectionType = "group";
    } else {
      tempEndpointSelectionType = "tenant";
    }

    if (type2 === "option1" || type2 === "option2" || type2 === "option5") {
      tempMatch2 = "exact";
    } else {
      tempMatch2 = "wild";
    }
    if (type2 === "option1") {
      tempSelection2 = e.endpointsval ? e.endpointsval : [];
    } else if (type2 === "option2") {
      tempSelection2 = e.epmGroupVal;
    } else if (type2 === "option5") {
      tempSelection2 = ["all"];
    } else {
      tempSelection2 = [e.endpointWildCard];
    }

    if (type3 === "option5") {
      tempEndpointUserSelectionType = "tenant";
      tempSelection3 = ["all"];
    }

    if (type3 === "option1" || type3 === "option5") {
      tempMatch3 = "exact";
    } else {
      tempMatch3 = "wild";
    }
    if (type3 === "option1") {
      tempSelection3 = e.endpointUser ? e.endpointUser : [];
    } else if (type3 === "option5") {
      tempSelection3 = ["all"];
    } else {
      tempSelection3 = [e.endpointUserWildCard];
    }

    let data = {
      id: selectedRow?.id,
      action: e.actionVal,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyName: e.policyName,
      policyType: policyType,
      protocol: [],
      os: e.os ? e.os : "",
      adOu: ouName,
      adDomain: adDomainName,
      sAMAccountName: false,
      userOrGroups: {
        selectionType:
          e.policyType === "ServiceAccount" && e.serviceAccountType === "ad"
            ? "user"
            : tempUserSelectionType,
        selectionValue:
          e.policyType === "ServiceAccount" && e.serviceAccountType === "ad"
            ? e.serviceAccountName
            : tempSelection,
        match:
          e.policyType === "ServiceAccount" && e.serviceAccountType === "ad" ? "exact" : tempMatch,
      },
      endpointOrGroups: {
        source: {
          match: "exact",
          selectionType: "endpoint",
          selectionValue: e.sourceEndpoint,
        },
        destination: {
          selectionType: tempEndpointSelectionType,
          selectionValue: tempSelection2,
          match: tempMatch2,
        },
      },
      endpointUsers: {
        selectionType:
          policyType === "ServiceAccount" && e.serviceAccountType === "local"
            ? "user"
            : tempEndpointUserSelectionType,
        selectionValue:
          policyType === "ServiceAccount" && e.serviceAccountType === "local"
            ? e.serviceAccountName
            : tempSelection3,
        match:
          policyType === "ServiceAccount" && e.serviceAccountType === "local"
            ? "exact"
            : tempMatch3,
      },
      walletUsers: e.walletUserVal,
      adDomainId: adDomainId,
      adOuId: ouId,
      requireAuthorization: e.authorizationRequired === 1 ? "true" : "false",
    };

    setBtnLoading(true);
    axios
      .post(
        `${REACT_APP_API_DID_URL}/policyService/IsPolicyExists`,
        { ...data, update: 1 },
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setBtnLoading(false);
        if (res.data.isPolicyExists == true) {
          setCheckPolicyNotification(true);
        } else {
          updatePolicy(data);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to create now, PLease try again",
          });
        }
      });
  };

  const updatePolicy = (payload) => {
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/UpdatePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        notification.open({
          type: "success",
          content: `${payload.policyName} policy updated successfully`,
        });
        setIsModalVisible(false);
        fetchPolicy();
      })

      .catch((err) => {
        setIsModalVisible(true);
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: `Unable to create ${payload.policyName} policies`,
        });
      });
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontSize: 18 }}>
            Edit policy - <span style={{ color: "#1677FF" }}>{` ${selectedRow?.policyName}`}</span>
          </span>
        }
        footer={null}
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="authModal"
      >
        <Row className="content-conatiner">
          {checkPolicyNotification && (
            <Modal
              title={`Policy already exists for this combination, Please Create with some other combination`}
              open={checkPolicyNotification}
              onOk={() => {
                setCheckPolicyNotification(false);
              }}
              centered
              closeIcon={false}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  loading={btnLoading}
                  onClick={() => setCheckPolicyNotification(false)}
                >
                  Ok
                </Button>,
              ]}
            ></Modal>
          )}
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              fields={formData}
              onFieldsChange={(_, allFields) => {
                setFormData(allFields);
              }}
              onFinish={(e) => {
                checkOldwithCurrentData(e);
              }}
            >
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <Form.Item
                    name="policyName"
                    label="Policy Name"
                    rules={[
                      {
                        required: true,
                        message: "Policy name is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="policyType"
                    label="Policy Type"
                    rules={[
                      {
                        required: true,
                        message: "Select policy type",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search Policy Type"
                      optionFilterProp="children"
                      defaultValue={form.getFieldValue("policyType")}
                      onChange={(e) => {
                        form.setFieldsValue({
                          policyType: e,
                        });
                        setPolicyType(e);
                      }}
                    >
                      <Option value="ad">Active Directory</Option>
                      <Option value="local">Local Users</Option>
                      <Option value="ServiceAccount">Service Account</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {policyType === "ServiceAccount" && (
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name="serviceAccountType"
                      label="Service Account Type"
                      rules={[
                        {
                          required: true,
                          message: "Select Account type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Search  Account Type"
                        optionFilterProp="children"
                        onChange={(e) => {
                          setServiceAccountType(e);
                          form.setFieldsValue({
                            policySAType: e,
                          });
                        }}
                      >
                        <Option value="ad">Active Directory</Option>
                        <Option value="local">Local Users</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <h3 style={{ color: "blue", marginTop: "1rem" }}>Resources that can be accessed</h3>
              {policyType === "ServiceAccount" ? (
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <Form.Item
                      name="type2"
                      label="Endpoints"
                      rules={[
                        {
                          required: false,
                          message: "Select any option",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Endpoint"
                        onChange={(e) => {
                          form.setFieldsValue({
                            type2: e,
                          });
                          setType2(e);
                        }}
                        defaultValue={type2}
                      >
                        <Option value="option1">Endpoints</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {type2 === "option1" && (
                    <Col span={12}>
                      <Form.Item
                        name="endpointsval"
                        label="Destination Endpoints List"
                        rules={[
                          {
                            required: true,
                            message: "Select Endpoints",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={(e, val) => {
                            setServiceAccountNameList([]);
                            fetchServiceAccount(val.map((item) => Number(item.key)));
                            form.setFieldsValue({
                              endpointsval: e,
                            });
                          }}
                          defaultValue={form.getFieldValue("endpointsval")}
                        >
                          {epm &&
                            epm.length > 0 &&
                            epm.map((endpoint) => (
                              <Option key={endpoint.id} value={endpoint.name}>
                              {`${endpoint.name} `}
                              <b>({endpoint.publicIpAddress})</b>
                            </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <Form.Item
                      name="type2"
                      label="Endpoints and Endpoint Groups"
                      rules={[
                        {
                          required: false,
                          message: "Select any option",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Endpoint And Endpoint Groups"
                        onChange={(e) => {
                          form.setFieldsValue({
                            type2: e,
                          });
                          setType2(e);
                        }}
                        defaultValue={type2}
                      >
                        <Option value="option1">Endpoints</Option>
                        <Option value="option2">Endpoint Groups</Option>
                        <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                        <Option value="option4">Endpoint Matching Wild Card</Option>
                        <Option value="option5">Apply To Whole Tenant</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {type2 === "option1" ? (
                    <Col span={12}>
                      <Form.Item
                        name="endpointsval"
                        label="Endpoints List"
                        rules={[
                          {
                            required: true,
                            message: "Select Endpoints",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={(e) =>
                            form.setFieldsValue({
                              endpointsval: e,
                            })
                          }
                          defaultValue={form.getFieldValue("endpointsval")}
                        >
                          {epm &&
                            epm.length > 0 &&
                            epm.map((endpoint) => (
                              <Option key={endpoint.id} value={endpoint.name}>
                              {`${endpoint.name} `}
                              <b>({endpoint.publicIpAddress})</b>
                            </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    type2 === "option2" && (
                      <Col span={12}>
                        <Form.Item
                          name="epmGroupVal"
                          label="Endpoints Groups List"
                          rules={[
                            {
                              required: true,
                              message: "Policy name is required",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            onChange={(e) =>
                              form.setFieldsValue({
                                epmGroupVal: e,
                              })
                            }
                          >
                            {endpointGroups &&
                              endpointGroups.length > 0 &&
                              endpointGroups.map((group) => (
                                <Option value={group.groupName}>{group.groupName}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )
                  )}
                  {(type2 == "option3" || type2 == "option4") && (
                    <Col span={12}>
                      <Form.Item
                        name="endpointWildCard"
                        label="Wild Card"
                        rules={[
                          {
                            required: true,
                            message: "Wild Card is required",
                          },
                        ]}
                      >
                        <Input placeholder="prefix*" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              )}

              {policyType === "local" && (
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={12}>
                    <Form.Item
                      name="type3"
                      label="Endpoints Users"
                      rules={[
                        {
                          required: false,
                          message: "Policy name is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Endpoint Users"
                        onChange={(e) => {
                          setType3(e);
                          form.setFieldsValue({
                            type3: e,
                          });
                        }}
                        defaultValue={type3}
                      >
                        <Option value="option1">Endpoint Users</Option>

                        <Option value="option3">Endpoint Users Matching Wild Card</Option>

                        <Option value="option5">Apply To Whole Tenant</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {type3 === "option1" && (
                    <Col span={12}>
                      <Form.Item
                        name="endpointUser"
                        label="Endpoint User List"
                        rules={[
                          {
                            required: true,
                            message: "Select endpoint users",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={(e) =>
                            form.setFieldsValue({
                              endpointUser: e,
                            })
                          }
                          defaultValue={form.getFieldValue("endpointUser")}
                        >
                          {epmUsers &&
                            epmUsers.length > 0 &&
                            epmUsers.map((user) => <Option value={user.user}>{user.user}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {(type3 == "option3" || type3 == "option4") && (
                    <Col span={12}>
                      <Form.Item
                        name="endpointUserWildCard"
                        label="Wild Card"
                        rules={[
                          {
                            required: true,
                            message: "Wild Card is required",
                          },
                        ]}
                      >
                        <Input placeholder="prefix*" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              )}
              {policyType === "ad" && (
                <>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="domainVal"
                        label="Domain"
                        rules={[
                          {
                            required: true,
                            message: "Policy name is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Search Domain"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          defaultValue={form.getFieldValue("domainVal")}
                          onChange={(e, val) => {
                            setAdDomainName(val.children);
                            setAdDomainId(e);
                            form.setFieldsValue({
                              domainVal: e,
                            });
                            const selectedDomain = domainData.find((domain) => domain.id === e);
                            if (selectedDomain) {
                              setOuData(
                                selectedDomain?.ous?.map((data) => {
                                  let ouVal = data.OuName.split(",")[0];
                                  let nameOu = ouVal.substring(3);
                                  return data;
                                })
                              );
                              fetchAdUser({ adDomainId: parseInt(e) });
                            } else {
                              setOuData([]);
                            }
                          }}
                        >
                          {domainData?.map((dom) => (
                            <Option key={dom.id} value={dom.id}>
                              {dom.directoryName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="ou"
                        label="OU"
                        rules={[
                          {
                            required: false,
                            message: "Select OU",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Search OU"
                          optionFilterProp="children"
                          onChange={(e, val) => {
                            setOuName(val.children);
                            setouId(e);
                            form.setFieldsValue({
                              ou: e,
                            });
                            let ouDn = {
                              adDomainId: parseInt(val.key),
                              adOuId: e,
                            };
                            fetchAdUser(ouDn);
                          }}
                          defaultValue={form.getFieldValue("ou")}
                        >
                          {ouData?.map((ou) => (
                            <Option key={ou.AdId} value={ou.Id}>
                              {ou.OuName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="type"
                        label="Directory Users and Groups"
                        rules={[
                          {
                            required: true,
                            message: "Select",
                          },
                        ]}
                      >
                        {policyType !== "local" ? (
                          <Select
                            placeholder="Select User And Groups"
                            onChange={(e) => {
                              setType(e);
                              form.setFieldsValue({
                                type: e,
                              });
                            }}
                            defaultValue={type}
                          >
                            <Option value="option1">Directory Users </Option>
                            <Option value="option2">Directory Groups</Option>
                            <Option value="option3">Groups Matching Wild Card</Option>
                            <Option value="option4">Users Matching Wild Card</Option>
                            <Option value="option5">Apply To Whole Tenant</Option>
                          </Select>
                        ) : (
                          <Select
                            placeholder="Select User"
                            onChange={(e) => setType(e)}
                            defaultValue={type}
                          >
                            <Option value="option1">Directory Users </Option>
                            <Option value="option4">Users Matching Wild Card</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    {type === "option1" ? (
                      <Col span={12}>
                        <Form.Item
                          name="directoryUser"
                          label="Directory User List"
                          rules={[
                            {
                              required: true,
                              message: "Select directory users",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select directory user"
                            mode="multiple"
                            onChange={(e) =>
                              form.setFieldsValue({
                                directoryUser: e,
                              })
                            }
                            defaultValue={form.getFieldValue("directoryUser")}
                          >
                            {adUser && adUser.length > 0 && (
                              <>
                                {/* <Option value="all">Select All</Option> */}
                                {adUser.map((user) => (
                                  <Option key={user.UserName} value={user.UserName}>
                                    {user.UserName}
                                  </Option>
                                ))}
                              </>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : (
                      type === "option2" && (
                        <Col span={12}>
                          <Form.Item
                            name="adGroupsVal"
                            label="Directory Groups List"
                            rules={[
                              {
                                required: true,
                                message: "Policy name is required",
                              },
                            ]}
                          >
                            <Select
                              onChange={(e) =>
                                form.setFieldsValue({
                                  adGroupsVal: e,
                                })
                              }
                              mode="multiple"
                            >
                              {adGroups &&
                                adGroups.length > 0 &&
                                adGroups.map((user) => (
                                  <Option value={user.name}>{user.name}</Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )
                    )}
                    {(type == "option3" || type == "option4") && (
                      <Col span={12}>
                        <Form.Item
                          name="userWildCard"
                          label="Wild Card"
                          rules={[
                            {
                              required: true,
                              message: "Policy name is required",
                            },
                          ]}
                        >
                          <Input placeholder="prefix*" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {policyType === "ServiceAccount" && (
                <>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="SASourceEndpoint"
                        label="Endpoints"
                        rules={[
                          {
                            required: false,
                            message: "Select any option",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Endpoint"
                          onChange={(e) => {
                            form.setFieldsValue({
                              type2: e,
                            });
                            setType2(e);
                          }}
                          defaultValue={type2}
                        >
                          <Option value="option1">Endpoints</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="sourceEndpoint"
                        label="Source Endpoints List"
                        rules={[
                          {
                            required: true,
                            message: "Select source endpoint",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={(e) =>
                            form.setFieldsValue({
                              sourceEndpoint: e,
                            })
                          }
                          defaultValue={form.getFieldValue("sourceEndpoint")}
                        >
                          {epm?.map((endpoint) => (
                            <Option key={endpoint.id} value={endpoint.name}>
                              {`${endpoint.name} `}
                              <b>({endpoint.publicIpAddress})</b>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {serviceAccountType === "ad" && (
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="domainVal"
                          label="Domain"
                          rules={[
                            {
                              required: true,
                              message: "Select Domain",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e, val) => {
                              setAdDomainName(val.children);
                              setAdDomainId(e);
                              form.setFieldsValue({
                                domainVal: e,
                              });
                              const selectedDomain = credentialData.find(
                                (domain) => domain.id === e
                              );
                              if (selectedDomain) {
                                setServiceAccountADOuList([selectedDomain]);
                              } else {
                                setServiceAccountADOuList([]);
                              }
                              if (serviceAccountType === "ad") {
                                fetchServiceAccountUser(selectedDomain);
                              }
                            }}
                          >
                            {credentialData?.map((dom) => (
                              <Option key={dom.id} value={dom.id}>
                                {dom.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="ou"
                          label="Ou"
                          rules={[
                            {
                              required: false,
                              message: "Select Ou",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Search OU"
                            optionFilterProp="children"
                            onChange={(e, val) => {
                              setOuName(val.children);
                              setouId(e);
                              form.setFieldsValue({
                                ou: e,
                              });
                            }}
                          >
                            {serviceAccountADOuList?.map((ou) => (
                              <Option key={ou?.id} value={ou?.id}>
                                {ou?.serviceAccountDomain}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="serviceAccountName"
                        label="Service Account Name"
                        rules={[
                          {
                            required: true,
                            message: "Select account name",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={(e) =>
                            form.setFieldsValue({
                              serviceAccountName: e,
                            })
                          }
                          defaultValue={form.getFieldValue("serviceAccountName")}
                        >
                          {serviceAccountType === "ad"
                            ? serviceAccountADUserList?.map((name) => (
                                <Option value={name.username}>{name?.username}</Option>
                              ))
                            : serviceAccountNameList?.map((name) => (
                                <Option value={name.userName}>{name?.userName}</Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="authorizationRequired"
                        label={
                          <span>
                            Authorization Required&nbsp;
                            <Tooltip title="Authorization Required if set to 'yes', notification will be shown in the wallet">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Select authorization required",
                          },
                        ]}
                      >
                        <Select
                          onChange={(e) =>
                            form.setFieldsValue({
                              authorizationRequired: e,
                            })
                          }
                          defaultValue={form.getFieldValue("authorizationRequired")}
                        >
                          <Option value={1}>Yes</Option>
                          <Option value={0}>No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <h3 style={{ color: "blue", marginTop: "1rem" }}>Policy applicable to</h3>

              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  {/* <p className="search-label">Wallet Users</p> */}
                  <Form.Item
                    name="walletUserVal"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: "Select wallet users",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      onChange={(e) =>
                        form.setFieldsValue({
                          walletUserVal: e,
                        })
                      }
                      placeholder="Select wallet users"
                      defaultValue={form.getFieldValue("walletUserVal")}
                    >
                      {walletData?.map((user) => (
                        <Option value={user.emailAddress}>{user.emailAddress}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <h3 style={{ color: "blue", marginTop: "1rem" }}>Select the policy action</h3>

              <Row>
                <Col span={8}>
                  {/* <p className="search-label">Policy Action</p> */}
                  <Form.Item
                    name="actionVal"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: "Select policy action",
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) =>
                        form.setFieldsValue({
                          actionVal: e,
                        })
                      }
                      defaultValue={form.getFieldValue("actionVal")}
                    >
                      <Option value="Allow">Allow</Option>
                      <Option value="Deny">Deny</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                  span={2}
                  offset={22}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    // onClick={() => checkOldwithCurrentData()}
                  >
                    Update Policy
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UpdatePolicyV2;
