import {
  Col,
  Row,
  Select,
  Button,
  Input,
  message,
  DatePicker,
  Collapse,
  Typography,
  Modal,
} from "antd";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_API_PAM_URL,
} from "../../../constants";
import { logOutUser } from "../../../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
const { Option } = Select;

const CreateEndpointAccessPolicy = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [type3, setType3] = useState("option1");
  const [additionalRules, setAdditionalRules] = useState([]);
  const navigate = useNavigate();
  const [policyName, setPolicyName] = useState();
  const [ou, setOu] = useState();
  const [type, setType] = useState("option1");
  const [type2, setType2] = useState("option1");
  const [adUser, setAdUser] = useState([]);
  const [epmGroups, setEpmGroups] = useState([]);
  const [epmUsers, setEpmUsers] = useState([]);
  const [epm, setEpm] = useState([]);
  const [dom, setDom] = useState([]);
  const [directoryUser, setDirectoryUser] = useState([]);
  const [AdUsersVal, setAdUsersVal] = useState();
  const [directoryGroups, setDirectoryGroups] = useState();
  const [endpoints, setEndpoints] = useState();
  const [endpointGroups, setEndpointGroups] = useState();
  const [userWildCard, setUserWildCard] = useState();
  const [endpointWildCard, setEndpointWildCard] = useState();
  const [endpointUserWildCard, setEndpointUserWildCard] = useState();
  const [endpointUser, setEndpointUser] = useState();
  const [credentialData, setCredentialData] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [userType, setUserType] = useState("local");
  const [domainData, setDomainData] = useState([]);
  const [domainVal, setDomainVal] = useState();
  const [os, setOs] = useState();
  const [protocol, setProtocol] = useState();
  const [actionVal, setActionVal] = useState("Allow");
  const [checkPolicyNotification, setCheckPolicyNotification] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleADUser = (val) => {
    if (directoryUser.length > 0 && directoryUser.includes("all")) {
      // If "all" was previously selected and is now deselected, remove it from the array
      const filteredValues = val.filter((item) => item !== "all");
      setDirectoryUser(filteredValues);
    } else if (val.includes("all")) {
      // If "all" is selected, reset the directoryUser array
      setDirectoryUser(["all"]);
    } else {
      setDirectoryUser(val);
    }
  };
  const validateForm = () => {
    if (!policyName || !ou || !type || !type2 || !type3) {
      message.error("Please fill in all the fields.");
      return false;
    }

    // Additional validation checks based on your specific requirements

    return true;
  };
  const handleFinish = () => {
    if (!validateForm()) {
      return;
    }
    console.log(policyName);
    let tempUserSelectionType;
    let tempMatch;
    let tempSelection;
    let tempEndpointSelectionType;
    let tempMatch2;
    let tempSelection2;
    let tempEndpointUserSelectionType = "user";
    let tempMatch3;
    let tempSelection3;

    if (type === "option1" || type === "option4") {
      tempUserSelectionType = "user";
    } else if (type === "option2" || type === "option3") {
      tempUserSelectionType = "groups";
    } else {
      tempUserSelectionType = "tenant";
    }
    if (type === "option1" || type === "option2" || type === "option5") {
      tempMatch = "exact";
    } else {
      tempMatch = "wild";
    }
    if (type === "option1") {
      tempSelection = AdUsersVal ? [AdUsersVal] : directoryUser;
    } else if (type === "option2") {
      tempSelection = directoryGroups;
    } else if (type === "option5") {
      tempSelection = ["all"];
    } else {
      tempSelection = userWildCard;
    }

    if (type2 === "option1" || type2 === "option4") {
      tempEndpointSelectionType = "endpoint";
    } else if (type2 === "option2" || type2 === "option3") {
      tempEndpointSelectionType = "group";
    } else {
      tempEndpointSelectionType = "tenant";
    }

    if (type2 === "option1" || type2 === "option2" || type2 === "option5") {
      tempMatch2 = "exact";
    } else {
      tempMatch2 = "wild";
    }
    if (type2 === "option1") {
      tempSelection2 = endpoints;
    } else if (type2 === "option2") {
      tempSelection2 = endpointGroups;
    } else if (type === "option5") {
      tempSelection2 = ["all"];
    } else {
      tempSelection2 = endpointWildCard;
    }

    if (type3 === "option5") {
      tempEndpointUserSelectionType = "tenant";
      tempSelection3 = ["all"];
    }

    if (type3 === "option1" || type3 === "option5") {
      tempMatch3 = "exact";
    } else {
      tempMatch3 = "wild";
    }
    if (type3 === "option1") {
      tempSelection3 = endpointUser;
    } else if (type === "option5") {
      tempSelection3 = ["all"];
    } else {
      tempSelection3 = endpointUserWildCard;
    }

    let data = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      action: actionVal,
      policyName: policyName,
      policyType: userType,
      protocol: [protocol],
      os: os,
      adOu: ou,
      adDomain: domainVal,
      sAMAccountName: false,
      userOrGroups: {
        selectionType: tempUserSelectionType,
        selectionValue: tempSelection,
        match: tempMatch,
      },
      endpointOrGroups: {
        // source: {
        //   match: "exact",
        //   selectionType: "",
        //   selectionValue: [],
        // },
        destination: {
          selectionType: tempEndpointSelectionType,
          selectionValue: tempSelection2,
          match: tempMatch2,
        },
      },
      endpointUsers: {
        selectionType: tempEndpointUserSelectionType,
        selectionValue: tempSelection3,
        match: tempMatch3,
      },
    };

    setBtnLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/IsPolicyExists`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setBtnLoading(false);
        console.log(res);
        if (res.data.isPolicyExists == true) {
          setCheckPolicyNotification(true);
        } else {
          createPolicy(data);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to create now, PLease try again",
          });
        }
      });
  };

  const createPolicy = (payload) => {
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/CreatePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: `${policyName} policy created successfully`,
        });
        setTimeout(() => {
          navigate("/pam/policy");
        }, 3000);
      })

      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: `Unable to create ${policyName} policies`,
        });
      });
  };
  useEffect(() => {
    fetchEndpointUser();
    fetchAdUser();
    fetchEndpoints();
    fetchEndpointGroups();
    fetchDomains();
    fetchGrp();
    listDomain();
  }, []);
  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            name: grp.groupName,

            ...grp,
          }));
        }
        setCredentialData(Data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpointUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmUsers(data.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain };
        });

        console.log(
          res.data.data.adDomains.map((dom) => dom?.domain),
          outputArray
        );

        setDomainData(outputArray);
        console.log({ outputArray });
      });
  };
  const fetchAdUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setAdUser(data.data.adUsers);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpointGroups = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-groups",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmGroups(data.data.groups);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchDomains = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setDom(data.data.adDomains);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpoints = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpm(data.data.endpoints);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const addRule = () => {
    setAdditionalRules((prevRules) => [...prevRules, {}]);
  };

  const deleteRule = (index) => {
    setAdditionalRules((prevRules) => prevRules.filter((_, i) => i !== index));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {checkPolicyNotification && (
          <Modal
            title={`Policy already exists for this combination, Please Create with some other combination`}
            open={checkPolicyNotification}
            onOk={() => {
              setCheckPolicyNotification(false);
            }}
            centered
            closeIcon={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                loading={btnLoading}
                onClick={() => setCheckPolicyNotification(false)}
              >
                Ok
              </Button>,
            ]}
          ></Modal>
        )}
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Create Policies</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <p className="search-label">Policy Name</p>
              <Input
                placeholder="Enter Policy Name"
                onChange={(e) => setPolicyName(e.target.value)}
              />
            </Col>
            <Col span={8}>
              <p className="search-label">Policy Type</p>
              <Select
                showSearch
                placeholder="Search  Policy Type"
                optionFilterProp="children"
                defaultValue={userType}
                onChange={(e) => {
                  setUserType(e);
                  console.log(e);
                }}
              >
                <Option value="ad">Active Directory</Option>
                <Option value="local">Local Users</Option>
              </Select>
            </Col>
          </Row>

          <h3 style={{ color: "blue", marginTop: "2rem" }}> What can Access</h3>
          {userType === "local" && (
            <>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">Endpoints and Endpoint Groups</p>
                  <Select
                    placeholder="Select Endpoint And Endpoint Groups"
                    onChange={(e) => setType2(e)}
                  >
                    <Option value="option1">Endpoints</Option>
                    <Option value="option2">Endpoint Groups</Option>
                    <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                    <Option value="option4">Endpoint Matching Wild Card</Option>
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type2 === "option1" ? (
                  <Col span={8}>
                    <p className="search-label">Endpoints List</p>
                    <Select mode="multiple" onChange={(e) => setEndpoints(e)}>
                      {epm &&
                        epm.length > 0 &&
                        epm.map((endpoint) => (
                          <Option value={endpoint.name}>{endpoint.name}</Option>
                        ))}
                    </Select>
                  </Col>
                ) : (
                  type2 === "option2" && (
                    <Col span={8}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple" onChange={(e) => setEndpointGroups(e)}>
                        {epmGroups &&
                          epmGroups.length > 0 &&
                          epmGroups.map((group) => (
                            <Option value={group.groupName}>{group.groupName}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type2 == "option3" || type2 == "option4") && (
                  <Col span={8}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setEndpointWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <p className="search-label">OS</p>
                  <Select placeholder="Select OS" onChange={(e) => setOs(e)}>
                    <Option value="Windows">Windows</Option>
                    <Option value="Linux">Linux</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <p className="search-label">Protocol</p>
                  <Select placeholder="Select Protocol" onChange={(e) => setProtocol(e)}>
                    <Option value="SSH">SSH</Option>
                    <Option value="RDP">RDP</Option>
                    <Option value="VNC">VNC</Option>
                    <Option value="Password">Password</Option>
                  </Select>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">Endpoints Users</p>
                  <Select placeholder="Select Endpoint Users" onChange={(e) => setType3(e)}>
                    <Option value="option1">Endpoint Users</Option>

                    <Option value="option3">Endpoint Users Matching Wild Card</Option>

                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type3 === "option1" ? (
                  <Col span={8}>
                    <p className="search-label">Endpoint User List</p>
                    <Select mode="multiple" onChange={(e) => setEndpointUser(e)}>
                      {epmUsers &&
                        epmUsers.length > 0 &&
                        epmUsers.map((user) => <Option value={user.user}>{user.user}</Option>)}
                    </Select>
                  </Col>
                ) : (
                  type3 === "option2" && (
                    <Col span={8}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple">
                        <Option value="option1">Label 1</Option>
                        <Option value="option2">Label 2</Option>
                        <Option value="option3">Label 3</Option>
                      </Select>
                    </Col>
                  )
                )}
                {(type3 == "option3" || type3 == "option4") && (
                  <Col span={8}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setEndpointUserWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
              <h3 style={{ color: "blue", marginTop: "2rem" }}>Who can Access</h3>
              <Row gutter={16}>
                <Col span={8}>
                  <p className="search-label">Domain</p>
                  <Select
                    showSearch
                    placeholder="Search Domain"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => setDomainVal(e)}
                  >
                    {domainData &&
                      domainData.length > 0 &&
                      domainData.map((endpoint) => (
                        <Option value={endpoint.value}>{endpoint.value}</Option>
                      ))}
                  </Select>
                </Col>
                <Col span={8}>
                  <p className="search-label">Ou</p>
                  <Select
                    showSearch
                    placeholder="Search OU "
                    optionFilterProp="children"
                    onChange={(e) => setOu(e)}
                    // Set the default value
                  >
                    <Option value="ou=rider-park">ou=rider-park</Option>
                    <Option value="ou=rider-park 2">ou=rider-park 2</Option>
                    <Option value="ou=rider-park 3">ou=rider-park 3</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">Directory Users and Groups</p>
                  <Select placeholder="Select User And Groups" onChange={(e) => setType(e)}>
                    <Option value="option1">Directory Users </Option>
                    {/* <Option value="option2">Directory Groups</Option> */}
                    {/* <Option value="option3">Groups Matching Wild Card</Option> */}
                    {/* <Option value="option4">Users Matching Wild Card</Option> */}
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type === "option1" ? (
                  <Col span={8}>
                    <p className="search-label">Directory User List</p>
                    <Select
                      placeholder="Select directory user"
                      onChange={(e) => setAdUsersVal(e)}
                      showSearch
                    >
                      {adUser && adUser.length > 0 && (
                        <>
                          {adUser.map((user) => (
                            <Option key={user.Email} value={user.Email}>
                              {user.Email}
                            </Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </Col>
                ) : (
                  type === "option2" && (
                    <Col span={8}>
                      <p className="search-label"> Directory Groups List</p>
                      <Select onChange={(e) => setDirectoryGroups(e)}>
                        {credentialData &&
                          credentialData.length > 0 &&
                          credentialData.map((user) => (
                            <Option value={user.name}>{user.name}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type == "option3" || type == "option4") && (
                  <Col span={8}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setUserWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
          {/* <h3 style={{ color: "#2B09FF" }}>Rules</h3> */}

          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              {/* <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">Approval Type</p>
                  <Select>
                    <Option value="option1">One Time</Option>
                    <Option value="option2">Few Hours</Option>
                    <Option value="option3">Recurring</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <p className="search-label">Approval Duration</p>
                  <Select>
                    <Option value="option1">1 Day</Option>
                    <Option value="option2">1 Hour</Option>
                    <Option value="option3">! week</Option>
                  </Select>
                </Col>

                <Col span={8}>
                  <p className="search-label">Schedule</p>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <RangePicker
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      // onChange={handleRangeChange}
                    />
                  </Space>
                </Col>
              </Row> */}
            </Col>
          </Row>
          {userType === "ad" && (
            <>
              <Row gutter={16}>
                <Col span={8}>
                  <p className="search-label">Endpoints and Endpoint Groups</p>
                  <Select
                    placeholder="Select Endpoint And Endpoint Groups"
                    onChange={(e) => setType2(e)}
                  >
                    <Option value="option1">Endpoints</Option>
                    <Option value="option2">Endpoint Groups</Option>
                    <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                    <Option value="option4">Endpoint Matching Wild Card</Option>
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type2 === "option1" ? (
                  <Col span={8}>
                    <p className="search-label">Endpoints List</p>
                    <Select mode="multiple" onChange={(e) => setEndpoints(e)}>
                      {epm &&
                        epm.length > 0 &&
                        epm.map((endpoint) => (
                          <Option value={endpoint.name}>{endpoint.name}</Option>
                        ))}
                    </Select>
                  </Col>
                ) : (
                  type2 === "option2" && (
                    <Col span={8}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple" onChange={(e) => setEndpointGroups(e)}>
                        {epmGroups &&
                          epmGroups.length > 0 &&
                          epmGroups.map((group) => (
                            <Option value={group.groupName}>{group.groupName}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type2 == "option3" || type2 == "option4") && (
                  <Col span={8}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setEndpointWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <p className="search-label">OS</p>
                  <Select placeholder="Select OS" onChange={(e) => setOs(e)}>
                    <Option value="Windows">Windows</Option>
                    <Option value="Linux">Linux</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <p className="search-label">Protocol</p>
                  <Select placeholder="Select Protocol" onChange={(e) => setProtocol(e)}>
                    <Option value="SSH">SSH</Option>
                    <Option value="RDP">RDP</Option>
                    <Option value="VNC">VNC</Option>
                    <Option value="Password">Password</Option>
                  </Select>
                </Col>
              </Row>
              <h3 style={{ color: "blue", marginTop: "2rem" }}>Who can Access</h3>

              <Row gutter={16}>
                <Col span={8}>
                  <p className="search-label">Domain</p>
                  <Select
                    showSearch
                    placeholder="Search Domain"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => setDomainVal(e)}
                  >
                    {domainData &&
                      domainData.length > 0 &&
                      domainData.map((endpoint) => (
                        <Option value={endpoint.value}>{endpoint.value}</Option>
                      ))}
                  </Select>
                </Col>
                <Col span={8}>
                  <p className="search-label">Ou</p>
                  <Select
                    showSearch
                    placeholder="Search OU "
                    optionFilterProp="children"
                    onChange={(e) => setOu(e)}
                    // Set the default value
                  >
                    <Option value="ou=rider-park">ou=rider-park</Option>
                    <Option value="ou=rider-park 2">ou=rider-park 2</Option>
                    <Option value="ou=rider-park 3">ou=rider-park 3</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={8}>
                  <p className="search-label">Directory Users and Groups</p>
                  <Select placeholder="Select User And Groups" onChange={(e) => setType(e)}>
                    <Option value="option1">Directory Users </Option>
                    <Option value="option2">Directory Groups</Option>
                    <Option value="option3">Groups Matching Wild Card</Option>
                    <Option value="option4">Users Matching Wild Card</Option>
                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type === "option1" ? (
                  <Col span={8}>
                    <p className="search-label">Directory User List</p>
                    <Select
                      placeholder="Select directory user"
                      mode="multiple"
                      onChange={handleADUser}
                      value={directoryUser}
                    >
                      {adUser && adUser.length > 0 && (
                        <>
                          <Option value="all">Select All</Option>
                          {adUser.map((user) => (
                            <Option key={user.Email} value={user.Email}>
                              {user.Email}
                            </Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </Col>
                ) : (
                  type === "option2" && (
                    <Col span={8}>
                      <p className="search-label"> Directory Groups List</p>
                      <Select mode="multiple" onChange={(e) => setDirectoryGroups(e)}>
                        {credentialData &&
                          credentialData.length > 0 &&
                          credentialData.map((user) => (
                            <Option value={user.name}>{user.name}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type == "option3" || type == "option4") && (
                  <Col span={8}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setUserWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
          <Row>
            <Col span={8}>
              <p className="search-label">Policy Action</p>
              <Select onChange={(e) => setActionVal(e)} defaultValue={actionVal}>
                <Option value="Allow">Allow</Option>
                <Option value="Deny">Deny</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
              span={2}
              offset={22}
            >
              <Button type="primary" onClick={() => handleFinish()}>
                Create Policy
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreateEndpointAccessPolicy;
