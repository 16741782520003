import { Col, Row, Table, Dropdown, Button, Input, message, Tag, Modal, Form } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_SAM_URL, DOMAIN_ID, ORG_ID } from "../../constants";
import axios from "axios";
import { MoreOutlined } from "@ant-design/icons";
import { useDebounce } from "../../common/debounce";
import ReactJoyride from "react-joyride";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";


const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const ServiceAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [Data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [Accountname, setAccountname] = useState("");
  const [description, setDescription] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const { TextArea } = Input;
  const [sortModel, setSortModel] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const onBoardType = queryParams.get("type");
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = Data
      .filter((data) => data.username.toLowerCase().includes(value.toLowerCase()))
      .map((data) => ({
        label: data.username,
        value: data.username,
      }));

    setOptions(filteredOptions);
  };


  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsOnboarding(onBoardType ? true : false);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: <span>Service account authentication logs</span>,
        disableBeacon: true,
      },
      {
        target: "#onboarding_step2",
        content: <span>Search here to view logs</span>,
        disableBeacon: true,
        // title: "Endpoint Authentication MFA",
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  useEffect(() => {
    fetchServiceaccounts();
    console.log(REACT_APP_API_SAM_URL);
  }, [debouncedValue, paginationParams]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const fetchServiceaccounts = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNo: paginationParams.currentPage,
      pageSize: paginationParams.pageSize,
      filter: {
        filterBy: "",
        Value: "",
      },
      search: search,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_SAM_URL}/getAll`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setData(res.data.data);
        setTotalCount(res.data.totalCount);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const AddService = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      username: Accountname,
      description: description,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_SAM_URL}/service-accounts`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setIsCreate(false);
        fetchServiceaccounts();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleStatus = (val, id) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      id: id,
      active: val,
    };
    setIsLoading(true);
    axios
      .put(`${REACT_APP_API_SAM_URL}/updateServiceAccount`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setIsCreate(false);
        fetchServiceaccounts();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const columns = [
    {
      headerName: "Service Account Name",
      field: "username",
      key: "serviceAccountName",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      key: "description",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      flex: 1,
      renderCell: (value, record) => (
        <>
          {value.row.status === "active" ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </>
      ),
    },
    {
      headerName: "Action",
      key: "action",
      flex: 1,
      renderCell: (value, record) => (
        <>
          {value.row.status == "active" ? (
            <Dropdown
              placement="bottomLeft"
              menu={{
                items: [
                  {
                    key: "deactivate",
                    label: (
                      <Link onClick={() => handleStatus(false, value.row.id)}>Deactivate</Link>
                    ),
                    danger: true,
                  },
                ],
              }}
            >
              <Button type="text" shape="circle" icon={<MoreOutlined />} />
            </Dropdown>
          ) : (
            <Dropdown
              placement="bottomLeft"
              menu={{
                items: [
                  {
                    key: "activate",
                    label: <Link onClick={() => handleStatus(true, value.row.id)}>Activate</Link>,
                  },
                ],
              }}
            >
              <Button type="text" shape="circle" icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </>
      ),
    },
  ];
  const data1source = [
    {
      serviceAccountName: "mysql-admin",
    },
    {
      serviceAccountName: "mysql-authnull",
    },
    {
      serviceAccountName: "mysql-kloudone",
    },
    {
      serviceAccountName: "mysql-server",
    },
  ];

  const filterOption = [
    {
      label: "Endpoint Groups",
      value: "endpointGroups",
    },
    {
      label: "Service Account Name",
      value: "serviceAccountName",
    },
  ];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {isOnBoarding && startTour()}
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Service Account</h2>
            </Col>
            {/* <Col>
              <Link
                onClick={() => {
                  setIsCreate(true);
                }}
              >
                <Button type="primary">Create Service Account</Button>
              </Link>
            </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6} id="onboarding_step2">
                      <p className="search-label">Search</p>
                      <div>
                        <AutoComplete
                          placeholder="Search Service Account Name"
                          allowClear
                          style={{width:250}}
                          onSearch={handleSearch}
                          options={options}
                          onChange={(value) => {
                            setSearch(value);
                          }}
                        />
                        {/* <Input
                          placeholder="Search"
                          allowClear
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        /> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24} id="onboarding_step1">
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={Data}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              /> */}

              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={Data}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isCreate && (
        <div className="video-container">
          <Modal
            title="Create Service Account"
            centered
            open={isCreate}
            onOk={() => setIsCreate(false)}
            onCancel={() => setIsCreate(false)}
            width={800}
            footer={null}
          >
            <Row style={{ marginTop: "30px" }}>
              <Col span={24}>
                <Form form={form} layout="vertical" onFinish={AddService}>
                  <Row className="search-box-container">
                    <Col span={24}>
                      <Form.Item
                        name="serviceName"
                        label="Service Account Name"
                        rules={[
                          {
                            required: true,
                            message: "Service Name required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Service Account Name"
                          onChange={(e) => setAccountname(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="search-box-container">
                    <Col span={24}>
                      <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Description required",
                          },
                        ]}
                      >
                        <TextArea
                          placeholder="Enter Description"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    style={{
                      marginTop: "1rem",
                      marginBottom: "0rem",
                      textAlign: "end",
                    }}
                  >
                    <Button htmlType="submit" type="primary" loading={isLoading}>
                      Create
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ServiceAccount;
