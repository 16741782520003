import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Input, Form, message, Space } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import { logOutUser } from "../../common";

const AddHolderrDID = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [notification, setNotification] = message.useMessage();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [formData, setFormData] = useState({});
  const [name, setName] = useState("");
  const [Discp, setDiscp] = useState("");
  const [WalletData, setWalletData] = useState([]);
  const [issuerData, setissuerData] = useState([]);
  const [SelectedIssuer, setSelectedIssuer] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");

  useEffect(() => {
    fetchWallets();
    fetchIssuerList();
  }, [console.log()]);

  useEffect(() => {
    const initialValues = {
      userType: "Holder DID",
      name: "",
    };
    form.setFieldsValue(initialValues);
    setFormData({
      userType: "Holder DID",
      name: "",
    });
  }, [console.log()]);

  const handleUserType = (value, data) => {
    form.setFieldsValue({
      userType: value,
    });
    setFormData({ ...formData, userType: value });
  };

  const fetchIssuerList = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let issuerData =
          res?.data?.map((data) => ({
            id: data.IssuerId,
            text: data.Name,
          })) || [];
        setissuerData(issuerData);
      });
  };

  const fetchWallets = async (searchText, pageNo) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_API_DID_URL}/walletService/walletUserList`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      let walletData = res?.data?.users?.map((user, i) => ({
        id: user.walletId,
        text: user.emailAddress,
      }));
      setWalletData(walletData);
    } catch (err) {
      if (err.response.status == 401) {
        logOutUser();
      }
    }
  };

  const createHolder = () => {
    let data = {
      method: "brcm",
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      walletUserId: selectedWallet,
      issuerId: SelectedIssuer,
      name: name,
      description: Discp,
    };
    let url = `${REACT_APP_API_DID_URL}/did/createHolderDid`;

    axios
      .post(url, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        // createdIssuerId = res.data.id;
        navigate(-1);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          logOutUser();
        }
      });
  };

  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">Create DID</h2>
          </Col>
        </Row>
        <Form form={form} layout="vertical" onFinish={() => createHolder()}>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={6}>
                  <Form.Item name="userType" label="User Type">
                    <Input disabled onChange={handleUserType} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Name required",
                      },
                    ]}
                  >
                    <Input onChange={(e) => setName(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {
                <Row align="middle" justify="space-between">
                  <Col span={6}>
                    <Form.Item
                      name="wallet"
                      label="Select User Wallet"
                      rules={[
                        {
                          required: true,
                          message: "Select User Wallet",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={(option) => setSelectedWallet(option.value)}
                        options={WalletData.map((WalletData) => ({
                          value: WalletData.text,
                          label: WalletData.text,
                        }))}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="select-issuer"
                      label="Select Issuer"
                      rules={[
                        {
                          required: true,
                          message: "Select Issuer",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        labelInValue={true}
                        optionFilterProp="children"
                        onChange={(option) => setSelectedIssuer(option.value)}
                        options={issuerData.map((issuerData) => ({
                          value: issuerData.id,
                          label: issuerData.text,
                        }))}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}></Col>
                </Row>
              }
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={16}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: false,
                        message: "Name Required!",
                      },
                    ]}
                  >
                    <TextArea rows={8} onChange={(e) => setDiscp(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "1rem", marginRight: "4rem" }}>
            <Col span={11}>
              <Space>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Create DID
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default AddHolderrDID;
