import { Button, Col, Row } from "antd";
import React from "react";
import onboard from "../../assets/onboard.png";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={style.welcome}>
        <Row style={{ textAlign: "center", margin: "1rem 2rem" }}>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <h2 className="titles">Welcome</h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <p style={{ fontSize: "16px" }}>
                  Let’s get you started.
                  <br />
                  <br />
                  In order to drive success for you to be able to test AuthNull, you’ll want to
                  complete a few suggested steps in the next screen
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={16}>
                <img src={onboard} alt="Image" width="350" height="300" />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={8}>
                <Button
                  block
                  type="primary"
                  style={{ margin: "1rem 0" }}
                  onClick={() => navigate("/onboard/config")}
                >
                  Let’s go
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

const style = {
  welcome: {
    margin: "auto",
    border: "1px solid #000",
  },
};

export default Welcome;
