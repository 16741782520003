import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useState, useEffect, useRef } from "react";
import ReactJson from "@microlink/react-json-view";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { AUTH_TOKEN, ORG_ID, DOMAIN_ID, REACT_APP_API_DID_URL } from "../../../constants";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const JsonCellRenderer = ({ value }) => (
  <ReactJson
    src={value}
    name={false}
    collapsed={true}
    theme={"rjv-default"}
    enableClipboard={false}
    enableEdit={false}
    enableAdd={false}
    enableDelete={false}
    displayDataTypes={false}
    displayObjectSize={false}
  />
);

const columns = [
  {
    field: "log_message",
    headerName: "JSON",
    flex: 1,
    height: 600,
    renderCell: (params) => <JsonCellRenderer value={params.value} />,
  },
  {
    field: "host_name",
    headerName: "Host Name",
    flex: 1,
  },
];

const AgentLogs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostName = queryParams.get("hostName");
  const [isLoading, setIsLoading] = useState(false);
  const [agentLogs, setAgentLogs] = useState([]);

  useEffect(() => {
    fetchAgentLogs();
  }, []);

  const getRowHeight = () => "auto";

  const fetchAgentLogs = async () => {
    try {
      setIsLoading(true);
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        hostName: hostName,
      };
      const res = await axios.post(`${REACT_APP_API_DID_URL}/log/endpoint/logdisplay`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      // Check if the data is null or empty
      if (!res?.data?.data || res.data.data.length === 0) {
        setAgentLogs([]);
        setIsLoading(false);
        return;
      }

      // Process data
      let tempData = res.data.data.map((item) => {
        return {
          ...item,
          id: item.id,
          org_id: item.org_id,
          tenant_id: item.tenant_id,
          log_message: {
            log_message: item.log_message,
            public_ip: item.public_ip,
            created_at: item.created_at,
          },
          host_name: item.host_name,
        };
      });

      setAgentLogs(tempData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <h2 className="title">
            Endpoint Agent Logs -<span style={{ color: "blue" }}>{hostName}</span>
          </h2>
        </Col>

        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoading}
            columns={columns}
            rows={agentLogs}
            style={{ border: "none", minHeight: "50" }}
            sx={{
              "& .MuiDataGrid-cell": {
                padding: "16px 8px",
              },
            }}
            getRowHeight={getRowHeight}
          />
        </Col>
      </Row>
    </>
  );
};

export default AgentLogs;
