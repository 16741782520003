import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { Col, Row, Select, Menu, Tag, message, Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../../../constants";
import axios from "axios";
import ReactJson from "@microlink/react-json-view";
import moment from "moment-timezone";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const JsonCellRenderer = ({ value }) => (
  <ReactJson
    src={value}
    name={false}
    collapsed={false}
    theme={"rjv-default"}
    enableClipboard={false}
    enableEdit={false}
    enableAdd={false}
    enableDelete={false}
    displayDataTypes={false}
    displayObjectSize={false}
    displayArrayKey={false}
  />
);

export default function EndpointRulesPolicies() {
  const apiRef = useGridApiRef();
  const [notification, notificationHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [endpoints, setEndpoints] = useState([]);

  //pagination
  const PAGESIZE = 10;
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  useEffect(() => {
    fetchEndpoints();
  }, []);

  useEffect(() => {
    fetchEndpointsPolicies();
  }, [appliedFilters, paginationParams, currentPage]);

  const getRowHeight = () => "auto";

  const columns = [
    {
      headerName: "Policy Name",
      field: "rule_name",
      key: "rule_name",
    },
    {
      headerName: "Endpoint Name",
      field: "endpoint_name",
      key: "endpoint_name",
    },

    {
      field: "rule_conditions",
      headerName: "Conditions",
      renderCell: (record) => <JsonCellRenderer value={record.value} />,
    },
    {
      field: "rule_permission",
      headerName: "Permissions",
      renderCell: (record) => <JsonCellRenderer value={record.value} />,
    },
    {
      headerName: "Created At",
      field: "created_at",
      key: "created_at",
      renderCell: (val) =>
        moment(val?.row?.created_at).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      renderCell: (val) => (
        <Tag color={val?.row?.status === "Active" ? "green" : "red"}>{val?.row?.status}</Tag>
      ),
    },
    {
      headerName: "Action",
      dataIndex: "id",
      key: "id",
      renderCell: (val) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Link
                  to="/dit/createPolicy?edit=true"
                  onClick={() => {
                    localStorage.setItem("policyData", JSON.stringify(val.row));
                  }}
                >
                  Edit
                </Link>
              </Menu.Item>
              <Menu.Item key="2" danger={true}>
                <a
                  onClick={() => {
                    handleDeletePolicy(val.row?.rule_id);
                  }}
                >
                  Delete
                </a>
              </Menu.Item>
              {val?.row?.status === "Active" ? (
                <Menu.Item key="3" danger={true}>
                  <a
                    onClick={() => {
                      handleStatusChange(val.row?.rule_id, "Inactive");
                    }}
                  >
                    Deactivate
                  </a>
                </Menu.Item>
              ) : (
                <Menu.Item key="3">
                  <a
                    onClick={() => {
                      handleStatusChange(val.row?.rule_id, "Active");
                    }}
                  >
                    Activate
                  </a>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomLeft"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const calculateColumnWidths = (data, columnsToAutoSize, includeHeaders = false) => {
    const columnWidths = {};

    columnsToAutoSize?.forEach((col) => {
      let maxWidth = includeHeaders ? col.length * 13 : 0;

      data?.forEach((row) => {
        const value = row[col];
        let width = 0;

        if (typeof value === "object" && value !== null) {
          const jsonString = JSON.stringify(value, null, 2);
          const jsonLines = jsonString.split("\n").length;

          if (jsonLines > 5) {
            width = jsonString.length * 1 + jsonLines * 1.5;
          } else {
            width = jsonString.length * 3 + jsonLines * 1.5;
          }
        } else {
          width = value ? value.toString().length * 8 : 0;
        }

        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      columnWidths[col] = maxWidth;
    });

    return columnWidths;
  };

  const columnWidths = calculateColumnWidths(
    policies,
    ["rule_name", "endpoint_name", "created_at", "action", "rule_permission", "rule_conditions"],
    true
  );

  // Apply the calculated widths to the columns
  const adjustedColumns = columns.map((col) => {
    return {
      ...col,
      width: columnWidths[col.field] || col.width,
    };
  });

  const fetchEndpointsPolicies = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      filters:
        Object.keys(appliedFilters).length === 0
          ? [{ filterParameter: "", filterValue: "" }]
          : Object.entries(appliedFilters).map(([key, value]) => ({
              filterParameter: key,
              filterValue: value,
            })),
    };
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listManualEndpointRule`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setPolicies(res?.data?.data || []);
      setTotalCount(res?.data?.total_count);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
      setIsLoading(false);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load policies, try reloading the page.",
      });
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchEndpoints = async () => {
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpoints`,
        {
          org_Id: ORG_ID(),
          tenant_Id: DOMAIN_ID(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const tempEndpoints = [...res?.data?.l_endpoint, ...res?.data?.w_endpoint];

      const tempData = tempEndpoints.map((item, index) => {
        return {
          id: index + 1,
          label: item.public_ip,
          value: item.public_ip,
        };
      });

      setEndpoints(tempData);
    } catch (error) {
      console.log("Failed to fetch endpoints:", error);
      notification.open({
        type: "error",
        content: "Failed to load endpoints, try reloading the page.",
      });
      setEndpoints([]);
    }
  };

  const applyFilter = (type, value) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  };

  const getAppliedFilterValue = (type) => {
    return appliedFilters[type] ?? null;
  };

  const handleDeletePolicy = async (ruleId) => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      ruleId: ruleId,
    };
    try {
      setIsLoading(true);
      await axios.post(`${REACT_APP_API}/api/v1/policyService/deleteManualEndpointRule`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      fetchEndpointsPolicies();
      notification.open({
        type: "success",
        content: "Enpoint Policy Deleted Successfully",
      });
      setIsLoading(false);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to delete policy, try again.",
      });
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        id: id,
        type: "DITPolicy",
        status: status,
      };
      setIsLoading(true);
      await axios.post(`${REACT_APP_API}/api/v1/policyService/changeStatus`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      notification.open({
        type: "success",
        content: `DIT Policy ${status} Successfully`,
      });
      setIsLoading(false);
      setAppliedFilters([]);
      fetchEndpointsPolicies();
    } catch (error) {
      notification.open({
        type: "error",
        content: "Something went wrong, try again.",
      });
      setIsLoading(false);
    }
  };

  return (
    <Row className="content-container">
      {notificationHolder}
      <Col span={24}>
        <Row justify="space-between">
          <h1 className="title">Endpoint Policies</h1>
          <Button type="primary">
            <Link to="/dit/createPolicy">Create Policy</Link>
          </Button>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={20} style={{ marginBottom: "1rem" }}>
          <Col span={6}>
            <h5 className="search-label">Filter By Endpoint:</h5>
            <Select
              showSearch
              placeholder="Select Endpoint"
              style={{ width: "100%" }}
              // options={endpoints}
              onChange={(val) => applyFilter("endpointName", val)}
              value={getAppliedFilterValue("endpointName")}
            >
              {endpoints.map((endpoint) => (
                <Select.Option key={endpoint.id} value={endpoint.value}>
                  {endpoint.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <h5 className="search-label">Status:</h5>
            <Select
              placeholder="Select Status"
              style={{ width: "100%" }}
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              onChange={(val) => applyFilter("status", val)}
              value={getAppliedFilterValue("status")}
            />
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: "2rem" }}>
          <Col>
            <p>{Object.entries(appliedFilters).length > 0 && "Applied Filters:"}</p>
            {Object.entries(appliedFilters).length > 0 &&
              Object.entries(appliedFilters).map(([key, value]) => {
                if (value) {
                  return (
                    <Tag
                      key={key}
                      closable={true}
                      onClose={() => {
                        setAppliedFilters((prevFilters) => {
                          let tempFilters = { ...prevFilters };
                          delete tempFilters[key];
                          return tempFilters;
                        });
                      }}
                    >{`${key}: ${value}`}</Tag>
                  );
                }
                return null;
              })}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <DataGrid
          apiRef={apiRef}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          style={{ border: "none", minHeight: "50" }}
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "16px 8px",
            },
          }}
          columns={adjustedColumns}
          rows={policies}
          loading={isLoading}
          getRowId={(row) => row.rule_id}
          getRowHeight={getRowHeight}
          paginationMode="server"
          rowCount={totalCount}
          page={currentPage}
          paginationModel={currentPageModel}
          filterMode="server"
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          onPaginationModelChange={(params) => {
            setCurrentPage(params.page);
          }}
        />
      </Col>
    </Row>
  );
}
