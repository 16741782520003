import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Tabs,
  Breadcrumb,
} from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Createworkload from "./Createworkload";
import WorkloadwalletAssign from "./WorkloadwalletAssign";


const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;

const ServiceAccounts = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("1");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ishistory = queryParams.get("history");
  const UserId = queryParams.get("userId");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    console.log(ishistory);
    handleTab();
  }, [search, currentPage]);

  const handleTab = (key) => {
    if (ishistory) {
      setCurrentTab("3");
    } else {
      setCurrentTab(key);
    }
  };

  const items = [
    { key: "1", label: "Create Workload Identity", children: <Createworkload /> },
    {
      key: "2",
      label: "Wallet Assignment",
      children: <WorkloadwalletAssign />,
    },
    // {
    //     key: "3",
    //     label: "Dynamic AuthZ",
    //     children: <Workloaddynamicauthz/>,

    //   },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  href: "",
                  title: <HomeOutlined />,
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Workload Identity</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Add Workload Identity",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">{`Add Workload Identity`} </h2>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                activeKey={currentTab}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ServiceAccounts;
