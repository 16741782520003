import { Button, Col, Dropdown, Input, Menu, Row, Select, Tag, message, Drawer } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../constants";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import ReactJson from "@microlink/react-json-view";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Pending", value: "Pending" },
  { label: "Deprecated", value: "Deprecated" },
];

const policyTypesOptions = [
  { label: "Endpoints", value: "endpoints" },
  { label: "Active Directory", value: "activeDirectory" },
  { label: "Radius", value: "radius" },
  { label: "App Control", value: "appControl" },
  { label: "Database", value: "database" },
];

const JsonCellRenderer = ({ value }) => (
  <ReactJson
    src={value}
    name={false}
    collapsed={false}
    theme={"rjv-default"}
    enableClipboard={false}
    enableEdit={false}
    enableAdd={false}
    enableDelete={false}
    displayDataTypes={false}
    displayObjectSize={false}
    displayArrayKey={false}
  />
);

const PAGESIZE = 10;

export default function Policies() {
  const [notification, notificationHolder] = message.useMessage();
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const [viewPolicyDrawer, setViewPolicyDrawer] = useState(false);
  const [policyJsonData, setPolicyJsonData] = useState();

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  useEffect(() => {
    fetchPolicyData();
  }, [appliedFilters, debouncedValue, currentPage]);

  const fetchPolicyData = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      requestId: "", // DOUBT: for backend use only.
      limit: paginationParams.pageSize,
      page: paginationParams.current,
      search: debouncedValue ? debouncedValue : "",
      ...(Object.keys(appliedFilters).length > 0 && {
        filters: Object.entries(appliedFilters).map(([key, value]) => ({
          filterParameter: key,
          filterValue: value,
        })),
      }),
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/ListPolicyV2`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setPolicies(res?.data?.data);
      setTotalCount(res?.data?.total_pages);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load policies",
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      headerName: "Policy Name",
      field: "policyName",
      flex: 1,
      renderCell: (val) => (
        <a
          onClick={() => {
            setViewPolicyDrawer(true);
            setPolicyJsonData(val?.row?.policyJson);
          }}
        >
          {val?.row?.policyName}
        </a>
      ),
    },
    {
      headerName: "Type",
      field: "policyType",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 0.7,
      renderCell: (val) => <Tag>{val?.row?.status}</Tag>,
    },
    {
      headerName: "Version",
      field: "version",
      flex: 0.8,
    },
    {
      headerName: "Last Modified",
      field: "updatedAt",
      flex: 1.5,
      renderCell: (val) =>
        moment(val?.row?.updatedAt).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <a onClick={() => {}}>View Policy</a>
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const applyFilter = (type, value) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  };

  const getAppliedFilterValue = (type) => {
    return appliedFilters[type] ?? null;
  };

  return (
    <>
      {notificationHolder}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
            <Col>
              <Link to={"/pam/policy/createEndpointAccessPolicy"}>
                <Button type="primary">Add Access Policy</Button>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: "1rem" }} gutter={20}>
            <Col span={4}>
              <p className="search-label">Search</p>
              <Input
                type="text"
                placeholder="Search policy"
                onChange={(event) => setSearch(event.target.value)}
                allowClear
              />
            </Col>
            <Col span={4}>
              <p className="search-label">Policy Type</p>
              <Select
                placeholder="Select status"
                onChange={(val) => applyFilter("policyType", val)}
                value={getAppliedFilterValue("policyType")}
              >
                {policyTypesOptions.map((type) => (
                  <Select.Option value={type.value}>{type.label}</Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={4}>
              <p className="search-label">Version</p>
              <Select
                placeholder="Select Version"
                onChange={(val) => applyFilter("version", val)}
                value={getAppliedFilterValue("version")}
              >
                <Select.Option value="1">1</Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <p className="search-label">Status</p>
              <Select
                placeholder="Select status"
                onChange={(val) => applyFilter("status", val)}
                value={getAppliedFilterValue("status")}
              >
                {statusOptions.map((status) => (
                  <Select.Option value={status.value}>{status.label}</Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {Object.keys(appliedFilters).length > 0 && (
                <p className="mb-2">
                  Applied Filters:{" "}
                  <Link
                    href="#"
                    onClick={() => setAppliedFilters([])}
                    style={{ marginLeft: "1rem", color: "#1890ff" }}
                  >
                    Clear filters
                  </Link>
                </p>
              )}

              {Object.entries(appliedFilters).map(([key, value]) => {
                if (value) {
                  const capitalize = (text) =>
                    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

                  return (
                    <Tag
                      key={key}
                      closable={true}
                      onClose={() => {
                        setAppliedFilters((prevFilters) => {
                          let tempFilters = { ...prevFilters };
                          delete tempFilters[key];
                          return tempFilters;
                        });
                      }}
                    >
                      {`${capitalize(key)}: ${capitalize(value)}`}
                    </Tag>
                  );
                }
                return null;
              })}
            </Col>
          </Row>

          <Row>
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay: CustomNoRowsOverlay,
              }}
              rows={policies}
              rowCount={totalCount}
              columns={columns}
              style={{ border: "none", width: "100%", overflow: "auto" }}
              loading={isLoading}
              page={currentPage}
              paginationMode="server"
              paginationModel={currentPageModel}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              onPaginationModelChange={(params) => {
                setCurrentPage(params.page);
              }}
            />
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Policy JSON"
        onClose={() => setViewPolicyDrawer(false)}
        open={viewPolicyDrawer}
      >
        <JsonCellRenderer value={policyJsonData} />
      </Drawer>
    </>
  );
}
