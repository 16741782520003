import { Button, Modal, Row, Col, Input, Table, message } from "antd";
import React, { useState, useEffect } from "react";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";


const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const AssignUser = ({ isAssignUser, setIsAssignUser, selectedEndpoints }) => {
  const [epmUserData, setEPMUserData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 5,
  });
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();

  useEffect(() => {
    setPaginationParams({ ...paginationParams, current: 1 });
  }, [debouncedValue]);
  useEffect(() => {
    setIsLoading(true);

    const fetchEPMUsers = () => {
      let pageDetails = {
        domainId: DOMAIN_ID(),
        pageId: paginationParams.current,
        pageSize: paginationParams.pageSize,
        token: AUTH_TOKEN(),
        instanceIds: [],
        filter: {
          filterBy: "",
          value: "",
        },
        search: "",
        orgId: ORG_ID(),
      };
      setIsLoading(true);
      axios
        .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setTotalCount(res?.data?.totalCount);
          setEPMUserData(
            res.data.epmUsers.map((data) => ({
              ...data,
              key: data.userId,
              id: data.userId,
            }))
          );
          const selectedId = [];
          res.data.epmUsers?.forEach((user) => {
            if (user.instances.map((i) => i.instanceId).includes(selectedEndpoints?.instanceId)) {
              selectedId.push(user.userId);
            }
          });
          setSelectedRowKeys(selectedId);
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err.response.data.message === "Invalid credentials" ||
            err?.response?.status === 401
          ) {
            logOutUser();
            notification.open({
              type: "error",
              content: "Credentials are invalid",
            });
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch endpoint users",
            });
          }
        });
    };
    fetchEPMUsers();
  }, [debouncedValue, paginationParams]);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const columns = [
    { field: "userName", headerName: "User Name", key: "userName", flex: 1 },
    { field: "userType", headerName: "User Type", key: "userType", flex: 1 },
    // { field: "credType", headerName: "Credential Type", key: "credType", flex: 1 },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys:", selectedRowKeys, "selectedRows: ", selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setEPMUserData([]);
    }
  };

  const handleOk = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsAssignUser(false);
    }, 3000);
    setIsLoading(false);
  };
  const handleCancel = () => {
    setIsAssignUser(false);
  };

  return (
    <Modal
      open={isAssignUser}
      title={
        <span style={{ fontSize: 18 }}>
          Assign User for{" "}
          <span style={{ color: "#1677FF" }}>{` ${selectedEndpoints.hostName}`}</span>
        </span>
      }
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Assign
        </Button>,
      ]}
      width={1000}
    >
      <Row>
        <Col span={24}>
          <Row style={{ margin: "1rem 0" }} className="search-box-container">
            <Col span={12}>
              <div>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Search"
                  allowClear
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (paginationParams.current !== 1) {
                      setPaginationParams({ current: 1, pageSize: 10 });
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={epmUserData}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                }}
              /> */}
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={epmUserData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                page={paginationParams.current}
                pageSize={paginationParams.pageSize}
                onPaginationModelChange={(newPage) => {
                  setPaginationParams({
                    ...paginationParams,
                    current: newPage.page,
                  });
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default AssignUser;
