import { MoreOutlined } from "@ant-design/icons";
import { Col, Row, Table, Dropdown, Button, Input, message, Tag } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_API_TENANTS_URL, AUTH_TOKEN, AUTH_TOKEN_ORG } from "../../constants";
import moment from "moment-timezone";

function Tenant() {
  const [isLoading, setIsLoading] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [notification, setNotification] = message.useMessage();

  useEffect(() => {
    fetchTenent();
  }, []);

  const columns = [
    { title: "Name", dataIndex: "tenant_name", key: "tenant_name" },
    {
      title: "Admin Email",
      dataIndex: "admin_email",
      key: "admin_email",
      render: (tags, record) => (
        <span>
          <Tag color={record.status.toLowerCase() === "active" ? "green" : "red"} key={tags}>
            {tags}
          </Tag>
        </span>
      ),
    },
    {
      title: "Tenant URL",
      dataIndex: "site_url",
      key: "site_url",
      render: (tags, record) => (
        <span>
          <a href={`https://${tags}`} target="_blank">
            {tags}
          </a>
        </span>
      ),
    },
    {
      title: "Authentication Method",
      dataIndex: "authentication_method",
      key: "authentication_method",
      render: (val) =>
        val.toLowerCase() === "normal"
          ? "Basic Password Method"
          : val.toLowerCase() === "okta"
          ? "SAML 2.0"
          : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => (val.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <span>
          {record && moment(record).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm [PST]")}
        </span>
      ),
    },
    {
      title: "Modified At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => (
        <span>
          {record && moment(record).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm [PST]")}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              // {
              //   key: "assign",
              //   label: <Link>Edit</Link>,
              // },

              {
                key: "delete",
                label: <Link onClick={() => handleDelete(record)}>Delete</Link>,
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const fetchTenent = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/tenantlist`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setTenantList(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleDelete = (val) => {
    console.log(val);
    const data = {
      email: localStorage.getItem("UserName"),
      tenantid: val.id,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/deletetenant`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        console.log(res);
        fetchTenent();
        notification
          .open({
            type: "success",
            content: `https://${val.site_url} deleted successfully`,
          })
          .catch((err) => {
            notification.open({
              type: "error",
              content: `Unable to delete the tenant`,
            });
          });
      });
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Tenant</h2>
            </Col>
            <Col>
              <Link to={"/tenant/add"}>
                <Button type="primary">Create Tenant</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <Input placeholder="Search" allowClear />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table loading={isLoading} columns={columns} dataSource={tenantList} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Tenant;
