import {
  Col,
  Row,
  Select,
  Button,
  Input,
  Tag,
  DatePicker,
  Collapse,
  Typography,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const CreateWindowsPolicy = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [type, setType] = useState("");
  const [type2, setType2] = useState("");
  const [file, setFile] = useState("option1");
  const [file2, setFile2] = useState("option1");
  const [additionalRules, setAdditionalRules] = useState([]);

  const addRule = () => {
    setAdditionalRules((prevRules) => [...prevRules, {}]);
  };

  const deleteRule = (index) => {
    setAdditionalRules((prevRules) => prevRules.filter((_, i) => i !== index));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const endpoints = [
    {
      policyName: "policy-1",
      lastChange: "10/10/2023 04:50pm PST",
      platform: "AWS",
      count: "5",
      description: "Policies for AWS JIT access",
      status: "active",
    },
    {
      policyName: "policy-2",
      lastChange: "10/11/2023 05:30pm PST",
      platform: "Azure",
      count: "8",
      description: "Azure security policies",
      status: "active",
    },
    {
      policyName: "policy-3",
      lastChange: "10/12/2023 03:20pm PST",
      platform: "GCP",
      count: "12",
      description: "Google Cloud security rules",
      status: "inactive",
    },
    {
      policyName: "policy-4",
      lastChange: "10/13/2023 06:45pm PST",
      platform: "AWS",
      count: "3",
      description: "AWS IAM policies",
      status: "active",
    },
    {
      policyName: "policy-5",
      lastChange: "10/14/2023 02:15pm PST",
      platform: "Azure",
      count: "6",
      description: "Advanced Azure policies",
      status: "inactive",
    },
    {
      policyName: "policy-6",
      lastChange: "10/15/2023 04:10pm PST",
      platform: "AWS",
      count: "9",
      description: "AWS security rules",
      status: "active",
    },
    {
      policyName: "policy-7",
      lastChange: "10/16/2023 05:25pm PST",
      platform: "GCP",
      count: "7",
      description: "GCP access policies",
      status: "active",
    },
    {
      policyName: "policy-8",
      lastChange: "10/17/2023 03:55pm PST",
      platform: "Azure",
      count: "4",
      description: "Azure access control",
      status: "inactive",
    },
    {
      policyName: "policy-9",
      lastChange: "10/18/2023 06:30pm PST",
      platform: "AWS",
      count: "11",
      description: "AWS security best practices",
      status: "active",
    },
    {
      policyName: "policy-10",
      lastChange: "10/19/2023 02:40pm PST",
      platform: "GCP",
      count: "10",
      description: "GCP security policies",
      status: "active",
    },
  ];

  const columns = [
    { title: "Policy Name", dataIndex: "policyName", key: "policyName" },
    {
      dataIndex: "lastChange",
      title: "Last Change",
      key: "lastChange",
    },

    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Rule Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (tags, record) => (
        <span>
          {record.status == "active" ? (
            <Tag color={"green"}>Live</Tag>
          ) : (
            <Tag color={"black"}>Audit</Tag>
          )}
        </span>
      ),
    },

    {
      title: "Logs",
      key: "logs",
      render: (_value, record) => <a>View Logs</a>,
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Create Policies</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Policy Name</p>
              <Input
                placeholder="Enter Policy Name"
                onSearch={(value) => console.log(value)}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">Tenant</p>
              <Input
                placeholder="Enter Tenant Name"
                onSearch={(value) => console.log(value)}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "1rem" }}>
            <Col span={6}>
              <p className="search-label">Endpoints and Groups</p>
              <Select
                placeholder="Select Endpoint And Groups"
                onChange={(e) => setType(e)}
              >
                <Option value="option1">Endpoint</Option>
                <Option value="option2">Endpoint Groups</Option>
                <Option value="option3">Groups Matching Wild Card</Option>
                <Option value="option4">Endpoints Matching Wild Card</Option>
                <Option value="option5">Apply To Whole Tenant</Option>
              </Select>
            </Col>
            {type === "option1" ? (
              <Col span={6}>
                <p className="search-label">Endpoints List</p>
                <Select mode="multiple">
                  <Option value="option1">Label 1</Option>
                  <Option value="option2">Label 2</Option>
                  <Option value="option3">Label 3</Option>
                </Select>
              </Col>
            ) : (
              type === "option2" && (
                <Col span={6}>
                  <p className="search-label">Endpoints Groups List</p>
                  <Select mode="multiple">
                    <Option value="option1">Label 1</Option>
                    <Option value="option2">Label 2</Option>
                    <Option value="option3">Label 3</Option>
                  </Select>
                </Col>
              )
            )}
            {(type == "option3" || type == "option4") && (
              <Col span={6}>
                <p className="search-label">Wild Card</p>
                <Input
                  placeholder="Enter Wild Card"
                  onSearch={(value) => console.log(value)}
                />
              </Col>
            )}
          </Row>
          <Row gutter={16} style={{ marginTop: "1rem" }}>
            <Col span={6}>
              <p className="search-label">
                Endpoint Users and Endpoint User Groups
              </p>
              <Select
                placeholder="Select Endpoint Users And Endpoint User Groups"
                onChange={(e) => setType2(e)}
              >
                <Option value="option1">Endpoint User</Option>
                <Option value="option2">Endpoint User Groups</Option>
                <Option value="option3">User Groups Matching Wild Card</Option>
                <Option value="option4">
                  Endpoint User Matching Wild Card
                </Option>
                <Option value="option5">Apply To Whole Tenant</Option>
              </Select>
            </Col>
            {type2 === "option1" ? (
              <Col span={6}>
                <p className="search-label">Endpoints List</p>
                <Select mode="multiple">
                  <Option value="option1">Label 1</Option>
                  <Option value="option2">Label 2</Option>
                  <Option value="option3">Label 3</Option>
                </Select>
              </Col>
            ) : (
              type2 === "option2" && (
                <Col span={6}>
                  <p className="search-label">Endpoints Groups List</p>
                  <Select mode="multiple">
                    <Option value="option1">Label 1</Option>
                    <Option value="option2">Label 2</Option>
                    <Option value="option3">Label 3</Option>
                  </Select>
                </Col>
              )
            )}
            {(type2 == "option3" || type2 == "option4") && (
              <Col span={6}>
                <p className="search-label">Wild Card</p>
                <Input
                  placeholder="Enter Wild Card"
                  onSearch={(value) => console.log(value)}
                />
              </Col>
            )}
          </Row>

          <Collapse accordion className="createWindow">
            <Panel header="Rules" key="1">
              <Row
                style={{
                  background: "#E6F2F9",
                  //   marginLeft: "-16px",
                  paddingLeft: "16px",
                  borderRadius: "6px",
                  paddingTop: "1rem",
                }}
              >
                <Col span={24}>
                  {/* <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                    <Col span={6}>
                      <p className="search-label">White List / Black List</p>
                      <Select mode="multiple">
                        <Option value="option1">White List</Option>
                        <Option value="option2">Black List</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">File Criteria</p>
                      <Select>
                        <Option value="option1">
                          Name Matches With Wild Card
                        </Option>
                        <Option value="option2">MD5 Hash</Option>
                        <Option value="option3">Publisher</Option>
                        <Option value="option4">Product Name</Option>
                        <Option value="option5">Original File Name</Option>
                        <Option value="option5">File Path</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">App</p>
                      <Select mode="multiple">
                        <Option value="option1">AWS</Option>
                        <Option value="option2">GCP</Option>
                        <Option value="option3">Authnull</Option>
                      </Select>
                    </Col>
                  </Row> */}
                  <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                    <Col span={6}>
                      <p className="search-label">White List</p>
                      <Select value={"option1"} disabled>
                        <Option value="option1">White List</Option>
                        <Option value="option2">Black List</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">File Criteria</p>
                      <Select onChange={(value) => setFile(value)}>
                        <Option value="option1">
                          Name Matches With Wild Card
                        </Option>
                        <Option value="option2">MD5 Hash</Option>
                        <Option value="option3">Publisher</Option>
                        <Option value="option4">Product Name</Option>
                        <Option value="option5">Original File Name</Option>
                        <Option value="option5">File Path</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">
                        {file === "option1"
                          ? "Name Matches With Wild Card"
                          : file === "option2"
                          ? "MD5 Hash"
                          : file === "option3"
                          ? "Publisher"
                          : file === "option4"
                          ? "Product Name"
                          : file === "option5"
                          ? "Original File Name"
                          : file === "option5"  ?  "File Path" : " "}
                      </p>
                      <Input />
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                    <Col span={6}>
                      <p className="search-label">Black List</p>
                      <Select value={"option1"} disabled>
                        <Option value="option1">White List</Option>
                        <Option value="option2">Black List</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">File Criteria</p>
                      <Select onChange={(value) => setFile(value)}>
                        <Option value="option1">
                          Name Matches With Wild Card
                        </Option>
                        <Option value="option2">MD5 Hash</Option>
                        <Option value="option3">Publisher</Option>
                        <Option value="option4">Product Name</Option>
                        <Option value="option5">Original File Name</Option>
                        <Option value="option5">File Path</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">
                        {file === "option1"
                          ? "Name Matches With Wild Card"
                          : file === "option2"
                          ? "MD5 Hash"
                          : file === "option3"
                          ? "Publisher"
                          : file === "option4"
                          ? "Product Name"
                          : file === "option5"
                          ? "Original File Name"
                          : file === "option5"  ?  "File Path" : " "}
                      </p>
                      <Input />
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                    <Col span={6}>
                      <p className="search-label">Permissions</p>
                      <Select>
                        <Option value="option1">Can Install Apps</Option>
                        <Option value="option1">Allow</Option>
                        <Option value="option2">Escalate For Approval</Option>
                        <Option value="option3">Escalate And Wait</Option>
                        <Option value="option4">
                          Audit(auto turned on in audit mode)
                        </Option>
                        <Option value="option5">Ignore</Option>
                        <Option value="option6">Deny</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">App</p>
                      <Select mode="multiple">
                        <Option value="option1">AWS</Option>
                        <Option value="option2">GCP</Option>
                        <Option value="option3">Authnull</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ paddingBottom: "2rem" }}>
                    <Col span={6}>
                      <p className="search-label">Execute As</p>
                      <Select>
                        <Option value="option1">User</Option>
                        <Option value="option2">Admin</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {additionalRules.map((rule, index) => (
                <Row
                  key={index}
                  style={{
                    background: "#E6F2F9",
                    paddingLeft: "16px",
                    borderRadius: "6px",
                    paddingTop: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  {/* ... Additional rule content ... */}
                  <Col span={24}>
                    {/* <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                    <Col span={6}>
                      <p className="search-label">White List / Black List</p>
                      <Select mode="multiple">
                        <Option value="option1">White List</Option>
                        <Option value="option2">Black List</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">File Criteria</p>
                      <Select>
                        <Option value="option1">
                          Name Matches With Wild Card
                        </Option>
                        <Option value="option2">MD5 Hash</Option>
                        <Option value="option3">Publisher</Option>
                        <Option value="option4">Product Name</Option>
                        <Option value="option5">Original File Name</Option>
                        <Option value="option5">File Path</Option>
                      </Select>
                    </Col>
                    <Col span={6}>
                      <p className="search-label">App</p>
                      <Select mode="multiple">
                        <Option value="option1">AWS</Option>
                        <Option value="option2">GCP</Option>
                        <Option value="option3">Authnull</Option>
                      </Select>
                    </Col>
                  </Row> */}
                    <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">White List</p>
                        <Select value={"option1"} disabled>
                          <Option value="option1">White List</Option>
                          <Option value="option2">Black List</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">File Criteria</p>
                        <Select onChange={(value) => setFile(value)}>
                          <Option value="option1">
                            Name Matches With Wild Card
                          </Option>
                          <Option value="option2">MD5 Hash</Option>
                          <Option value="option3">Publisher</Option>
                          <Option value="option4">Product Name</Option>
                          <Option value="option5">Original File Name</Option>
                          <Option value="option5">File Path</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">
                          {file === "option1"
                            ? "Name Matches With Wild Card"
                            : file === "option2"
                            ? "MD5 Hash"
                            : file === "option3"
                            ? "Publisher"
                            : file === "option4"
                            ? "Product Name"
                            : file === "option5"
                            ? "Original File Name"
                            : file === "option5" ?  "File Path" : " "}
                        </p>
                        <Input />
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Black List</p>
                        <Select value={"option1"} disabled>
                          <Option value="option1">White List</Option>
                          <Option value="option2">Black List</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">File Criteria</p>
                        <Select onChange={(value) => setFile2(value)}>
                          <Option value="option1">
                            Name Matches With Wild Card
                          </Option>
                          <Option value="option2">MD5 Hash</Option>
                          <Option value="option3">Publisher</Option>
                          <Option value="option4">Product Name</Option>
                          <Option value="option5">Original File Name</Option>
                          <Option value="option5">File Path</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">
                          {file2 === "option1"
                            ? "Name Matches With Wild Card"
                            : file2 === "option2"
                            ? "MD5 Hash"
                            : file2 === "option3"
                            ? "Publisher"
                            : file2 === "option4"
                            ? "Product Name"
                            : file2 === "option5"
                            ? "Original File Name"
                            : file2 === "option5"  ?  "File Path" : " "}
                        </p>
                        <Input />
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ paddingBottom: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Permissions</p>
                        <Select>
                          <Option value="option1">Can Install Apps</Option>
                          <Option value="option1">Allow</Option>
                          <Option value="option2">Escalate For Approval</Option>
                          <Option value="option3">Escalate And Wait</Option>
                          <Option value="option4">
                            Audit(auto turned on in audit mode)
                          </Option>
                          <Option value="option5">Ignore</Option>
                          <Option value="option6">Deny</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">App</p>
                        <Select mode="multiple">
                          <Option value="option1">AWS</Option>
                          <Option value="option2">GCP</Option>
                          <Option value="option3">Authnull</Option>
                        </Select>
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ paddingBottom: "2rem" }}>
                      <Col span={6}>
                        <p className="search-label">Execute As</p>
                        <Select>
                          <Option value="option1">User</Option>
                          <Option value="option2">Admin</Option>
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={4}
                        offset={20}
                        style={{ textAlign: "right", marginTop: "1rem" }}
                      >
                        <Button
                          type="danger"
                          style={{ color: "red" }}
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          onClick={() => deleteRule(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {/* Delete rule button */}
                </Row>
              ))}
              <Row style={{ marginTop: "1rem" }}>
                <Col offset={18} span={6} style={{ textAlign: "right" }}>
                  <Button type="default" onClick={addRule}>
                    Add More Rules
                  </Button>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          {/* <h3 style={{ color: "#2B09FF" }}>Rules</h3> */}

          <Row>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={6}>
                  <p className="search-label">
                    When Executing App Not Covered By Policy
                  </p>
                  <Select>
                    <Option value="option1">Allow</Option>
                    <Option value="option2">Escalate For Approval</Option>
                    <Option value="option3">Escalate And Wait</Option>
                    <Option value="option4">
                      Audit(auto turned on in audit mode)
                    </Option>
                    <Option value="option5">Ignore</Option>
                    <Option value="option6">Deny</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem", marginTop: "2rem" }}>
            <Col span={24}>
              <Paragraph>
                * This permission will be executed when the user or group does
                not fall in the covered policy
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
              span={2}
              offset={22}
            >
              <Button type="primary">Add Policy</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreateWindowsPolicy;
