import { Col, Input, Row, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const EndpointDetailsGroup = ({ stateHostAddress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [endpointGroups, setEndpointGroups] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  const isInstanceId = queryParams.get("instanceId");

  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [endpointGroup, setEndpointGroup] = useState([]);
  const [sortModel, setSortModel] = useState();
  const debouncedValue = useDebounce(search, 500);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const { instanceId } = location.state || {};

  useEffect(() => {}, [paginationParams, search, filterBy, filteredValue]);

  useEffect(() => {
    fetchGroups();
  }, [debouncedValue, currentPage, paginationParams]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const fetchGroups = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      instanceId: Number(instanceId ? instanceId : stateHostAddress.instanceId),
      Filter: {
        filterBy: search ? "groupName" : "localGroups",
        value: search,
      },
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data = [];
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            text: grp.groupName,
            people: grp.users.length,
            application: grp.applications.length,
            selectedApps: grp.applications ? grp.applications.map((app) => app.id) : [],
            selectedUsers: grp.users ? grp.users.map((user) => user.id) : [],
            ...grp,
          }));
        }
        setEndpointGroups(Data);
        setTotalCount(res?.data?.totalGroups);
        setIsLoading(false);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const tab2columns = [
    {
      headerName: "Group Name",
      field: "groupName",
      flex: 1,
    },
    {
      headerName: "Users",
      field: "users",
      flex: 1,
      renderCell: (params) => {
        const users = params.value;
        if (users && users.length > 0) {
          const validUsers = users.filter((user) => user.emailAddress || user.firstName);

          if (validUsers.length > 0) {
            const displayValue = validUsers.map((user, index) => (
              <Tag key={index} color="blue">
                {user.emailAddress || user.firstName}
              </Tag>
            ));
            return <div>{displayValue}</div>;
          } else {
            return <div>-</div>;
          }
        } else {
          return <div>-</div>;
        }
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);
    console.log(isTabChange);
    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpointGroup([]);
    }
    fetchGroups();
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                style={{ border: "none" }}
                loading={isLoading}
                rows={endpointGroups}
                columns={tab2columns}
                paginationMode="server"
                rowCount={totalCount}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointDetailsGroup;
