import React, { useState, useEffect } from "react";
import { Col, Row, Tag, Select, message, Typography } from "antd";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../constants";
import { logOutUser } from "../../common/index";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const { Text } = Typography;

const Policy = () => {
  const [endpoints, setEndpoints] = useState([]);
  const [totalPages, setTotalPage] = useState();
  const [tenantData, setTenantData] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 100,
    page: 0,
  });
  const [isNotification, setNotification] = message.useMessage();
  const location = useLocation();
  const [dom, setDom] = useState([]);
  const [ouData, setOuData] = useState([]);
  const [userType, setUserType] = useState(
    location?.state?.logType ? location?.state?.logType : "local"
  );
  const [isLoading, setIsLoading] = useState(true);
  const RequestFilterState = [
    { filterParameter: "destinationEndpoint", filterValue: location?.state?.destinationEndpoint },
    {
      filterParameter: location?.state?.logType === "ad" ? "adUser" : "endpointUser",
      filterValue:
        location?.state?.logType === "ad" ? location?.state?.adUser : location?.state?.endpointUser,
    },
    { filterParameter: "walletUsers", filterValue: localStorage.getItem("UserName") },
    { filterParameter: "policyType", filterValue: location?.state?.logType },
  ];
  const [appliedFilters, setAppliedFilters] = useState(
    location?.state
      ? RequestFilterState
      : [
          { filterParameter: "policyType", filterValue: "local" },
          { filterParameter: "walletUsers", filterValue: localStorage.getItem("UserName") },
        ]
  );
  useEffect(() => {
    fetchDomains();
    fetchTenantDetails();
    // Clean up the message when the component unmounts
    return () => {
      message.destroy("permanentMessage");
    };
  }, []);

  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    }).then((res) => {
      setTenantData(res.data.data);
      message.info({
        content: `Tenant has policies in ${
          res.data.data.authenticationpolicy === 1
            ? "audit"
            : res.data.data.authenticationpolicy === 2
            ? "policy"
            : "live"
        } mode`,
        key: "permanentMessage",
        duration: 0, // Set duration to 0 for a permanent message
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        },
      });
    });
  };

  useEffect(() => {
    // Display a permanent message on mount
    fetchPolicy();
  }, [appliedFilters, sortModel, userType]);

  const fetchDomains = (page) => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        setDom(inputArray);
      })

      .catch((err) => {
        console.log(err);
        isNotification.open({
          type: "error",
          content: "Unable to fetch domains",
        });
      });
  };
  const fetchPolicy = (page, filters) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      policyType: location?.state?.logType ? location?.state?.logType : userType,
      filters: appliedFilters,
      requestId: "",
      limit: 100,
      page: page,
      sort: {
        field: sortModel ? sortModel[0]?.field : "",
        order: sortModel ? sortModel[0]?.sort : "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/FilterPolicy`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        setEndpoints(data.data);
        setTotalPage(data.total_pages * 100);
        setCurrentPageModal({
          pageSize: 100,
          page: data.page - 1,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        isNotification.open({
          type: "error",
          content: "Unable to fetch policies",
        });
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };

  const columns = [
    userType === "ad"
      ? {
          headerName: "Directory User",
          field: "adUser",
          width: 180,
          sortable: true,
          renderCell: (val) => {
            const tagsArray = val.row.adUser.split(",");

            let tooltipVal = tagsArray.slice(-2).map((tag, index) => {
              return tag;
            });

            return (
              <div style={{ whiteSpace: "normal" }}>
                {tagsArray.length > 2 ? (
                  <>
                    {tagsArray.slice(0, 2).map((tag, index) => (
                      <Tag
                        key={index}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleApplyFilter("adUser", tag)}
                      >
                        {tag.trim()}
                      </Tag>
                    ))}

                    <Tooltip title={tooltipVal.join(", ")}>
                      <Tag key="ellipsis" style={{ cursor: "default" }}>
                        ...
                      </Tag>
                    </Tooltip>
                  </>
                ) : (
                  tagsArray.map((tag, index) => (
                    <Tag
                      key={index}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleApplyFilter("adUser", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))
                )}
              </div>
            );
          },
        }
      : {
          headerName: "Endpoint Users",
          field: "endpointUser",
          sortable: true,
          width: 210,
          renderCell: (val) => {
            const tagsArray = val.row.endpointUser.split(",");
            let tooltipVal = tagsArray.slice(-2).map((tag, index) => {
              return tag;
            });

            return (
              <div style={{ whiteSpace: "normal" }}>
                {tagsArray.length > 2 ? (
                  <>
                    {tagsArray.slice(0, 2).map((tag, index) => (
                      <Tag
                        key={index}
                        style={{
                          cursor: "pointer",
                          // whiteSpace: "break-spaces"
                        }}
                        onClick={() => handleApplyFilter("endpointUser", tag)}
                      >
                        {tag.trim()}
                      </Tag>
                    ))}
                    <Tooltip title={tooltipVal.join(", ")}>
                      <Tag key="ellipsis" style={{ cursor: "default" }}>
                        ...
                      </Tag>
                    </Tooltip>
                  </>
                ) : (
                  tagsArray.map((tag, index) => (
                    <Tag
                      key={index}
                      style={{
                        cursor: "pointer",
                        // whiteSpace: "break-spaces"
                      }}
                      onClick={() => handleApplyFilter("endpointUser", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))
                )}
              </div>
            );
          },
        },
    {
      headerName: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Policy Action</span>
          <Tooltip title="Policy action can be allow or deny.Allowed policy can be able to access the policy.Deny means unable to access the policy. ">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),

      field: "action",
      sortable: true,
      width: 180,
      renderCell: (val) => {
        const result = val?.row?.action.toLowerCase();
        return (
          <div style={{ whiteSpace: "normal" }}>
            <Tag
              style={{
                cursor: "pointer",
              }}
              color={result === "deny" ? "red" : "green"}
              onClick={() => handleApplyFilter("action", val?.row?.action)}
            >
              {result === "" ? "Allow" : val?.row?.action}
            </Tag>
          </div>
        );
      },
    },

    {
      headerName: "Destination",
      field: "destinationEndpoint",
      sortable: true,
      width: 180,
      renderCell: (val) => {
        const tagsArray = val.row.destinationEndpoint.split(",");
        let tooltipVal = tagsArray.slice(-1).map((tag, index) => {
          return tag;
        });

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.length > 1 ? (
              <>
                {tagsArray.slice(0, 1).map((tag, index) => (
                  <Tag
                    key={index}
                    style={{
                      cursor: "pointer",
                      whiteSpace: "break-spaces",
                    }}
                    onClick={() => handleApplyFilter("destinationEndpoint", tag)}
                  >
                    {tag.trim()}
                  </Tag>
                ))}
                <Tooltip title={tooltipVal.join(", ")}>
                  <Tag key="ellipsis" style={{ cursor: "default" }}>
                    ...
                  </Tag>
                </Tooltip>
              </>
            ) : (
              tagsArray.map((tag, index) => (
                <Tag
                  key={index}
                  style={{
                    cursor: "pointer",
                    whiteSpace: "break-spaces",
                  }}
                  onClick={() => handleApplyFilter("destinationEndpoint", tag)}
                >
                  {tag.trim()}
                </Tag>
              ))
            )}
          </div>
        );
      },
    },
    {
      headerName: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Status</span>
          <Tooltip title="Status of the policy can be Active,Inactive or In Review">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      field: "policyStatus",
      width: 140,
      renderCell: (val) => {
        const statusVal = val?.row?.status?.toLowerCase();
        return (
          <div>
            {statusVal === "approved" ? (
              <Text type="success">Registered</Text>
            ) : statusVal === "denied" ? (
              <Text type="danger">Denied</Text>
            ) : (
              <Text type="warning">In Review</Text>
            )}
          </div>
        );
      },
    },

    {
      headerName: "Policy Name",
      field: "policyName",
      width: 220,
      sortable: true,
    },
    {
      headerName: "Updated At",
      field: "timestamp",
      width: 160,
      sortable: true,
      renderCell: (val) => {
        const formatString = "DD-MM-YYYY HH:mm [PST]";

        const formattedDate = moment.unix(val.row.timestamp).format(formatString);

        return <span>{formattedDate}</span>;
      },
    },
  ];

  const handleApplyFilter = (filter, value) => {
    let filterParameter = filter;
    let filterValue = value;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    setAppliedFilters(uniqueFilters);
  };

  const getCurrentOption = (type) => {
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const handleRemoveFilter = (filter, value) => {
    if (userType === "ad") {
      setUserType("ad");
    } else {
      setUserType("local");
    }
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Policy Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Policy Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={getCurrentOption("policyType")}
                        onChange={(e) => {
                          handleApplyFilter("policyType", e);
                          setUserType(e);
                        }}
                      >
                        <Option value="local">Local User</Option>
                        <Option value="ad">Active Directory</Option>
                      </Select>
                    </Col>
                    {userType === "ad" && (
                      <>
                        <Col span={4}>
                          <p className="search-label">Domain</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={getCurrentOption("adDomain")}
                            onChange={(e, val) => {
                              handleApplyFilter("adDomain", val?.children);
                              const selectedDomain = dom.find((domain) => domain.id === e);
                              if (selectedDomain) {
                                setOuData(
                                  selectedDomain?.ous?.map((data) => {
                                    let ouVal = data.OuName.split(",")[0];
                                    let nameOu = ouVal.substring(3);
                                    return { ...data, ouNames: nameOu };
                                  })
                                );
                              } else {
                                setOuData([]);
                              }
                              // console.log(e, val);
                            }}
                          >
                            {dom?.map((val) => (
                              <Option key={val.id} value={val.id}>
                                {val.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <p className="search-label">Ou</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search OU "
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(_, val) => {
                              handleApplyFilter("adOu", val.children);
                            }}
                            value={getCurrentOption("adOu")}
                          >
                            {ouData?.map((ou) => (
                              <Option key={ou.AdId} value={ou.Id}>
                                {ou.OuName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </>
                    )}
                    {/* <Col span={4}>
                      <p className="search-label">Endpoint</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={getCurrentOption("destinationEndpoint")}
                        onChange={(e) => handleApplyFilter("destinationEndpoint", e)}
                      >
                        {epm &&
                          epm.length > 0 &&
                          epm.map((endpoint) => (
                            <Option value={endpoint.name}>{endpoint.name}</Option>
                          ))}
                      </Select>
                    </Col> */}
                    <Col span={4}>
                      <p className="search-label">Status</p>
                      <Select
                        value={getCurrentOption("policyStatus")}
                        onChange={(e) => handleApplyFilter("policyStatus", e)}
                        placeholder="Select Status"
                      >
                        <Option value="In Review">In Review</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Denied">Denied</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters
                      </h4>

                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          {filterParameter !== "walletUsers" && (
                            <Tag
                              key={`${filterParameter}:${filterValue}`}
                              closable
                              onClose={() => handleRemoveFilter(filterParameter, filterValue)}
                            >
                              {`${filterParameter}: ${filterValue}`}
                            </Tag>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} id="onboarding_step1">
                  <DataGrid
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      noResultsOverlay: CustomNoRowsOverlay,
                    }}
                    loading={isLoading}
                    rows={endpoints}
                    columns={columns}
                    pageSizeOptions={[10]}
                    rowCount={totalPages}
                    paginationMode="server"
                    // Add autoHeight property
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 100, page: 0 },
                      },
                    }}
                    paginationModel={currentPageModal}
                    sortModel={sortModel}
                    onSortModelChange={(val) => {
                      if (val?.length > 0) {
                        setSortModel(val);
                      }
                    }}
                    // isRowExpandable={isRowExpandable}
                    getRowId={(row) => row.id.toString()}
                    // expandedRows={expandedRows}
                    // onRowExpand={handleRowExpand}
                    onPaginationModelChange={(params) => {
                      fetchPolicy(params.page + 1, appliedFilters);
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) =>
                      handleApplyFilter(e.items[0].field, e.items[0].value)
                    }
                    style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                    rowThreshold={0}
                    pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                    sx={{
                      "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Policy;
