import { Button, Card, Col, Collapse, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, AUTH_TOKEN_ORG, ORG_ID, REACT_APP_API_TENANTS_URL } from "../../../constants";
import axios from "axios";
import Meta from "antd/es/card/Meta";

const Settings = () => {
  const [orgDetail, setOrgDetail] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [checkAuthnMethod, setCheckAuthnMethod] = useState("");
  const [IsLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrgDetail();
  }, []);

  const fetchOrgDetail = () => {
    let data = {
      email: localStorage.getItem("UserName"),
      orgID: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/orgdetail`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setOrgDetail(res.data.data);
        setCheckAuthnMethod(res.data.data.authentication_method);
      });
  };

  const handleSave = (val) => {
    const loginData = {
      ssoUrl: "",
      authenticationMethod: val,
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/authenticatemethod`, loginData)
      .then((res) => {
        console.log(res.data);
        notification.open({
          type: "success",
          content: "Authenticate Method activated successfully",
        });
        fetchOrgDetail();
        // setTimeout(() => {
        //   navigate(-1);
        // }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      });
  };

  const handleDeactivate = () => {
    const loginData = {
      ssoUrl: "",
      authenticationMethod: "",
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/authenticatemethod`, loginData)
      .then((res) => {
        console.log(res.data);
        fetchOrgDetail();
        notification.open({
          type: "success",
          content: "Authenticate Method deactivated successfully",
        });
        // setTimeout(() => {
        //   navigate(-1);
        // }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      });
  };

  return (
    <>
      {setNotification}
      <div className="Setting-container">
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title">Settings</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Collapse
                  ghost
                  defaultActiveKey={["1"]}
                  items={[
                    {
                      key: "1",
                      label: "Account",
                      children: (
                        <>
                          <Row gutter={[12, 24]}>
                            <Col span={16}>
                              <Card title="Organization Contact">
                                <Row>
                                  <Col span={20}>
                                    <Row justify="space-between" style={{ marginBottom: "10px" }}>
                                      <Col>
                                        <p style={{ fontWeight: 500 }}>Email</p>
                                      </Col>
                                      <Col>
                                        <p>{localStorage.getItem("UserName")}</p>
                                      </Col>
                                    </Row>
                                    <Row justify="space-between" style={{ marginBottom: "10px" }}>
                                      <Col>
                                        <p style={{ fontWeight: 500 }}>Organization Name</p>
                                      </Col>
                                      <Col>
                                        <p>{orgDetail.organization_name}</p>
                                      </Col>
                                    </Row>
                                    <Row justify="space-between" style={{ marginBottom: "10px" }}>
                                      <Col>
                                        <p style={{ fontWeight: 500 }}>Company URL</p>
                                      </Col>
                                      <Col>
                                        <p>{orgDetail.site_url}</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Collapse
                  ghost
                  // defaultActiveKey={["1"]}
                  items={[
                    {
                      key: "1",
                      label: "Authentication Method",
                      children: (
                        <>
                          <Row>
                            <Col span={20}>
                              <Row justify="space-between">
                                <Col span={6}>
                                  <Card
                                    hoverable
                                    style={{
                                      // background: "#f2f2f7",
                                      width: 300,
                                    }}
                                    cover={
                                      <img
                                        style={{
                                          height: "12rem",
                                          // width: "15rem",
                                          display: "flex",
                                          margin: "auto",
                                          // paddingTop: "2rem",
                                        }}
                                        alt="SAML 2.0"
                                        src="https://1000logos.net/wp-content/uploads/2022/03/Okta-Logo.jpg"
                                      />
                                    }
                                    actions={[
                                      <>
                                        {(checkAuthnMethod === "" ||
                                          checkAuthnMethod.toUpperCase() !== "OKTA") && (
                                          <Button
                                            type="primary"
                                            disabled={checkAuthnMethod !== ""}
                                            onClick={() => navigate("/setting/okta")}
                                          >
                                            Activate
                                          </Button>
                                        )}
                                        {checkAuthnMethod.toUpperCase() === "OKTA" && (
                                          <Button danger onClick={handleDeactivate}>
                                            Deactivate
                                          </Button>
                                        )}
                                      </>,
                                    ]}
                                  >
                                    <Meta
                                      style={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                      title="SAML 2.0"
                                      description="SAML 2.0 is an open standard created to provide cross-domain SSO"
                                    />
                                  </Card>
                                </Col>
                                <Col span={6}>
                                  <Card
                                    style={{
                                      width: 300,
                                    }}
                                    hoverable
                                    cover={
                                      <img
                                        style={{
                                          height: "12rem",
                                          // width: "14rem",
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                        alt="Active Directory"
                                        src="https://www.silviodibenedetto.com/wp-content/uploads/2019/08/hero_activedirectory.jpg"
                                      />
                                    }
                                    actions={[
                                      <>
                                        {(checkAuthnMethod === "" ||
                                          checkAuthnMethod.toUpperCase() !== "ADWALLET") && (
                                          <Button
                                            type="primary"
                                            disabled={checkAuthnMethod !== ""}
                                            onClick={() => handleSave("ADWallet")}
                                          >
                                            Activate
                                          </Button>
                                        )}

                                        {checkAuthnMethod === "ADWallet" && (
                                          <Button danger onClick={handleDeactivate}>
                                            Deactivate
                                          </Button>
                                        )}
                                      </>,
                                    ]}
                                  >
                                    <Meta
                                      style={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                      title="Active Directory"
                                      description="Authentication method using Active Directory"
                                    />
                                  </Card>
                                </Col>
                                <Col span={6}>
                                  <Card
                                    style={{
                                      width: 300,
                                    }}
                                    hoverable
                                    cover={
                                      <img
                                        style={{
                                          height: "12rem",
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                        alt="Password"
                                        src="password.jpg"
                                      />
                                    }
                                    actions={[
                                      <>
                                        {(checkAuthnMethod === "" ||
                                          checkAuthnMethod.toUpperCase() !== "NORMAL") && (
                                          <Button
                                            type="primary"
                                            disabled={checkAuthnMethod !== ""}
                                            onClick={() => handleSave("Normal")}
                                          >
                                            Activate
                                          </Button>
                                        )}
                                        {checkAuthnMethod === "Normal" && (
                                          <Button danger onClick={handleDeactivate}>
                                            Deactivate
                                          </Button>
                                        )}
                                      </>,
                                    ]}
                                  >
                                    <Meta
                                      style={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                      title="Password"
                                      description="Basic authentication method using password"
                                    />
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ),
                    },
                  ]}
                />
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Settings;
