import React, { useState, useEffect } from "react";
import { Col, Row, Tag, Button, Select, Badge, message, notification } from "antd";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../../constants";
import { logOutUser } from "../../../common";
import ReactJoyride from "react-joyride";
import { EditOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import UpdatePolicy from "./UpdatePolicy";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import UpdatePolicyV2 from "./UpdatePolicyV2";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const Policy = () => {
  const [endpoints, setEndpoints] = useState([]);
  const [totalPages, setTotalPage] = useState();
  const [tenantData, setTenantData] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [endpointGroups, setEndpointGroups] = useState([]);

  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 100,
    page: 0,
  });
  const [isNotification, setNotification] = message.useMessage();
  const [user, setUser] = useState([]);
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [statusLoading, setStatusLoading] = useState(false);
  const [adGroups, setAdGroups] = useState([]);
  const [userType, setUserType] = useState(
    location?.state?.logType ? location?.state?.logType : "local"
  );
  const [epmUsers, setEpmUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const RequestFilterState = [
    {
      filterParameter: "destinationEndpoint",
      filterValue: location?.state?.destinationEndpoint,
    },
    {
      filterParameter: location?.state?.logType === "ad" ? "adUser" : "endpointUser",
      filterValue:
        location?.state?.logType === "ad" ? location?.state?.adUser : location?.state?.endpointUser,
    },
    {
      filterParameter: "walletUsers",
      filterValue: location?.state?.walletUser,
    },
    { filterParameter: "policyType", filterValue: location?.state?.logType },
  ];
  const [appliedFilters, setAppliedFilters] = useState(
    location?.state ? RequestFilterState : [{ filterParameter: "policyType", filterValue: "local" }]
  );

  useEffect(() => {
    //TODO : Onboarding api call done here
    fetchEndpoints();
    fetchDomains();
    fetchAdUser();
    fetchEndpointGroup();
    fetchGrp();
    fetchEndpointUser();
    fetchTenantDetails();
    // Clean up the message when the component unmounts
    return () => {
      message.destroy("permanentMessage");
    };
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: <span>View audited policies </span>,
        disableBeacon: true,
      },
      {
        target: "#onboarding_step2",
        content: <span> Approved or Rejected the policies </span>,
        disableBeacon: true,
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  const [epm, setEpm] = useState([]);
  const [dom, setDom] = useState([]);
  const [ouData, setOuData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios.post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    }).then((res) => {
      setTenantData(res.data.data);
      message.info({
        content: `Tenant has policies in ${
          res.data.data.authenticationpolicy === 1
            ? "audit"
            : res.data.data.authenticationpolicy === 2
            ? "policy"
            : "live"
        } mode`,
        key: "permanentMessage",
        duration: 0, // Set duration to 0 for a permanent message
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        },
      });
    });
  };

  useEffect(() => {
    // Display a permanent message on mount
    fetchPolicy();
  }, [appliedFilters, sortModel, userType]);

  const fetchEndpointUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setEpmUsers(data.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            name: grp.groupName,
            ...grp,
          }));
        }
        setAdGroups(Data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const fetchAdUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setUser(res?.data?.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchEndpointGroup = async () => {
    const DOMAIN_ID = localStorage.getItem("DomainId");
    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      const originalGroups = res?.data?.groups;

      // Adding an 'id' property to each object
      const groupsWithId = originalGroups.map((group, index) => ({
        ...group,
        id: index + 1, // You can use a different logic to generate IDs if needed
      }));
      setEndpointGroups(groupsWithId);
    } catch (err) {
      if (err?.response?.data?.message === "Invalid credentials" || err?.response?.status === 401) {
        isNotification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        //logOutUser();
      } else {
        isNotification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };
  const changePolicyStatus = (e, val) => {
    let id = val.row.id;
    // const status = val.row.policyStatus.toLowerCase() === "approved" ?  "Approved" :"Denied" ;

    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      requestId: id.toString(),
      status: e,
    };
    setStatusLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/UpdateUserAccessRequest`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setStatusLoading(false);
        isNotification.open({
          type: "success",
          content: `${val.row.policyName} policies ${e} successfully`,
        });
        fetchPolicy(currentPageModal.page + 1);
      })
      .catch((err) => {
        setStatusLoading(false);
      });
  };

  const fetchEndpoints = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        setEpm(data.data.endpoints);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const fetchDomains = (page) => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain, ou: item.ous };
        });

        setDom(inputArray);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPolicy = (page, filters) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      policyType: location?.state?.logType ? location?.state?.logType : userType,
      filters: appliedFilters,
      requestId: "",
      limit: 100,
      page: page,
      sort: {
        field: sortModel ? sortModel[0]?.field : "",
        order: sortModel ? sortModel[0]?.sort : "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/FilterPolicy`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        setEndpoints(data.data);
        setTotalPage(data.total_pages * 100);
        setCurrentPageModal({
          pageSize: 100,
          page: data.page - 1,
        });
        setIsOnboarding(type ? true : false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };

  const handleSortModelChange = (val) => {
    if (val?.length > 0) {
      setSortModel(val);
    }
  };

  const columns = [
    {
      headerName: "", // Empty header for the expand/collapse column
      field: "expand",
      width: 10,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleRowExpand(params);
            showModal();
          }}
        >
          <EditOutlined />
        </Link>
      ),
    },
    {
      headerName: "Wallet Users",
      field: "walletUsers",
      width: 160,
      minWidth: 200,
      maxWidth: 500,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.walletUsers?.split(",");
        let tooltipVal = tagsArray.slice(-1).map((tag, index) => {
          return tag;
        });

        return (
          <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
            {/* {tagsArray?.map((tag, index) => (
              <Tag key={index} onClick={() => handleApplyFilter("walletUsers", tag)}>
                {tag.trim()}
              </Tag>
            ))} */}

            {tagsArray.length > 1 ? (
              <>
                {tagsArray.slice(0, 1).map((tag, index) => (
                  <Tag
                    key={index}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleApplyFilter("walletUsers", tag)}
                  >
                    {tag.trim()}
                  </Tag>
                ))}

                <Tooltip title={tooltipVal.join(", ")}>
                  <Tag key="ellipsis" style={{ cursor: "default" }}>
                    ...
                  </Tag>
                </Tooltip>
              </>
            ) : (
              tagsArray.map((tag, index) => (
                <Tag
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleApplyFilter("walletUsers", tag)}
                >
                  {tag.trim()}
                </Tag>
              ))
            )}
          </div>
        );
      },
    },
    userType === "ad"
      ? {
          headerName: "Directory User",
          field: "adUser",
          width: 180,
          sortable: true,
          renderCell: (val) => {
            const tagsArray = val.row.adUser.split(",");

            let tooltipVal = tagsArray.slice(-2).map((tag, index) => {
              return tag;
            });

            return (
              // <div style={{ whiteSpace: "normal" }}>
              //   {tagsArray.map((tag, index) => (
              //     <>
              //       <Tag
              //         key={index}
              //         style={{ cursor: "pointer" }}
              //         onClick={() => handleApplyFilter("adUser", tag)}
              //       >
              //         {tag.trim()}
              //       </Tag>
              //     </>
              //   ))}
              // </div>
              <div style={{ whiteSpace: "normal" }}>
                {tagsArray.length > 2 ? (
                  <>
                    {tagsArray.slice(0, 2).map((tag, index) => (
                      <Tag
                        key={index}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleApplyFilter("adUser", tag)}
                      >
                        {tag.trim()}
                      </Tag>
                    ))}

                    <Tooltip title={tooltipVal.join(", ")}>
                      <Tag key="ellipsis" style={{ cursor: "default" }}>
                        ...
                      </Tag>
                    </Tooltip>
                  </>
                ) : (
                  tagsArray.map((tag, index) => (
                    <Tag
                      key={index}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleApplyFilter("adUser", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))
                )}
              </div>
            );
          },
        }
      : {
          headerName: userType === "ServiceAccount" ? "Service Account Users" : "Endpoint Users",
          field: "endpointUser",
          sortable: true,
          width: 210,
          renderCell: (val) => {
            let tooltipVal;
            const serviceAccountLocal = val.row.endpointUser.split(",");
            tooltipVal = serviceAccountLocal.slice(-2).map((tag, index) => {
              return tag;
            });

            const serviceAccountAd = val.row.adUser.split(",");

            tooltipVal = serviceAccountAd.slice(-2).map((tag, index) => {
              return tag;
            });

            const tagsArray =
              userType === "ServiceAccount" && val.row.endpointUser === ""
                ? serviceAccountAd
                : serviceAccountLocal;

            return (
              // <div style={{ whiteSpace: "normal" }}>
              //   {tagsArray.map((tag, index) => (
              //     <Tag
              //       key={index}
              //       style={{
              //         cursor: "pointer",
              //         // whiteSpace: "break-spaces"
              //       }}
              //       onClick={() => handleApplyFilter("endpointUser", tag)}
              //     >
              //       {tag.trim()}
              //     </Tag>
              //   ))}
              // </div>
              <div style={{ whiteSpace: "normal" }}>
                {tagsArray.length > 2 ? (
                  <>
                    {tagsArray.slice(0, 2).map((tag, index) => (
                      <Tag
                        key={index}
                        style={{
                          cursor: "pointer",
                          // whiteSpace: "break-spaces"
                        }}
                        onClick={() =>
                          handleApplyFilter(
                            userType === "ServiceAccount" && val.row.endpointUser === ""
                              ? "adUser"
                              : "endpointUser",
                            tag
                          )
                        }
                      >
                        {tag.trim()}
                      </Tag>
                    ))}
                    <Tooltip title={tooltipVal.join(", ")}>
                      <Tag key="ellipsis" style={{ cursor: "default" }}>
                        ...
                      </Tag>
                    </Tooltip>
                  </>
                ) : (
                  tagsArray.map((tag, index) => (
                    <Tag
                      key={index}
                      style={{
                        cursor: "pointer",
                        // whiteSpace: "break-spaces"
                      }}
                      onClick={() =>
                        handleApplyFilter(
                          userType === "ServiceAccount" && val.row.endpointUser === ""
                            ? "adUser"
                            : "endpointUser",
                          tag
                        )
                      }
                    >
                      {tag.trim()}
                    </Tag>
                  ))
                )}
              </div>
            );
          },
        },
    {
      headerName: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Policy Action</span>
          <Tooltip title="Policy action can be allow or deny.Allowed policy can be able to access the policy.Deny means unable to access the policy. ">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),

      field: "action",
      sortable: true,
      width: 180,
      renderCell: (val) => {
        const result = val?.row?.action.toLowerCase();
        return (
          <div style={{ whiteSpace: "normal" }}>
            <Tag
              style={{
                cursor: "pointer",
              }}
              color={result === "deny" ? "red" : "green"}
              onClick={() => handleApplyFilter("action", val?.row?.action)}
            >
              {result === "" ? "Allow" : val?.row?.action}
            </Tag>
          </div>
        );
      },
    },
    // {
    //   headerName: "Policy Type",
    //   field: "policyType",
    //   sortable: true,
    //   width: 140,
    //   renderCell: (val) => {
    //     const tagsArray = val.row.policyType.split(",");

    //     return (
    //       <div style={{ whiteSpace: "normal" }}>
    //         {tagsArray.map((tag, index) => (
    //           <Tag
    //             key={index}
    //             style={{
    //               cursor: "pointer",
    //               whiteSpace: "break-spaces",
    //             }}
    //             onClick={(e) => {
    //               handleApplyFilter("policyType", tag);
    //               setUserType(tag);
    //             }}
    //           >
    //             {tag.toLowerCase() === "local"
    //               ? "Local User"
    //               : tag === "ad"
    //               ? "Active Directory"
    //               : "Local User"}
    //           </Tag>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    ...(userType === "ServiceAccount"
      ? [
          {
            headerName: "Source",
            field: "sourceEndpoint",
            width: 50,
            minWidth: 200,
            maxWidth: 400,
            sortable: true,
            renderCell: (val) => {
              const tagsArray = val?.row?.sourceEndpoint?.split(","); //NOTE : SOURCE HOSTNAME IS NOT AVAILABLE IN THE RESPONSE
              return (
                <div style={{ whiteSpace: "normal" }}>
                  {tagsArray?.map((tag, index) => (
                    <Tag
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => handleApplyFilter("sourceEndpoint", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))}
                </div>
              );
            },
          },
        ]
      : []),
    {
      headerName: "Destination",
      field: "destinationEndpoint",
      sortable: true,
      width: 180,
      renderCell: (val) => {
        const tagsArray = val.row.destinationEndpoint.split(",");
        let tooltipVal = tagsArray.slice(-1).map((tag, index) => {
          return tag;
        });

        return (
          // <div style={{ whiteSpace: "normal" }}>
          //   {tagsArray.map((tag, index) => (
          //     <Tag
          //       key={index}
          //       style={{
          //         cursor: "pointer",
          //         whiteSpace: "break-spaces",
          //       }}
          //       onClick={() => handleApplyFilter("destinationEndpoint", tag)}
          //     >
          //       {tag.trim()}
          //     </Tag>
          //   ))}
          // </div>
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray.length > 1 ? (
              <>
                {tagsArray.slice(0, 1).map((tag, index) => (
                  <Tag
                    key={index}
                    style={{
                      cursor: "pointer",
                      whiteSpace: "break-spaces",
                    }}
                    onClick={() => handleApplyFilter("destinationEndpoint", tag)}
                  >
                    {tag.trim()}
                  </Tag>
                ))}
                <Tooltip title={tooltipVal.join(", ")}>
                  <Tag key="ellipsis" style={{ cursor: "default" }}>
                    ...
                  </Tag>
                </Tooltip>
              </>
            ) : (
              tagsArray.map((tag, index) => (
                <Tag
                  key={index}
                  style={{
                    cursor: "pointer",
                    whiteSpace: "break-spaces",
                  }}
                  onClick={() => handleApplyFilter("destinationEndpoint", tag)}
                >
                  {tag.trim()}
                </Tag>
              ))
            )}
          </div>
        );
      },
    },
    {
      headerName: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Status</span>
          <Tooltip title="Status of the policy can be Active,Inactive or In Review">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      field: "policyStatus",
      width: 140,
      renderCell: (value) => {
        return (
          <>
            <Select
              size="small"
              defaultValue={
                value.row.policyStatus.toLowerCase() === "approved"
                  ? "Approved"
                  : value.row.policyStatus.toLowerCase() === "denied"
                  ? "Denied"
                  : "In Review"
              }
              onChange={(e) => changePolicyStatus(e, value)}
              disabled={statusLoading}
            >
              <Option value="In Review">
                {" "}
                <Badge status="warning" text="In Review" />
              </Option>
              <Option value="Approved">
                <Badge status="success" text="Active" />
              </Option>
              <Option value="Denied">
                <Badge status="error" text="Inactive" />
              </Option>
            </Select>
          </>
        );
      },
    },

    // {
    //   headerName: "Domain",
    //   field: "adDomain",
    //   width: 150,
    //   sortable: true,
    //   renderCell: (val) => {
    //     const tagsArray = val.row.adDomain.split(",");

    //     return (
    //       <div style={{ whiteSpace: "normal" }}>
    //         {tagsArray.map((tag, index) => (
    //           <Tag
    //             key={index}
    //             style={{ cursor: "pointer" }}
    //             onClick={() => handleApplyFilter("adDomain", tag)}
    //           >
    //             {tag.trim()}
    //           </Tag>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   headerName: "Ou",
    //   field: "adOu",
    //   width: 120,
    //   sortable: true,
    //   renderCell: (val) => {
    //     const tagsArray = val.row.adOu.split(",");

    //     return (
    //       <div style={{ whiteSpace: "normal" }}>
    //         {tagsArray.map((tag, index) => (
    //           <Tag
    //             key={index}
    //             style={{ cursor: "pointer" }}
    //             onClick={() => handleApplyFilter("adOu", tag)}
    //           >
    //             {tag.trim()}
    //           </Tag>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   headerName: "OS",
    //   field: "os",
    //   width: 120,
    //   sortable: true,
    //   renderCell: (val) => {
    //     const tagsArray = val.row.os.split(",");

    //     return (
    //       <div style={{ whiteSpace: "normal" }}>
    //         {tagsArray.map((tag, index) => (
    //           <>
    //             <Tag
    //               key={index}
    //               style={{ cursor: "pointer" }}
    //               onClick={() => handleApplyFilter("os", tag)}
    //             >
    //               {tag.trim()}
    //             </Tag>

    //             <br />
    //           </>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Policy Name",
      field: "policyName",
      width: 220,
      sortable: true,
    },
    {
      headerName: "Updated At",
      field: "timestamp",
      width: 160,
      sortable: true,
      renderCell: (val) => {
        const formatString = "DD-MM-YYYY HH:mm [PST]";

        const formattedDate = moment.unix(val.row.timestamp).format(formatString);

        return <span>{formattedDate}</span>;
      },
    },

    // {
    //   headerName: "Execution Count",
    //   field: "count",
    //   flex: 1.3,
    //   sortable: true,
    // },
  ];

  const handleApplyFilter = (filter, value) => {
    let filterParameter = filter;
    let filterValue = value;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    setAppliedFilters(uniqueFilters);
  };

  const getCurrentOption = (type) => {
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const handleRemoveFilter = (filter, value) => {
    if (userType === "ad") {
      setUserType("ad");
    } else if (userType === "local") {
      setUserType("local");
    } else {
      setUserType("ServiceAccount");
    }
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };

  const handleRowExpand = (params) => {
    setSelectedRow(params.row);
    setExpandedRows((prevRows) =>
      prevRows.includes(params.id.toString())
        ? prevRows.filter((row) => row !== params.id.toString())
        : [...prevRows, params.id.toString()]
    );
  };

  const isRowExpandable = (params) => {
    // Example condition for expandable rows
    return true;
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {isOnBoarding && startTour()}
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
            <Col>
              <Link to={"/pam/policy/createEndpointAccessPolicy"}>
                <Button type="primary">Add Access Policy</Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Policy Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Policy Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={getCurrentOption("policyType")}
                        onChange={(e) => {
                          handleApplyFilter("policyType", e);
                          setUserType(e);
                        }}
                      >
                        <Option value="local">Local User</Option>
                        <Option value="ad">Active Directory</Option>
                        <Option value="ServiceAccount">Service Account</Option>
                      </Select>
                    </Col>
                    {userType === "ad" && (
                      <>
                        <Col span={4}>
                          <p className="search-label">Domain</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={getCurrentOption("adDomain")}
                            onChange={(e, val) => {
                              handleApplyFilter("adDomain", val?.children);
                              const selectedDomain = dom.find((domain) => domain.id === e);
                              if (selectedDomain) {
                                setOuData(
                                  selectedDomain?.ous?.map((data) => {
                                    let ouVal = data.OuName.split(",")[0];
                                    let nameOu = ouVal.substring(3);
                                    return { ...data, ouNames: nameOu };
                                  })
                                );
                              } else {
                                setOuData([]);
                              }
                              // console.log(e, val);
                            }}
                          >
                            {dom?.map((val) => (
                              <Option key={val.id} value={val.id}>
                                {val.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <p className="search-label">Ou</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search OU "
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(_, val) => {
                              handleApplyFilter("adOu", val.children);
                            }}
                            value={getCurrentOption("adOu")}
                          >
                            {ouData?.map((ou) => (
                              <Option key={ou.AdId} value={ou.Id}>
                                {ou.OuName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </>
                    )}
                    <Col span={4}>
                      <p className="search-label">Endpoint</p>
                      <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search Endpoint "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={getCurrentOption("destinationEndpoint")}
                        onChange={(e) => handleApplyFilter("destinationEndpoint", e)}
                      >
                        {epm &&
                          epm.length > 0 &&
                          epm.map((endpoint) => (
                            <Option key={endpoint.id} value={endpoint.name}>
                              {`${endpoint.name} `}
                              <strong>({endpoint.publicIpAddress})</strong>
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Status</p>
                      <Select
                        value={getCurrentOption("policyStatus")}
                        onChange={(e) => handleApplyFilter("policyStatus", e)}
                        placeholder="Select Status"
                      >
                        <Option value="In Review">In Review</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Denied">Denied</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters
                      </h4>

                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          <Tag
                            key={`${filterParameter}:${filterValue}`}
                            closable={filterParameter === "policyType" ? false : true}
                            onClose={() => {
                              if (filterParameter !== "policyType") {
                                handleRemoveFilter(filterParameter, filterValue);
                              }
                            }}
                          >
                            {`${filterParameter}: ${filterValue}`}
                          </Tag>
                        </>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} id="onboarding_step1">
                  <DataGrid
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      noResultsOverlay: CustomNoRowsOverlay,
                    }}
                    loading={isLoading}
                    rows={endpoints}
                    columns={columns}
                    pageSizeOptions={[10]}
                    rowCount={totalPages}
                    paginationMode="server"
                    // Add autoHeight property
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 100, page: 0 },
                      },
                    }}
                    paginationModel={currentPageModal}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    isRowExpandable={isRowExpandable}
                    getRowId={(row) => row.id.toString()}
                    expandedRows={expandedRows}
                    onRowExpand={handleRowExpand}
                    onPaginationModelChange={(params) => {
                      fetchPolicy(params.page + 1, appliedFilters);
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) => {
                      let filterParameter = e.items[0].field;
                      let filterValue = e.items[0].value;
                      let obj = [
                        {
                          filterParameter: filterParameter,
                          filterValue: filterValue,
                        },
                      ];
                      let tempAppliedFilter = appliedFilters;
                      let tempfilter = tempAppliedFilter.concat(obj);
                      const uniqueFilters = tempfilter.reduceRight((acc, current) => {
                        const existingFilter = acc.find(
                          (filter) => filter.filterParameter === current.filterParameter
                        );

                        if (!existingFilter) {
                          acc.push(current);
                        }

                        return acc;
                      }, []);
                      setAppliedFilters(uniqueFilters);
                    }}
                    style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                    rowThreshold={0}
                    pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                    sx={{
                      "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {isModalVisible && (
        <UpdatePolicyV2
          selectedRow={selectedRow}
          notification={isNotification}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          endpointGroups={endpointGroups}
          domainData={dom}
          epmUsers={epmUsers}
          epmGroups={endpointGroups}
          adGroups={adGroups}
          epm={epm}
          adUser={user}
          fetchPolicy={fetchPolicy}
        />
      )}
    </>
  );
};

export default Policy;
