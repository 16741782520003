import { Col, Row, Select, Input, message, Popconfirm, Dropdown, Button, Typography } from "antd";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;

const WalletUsers = () => {
  const [walletData, setWalletData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const location = useLocation();
  const { email_address } = location.state || {};
  const [search, setSearch] = useState(email_address || "");
  const debouncedValue = useDebounce(search, 500);
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [sortModel, setSortModel] = useState();
  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });

  const { Paragraph, Text } = Typography;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchWallet();
  }, [filter, debouncedValue, currentPageModal]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    const filteredOptions = walletData
      .filter((wallet) => wallet.emailId.toLowerCase().includes(value.toLowerCase()))
      .map((wallet) => ({
        label: wallet.emailId,
        value: wallet.emailId,
      }));

    setOptions(filteredOptions);
  };

  const handleResetWallet = (val) => {
    let payload = {
      userId: val.userId,
      walletId: val.walletId,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/credential/resetWallet`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification.open({
          type: "success",
          content: "Wallet reset successfully",
        });
        fetchWallet();
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to reset wallet",
          });
        }
      });
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const fetchWallet = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: currentPageModal.page + 1,
      pageSize: currentPageModal?.pageSize,
      filter: debouncedValue,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data = [];
        if (res.data.users) {
          Data = res.data.users.map((wallet, i) => ({
            id: i,
            emailId: wallet.emailAddress,
            status: wallet.status,
            walletId: wallet.walletId,
            walletkey: wallet.walletKey,
            ...wallet,
          }));
          // Extract domains from email addresses
          // const uniqueDomains = [
          //   ...new Set(res.data.users),
          // ];
          // setDomains(uniqueDomains);
        }
        setWalletData(Data);
        setTotalCount(res?.data?.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    {
      headerName: "Email Id",
      field: "emailAddress",
      key: "emailAddress",
      flex: 1.3,
    },
    {
      headerName: "Wallet Key",
      field: "walletKey",
      key: "walletkey",
      flex: 2.6,
      renderCell: (val) => {
        return (
          <div>
            <Paragraph
              copyable={{
                text: async () =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve(val.row.walletkey);
                    }, 500);
                  }),
              }}
            >
              {val.row.walletkey}{" "}
            </Paragraph>
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      flex: 0.6,
      renderCell: (val) => {
        const statusVal = val?.row?.status?.toLowerCase();
        return (
          <div>
            {statusVal === "registered" ? (
              <Text type="success">Registered</Text>
            ) : statusVal === "pending" ? (
              <Text type="warning">Pending</Text>
            ) : statusVal === "inactive" ? (
              <Text type="danger">Inactive</Text>
            ) : (
              statusVal === "revoked" && <Text type="danger">Revoked</Text>
            )}
          </div>
        );
      },
    },

    {
      headerName: "View Credentials",
      field: "viewCredentials",
      key: "viewCredentials",
      flex: 0.8,
      renderCell: (value, record) => (
        <Link
          to={`view-credentials`}
          state={{ walletId: value.row.walletId }}
          onClick={() => {
            let values = {
              walletId: value.row.walletId,
              mailId: value.row.emailAddress,
            };
            localStorage.setItem("details", JSON.stringify(values));
          }}
        >
          View
        </Link>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      key: "action",
      flex: 0.7,
      renderCell: (val) => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items: [
              {
                key: "revokeWallet",
                label: (
                  <Popconfirm
                    title={`Are you sure want to reset the wallet for ${val.row.emailAddress}?`}
                    onConfirm={() => handleResetWallet(val.row)}
                  >
                    <Link>Reset Wallet</Link>
                  </Popconfirm>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];
  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Wallet Users</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <AutoComplete
                      placeholder="Search E-mail"
                      allowClear
                      value={search}
                      onSearch={handleSearch}
                      options={options}
                      onChange={(value) => {
                        setSearch(value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <Table
            loading={isLoading}
            columns={columns}
            dataSource={walletData}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              ...paginationParams,
              total: totalCount,
              onChange: (page, pageSize) => {
                setPaginationParams({
                  current: page,
                  pageSize: pageSize,
                });
              },
            }}
          /> */}

          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            rows={walletData}
            columns={columns}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPageModal.page}
            pageSize={currentPageModal.pageSize}
            onPaginationModelChange={(params) => {
              setCurrentPageModal({
                page: params.page,
                pageSize: 10,
              });
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default WalletUsers;
