import { Col, Row, Space, Checkbox } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Splunk = () => {
  const [sortModel, setSortModel] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState([]);
  const [isReset, setIsReset] = useState();
  const [locationFilter, setLocationFilter] = useState([]);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const generateDataset = (count) => {
    const dataset = [];

    for (let i = 0; i < count; i++) {
      dataset.push({
        id: i + 1,
        dateTime: moment()
          .add(i, "days")
          .tz("America/Los_Angeles")
          .format("DD-MM-YYYY HH:mm [PST]"),
        json: `{
          "username": "muthu"
          "userId": 123,
          "userType": "localuser",
          "os": "Linux",
          "authMode": 1,
          "tenantId": 24,
          "orgId": 3,
          "endpoint": "192.178.1.29",
          "accessMedium": "Direct",
          "protocol": "SSH"
          "mfaDevice": 1,
          "timestamp": 1701783912,
          "policyId": 123,
          "endpointId": 300,
          "endpointGroupId": 400,
          "ssoSession": "Active"
        }`,

        username: `User ${i + 1}`,
        userType: i % 2 ? "Service Account" : "Local user",
        endpoint: `Endpoint ${i + 1}`,
        endpointGroup: `Group ${i + 1}`,
        ip: `192.168.${i % 256}.${i % 256}`,
        os: i % 2 ? "Linux" : "Windows",
        tenantName: `Tenant ${i + 1}`,
        orgName: `Org ${i + 1}`,
        accessMedium: "Direct",
        protocol: i % 2 ? "SSH" : "RDP",
        status: i % 2 ? "Active" : "Inactive",
        approvedBy: `User ${i + 1}`,
        request: `Request ${i + 1}`,
        mfaDevice: `Device ${i + 1}`,
      });
    }

    return dataset;
  };
  const endpoints = generateDataset(20);
  const [splnkData, setsplunkData] = useState(
    endpoints.filter((endpoint) => {
      return (
        endpoint.endpoint.toLowerCase().includes(searchText.toLowerCase()) &&
        appliedFilters.every(({ filter, value }) =>
          endpoint[filter].toLowerCase().includes(value.toLowerCase())
        )
      );
    })
  );

  const columns = [
    { headerName: "Date", field: "dateTime", key: "dateTime", width: 200 },
    { headerName: "Json", field: "json", key: "json", width: 860 },
  ];

  const handleReset = () => {
    setLocationFilter([]);
  };
  const handleFilter = (e) => {
    console.log(e);
    let selectedValue = [];
    if (e.target.checked) {
      selectedValue = [...locationFilter, e.target.value];
    } else {
      selectedValue = locationFilter?.filter((filter) => filter !== e.target.value);
    }
    // setAppliedFilters([...appliedFilters, { selectedValue }]);

    setLocationFilter(selectedValue);
  };
  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Splunk</h2>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={splnkData}
                paginationMode="server"
                columns={columns}
                pageSize={10}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
              />
            </Col>
            {/* <Col span={6}>
              <div
                style={{
                  boxShadow: "0 10px 20px #00000008, 0 2px 2px #00000005",
                  marginLeft: "1rem",
                  padding: "0 0.5rem",
                }}
              >
                <Row justify="space-between">
                  <Col>
                    <h2 className="title">Filter</h2>
                  </Col>
                  <Col>
                    <Space>
                      <Button onClick={handleReset}>Reset</Button>
                      <Button type="primary"> Apply</Button>
                    </Space>
                  </Col>
                </Row>
                <hr />
                <Collapse
                  defaultActiveKey={["1"]}
                  ghost
                  items={[
                    {
                      key: "1",
                      label: "User Name",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            {splnkData.map((res) => (
                              <Col span={12}>
                                <Checkbox
                                  value={res.id}
                                  onChange={(e) => {
                                    handleFilter(e);
                                  }}
                                  checked={locationFilter?.includes(res.id)}
                                >
                                  {res.username}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },
                    {
                      key: "2",
                      label: "User Type",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            <Col span={12}>
                              <Checkbox
                                onChange={(e) => {
                                  setFilter(e);
                                  setIsReset(!isReset);
                                }}
                                checked={isReset}
                              >
                                Active
                              </Checkbox>
                            </Col>
                            <Col>
                              <Checkbox
                                onChange={(e) => {
                                  setFilter(e);
                                  setIsReset(!isReset);
                                }}
                                checked={isReset}
                              >
                                Inactive
                              </Checkbox>
                            </Col>
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },
                    {
                      key: "3",
                      label: "OS",
                      // children: ,
                    },
                    {
                      key: "4",
                      label: "Status",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            <Col span={12}>
                              <Checkbox
                                onChange={(e) => {
                                  setFilter(e);
                                  setIsReset(!isReset);
                                }}
                                checked={isReset}
                              >
                                Active
                              </Checkbox>
                            </Col>
                            <Col>
                              <Checkbox
                                onChange={(e) => {
                                  setFilter(e);
                                  setIsReset(!isReset);
                                }}
                                checked={isReset}
                              >
                                Inactive
                              </Checkbox>
                            </Col>
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },
                    {
                      key: "5",
                      label: "Protocol",
                      // children: <p>{text}</p>,
                    },
                    {
                      key: "6",
                      label: "Access Medium",
                      // children: <p>{text}</p>,
                    },
                    {
                      key: "7",
                      label: "Endpoint",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            {splnkData.map((res) => (
                              <Col span={12}>
                                <Checkbox
                                  value={res.ip}
                                  onChange={(e) => {
                                    setFilter(e);
                                    setIsReset(!isReset);
                                  }}
                                  checked={isReset}
                                >
                                  {res.endpoint}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },
                    {
                      key: "8",
                      label: "Endpoint Group",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            {splnkData.map((res) => (
                              <Col span={12}>
                                <Checkbox
                                  value={res.ip}
                                  onChange={(e) => {
                                    setFilter(e);
                                    setIsReset(!isReset);
                                  }}
                                  checked={isReset}
                                >
                                  {res.endpointGroup}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },

                    {
                      key: "9",
                      label: "Tenant Name",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            {splnkData.map((res) => (
                              <Col span={12}>
                                <Checkbox
                                  value={res.ip}
                                  onChange={(e) => {
                                    setFilter(e);
                                    setIsReset(!isReset);
                                  }}
                                  checked={isReset}
                                >
                                  {res.tenantName}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },
                    {
                      key: "10",
                      label: "Org Nmae",
                      children: (
                        <>
                          <Row gutter={[12, 14]}>
                            {splnkData.map((res) => (
                              <Col span={12}>
                                <Checkbox
                                  value={res.ip}
                                  onChange={(e) => {
                                    setFilter(e);
                                    setIsReset(!isReset);
                                  }}
                                  checked={isReset}
                                >
                                  {res.orgName}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                          <br />
                          <hr />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Splunk;
