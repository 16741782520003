import { Button, Col, Drawer, message, Row, Select, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../constants";
import axios from "axios";
import { logOutUser } from "../../common";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import ReactJson from "@microlink/react-json-view";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { CSVDownload, CSVLink } from "react-csv";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const AllLogs = () => {
  const [isNotification, setNotification] = message.useMessage();
  const [logType, setLogType] = useState("Endpoint");
  const [appliedFilters, setAppliedFilters] = useState([
    { filterParameter: "LogType", filterValue: "Endpoint" },
    { filterParameter: "OS", filterValue: "Windows" },
  ]);
  const [endpointData, setEndpointData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [ouData, setOuData] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });
  const [osType, setOsType] = useState("Windows");
  const [logsData, setLogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [isOpenJson, setIsOpenJson] = useState(false);
  const [selectedRowJson, setSelectedRowJson] = useState();
  const { Option } = Select;

  useEffect(() => {
    fetchInstance();
  }, [osType]);
  useEffect(() => {
    fetchLogs();
  }, [appliedFilters, logType, currentPage]);

  const handleApplyFilter = (filter, value) => {
    let filterParameter = filter;
    let filterValue = value;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    setAppliedFilters(uniqueFilters);
  };

  const handleRemoveFilter = (filter, value) => {
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };
  const getCurrentOption = (type) => {
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        let endpoint;
        if (osType === "Windows") {
          endpoint = res?.data?.instances
            ?.filter((user) => user.osName.toLowerCase() === "windows")
            .map((endpoint) => ({
              label: `${endpoint.hostName} - (${endpoint?.publicIp})`,
              value: endpoint.instanceId,
              ...endpoint,
            }));
        } else {
          endpoint = res?.data?.instances
            ?.filter((user) => user.osName.toLowerCase() === "linux")
            .map((endpoint) => ({
              label: `${endpoint.hostName} - (${endpoint?.publicIp})`,
              value: endpoint.instanceId,
              ...endpoint,
            }));
        }

        setEndpointData(endpoint);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        setDomainData(inputArray);
      });
  };
  const JsonCellRenderer = ({ value }) => (
    <ReactJson
      src={value}
      name={false}
      collapsed={false}
      // theme={"rjv-default"}
      theme="monokai"
      enableClipboard={false}
      enableEdit={false}
      enableAdd={false}
      enableDelete={false}
      displayDataTypes={false}
      displayObjectSize={false}
    />
  );
  const columns = [
    ...(logType !== "Agent"
      ? [
          {
            field: logType === "AD" ? "user_name" : "user_name",
            headerName: logType === "AD" ? "Username" : "Username",
            flex: 1,
          },
        ]
      : []),
    ...(logType === "Endpoint" && osType === "Windows"
      ? [
          {
            field: "sub_component",
            headerName: "User Type",
            flex: 1,
            height: 600,
            renderCell: (val) => {
              const result = val?.row?.sub_component?.toLowerCase();
              return (
                <div style={{ whiteSpace: "normal" }}>
                  <Tag
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleApplyFilter("Component", result === "ldap-plugin" ? "LDAP" : "Local")
                    }
                  >
                    {result === "ldap-plugin" ? "LDAP" : "Local"}
                  </Tag>
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: "json",
      headerName: "JSON",
      flex: 4,
      renderCell: (val) => {
        const data = val.row;

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpenJson(true);
              setSelectedRowJson(data);
            }}
          >
            {JSON.stringify(data, null, 2)}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (val) => {
        const formatString = "DD-MM-YYYY HH:mm [PST]";

        const formattedDate =
          logType === "Agent"
            ? moment(val.row.created_at).tz("America/Los_Angeles").format(formatString)
            : moment.unix(val.row.created_at).format(formatString);

        return <span>{formattedDate}</span>;
      },
    },
  ];

  const fetchLogs = () => {
    let LogPayload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      filters: appliedFilters,
      requestId: "",
      limit: currentPageModel.pageSize,
      page_id: currentPage + 1,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/log/endpoint/listlog`, LogPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        console.log(res);

        if (!res?.data?.data || res.data.data.length === 0) {
          setLogsData([]);
          setIsLoading(false);
          return;
        }
        let tempData = res?.data?.data.map((data) => {
          return { ...data };
        });
        setLogsData(tempData);
        setTotalCount(res?.data?.total_count);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const headers =
    logType !== "Agent"
      ? [
          { label: "id", key: "id" },
          { label: "org_id", key: "org_id" },
          { label: "tenant_id", key: "tenant_id" },
          { label: "user_name", key: "user_name" },
          { label: "domain_name", key: "domain_name" },
          { label: "log_description", key: "log_description" },
          { label: "host_name", key: "host_name" },
          { label: "destination_ip", key: "destination_ip" },
          { label: "source_ip", key: "source_ip" },
          { label: "status", key: "status" },
          { label: "component", key: "component" },
          { label: "sub_component", key: "sub_component" },
          { label: "group_name", key: "group_name" },
          { label: "created_at", key: "created_at" },
        ]
      : [
          { label: "id", key: "id" },
          { label: "org_id", key: "org_id" },
          { label: "tenant_id", key: "tenant_id" },
          { label: "public_ip", key: "public_ip" },
          { label: "log_message", key: "log_message" },
          { label: "component", key: "component" },
          { label: "created_at", key: "created_at" },
          { label: "host_name", key: "host_name" },
          { label: "os", key: "os" },
        ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Logs Management</h2>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Log Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Access Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          handleApplyFilter("LogType", e);
                          setAppliedFilters(
                            e !== "AD"
                              ? [
                                  {
                                    filterParameter: "OS",
                                    filterValue: "Windows",
                                  },
                                  { filterParameter: "LogType", filterValue: e },
                                ]
                              : [{ filterParameter: "LogType", filterValue: e }]
                          );
                          setOsType(e !== "AD" && "Windows");
                          setLogType(e);
                          if (e === "AD") {
                            listDomain();
                          }
                        }}
                        value={getCurrentOption("LogType")}
                        options={[
                          { value: "Endpoint", label: "Endpoint" },
                          {
                            value: "AD",
                            label: "Active Directory",
                          },
                          {
                            value: "Agent",
                            label: "Agent",
                          },
                        ]}
                      />
                    </Col>

                    {logType === "AD" ? (
                      <>
                        <Col span={4}>
                          <p className="search-label">Domain</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={getCurrentOption("DomainName")}
                            onChange={(e, val) => {
                              const domainFilter = val?.children;
                              handleApplyFilter("DomainName", domainFilter.split("(")[0].trim());
                              const selectedDomain = domainData.find((domain) => domain.id === e);
                              if (selectedDomain) {
                                setOuData(
                                  selectedDomain?.ous?.map((data) => {
                                    let ouVal = data.OuName.split(",")[0];
                                    let nameOu = ouVal.substring(3);
                                    return { ...data, ouNames: nameOu };
                                  })
                                );
                              } else {
                                setOuData([]);
                              }
                            }}
                          >
                            {domainData?.map((dom) => (
                              <Option key={dom.id} value={dom.id}>
                                {dom.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        {/* <Col span={4}>
                          <p className="search-label">Ou</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search OU"
                            optionFilterProp="children"
                            onChange={(e, val) => handleApplyFilter("adOu", val?.children)}
                            value={getCurrentOption("adOu")}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {ouData?.map((ou) => (
                              <Option key={ou.AdId} value={ou.Id}>
                                {ou.OuName}
                              </Option>
                            ))}
                          </Select>
                        </Col> */}
                      </>
                    ) : (
                      <>
                        <Col span={4}>
                          <p className="search-label">
                            {logType === "Agent" ? "Agent Type" : "OS"}
                          </p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search OS "
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => {
                              handleApplyFilter(logType === "Agent" ? "OS" : "OS", e);
                              setAppliedFilters(
                                logType !== "AD"
                                  ? [
                                      {
                                        filterParameter: "OS",
                                        filterValue: e,
                                      },
                                      { filterParameter: "LogType", filterValue: logType },
                                    ]
                                  : [{ filterParameter: "LogType", filterValue: logType }]
                              );
                              setOsType(e);
                            }}
                            value={getCurrentOption("OS")}
                            options={[
                              { value: "Windows", label: "Windows" },
                              { value: "Linux", label: "Linux" },
                            ]}
                          />
                        </Col>
                        <Col span={4}>
                          <p className="search-label">Endpoint</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search Endpoint "
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e, val) => {
                              handleApplyFilter("HostName", e);
                            }}
                            value={getCurrentOption("HostName")}
                            // options={endpointData}
                          >
                            {endpointData.map((endpoint) => (
                              <Option key={endpoint.value} value={endpoint.hostName}>
                                {endpoint.label}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }} justify={"space-between"}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters:
                      </h4>
                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          <Tag
                            key={`${filterParameter}:${filterValue}`}
                            closable={filterParameter === "LogType" ? false : true}
                            onClose={() => {
                              if (filterParameter !== "LogType") {
                                handleRemoveFilter(filterParameter, filterValue);
                              }
                            }}
                          >
                            {`${filterParameter}: ${filterValue}`}
                          </Tag>
                        </>
                      ))}
                    </div>
                  )}
                </Col>
                <Col style={{ margin: "auto 0" }}>
                  <Button type="primary" ghost>
                    <CSVLink
                      data={logsData}
                      headers={headers}
                      filename={"log.csv"}
                      className="btn btn-primary"
                    >
                      {" "}
                      Export to CSV
                    </CSVLink>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DataGrid
                    rows={logsData}
                    columns={columns}
                    loading={isLoading}
                    rowCount={totalCount}
                    getRowId={(row) => row?.id?.toString()}
                    paginationMode="server"
                    page={currentPage}
                    paginationModel={currentPageModel}
                    onPaginationModelChange={(params) => {
                      setCurrentPage(params.page);
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) =>
                      handleApplyFilter(e.items[0].field, e.items[0].value)
                    }
                    disableRowSelectionOnClick
                    sx={{
                      "& .MuiDataGrid-cell": {
                        padding: "16px 8px",
                      },
                    }}
                    style={{ border: "none", minHeight: "50" }}
                    getRowHeight={() => "auto"}
                    columnVisibilityModel={columnVisibilityModel}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      noResultsOverlay: CustomNoRowsOverlay,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpenJson && (
        <Drawer
          title="Preview"
          onClose={() => setIsOpenJson(false)}
          open={isOpenJson}
          size={"large"}
        >
          <>
            {logType !== "Agent" && (
              <>
                <div style={{ paddingBottom: "1rem" }}>
                  <Row style={{ alignItems: "center" }}>
                    <h3>Status : </h3>
                    <Tag
                      style={{ marginLeft: "0.5rem" }}
                      // bordered={false}
                      color={
                        (selectedRowJson?.status?.toLowerCase() ||
                          selectedRowJson?.login_status?.toLowerCase()) === "success"
                          ? "success"
                          : "error"
                      }
                    >
                      {selectedRowJson?.status || selectedRowJson?.login_status}
                    </Tag>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    <Col span={4}>
                      <h4>Source IP : </h4>
                    </Col>
                    <Col span={18}>
                      <p>{selectedRowJson.source_ip || selectedRowJson.src_ip}</p>
                    </Col>
                    <Col span={5}>
                      <h4>Destination IP : </h4>
                    </Col>
                    <Col span={16}>
                      <p>{selectedRowJson.destination_ip || selectedRowJson.dest_ip}</p>
                    </Col>
                  </Row>
                </div>
                <hr />
              </>
            )}

            <div style={logType !== "Agent" ? { padding: "1rem 0" } : {}}>
              <Row>
                <Col span={24}>
                  <h3>Log Message</h3>
                  <p>{selectedRowJson.log_description || selectedRowJson.log_message}</p>
                </Col>
              </Row>
            </div>
            <hr />
            <div style={{ padding: "1rem 0" }}>
              <h3>Logs</h3>
              <JsonCellRenderer value={selectedRowJson} />
            </div>
          </>
        </Drawer>
      )}
    </>
  );
};

export default AllLogs;
