import { Card, Checkbox, Col, Progress, Row } from "antd";
import React, { useState } from "react";
import "../../../styles/onboard.scss";
import { Link } from "react-router-dom";

const PamLocalAccount = () => {
  const [percent, setPercent] = useState(37.5);
  const [checkStatus, setCheckStatus] = useState({
    mfa: true,
    adduser: true,
    statusPolicy: true,
    turnOnpolicy: false,
    windows: true,
    linux: false,
    password: false,
    ssh: false,
  });
  const [selectedCheck, setSelectedCheck] = useState(false);

  const handleChangeStatus = (e, val) => {
    // setCheckStatus((prevArray) => {
    //   const existingObjectIndex = prevArray.findIndex((item) => item.value === val);
    //   // setSelectedCheck(e);
    //   if (existingObjectIndex !== -1) {
    //     // If value is already present, update the status
    //     const newArray = [...prevArray];
    //     newArray[existingObjectIndex] = { value: val, status: e };
    //     return newArray;
    //   } else {
    //     // If value is not present, add a new object
    //     return [...prevArray];
    //   }
    // });
    setCheckStatus((prevStatus) => ({
      ...prevStatus,
      [val]: e,
    }));
    if (e) {
      setSelectedCheck(e);

      setPercent((prevPercent) => {
        const newPercent = prevPercent + 12.5;
        if (newPercent > 100) {
          return 100;
        }
        return newPercent;
      });

      // const newObject = { value: val, staus: e };
      // setCheckStatus((prevArray) => {
      //   const newArray = Array.isArray(prevArray) ? [...prevArray, newObject] : [newObject];
      //   return newArray;
      // });
    } else {
      if (val === "mfa" || "window" || "linux" || "addUser") {
        setSelectedCheck(e);

        setPercent((prevPercent) => {
          const newPercent = prevPercent - 12.5;
          if (newPercent < 0) {
            return 0;
          }
          console.log({ newPercent });
          return newPercent;
        });
      }
    }
  };

  localStorage.setItem("onboardLocalAccount", percent);
  return (
    <>
      <div className="onboarding-container">
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title"> Let’s setup privileged access for local accounts</h2>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "2rem" }}>
              <Col span={8}>
                <Card
                  className="card-container card-border-config"
                  style={{ cursor: "pointer" }}
                  actions={[<Progress percent={percent} showInfo={false} />]}
                >
                  <h3 className="card-header-h3">
                    Privileged access for local accounts
                    <br />
                    {percent}% complete <br />
                  </h3>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Configure Tenant</h3>{" "}
                <Row style={{ margin: "1rem 0" }}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.mfa}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "mfa");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/onboard/setup/tenantConfig?type=pamLocal`}>
                      Configure MFA{" "}
                    </Link>{" "}
                  </Col>
                </Row>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.addUser}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "addUser");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/pam/endpointsUsers/add?type=pamLocalAccount`}>
                      Add user and setup wallets{" "}
                    </Link>{" "}
                  </Col>
                </Row>
              </Col>
              {/* <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Setup your directory</h3>
                <Row style={style.row}>
                  <Col>
                    <a style={style.a} href="">
                      Configure AD{" "}
                    </a>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <a style={style.a} href="">
                      Configure Azure AD
                    </a>
                  </Col>
                </Row>
              </Col> */}
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Policies</h3>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.statusPolicy}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "statusPolicy");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/pam/policy?type=pamLocalAccount"}>
                      {" "}
                      View audited policies and approve or reject{" "}
                    </Link>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.turnOnPolicy}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "turnOnPolicy");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} href="">
                      Turn on policies
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Endpoints</h3>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.windows}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "windows");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/pam/endpoint/addEndpoint?type=windows"}>
                      Configure windows endpoints
                    </Link>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.linux}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "linux");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={"/pam/endpoint/addEndpoint?type=linux"}>
                      Configure linux endpoint
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Password rotation</h3>{" "}
                <Row style={{ margin: "1rem 0" }}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.password}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "password");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/pam/credentialRotation/addPassword?type=password`}>
                      Setup password rotation{" "}
                    </Link>{" "}
                  </Col>
                </Row>
                <Row style={style.row}>
                  <Col>
                    <Checkbox
                      className="checkbox-container"
                      checked={checkStatus?.ssh}
                      onChange={(e) => {
                        console.log(e);
                        handleChangeStatus(e.target.checked, "ssh");
                      }}
                    ></Checkbox>{" "}
                    <Link style={style.a} to={`/pam/credentialRotation/addSshKeys?type=sshKey`}>
                      Setup SSH Key Rotation{" "}
                    </Link>
                  </Col>
                </Row>
              </Col>
              {/* <Col span={8}>
                <h3 style={{ fontSize: "20px", marginBottom: "2rem" }}>Service accounts</h3>
                <Row style={style.row}>
                  <Col>
                    <a style={style.a} href="">
                      View discovered service accounts
                    </a>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col>
                    <a style={style.a} href="">
                      Setup service account policies{" "}
                    </a>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};
const style = {
  a: {
    fontSize: "18px",
  },
  row: {
    marginBottom: "1rem",
  },
};
export default PamLocalAccount;
