import { Row, Col, List, Typography, Button } from "antd";
import { Link } from "react-router-dom";
const { Title } = Typography;


const AddDirectory = () => {
  const data = [
    {
      title: "Install on Windows Server 2012 or later",
      content:
        "You need access to a Windows server to install the AuthNull Active Directory agent. You don't need to install the agent on the domain controller itself.",
    },
    {
      title: "Must be a member of your Active Directory domain",
      content:
        "The agent's host server must be a member of the same Windows domain as your Active Directory users.                                                                           ",
    },
    {
      title: "Consider the agent a part of your IT infrastructure",
      content:
        "The Windows server where the agent resides must be on at all times. In other words, don't install it on your laptop. The agent host server must have a continuous connection to the internet so it can communicate with AuthNull.",
    },
    {
      title: "Run this setup wizard from the host server",
      content:
        "We recommend running this setup wizard in a web browser on the Windows server where you want to install the agent. Otherwise, you will need to transfer the agent installer to the agent host server, then run the installer.",
    },
  ];
  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Integrate Active Directory</h2>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ alignItems: "center", marginTop: "2rem" }}
        >
          <Col>
            <h4 className="sub-title" style={{ margin: 0 }}>
              Set Up Active Directory
            </h4>
          </Col>
          <Col>
            <a>Read more from help doc</a>
          </Col>
        </Row>
        <Row>
          <h3 style={{ fontWeight: 600, marginTop: "2rem" }}>
            Install AuthNull's lightweight agent to integrate with Active
            Directory
          </h3>
        </Row>
        <Row>
          <p>Agent Architecture</p>
        </Row>
        <Row>
          <p style={{ marginTop: 0 }}>Installation Requirements</p>
        </Row>
        <Row>
          <List
            style={{ width: "70%" }}
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <span
                  className="bullet"
                  style={{
                    marginTop: "-1.6rem",
                    fontSize: "24px",
                    marginRight: "16px",
                  }}
                >
                  &#8226;
                </span>
                <List.Item.Meta
                  title={<Title level={5}>{item.title}</Title>}
                  description={item.content}
                />
              </List.Item>
            )}
          />
        </Row>
        <Row justify="space-between" style={{ width: "70%" }}>
          <Col>
            {/* <h2 className="title">Integrate Active Directory</h2> */}
          </Col>
          <Col>
          <Link to={'step'}>
          <Button
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              type="primary"
            >
              Add Directory
            </Button>
          </Link>
           
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AddDirectory;
