import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../../constants";

import { logOutUser } from "../../../../common";
import axios from "axios";
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Input,
  Row,
  Space,
  Tabs,
  Tooltip,
  message,
  Typography,
} from "antd";
import { Image } from "antd";

import onBoardImg from "../../../../assets/onboard-endpoint.svg";

import SCRIPTRUN from "../../../../assets/LinuxOnboard/image1.png";
import LOGS from "../../../../assets/LinuxOnboard/image2.png";
import LOCALAUTH from "../../../../assets/LinuxOnboard/image3.png";

import AGENTSCRIPT from "../../../../assets/LinuxOnboard/AgentConfig/Image1.png";
import AGENTINSTALL from "../../../../assets/LinuxOnboard/AgentConfig/Image2.png";
import AGENTVALID from "../../../../assets/LinuxOnboard/AgentConfig/Image3.png";

export const OnboardLinux = () => {
  const [notification, setNotification] = message.useMessage();
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {/* {isOnBoarding && startTour()} */}

        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Add Linux</h2>
            </Col>
          </Row>
        </Col>
        <Row className="tab-container" style={{ width: "100%" }}>
          <Col span={24}>
            <Tabs
              activeKey={currentTab}
              items={[
                {
                  key: 1,
                  label: "Agent Configuration",
                  children: (
                    <AgentConfiguration
                      notification={notification}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  ),
                },
                {
                  key: 2,
                  label: "Google PAM Config",
                  children: (
                    <GooglePAMConfig
                      notification={notification}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  ),
                },
              ]}
              onChange={(key) => {
                setCurrentTab(key);
              }}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export const AgentConfiguration = ({ notification, setCurrentTab, currentTab }) => {
  const [agentToken, setAgentToken] = useState("");
  const [orgId, setOrgId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [userId, setUserId] = useState("");
  const [uuid, setUuid] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const Local_User_ID = localStorage.getItem("userId");
    const Local_Org_ID = localStorage.getItem("OrgId");
    const Local_Domain_ID = localStorage.getItem("DomainId");

    // Update state with retrieved values
    if (Local_User_ID) setUserId(Local_User_ID);
    if (Local_Org_ID) setOrgId(Local_Org_ID);
    if (Local_Domain_ID) setTenantId(Local_Domain_ID);
    fetchAgentKey();
    generateDynamicUuid();
  }, []);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  const generateEnvFileContent = () => {
    const content = `KEY=LINUX02
MACHINE_KEY=LINUX02
AGENT_TOKEN=${agentToken}
ORG_ID=${orgId}
TENANT_ID=${tenantId}
USER_ID=${userId}
AWS_ACCESS_KEY_ID=AKIATQW4CGR3AR5WPS3H
AWS_SECRET_ACCESS_KEY=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
AWS_REGION=us-west-1
BUCKET_NAME=guacwest
RECORDING_DIR=/anchor_dvr/
TEXT_RECORDING_DIR=/text_recordings/
FILE_NAME=test.guac
BUCKET_NAME_GCS=gto-did-app-dev
STORAGE_AWS_FLAG=true
INSTALL_SERVICES=false
UUID=${uuid}`;
    return content;
  };

  const generateDynamicUuid = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    // Define the format for each section
    const sections = [8, 4, 4, 4, 12];

    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      result += "-";
    }

    // Remove the trailing hyphen
    result = result.slice(0, -1);

    return setUuid(result);
  };

  const downloadEnvFile = () => {
    const content = generateEnvFileContent();
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "app.env";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const fetchAgentKey = () => {
    let keyData = {
      domainId: DOMAIN_ID(),
      expired: false,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setAgentToken(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };

  const generateSudoCommand = () => {
    const content = generateEnvFileContent().replace(/\n/g, "\\n").replace(/"/g, '\\"');
    return `echo -e "${content}"`;
  };

  const items = [
    {
      key: "1",
      label: "Why is this needed?",
      children: (
        <div
          style={{
            paddingLeft: 24,
          }}
        >
          <p>
            Agents communicate to authnull and help local privileged users to be managed through
            AuthNull’s Privileged Access Manager. How does it work?
          </p>
          <img src={onBoardImg} alt="img" width={700} height={700} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Agent Download",
      children: (
        <div
          style={{
            paddingLeft: 24,
          }}
        >
          <p>Step1 → Download the script using the following command:</p>
          <Text copyable>
            sudo wget
            https://github.com/authnull0/windows-endpoint/raw/linux-agent/agent/linux-agent/install_agent.sh
            -O install_agent.sh
          </Text>
          <br />
          <br />
          <Image src={AGENTSCRIPT} />

          <p>Step 2 → Make the script Executable using the following command: </p>
          <Text copyable>sudo chmod +x install_agent.sh</Text>
          <p>Step 3 → Run the script and install the Agent using the following command:</p>
          <Text copyable>./install_agent.sh</Text>
          <br />
          <br />
          <Image src={AGENTINSTALL} />

          <p>Step 4 → Download the app.env file, by clicking on the link below</p>

          <a type="primary" onClick={downloadEnvFile}>
            Download the app.env file
          </a>
          <p>
            Step 5 → Copy the content from the app.env file , paste it in your VM and press ctrl+D
            to save the file
          </p>
          <Image src={AGENTVALID} />
          <p>
            After performing the above steps, check the logs. If you see the Token is valid, the
            Agent configuration is successful, and you are ready to proceed.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <Row className="content-conatiner">
        <Col span={24} className="endpoint-collapse-container">
          <Collapse defaultActiveKey={["1"]} bordered={false} items={items} />
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: "1rem" }}>
        <Col>
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button type="primary" htmlType="submit" onClick={() => setCurrentTab(currentTab + 1)}>
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

const { Text } = Typography;

export const GooglePAMConfig = ({ notification, currentTab }) => {
  const [code, setCode] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchAgentKey();
  }, []);

  const fetchAgentKey = () => {
    let keyData = {
      domainId: DOMAIN_ID(),
      expired: false,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setCode(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };

  const items = [
    {
      key: "1",
      label: <strong>Steps For Configuring Google PAM Authenticator (SSH Login)</strong>,
      children: (
        <div
          style={{
            padding: "1rem",
          }}
        >
          <p>
            Step 1 → Log in to any virtual machine and download the script using the following
            command:
          </p>
          <p>
            <Text copyable>
              sudo wget
              https://github.com/authnull0/windows-endpoint/raw/google-authenticator-pam/pam/google-authenticator-pam.sh
            </Text>
          </p>
          <p>Step 2 → Make the Script Executable using the following command: </p>
          <Text copyable>chmod +x google-authenticator-pam.sh</Text>
          <p>Step 3 → Run the script using the following command:</p>
          <Text copyable>./google-authenticator-pam.sh</Text>
          <br />
          <br />
          <Image width={1000} src={SCRIPTRUN} />
        </div>
      ),
    },

    {
      key: "2",
      label: <strong>Now login to the VM using SSH</strong>,
      children: (
        <div
          style={{
            padding: "1rem",
          }}
        >
          <p>Step 1 → Open the Ternminal in your local machine , log in to the VM using SSH .</p>
          <p>The DID Authentication will initiate a DID Assertion DoAuthentication Request.</p>

          <Image width={800} src={LOGS} />
          <br />
          <br />
          <Image width={800} src={LOCALAUTH} />
          <p>
            After performing the above steps, check the logs. If you see the expected output, the
            configuration is successful, and you are ready to proceed.
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]} bordered={false} items={items} />
        </Col>
      </Row>
    </>
  );
};
