import { Col, Row, Select, Input, message, Tag } from "antd";
import { AUTH_TOKEN, REACT_APP_API_TRANS_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";
import Typography from "antd/es/typography/Typography";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const { Option } = Select;
const {Text} =Typography;

const Merkle = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [sortModel, setSortModel] = useState();
  const [paginationParams, setPaginationParams] = useState({
    current: 0,
    pageSize: 10,
  });

  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchLog();
  }, [filter, debouncedValue, paginationParams,currentPageModal]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const fetchLog = () => {
    let currentOffset = 0;
    let pageDetails = {
      domainId: DOMAIN_ID().toString,
      orgId: ORG_ID(),
      filters: {
        date: "",
        ldapUser: "",
        hour: "",
      },
      limit: currentPageModal?.pageSize,
      offset: currentPageModal?.page + 1,

      // limit: paginationParams?.pageSize,
      // offset: paginationParams.current
      //   ? (paginationParams.current - 1) * paginationParams?.pageSize
      //   : currentOffset,
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TRANS_URL}/GetMerkleHashTransactions`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data = [];
        if (res.data.Data) {
          Data = res.data.Data.map((data, i) => ({
            id: i,
            merkleId: data.ID,
            cid: data.CID,
            merkleStatus: data.MerkleStatus,
            merkleHash: data.MerkleHash,
            directoryUser: data.LdapUser,
            createdAt: moment(data.CreatedAt,'MM-DD-YYYY H[h]').format('DD-MM-YYYY H[h]'),
            
          }));
        }
        console.log(Data);
        setCredentialData(Data);
        setTotalCount(res?.data?.count);
        // if (paginationParams.current && paginationParams.current > 0) {
        //   currentOffset = pageDetails.offset;
        //   paginationParams.pageSize = pageDetails.limit;
        // }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const toCamelCase = (str) => {
    const s =
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
        .join('');
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const columns = [
    {
      key: "directoryUser",
      headerName: "Directory User",
      field: "directoryUser",
      flex: 1,
      renderCell: (params) => (
        <span style={{whiteSpace: "pre-wrap", wordBreak: "break-word"  }}>
         {params.value}</span>
     )
    },
    {
      key: "merkleHash",
      headerName: "Merkle Hash",
      field: "merkleHash",
      width:310,
      renderCell: (params) => (
        <span style={{whiteSpace: "pre-wrap", wordBreak: "break-word"  }}>
         {params.value}</span>
     )
    },
    {
      key: "merkleStatus",
      headerName: "Merkle Status",
      field: "merkleStatus",
      flex: 1,
      renderCell: (params) =>
        <Text type="success"> {toCamelCase(params.value)}
      </Text>
    },
    {
      key: "cid",
      headerName: "CID",
      field: "cid",
      flex: 1,
    },
    {
      key: "createdAt",
      headerName: "Created Date",
      field: "createdAt",
      flex: 1,
    },
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 0, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            rows={credentialData}
            columns={columns}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPageModal.page}
            pageSize={currentPageModal.pageSize}
            onPaginationModelChange={(params) => {
              setCurrentPageModal({
                page: params.page,
                pageSize: 10,
              });
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default Merkle;