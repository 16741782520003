import { Col, Row, Select, message, Space, Tag, Modal } from "antd";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../constants";
import { logOutUser } from "../../common";
import { useState, useEffect } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import moment from "moment";

import InfiniteScrollDropdown from "../../common/InfiniteScrollDropdown";
import { useDebounce } from "../../common/debounce";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;

const Logs = () => {
  const endpoints = [];
  const [sortModel, setSortModel] = useState([]);
  const [modal, setModal] = useState(false);
  const [log, setLog] = useState({});
  const [notification, setNotification] = message.useMessage();
  const [domainData, setDomainData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([
    { filterParameter: "LogType", filterValue: "AD" },
  ]);
  const [filteredEndpoints, setFilteredEndpoints] = useState(endpoints);
  const [authLogs, setAuthLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 100,
    page: 0,
  });
  const [logType, setLogType] = useState("AD");
  const [ouData, setOuData] = useState([]);

  const [instancePage, setInstancePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreWallets, setHasMoreWallets] = useState(true);
  const [hasMoreInstances, setHasMoreInstances] = useState(true);
  const [walletOptions, setWalletOptions] = useState([]);
  const [endpointOptions, setEndpointOptions] = useState([]);

  const [search, setSearch] = useState("");

  const [instanceSearch, setInstanceSearch] = useState("");
  const debouncedInstanceSearch = useDebounce(instanceSearch, 500);

  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchAuthLogs();
  }, [appliedFilters, sortModel]);
  useEffect(() => {
    listDomain();
  }, []);

  useEffect(() => {
    fetchInstance();
  }, [debouncedInstanceSearch]);

  useEffect(() => {
    fetchWallet();
  }, [debouncedValue]);

  const fetchAuthLogs = (page) => {
    let payload = {
      filters: appliedFilters ? appliedFilters : [],
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      page: page ? page : 1,
      limit: currentPageModal.pageSize,
      sort: {
        field: sortModel ? sortModel[0]?.field : "",
        order: sortModel ? sortModel[0]?.sort : "",
      },
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/AuthenticationLog`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        const resData = data?.logs?.map((res) => ({
          id: res.ID,
          ...res,
        }));
        setAuthLogs(resData);
        setTotalCount(data.total_pages * 100);
        setCurrentPageModal({
          pageSize: 100,
          page: data.page - 1,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };

  const handleApplyFilter = (filterParameter, filterValue) => {
    // Check if a filter with the same parameter already exists
    const existingFilterIndex = appliedFilters.findIndex(
      (filter) => filter.filterParameter === filterParameter
    );

    if (existingFilterIndex !== -1) {
      // Update the existing filter's value
      setAppliedFilters((prevFilters) => {
        const updatedFilters = [...prevFilters];
        updatedFilters[existingFilterIndex] = {
          ...updatedFilters[existingFilterIndex],
          filterValue: filterValue,
        };
        return updatedFilters;
      });
    } else {
      // Add a new filter
      const newFilter = { filterParameter, filterValue };
      setAppliedFilters((prevFilters) => [...prevFilters, newFilter]);
    }
  };

  const handleRemoveFilter = (filterParameter, filterValue) => {
    setLogType("");
    setAppliedFilters((prevFilters) =>
      prevFilters.filter(
        (filter) => filter.filterParameter !== filterParameter || filter.filterValue !== filterValue
      )
    );
  };

  useEffect(() => {
    // Filter the endpoints based on applied filters
    const filteredData = endpoints.filter((endpoint) =>
      appliedFilters.every(({ filterParameter, filterValue }) => {
        // Implement your custom filtering logic based on filterParameter and filterValue
        // For simplicity, I'm using includes() for string matching
        return String(endpoint[filterParameter]).includes(filterValue);
      })
    );

    // Update the state with the filtered data
    setFilteredEndpoints(filteredData);
  }, [appliedFilters, endpoints]);

  const getCurrentOption = (filterParameter) => {
    const filter = appliedFilters.find((filter) => filter.filterParameter === filterParameter);
    return filter ? filter.filterValue : undefined;
  };

  const fetchWallet = () => {
    if (!hasMoreWallets) return;

    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: paginationParams?.current,
      pageSize: paginationParams?.pageSize,
      filter: debouncedValue,
    };

    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        const wallets = res?.data?.users || [];
        const mappedWallets = wallets.map((wallet) => ({
          id: wallet.walletId,
          label: wallet.emailAddress,
          value: wallet.emailAddress,
        }));

        setWalletOptions((prevData) => {
          const newData = mappedWallets.filter(
            ({ value: newValue }) =>
              !prevData.some(({ value: existingValue }) => existingValue === newValue)
          );
          return [...prevData, ...newData];
        });

        setTotalCount(res?.data?.count || 0);

        if (wallets.length < pageDetails.pageSize) {
          setHasMoreWallets(false);
        }

        setPaginationParams((prev) => ({
          ...prev,
          current: prev.current + 1,
        }));
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch wallets",
          });
        }
      });
  };

  const fetchInstance = () => {
    if (!hasMoreInstances) return;

    setLoading(true);

    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: instancePage,
      pageSize: 10,
      search: debouncedInstanceSearch,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const Instances = res?.data?.instances || [];

        if (Instances.length > 0) {
          const mappedInstances = Instances.map((instance) => ({
            label: (
              <>
                {instance.hostName} <b>({instance.publicIp})</b>
              </>
            ),
            value: instance.hostName,
            id: instance.instanceId,
          }));

          setEndpointOptions((prevData) => {
            const newData = mappedInstances.filter(
              (newInstance) =>
                !prevData.some((existingInstance) => existingInstance.value === newInstance.value)
            );
            return [...prevData, ...newData];
          });

          if (Instances.length < pageDetails.pageSize) {
            setHasMoreInstances(false);
          }

          setInstancePage((prevPage) => prevPage + 1);
        } else {
          setHasMoreInstances(false);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then(({ data }) => {
        const inputArray = data?.data?.adDomains === null ? [] : data?.data?.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain, ou: item.ous };
        });

        setDomainData(outputArray);
      });
  };

  const downloadJsonAsFile = (data) => {
    // Convert JSON data to string
    const jsonString = JSON.stringify(data, null, 2);

    var fileName = "userData.json";
    var fileContent = data;
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    // Create a Blob with the JSON string
    // const blob = new Blob([jsonString], { type: "application/json" });

    // Create a download link
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);

    // Set the filename for the download
    // link.download = "userData.json";

    // Append the link to the document
    // document.body.appendChild(link);

    // Trigger the download
    // link.click();

    // Remove the link from the document
    // document.body.removeChild(link);
  };

  const columns = [
    {
      headerName: "Wallet User",
      dataIndex: "AdUser",
      field: "AdUser",
      width: 210,
      sortable: true,
    },
    {
      headerName: "Username",
      field: "EndpointUser",
      width: 120,
      sortable: true,
      renderCell: (val) => {
        const tagsArray =
          val?.row?.LogType.toLowerCase() === "ad"
            ? val?.row?.AdUser && val?.row?.AdUser.split(",")
            : val?.row?.EndpointUser && val?.row?.EndpointUser.split(",");

        return (
          <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
            {tagsArray &&
              tagsArray.map((tag, index) => (
                <Space size={[0, "small"]} wrap>
                  <Tag key={index} onClick={() => handleApplyFilter("EndpointUser", tag)}>
                    {tag.trim()}
                  </Tag>
                </Space>
              ))}
          </div>
        );
      },
    },
    ...(logType === "ServiceAccount"
      ? [
          {
            headerName: "Source Hostname",
            field: "SourceEndpoint",
            width: 50,
            minWidth: 200,
            maxWidth: 400,
            sortable: true,
            renderCell: (val) => {
              const tagsArray = val?.row?.SourceEndpoint?.split(","); //NOTE : SOURCE HOSTNAME IS NOT AVAILABLE IN THE RESPONSE
              return (
                <div style={{ whiteSpace: "normal" }}>
                  {tagsArray?.map((tag, index) => (
                    <Tag
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => handleApplyFilter("SourceEndpoint", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))}
                </div>
              );
            },
          },
          {
            headerName: "Source IP",
            field: "SourceEndpointIP",
            width: 50,
            minWidth: 150,
            maxWidth: 500,
            sortable: true,
            renderCell: (val) => {
              const tagsArray = val?.row?.SourceEndpointIP?.split(",");

              return (
                <div style={{ whiteSpace: "normal" }}>
                  {tagsArray?.map((tag, index) => (
                    <Tag
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => handleApplyFilter("SourceEndpointIP", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))}
                </div>
              );
            },
          },
        ]
      : []),
    {
      headerName: "Destination",
      field: "DestinationEndpoint",
      width: 180,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.DestinationEndpoint && val.row.DestinationEndpoint.split(",");

        return (
          <div>
            {tagsArray &&
              tagsArray.map((tag, index) => (
                <Tag
                  style={{ cursor: "pointer", whiteSpace: "break-spaces" }}
                  key={index}
                  onClick={() => handleApplyFilter("DestinationEndpoint", tag)}
                >
                  {tag.trim()}
                </Tag>
              ))}
          </div>
        );
      },
    },

    {
      headerName: "Destination IP",
      field: "DestinationEndpointIp",
      width: 180,
      sortable: true,
      renderCell: (val) => {
        const tagsArray =
          val?.row?.DestinationEndpointIp && val.row.DestinationEndpointIp.split(",");

        return (
          <div>
            {tagsArray &&
              tagsArray.map((tag, index) => (
                <Tag
                  style={{ cursor: "pointer", whiteSpace: "break-spaces" }}
                  key={index}
                  onClick={() => handleApplyFilter("DestinationEndpointIp", tag)}
                >
                  {tag.trim()}
                </Tag>
              ))}
          </div>
        );
      },
    },

    {
      headerName: "Log Type",
      field: "LogType",
      width: 150,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>
            {val.row.LogType.toLowerCase() === "ad"
              ? "Active Directory"
              : val.row.LogType.toLowerCase() === "local"
              ? "Local User"
              : "Service Account"}
          </span>
        </>
      ),
    },
    {
      headerName: "Protocol",
      dataIndex: "Protocol",
      field: "Protocol",
      width: 100,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag
            style={{ cursor: "pointer" }}
            key={val.row.protocol}
            onClick={() => handleApplyFilter("Protocol", val.row.Protocol)}
          >
            {val.row.Protocol}
          </Tag>
        </>
      ),
    },

    // {
    //   dataIndex: "component",
    //   headerName: "Component",
    //   field: "component",
    //   width: 210,
    //   sortable: true,
    // },
    {
      dataIndex: "OS",
      headerName: "OS",
      field: "Os",
      width: 150,
      sortable: true,
    },

    {
      headerName: "Ou",
      field: "AdOu",
      width: 150,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag
            style={{ cursor: "pointer" }}
            key={val.row.adOu}
            onClick={() => handleApplyFilter("AdOu", val.row.AdOu)}
          >
            {val.row.AdOu}
          </Tag>
        </>
      ),
    },
    {
      headerName: "Domain",
      field: "AdDomain",
      width: 160,
      sortable: true,
      renderCell: (val) => (
        <>
          <Tag
            style={{ cursor: "pointer" }}
            key={val.row.adDomain}
            onClick={() => handleApplyFilter("AdDomain", val.row.AdDomain)}
          >
            {val.row.AdDomain}
          </Tag>
        </>
      ),
    },

    // {
    //   headerName: "Risk",
    //   field: "risk",
    //   width: 120,
    //   sortable: true,
    //   renderCell: (val) => (
    //     <>
    //       <span>{val.row.id % 5 ? "Low risk" : val.row.id % 2 ? "Medium risk" : "High risk"}</span>
    //     </>
    //   ),
    // },

    {
      headerName: "Created At",
      field: "Timestamp",
      width: 180,
      sortable: true,
      renderCell: (val) => {
        const formatString = "DD-MM-YYYY HH:mm [PST]";
        const formattedDate = moment.unix(val.row.Timestamp).format(formatString);
        return <span>{formattedDate}</span>;
      },
    },
    {
      headerName: "View / Download",
      field: "logs",

      width: 210,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>
            <a
              onClick={() => {
                setModal(true);
                setLog(val?.row);
              }}
            >
              View
            </a>{" "}
            /{" "}
            <a
              onClick={() => {
                setLog(val?.row);
                downloadJsonAsFile(val?.row);
              }}
            >
              Download
            </a>
          </span>
        </>
      ),
    },
  ];
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Authentication Logs</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                <Col span={4}>
                  <p className="search-label">Log Type</p>
                  <Select
                    style={{ width: 200 }}
                    placeholder="Search Access Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => {
                      handleApplyFilter("LogType", e);
                      setLogType(e);
                    }}
                    value={getCurrentOption("LogType")}
                    options={[
                      { value: "local", label: "Local User" },
                      { value: "AD", label: "Active Directory" },
                      { value: "ServiceAccount", label: "Service Account" },
                    ]}
                  />
                </Col>
                <Col span={4}>
                  <p className="search-label">Wallet Users</p>

                  <InfiniteScrollDropdown
                    fetchData={() => fetchWallet(debouncedValue)}
                    loading={loading}
                    options={walletOptions}
                    placeholder="Search username"
                    hasMore={hasMoreWallets}
                    onSelect={(e) => handleApplyFilter("AdUser", e)}
                    onSearch={(value) => {
                      const inputValue = value;
                      setSearch(inputValue);

                      setPaginationParams({ current: 1, pageSize: 10 });
                      setWalletOptions([]);
                      setHasMoreWallets(true);
                    }}
                    value={getCurrentOption("AdUser")}
                  />
                </Col>
                {logType === "AD" && (
                  <>
                    <Col span={4}>
                      <p className="search-label">Domain</p>
                      <Select
                        showSearch
                        placeholder="Search Domain"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e, val) => {
                          handleApplyFilter("AdDomain", val?.children);
                          const selectedDomain = domainData.find((domain) => domain.id === e);
                          if (selectedDomain) {
                            setOuData(
                              selectedDomain?.ou?.map((data) => {
                                let ouVal = data.OuName.split(",")[0];
                                let nameOu = ouVal.substring(3);
                                return { ...data, ouNames: nameOu };
                              })
                            );
                          } else {
                            setOuData([]);
                          }
                        }}
                      >
                        {domainData?.map((dom) => (
                          <Option key={dom.id} value={dom.id}>
                            {dom.value}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Ou</p>
                      <Select
                        showSearch
                        placeholder="Search OU "
                        optionFilterProp="children"
                        onChange={(e, val) => handleApplyFilter("AdOu", val.children)}
                      >
                        {ouData?.map((ou) => (
                          <Option key={ou.Id} value={ou.Id}>
                            {ou.ouNames}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                )}
                <Col span={4}>
                  <p className="search-label">Endpoint</p>

                  <InfiniteScrollDropdown
                    fetchData={() => fetchInstance(instanceSearch)}
                    loading={loading}
                    options={endpointOptions}
                    placeholder="Search Endpoint"
                    hasMore={hasMoreInstances}
                    onSelect={(e) => handleApplyFilter("DestinationEndpoint", e)}
                    value={getCurrentOption("DestinationEndpoint")}
                    onSearch={(value) => {
                      const inputValue = value;
                      setInstanceSearch(inputValue);
                      setInstancePage(1);
                      setEndpointOptions([]);
                      setHasMoreInstances(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {appliedFilters.length > 0 && (
                <div className="filter-tags">
                  <h4
                    style={{
                      marginTop: "0rem",
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                    }}
                  >
                    Applied Filters:
                  </h4>
                  {appliedFilters.map(({ filterParameter, filterValue }) => (
                    <>
                      <Tag
                        key={`${filterParameter}:${filterValue}`}
                        closable={filterParameter === "LogType" ? false : true}
                        onClose={() => {
                          if (filterParameter !== "LogType") {
                            handleRemoveFilter(filterParameter, filterValue);
                          }
                        }}
                      >
                        {`${filterParameter}: ${filterValue}`}
                      </Tag>
                    </>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                rows={authLogs}
                columns={columns}
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                getRowId={(row) => row.id.toString()}
                paginationMode="server"
                page={currentPageModal.current}
                pageSize={currentPageModal.pageSize}
                pageSizeOptions={[10]}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 100, page: 0 },
                  },
                }}
                paginationModel={currentPageModal}
                onPaginationModelChange={(params) => {
                  fetchAuthLogs(params.page + 1);
                }}
                filterMode="server"
                onFilterModelChange={(e) => handleApplyFilter(e.items[0].field, e.items[0].value)}
                disableRowSelectionOnClick
                style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                rowThreshold={0}
                pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                sx={{
                  "& .MuiDataGrid-detailPanel": {
                    overflow: "visible",
                  },
                }}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {modal && (
        <Modal
          open={modal}
          title={
            <span style={{ fontSize: 18 }}>
              Authentication Log <span style={{ color: "#1677FF" }}>{` ${log?.AdUser}`}</span>
            </span>
          }
          onCancel={() => setModal(false)}
          footer={null}
          width={900}
        >
          <Row>
            <Col span={24}>
              <pre>{JSON.stringify(log, null, 2)}</pre>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default Logs;
