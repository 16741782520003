import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { REACT_APP_API, REACT_APP_API_TENANTS_URL, AUTH_TOKEN } from "../../../constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logOutUser } from "../../../common";

const ResetPassword = ({ loginDetails, token, setIsResetPassword }) => {
  const [notification, setNotification] = message.useMessage();
  const [IsLoading, setIsLoading] = useState(false);
  const [loginAgain, setLoginAgain] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    setIsLoading(true);
    let domainUrl = localStorage.getItem("URL");
    // let domainUrl = "kloudlearn.kloudone.prod.authnull.com";

    axios
      .post(
        `${REACT_APP_API}/authnz/getUserDetails`,
        {},
        {
          headers: {
            withCredentials: true,
            "X-Authorization": `${token}&DOMAIN&${domainUrl}`,
            "X-RequestUrl": localStorage.getItem("URL"),
          },
        }
      )
      .then(({ data }) => {
        if (data?.Validation === true && data?.Status === "Success") {
          resetPassword(values, data);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };

  const resetPassword = (values, data) => {
    console.log(values, data);
    const loginData = {
      ...values,
      email: loginDetails.username,
      oldpassword: loginDetails.password,
      tenantId: data.DomainID,
      orgId: data.OrgID,
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/resetPassword`, loginData, {
        headers: {
          withCredentials: true,
          "X-Authorization": `${token}&DOMAIN&${localStorage.getItem("URL")}&ORGID&${data.OrgID}`,
        },
      })
      .then((res) => {
        console.log(res);
        // navigate("/");
        setIsLoading(false);
        setLoginAgain(true);
        // setIsResetPassword(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setLoginAgain(false);
        notification.open({
          type: "error",
          content: "Unable to login, Please try again later",
        });
      });
  };

  return (
    <>
      {setNotification}

      <div className="login-container">
        <div className="login-box">
          <h2 className="title">
            {" "}
            {loginAgain ? "Password Reset Successfully!" : "Reset Password"}
          </h2>
          {loginAgain ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  setIsResetPassword(false);
                }}
                // style={{
                //   display: "flex",
                //   margin: "auto",
                //   marginTop: "2rem",
                //   marginBottom: "1rem",
                // }}
              >
                Login Again with New Password
              </Button>
            </>
          ) : (
            <>
              <Form
                form={form}
                name="register"
                onFinish={handleFinish}
                scrollToFirstError
                className="login-form"
              >
                <Form.Item
                  name="newpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="New Password" size="large" />
                </Form.Item>

                <Form.Item
                  name="confirmpassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newpassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The new password that you entered do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" size="large" />
                </Form.Item>
                <div className="login-form-button">
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={IsLoading}
                    >
                      Reset
                    </Button>
                    <br />
                  </Form.Item>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
