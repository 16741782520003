import { Col, Input, Row, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../common";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../constants";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDebounce } from "../../common/debounce";

const UserDetailsGroups = (userId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [userData, setuserData] = useState([]);
  const [appData, setappData] = useState([]);
  const [groupData, setgroupData] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchUserDetails();
  }, [paginationParams, debouncedValue, filterBy, filteredValue]);

  const columns = [{ title: "Groups", dataIndex: "groupname", key: "groupname", fixed: "left" }];

  const fetchUserDetails = async () => {
    let pageDetails = {
      id: userId.UserId,
      DomainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    try {
      let response = await axios.post(`${REACT_APP_PAM_API}/users/${userId.UserId}`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });
      let data = response.data;
      let AppData = data.applications.map((app, i) => ({
        id: i,
        appid: app.id,
        appname: app.appName,
        type: app.typeOfRegn,
      }));

      let GroupData = data.groups.map((grp, i) => ({
        id: i,
        groupid: grp.id,
        groupname: grp.groupName,
      }));
      setuserData(data);
      setappData(AppData);
      setgroupData(GroupData);
    } catch (err) {
      if (err?.response?.data?.message === "Invalid credentials" || err?.response?.status === 401) {
        logOutUser();
      } else {
      }
    }
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={16}>
              <Row className="search-box-container">
                <Col span={12}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={14}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={groupData}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
            <Col span={1}></Col>
            <Col span={6}>
              <div class="group-instruction">
                <h6 className="para-title">Groups</h6>
                <p className="body-text">
                  <Link to={`/directory/groups`}>
                    <span style={{ fontSize: "15px" }}>Groups </span>
                  </Link>
                  allow you to manage app assignments and user profile attributes more efficiently.
                </p>
                <h6 className="para-title">Converting Assignments</h6>
                <p className="body-text">
                  Application access and user profile attributes can be converted from being
                  individually-managed to group-managed. You can convert assignments from an app's
                  Group tab.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserDetailsGroups;
