import { Col, Row, Select, Table, Button, Input, message } from "antd";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  ORG_ID,
  REACT_APP_API_PAM_URL,
  DOMAIN_ID,
  REACT_APP_API,
} from "../../../constants";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const ActiveDirectory = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  // const PAGESIZE = 10;
  const [filter, setFilter] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  const [notification, setNotification] = message.useMessage();
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();

  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });
  const [paginationParams, setPaginationParams] = useState({
    current: 0,
    pageSize: 10,
  });

  const [options, setOptions] = React.useState([]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  useEffect(() => {
    fetchLog();
  }, [filter, debouncedValue, currentPageModal]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    const filteredOptions = credentialData
      .filter((user) => user.directoryName.toLowerCase().includes(value.toLowerCase()))
      .map((user) => ({
        label: user.directoryName,
        value: user.directoryName,
      }));

    setOptions(filteredOptions);
  };

  const fetchLog = () => {
    let pageDetails = {
      tenantId: DOMAIN_ID(),
      // pageId: currentPage,
      // pageSize: PAGESIZE,
      pageNumber: currentPageModal.page + 1,
      pageSize: currentPageModal?.pageSize,
      filter: search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API}/ad/GetAllDomains`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        const ADData = res?.data?.domains?.map((ad, i) => ({
          id: i + 1,
          ...ad,
        }));

        setCredentialData(ADData ? ADData : []);
        setTotalCount(res?.data?.totalGroups);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    { field: "directoryName", headerName: "Directory Name", dataIndex: "directoryName", flex: 1 },
    {
      field: "IntegrationType",
      headerName: "Integration Type",
      dataIndex: "IntegrationType",
      flex: 1,
    },
    {
      field: "dc1",
      headerName: "DC1",
      dataIndex: "dc1",
      flex: 1,
    },
    {
      field: "dc2",
      headerName: "DC2",
      dataIndex: "dc2",
      flex: 1,
    },
    {
      field: "userCount",
      headerName: "User Count",
      dataIndex: "userCount",
      flex: 1,
    },
    {
      field: "groupCount",
      headerName: "Group Count",
      dataIndex: "groupCount",
      flex: 1,
    },
    {
      field: "adMfa",
      headerName: "AD MFA",
      dataIndex: "adMfa",
      flex: 1,
      renderCell: (val) => {
        return val?.row?.adMfa === 0 ? "Agentbased" : val?.row?.adMfa === 1 ? "Agentless" : "-";
      },
    },
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Active Directory</h2>
            </Col>
            <Col>
              <Link to={"/directory/activeDirectory/addDirectory/step"}>
                <Button type="primary">Add Directory</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    {/* <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    /> */}

                    <AutoComplete
                      placeholder="Search E-mail"
                      allowClear
                      onSearch={handleSearch}
                      options={options}
                      onChange={(value) => {
                        setSearch(value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 0, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            rows={credentialData}
            paginationMode="server"
            columns={columns}
            rowCount={totalCount}
            page={currentPageModal.page}
            pageSize={currentPageModal.pageSize}
            onPaginationModelChange={(params) => {
              setCurrentPageModal({
                page: params.page,
                pageSize: 10,
              });
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
          {/* <Table
            loading={isLoading}
            columns={columns}
            dataSource={credentialData}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              pageSize: PAGESIZE,
              total: totalCount,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
          /> */}
        </Col>
      </Row>
    </>
  );
};

export default ActiveDirectory;
