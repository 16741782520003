import React, { useState } from "react";
import { Col, Row, Input, Tag, Button, Select } from "antd";
import { Link } from "react-router-dom";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from '@ant-design/icons';


const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Search } = Input;
const { Option } = Select;

const EndpointPolicies = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const endpoints = Array.from({ length: 100 }, (_, i) => ({
    id: i + 1,
    policy: `Policy of ${i + 1}`,
    endpoint: `Endpoint ${i * 13}`,
    endpointGroup: [
      {
        endpointName: `Endpoint Group ${i % 10}`,
        id: i + 1,
      },
      {
        endpointName: `Endpoint Group ${(i % 10) + 1}`,
        id: i + 2,
      },
    ],
    users: [
      {
        userName: `User ${i % 10}`,
        id: i + 1,
      },
      {
        userName: `User ${(i % 10) + 1}`,
        id: i + 2,
      },
    ],
    groups: [
      {
        groupName: `Group ${i % 10}`,
        id: i + 1,
      },
      {
        groupName: `Group ${(i % 10) + 1}`,
        id: i + 2,
      },
    ],
    apps: i % 2 ? "Yes" : "No",
    status: i % 2 === 0 ? "Active" : "Inactive",
  }));

  const [filteredEndpoints, setFilteredEndpoints] = useState(
    endpoints.filter((endpoint) => {
      return (
        endpoint.endpoint.toLowerCase().includes(searchText.toLowerCase()) &&
        appliedFilters.every(({ filter, value }) =>
          endpoint.endpoint.toLowerCase().includes(value.toLowerCase())
        )
      );
    })
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const columns = [
    {
      headerName: "", // Empty header for the expand/collapse column
      field: "expand",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowExpand(params)}
        >
          {expandedRows.includes(params.id.toString()) ? "[-]" : "[+]"}
        </div>
      ),
    },
    {
      headerName: "Policy Name",
      field: "policy",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Endpoints",
      field: "endpoint",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Endpoint Groups",
      field: "endpointGroup",
      flex: 3,
      sortable: true,
      renderCell: (val) => (
        <>
          {val.row.endpointGroup.map((group) => (
            <Tag color="blue" key={group.id}>
              {group.endpointName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      headerName: "Endpoint Users",
      field: "users",
      flex: 1.5,
      sortable: true,
      renderCell: (val) => (
        <>
          {val.row.users.map((user) => (
            <Tag color="blue" key={user.id}>
              {user.userName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      headerName: "User Groups",
      field: "groups",
      flex: 1.5,
      sortable: true,
      renderCell: (val) => (
        <>
          {val.row.groups.map((group) => (
            <Tag color="blue" key={group.id}>
              {group.groupName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      headerName: "Can Install New Apps",
      field: "apps",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (value) => (
        <a>{value.row.status === "Active" ? "Deactivate" : "Activate"}</a>
      ),
    },
  ];

  const handleApplyFilter = (filter, value) => {
    if (value) {
      const newFilter = { filter, value };
      setAppliedFilters([...appliedFilters, newFilter]);

      // Update searchText immediately
      setSearchText(value);

      // Update filteredEndpoints based on searchText and appliedFilters
      setFilteredEndpoints((prevFilteredEndpoints) => {
        return prevFilteredEndpoints.filter((endpoint) => {
          const searchTextMatch = endpoint.endpoint
            .toLowerCase()
            .includes(value.toLowerCase()); // Use the current value

          const filtersMatch = appliedFilters.every(({ filter, value }) =>
            endpoint[filter].toLowerCase().includes(value.toLowerCase())
          );

          return searchTextMatch && filtersMatch;
        });
      });
    }
  };

  const handleRemoveFilter = (filter, value) => {
    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) =>
          filterItem.filter !== filter || filterItem.value !== value
      )
    );
  };

  const handleRowExpand = (params) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(params.id.toString())
        ? prevRows.filter((row) => row !== params.id.toString())
        : [...prevRows, params.id.toString()]
    );
  };

  const isRowExpandable = (params) => {
    // Example condition for expandable rows
    return params.row.id % 2 === 0;
  };

  const renderRowSubComponent = (params) => {
    <div style={{ padding: '10px', border: '1px solid #ccc' }}>
      {/* Your expanded content goes here */}
      <p>ID</p>
      {/* ... other expanded content */}
    </div>
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Windows Endpoint Policies</h2>
            </Col>
            <Col>
              <Link to={"/windows/createWindowPolicy"}>
                <Button type="primary">Add Endpoint Policy</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row
                style={{ marginBottom: "2rem", gap: "2rem" }}
                className="search-box-container"
              >
                <Col span={4}>
                  <p className="search-label">Policy</p>
                  <Input placeholder="Policy Name" value={"Policy of 1"} />
                </Col>
                <Col span={4}>
                  <p className="search-label">Endpoint Groups</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search Endpoint Group"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("groups", e)}
                    // Set the default value
                  >
                    <Option value="Group 1">Group 1</Option>
                    <Option value="Group 2">Group 2</Option>
                    <Option value="Group 3">Group 3</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>
                <Col span={4}>
                  <p className="search-label">Endpoint</p>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search Endpoint "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleApplyFilter("groups", e)}
                    // Set the default value
                  >
                    <Option value="Endpoint 1">Endpoint 1</Option>
                    <Option value="Endpoint 2">Endpoint 2</Option>
                    <Option value="Endpoint 3">Endpoint 3</Option>
                    {/* Add other options here */}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {appliedFilters.length > 0 && (
                <div className="filter-tags">
                  <h4
                    style={{
                      marginTop: "0rem",
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                    }}
                  >
                    Applied Filters:
                  </h4>
                  {appliedFilters.map(({ filter, value }) => (
                    <Tag
                      key={`${filter}:${value}`}
                      closable
                      onClose={() => handleRemoveFilter(filter, value)}
                    >
                      {`${filter}: ${value}`}
                    </Tag>
                  ))}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                        components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={filteredEndpoints}
                paginationMode='server'
                columns={columns}
                pageSize={10}
                checkboxSelection
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                isRowExpandable={isRowExpandable}
                getRowId={(row) => row.id.toString()}
                expandedRows={expandedRows}
                onRowExpand={handleRowExpand}
                renderRowSubComponent={(props) =>
                  renderRowSubComponent({ ...props, data: { id: props.id } })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointPolicies;
