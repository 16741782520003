import { useState, useEffect } from "react";
import { Col, Row, Input } from "antd";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

// import { useDebounce } from "../../../common/debounce";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const EndpointGroupDetailsAuth = () => {
  const [search, setSearch] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   const debouncedSearch = useDebounce(search, 500);

//   // pagination
//   const [paginationParams, setPaginationParams] = useState({
//     current: 1,
//     pageSize: 10,
//   });
//   const [totalCount, setTotalCount] = useState(1);
//   const [currentPage, setCurrentPage] = useState(0);
//   const PAGESIZE = 10;
//   const [currentPageModel, setCurrentPageModel] = useState({
//     pageSize: PAGESIZE,
//     page: 0,
//   });

//   useEffect(() => {
//     // function call here
//   }, [debouncedSearch, paginationParams, currentPage]);

  const columns = [
    {
      headerName: "First Factor",
      field: "firstfactor",
      key: "firstfactor",
      flex: 1,
    },
    {
      headerName: "Second Factor",
      field: "secondfactor",
      key: "secondfactor",
      flex: 1,
    },
    {
      headerName: "Third Factor",
      field: "thirdFactor",
      key: "thirdfactor",
      flex: 1,
    },
    {
      headerName: "PAM Code",
      field: "pamcode",
      key: "pamcode",
      flex: 1,
    },
  ];
  return (
    <>
      <Row className="content-container">
        <Col span={24}>
          <Row
            style={{ marginBottom: "2rem", gap: "2rem" }}
            className="search-box-container"
          >
            <Col span={6}>
              <p className="search-label">Search</p>

              <Input
                style={{ width: "100%" }}
                placeholder="Search"
                allowClear
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                //   if (paginationParams.current !== 1) {
                //     setPaginationParams({ current: 1, pageSize: 10 });
                //   }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            rows={[]}
            columns={columns}
            // loading={isLoading}
            style={{ border: "none" }}
            // paginationMode="server"
            // rowCount={totalCount}
            // page={currentPage}
            // paginationModel={currentPageModel}
            // initialState={{
            //   pagination: {
            //     paginationModel: { pageSize: 10, page: 0 },
            //   },
            // }}
            // onPaginationModelChange={(params) => {
            //   setCurrentPage(params.page);
            // }}
          />
        </Col>
      </Row>
    </>
  );
};

export default EndpointGroupDetailsAuth;
