import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../../constants";
import { logOutUser } from "../../../../common";
import axios from "axios";
import { Button, Col, Input, Row, message, Typography, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

export const OnboardLinuxV2 = () => {
  const [notification, setNotification] = message.useMessage();
  const [agentToken, setAgentToken] = useState("");
  const [orgId, setOrgId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [userId, setUserId] = useState("");
  const [uuid, setUuid] = useState("");
  const { Text } = Typography;
  const navigate = useNavigate();

  useEffect(() => {
    const Local_User_ID = localStorage.getItem("userId");
    const Local_Org_ID = localStorage.getItem("OrgId");
    const Local_Domain_ID = localStorage.getItem("DomainId");

    // Update state with retrieved values
    if (Local_User_ID) setUserId(Local_User_ID);
    if (Local_Org_ID) setOrgId(Local_Org_ID);
    if (Local_Domain_ID) setTenantId(Local_Domain_ID);
    fetchAgentKey();
    generateDynamicUuid();
  }, []);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
  };

  const generateEnvFileContent = () => {
    const content = `KEY=LINUX02
MACHINE_KEY=LINUX02
AGENT_TOKEN=${agentToken}
ORG_ID=${orgId}
TENANT_ID=${tenantId}
USER_ID=${userId}
AWS_ACCESS_KEY_ID=AKIATQW4CGR3AR5WPS3H
AWS_SECRET_ACCESS_KEY=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
AWS_REGION=us-west-1
BUCKET_NAME=guacwest
RECORDING_DIR=/anchor_dvr/
TEXT_RECORDING_DIR=/text_recordings/
FILE_NAME=test.guac
BUCKET_NAME_GCS=gto-did-app-dev
STORAGE_AWS_FLAG=true
INSTALL_SERVICES=false
UUID=${uuid}`;
    return content;
  };

  const generateDynamicUuid = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    // Define the format for each section
    const sections = [8, 4, 4, 4, 12];

    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      result += "-";
    }

    // Remove the trailing hyphen
    result = result.slice(0, -1);

    return setUuid(result);
  };

  const downloadEnvFile = () => {
    const content = generateEnvFileContent();
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "app.env";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const fetchAgentKey = () => {
    let keyData = {
      domainId: DOMAIN_ID(),
      expired: false,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/generateAgentkey`, keyData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        if (res.data) {
          setAgentToken(res.data.agentKey);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch agent key",
          });
        }
      });
  };

  const generateSudoCommand = () => {
    const content = generateEnvFileContent().replace(/\n/g, "\\n").replace(/"/g, '\\"');
    return `echo -e "${content}"`;
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Onboarding Linux </h2>
            </Col>
          </Row>
          <Col span={21}>
            <h2>
              <strong>Agent Onboarding</strong>
            </h2>
            <p>
              <b>Step1:</b> Download the script using the following command:
            </p>
            <Input
              style={{ border: "none", padding: "1rem" }}
              size="large"
              value="sudo wget https://github.com/authnull0/windows-endpoint/raw/linux-agent/agent/linux-agent/install_agent.sh -O install_agent.sh"
              disabled
              suffix={
                <Text
                  style={{ color: "GrayText" }}
                  copyable={{
                    text: "sudo wget https://github.com/authnull0/windows-endpoint/raw/linux-agent/agent/linux-agent/install_agent.sh -O install_agent.sh",
                    tooltips: ["Copy Command", "Copied"],
                  }}
                ></Text>
              }
            />
          </Col>
          <Col span={21}>
            <p>
              <b>Step2:</b> Make the script Executable using the following command:{" "}
            </p>
            <Input
              style={{ border: "none", padding: "1rem" }}
              size="large"
              value="sudo chmod +x install_agent.sh"
              disabled
              suffix={
                <Text
                  style={{ color: "GrayText" }}
                  copyable={{
                    text: "sudo chmod +x install_agent.sh",
                    tooltips: ["Copy Command", "Copied"],
                  }}
                ></Text>
              }
            />
          </Col>
          <Col span={21}>
            <p>
              <b>Step3:</b> Run the script and install the Agent using the following command:
            </p>
            <Input
              style={{ border: "none", padding: "1rem" }}
              size="large"
              value="./install_agent.sh"
              disabled
              suffix={
                <Text
                  style={{ color: "GrayText" }}
                  copyable={{
                    text: "./install_agent.sh",
                    tooltips: ["Copy Command", "Copied"],
                  }}
                ></Text>
              }
            />
          </Col>
          <Col span={21}>
            <p>
              <b>Step4:</b> Download the app.env file
            </p>
            <Button type="primary" onClick={downloadEnvFile}>
              Download <DownloadOutlined />
            </Button>
          </Col>
          <br></br>
          <Col span={21} style={{ marginTop: "2rem" }}>
            <h2>
              <strong>Configure PAM Module</strong>
            </h2>
            <p>
              After performing the above steps, check the logs. If you see the Token is valid, the
              Agent configuration is successful, and you are ready to proceed.
            </p>
            <p>
              <b>Step1:</b> Log in to the virtual machine and download the script using the
              following command:
            </p>
            <p>
              <Input
                style={{ border: "none", padding: "1rem" }}
                size="large"
                value="sudo wget https://github.com/authnull0/windows-endpoint/raw/google-authenticator-pam/pam/google-authenticator-pam.sh"
                disabled
                suffix={
                  <Text
                    style={{ color: "GrayText" }}
                    copyable={{
                      text: "sudo wget https://github.com/authnull0/windows-endpoint/raw/google-authenticator-pam/pam/google-authenticator-pam.sh",
                      tooltips: ["Copy Command", "Copied"],
                    }}
                  ></Text>
                }
              />
            </p>
          </Col>
          <Col span={21}>
            <p>
              <b>Step2:</b> Make the Script Executable using the following command:{" "}
            </p>
            <Input
              style={{ border: "none", padding: "1rem" }}
              size="large"
              value="chmod +x google-authenticator-pam.sh"
              disabled
              suffix={
                <Text
                  style={{ color: "GrayText" }}
                  copyable={{
                    text: "chmod +x google-authenticator-pam.sh",
                    tooltips: ["Copy Command", "Copied"],
                  }}
                ></Text>
              }
            />
          </Col>
          <Col span={21}>
            <p>
              <b>Step3:</b> Run the script using the following command:
            </p>
            <Input
              style={{ border: "none", padding: "1rem" }}
              size="large"
              value="./google-authenticator-pam.sh"
              disabled
              suffix={
                <Text
                  style={{ color: "GrayText" }}
                  copyable={{
                    text: "./google-authenticator-pam.sh",
                    tooltips: ["Copy Command", "Copied"],
                  }}
                ></Text>
              }
            />
          </Col>
          <Col span={21}>
            <p>
              <b>Step4:</b> Open the Ternminal in your local machine , log in to the VM using SSH .
            </p>
            <p>The DID Authentication will initiate a DID Assertion DoAuthentication Request.</p>
            <p>
              After performing the above steps, check the logs. If you see the expected output, the
              configuration is successful, and you are ready to proceed.
            </p>
          </Col>
          <Col span={21} style={{ marginTop: "2rem", textAlign: "right" }}>
            <Space>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button type="primary" htmlType="submit" onClick={() => navigate(-1)}>
                Finish
              </Button>
            </Space>
          </Col>
        </Col>
      </Row>
    </>
  );
};
