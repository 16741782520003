import { Button, Col, Input, Modal, Row, Select, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

const { Option } = Select;
const UpdatePolicy = ({
  selectedRow,
  notification,
  setIsModalVisible,
  isModalVisible,
  endpointGroups,
  domainData,
  epmUsers,
  epmGroups,
  adGroups,
  epm,
  fetchPolicy,
}) => {
  const [type, setType] = useState("option1");
  const [type2, setType2] = useState("option1");
  const [type3, setType3] = useState("option1");
  const [policyType, setPolicyType] = useState(selectedRow?.policyType);
  const [endpointsval, setEndpointsval] = useState(selectedRow?.destinationEndpoint?.split(","));
  const [epmGroupVal, setEpmGroupVal] = useState([]);
  const [endpointWildCard, setEndpointWildCard] = useState();
  const [endpointUser, setEndpointUser] = useState(selectedRow?.endpointUser?.split(","));
  const [endpointUserWildCard, setEndpointUserWildCard] = useState();
  const [adUsersVal, setAdUsersVal] = useState(
    selectedRow?.policyType === "local" && selectedRow?.adUser
  );
  const [os, setOs] = useState(selectedRow?.os);
  const [protocol, setProtocol] = useState(selectedRow?.protocol);
  const [domainVal, setDomainVal] = useState(selectedRow?.adDomain);
  const [ou, setOu] = useState(selectedRow?.adOu);
  const [directoryGroups, setDirectoryGroups] = useState([]);
  const [directoryUser, setDirectoryUser] = useState(selectedRow?.adUser.split(","));
  const [userWildCard, setUserWildCard] = useState();
  const [policyName, setPolicyName] = useState(selectedRow?.policyName);
  const [actionVal, setActionVal] = useState(selectedRow?.action);
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkPolicyNotification, setCheckPolicyNotification] = useState(false);
  const [ouData, setOuData] = useState([]);
  const [walletUserVal, setWalletUserVal] = useState(selectedRow?.walletUsers?.split(","));
  const [walletData, setwalletData] = useState([]);
  const [adUser, setAdUser] = useState([]);

  const handleADUser = (val) => {
    if (directoryUser.length > 0 && directoryUser.includes("all")) {
      // If "all" was previously selected and is now deselected, remove it from the array
      const filteredValues = val.filter((item) => item !== "all");
      setDirectoryUser(filteredValues);
    } else if (val.includes("all")) {
      // If "all" is selected, reset the directoryUser array
      setDirectoryUser(["all"]);
    } else {
      setDirectoryUser(val);
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    fetchWalletUser();
  }, []);

  const fetchWalletUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: 1,
      pageSize: 100,
      search: "",

      filter: "",
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setwalletData(res.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const fetchAdUser = (ouDn) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-users",
      ...ouDn,
      // adDomainId: 1ouDn.adDomainId,
      // adOuId: 12,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data.data.adUsers);
        setAdUser(data.data.adUsers);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const checkOldwithCurrentData = () => {
    let newData = {
      policyName: policyName,
      action: actionVal,
      adDomain: domainVal,
      adOu: ou,
      adUser: directoryUser.join(","),
      destinationEndpoint: endpointsval.join(","),
      endpointUser: endpointUser.join(","),
      os: os,
      policyType: policyType,
      protocol: protocol,
      walletUsers: walletUserVal.join(","),
    };
    const relevantFields = [
      "policyName",
      "action",
      "adDomain",
      "adOu",
      "os",
      "policyType",
      "protocol",
      "adUser",
      "destinationEndpoint",
      "endpointUser",
      "walletUsers",
    ];
    let relevantFieldsMatch = true;

    for (const field of relevantFields) {
      if (selectedRow[field] !== newData[field]) {
        relevantFieldsMatch = false;
        break;
      }
    }
    if (relevantFieldsMatch) {
      console.log("JSON objects match on relevant fields.");
      setIsModalVisible(false);
    } else {
      console.log("JSON objects do not match on relevant fields.");
      handleFinish();
    }
  };

  const validateForm = () => {
    // if (
    //   !policyName ||
    //   !ou ||
    //   !type ||
    //   !type2 ||
    //   !type3 ||
    //   !endpointUser ||
    //   !endpointsval ||
    //   !directoryUser ||
    //   !epmGroupVal
    // ) {
    //   message.error("Please fill in all the fields.");
    //   return false;
    // }

    // // Additional validation checks based on your specific requirements

    // return true;

    let checkEndpointVal =
      type2 === "option1"
        ? !endpointsval?.length
        : type2 === "option2"
        ? !endpointGroups?.length
        : (type2 === "option3" || type2 === "option4") && !endpointWildCard;
    let checkdirectoryUserVal =
      type === "option1"
        ? !directoryUser?.length
        : type === "option2"
        ? !directoryGroups?.length
        : (type === "option3" || type === "option4") && !userWildCard;

    let checkDirectoryUserForLocal =
      type === "option1"
        ? !directoryUser
        : (type2 === "option3" || type2 === "option4") && !endpointWildCard;

    let checkEPMUser =
      (type3 === "option1" && !endpointUser?.length) ||
      (type3 === "option3" && !endpointUserWildCard);

    if (policyType.toLowerCase() === "ad") {
      if (checkEndpointVal || checkdirectoryUserVal || !domainVal || !ou) {
        message.error("Please fill in all the fields.");
        return false;
      }
      return true;
    }
    if (policyType.toLowerCase() === "local") {
      if (checkEndpointVal || checkEPMUser || !walletUserVal.length) {
        message.error("Please fill in all the fields.");
        return false;
      }
      return true;
    }
  };

  const handleFinish = () => {
    if (!validateForm()) {
      return;
    }
    console.log(policyName);
    let tempUserSelectionType;
    let tempMatch;
    let tempSelection;
    let tempEndpointSelectionType;
    let tempMatch2;
    let tempSelection2;
    let tempEndpointUserSelectionType = "user";
    let tempMatch3;
    let tempSelection3;
    if (type === "option1" || type === "option4") {
      tempUserSelectionType = "user";
    } else if (type === "option2" || type === "option3") {
      tempUserSelectionType = "groups";
    } else {
      tempUserSelectionType = "tenant";
    }
    if (type === "option1" || type === "option2" || type === "option5") {
      tempMatch = "exact";
    } else {
      tempMatch = "wild";
    }
    if (type === "option1") {
      tempSelection = adUsersVal ? [adUsersVal] : directoryUser;
    } else if (type === "option2") {
      tempSelection = directoryGroups;
    } else if (type === "option5") {
      tempSelection = ["all"];
    } else {
      tempSelection = userWildCard;
    }

    if (type2 === "option1" || type2 === "option4") {
      tempEndpointSelectionType = "endpoint";
    } else if (type2 === "option2" || type2 === "option3") {
      tempEndpointSelectionType = "group";
    } else {
      tempEndpointSelectionType = "tenant";
    }

    if (type2 === "option1" || type2 === "option2" || type2 === "option5") {
      tempMatch2 = "exact";
    } else {
      tempMatch2 = "wild";
    }
    if (type2 === "option1") {
      tempSelection2 = endpointsval;
    } else if (type2 === "option2") {
      tempSelection2 = epmGroupVal;
    } else if (type === "option5") {
      tempSelection2 = ["all"];
    } else {
      tempSelection2 = endpointWildCard;
    }

    if (type3 === "option5") {
      tempEndpointUserSelectionType = "tenant";
      tempSelection3 = ["all"];
    }

    if (type3 === "option1" || type3 === "option5") {
      tempMatch3 = "exact";
    } else {
      tempMatch3 = "wild";
    }
    if (type3 === "option1") {
      tempSelection3 = endpointUser;
    } else if (type === "option5") {
      tempSelection3 = ["all"];
    } else {
      tempSelection3 = endpointUserWildCard;
    }

    let data = {
      id: selectedRow?.id,
      action: actionVal,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyName: policyName,
      policyType: policyType,
      protocol: [protocol],
      os: os,
      adOu: ou,
      adDomain: domainVal,
      sAMAccountName: false,
      userOrGroups: {
        selectionType: tempUserSelectionType,
        selectionValue: tempSelection,
        match: tempMatch,
      },
      endpointOrGroups: {
        // source: {
        //   match: "exact",
        //   selectionType: "endpoint",
        //   selectionValue: ["epm4", "epm5"],
        // },
        destination: {
          selectionType: tempEndpointSelectionType,
          selectionValue: tempSelection2,
          match: tempMatch2,
        },
      },
      endpointUsers: {
        selectionType: tempEndpointUserSelectionType,
        selectionValue: tempSelection3,
        match: tempMatch3,
      },
      walletUsers: walletUserVal,
    };
    setBtnLoading(true);
    axios
      .post(
        `${REACT_APP_API_DID_URL}/policyService/IsPolicyExists`,
        { ...data, update: 1 },
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setBtnLoading(false);
        console.log(res);
        if (res.data.isPolicyExists == true) {
          setCheckPolicyNotification(true);
        } else {
          updatePolicy(data);
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to create now, PLease try again",
          });
        }
      });
  };

  const updatePolicy = (payload) => {
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/UpdatePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        notification.open({
          type: "success",
          content: `${policyName} policy updated successfully`,
        });
        setIsModalVisible(false);
        fetchPolicy();
      })

      .catch((err) => {
        setIsModalVisible(true);
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: `Unable to create ${policyName} policies`,
        });
      });
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontSize: 18 }}>
            Edit policy - <span style={{ color: "#1677FF" }}>{` ${selectedRow?.policyName}`}</span>
          </span>
        }
        footer={null}
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="authModal"
      >
        <Row className="content-conatiner">
          {checkPolicyNotification && (
            <Modal
              title={`Policy already exists for this combination, Please Create with some other combination`}
              open={checkPolicyNotification}
              onOk={() => {
                setCheckPolicyNotification(false);
              }}
              centered
              closeIcon={false}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  loading={btnLoading}
                  onClick={() => setCheckPolicyNotification(false)}
                >
                  Ok
                </Button>,
              ]}
            ></Modal>
          )}
          <Col span={24}>
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={12}>
                <p className="search-label">Policy Name</p>
                <Input value={policyName} onChange={(e) => setPolicyName(e.target.value)} />
              </Col>
              <Col span={12}>
                <p className="search-label">Policy Type</p>
                <Select
                  showSearch
                  placeholder="Search Policy Type"
                  optionFilterProp="children"
                  defaultValue={policyType}
                  onChange={(e) => {
                    setPolicyType(e);
                    console.log(e);
                  }}
                >
                  <Option value="ad">Active Directory</Option>
                  <Option value="local">Local Users</Option>
                </Select>
              </Col>
            </Row>

            <h3 style={{ color: "blue", marginTop: "2rem" }}>Resources that can be accessed</h3>

            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={12}>
                <p className="search-label">Endpoints and Endpoint Groups</p>
                <Select
                  placeholder="Select Endpoint And Endpoint Groups"
                  onChange={(e) => setType2(e)}
                  defaultValue={type2}
                >
                  <Option value="option1">Endpoints</Option>
                  <Option value="option2">Endpoint Groups</Option>
                  <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                  <Option value="option4">Endpoint Matching Wild Card</Option>
                  <Option value="option5">Apply To Whole Tenant</Option>
                </Select>
              </Col>
              {type2 === "option1" ? (
                <Col span={12}>
                  <p className="search-label">Endpoints List</p>
                  <Select
                    mode="multiple"
                    onChange={(e) => setEndpointsval(e)}
                    defaultValue={endpointsval}
                  >
                    {epm &&
                      epm.length > 0 &&
                      epm.map((endpoint) => <Option value={endpoint.name}>{endpoint.name}</Option>)}
                  </Select>
                </Col>
              ) : (
                type2 === "option2" && (
                  <Col span={12}>
                    <p className="search-label">Endpoints Groups List</p>
                    <Select mode="multiple" onChange={(e) => setEpmGroupVal(e)}>
                      {endpointGroups &&
                        endpointGroups.length > 0 &&
                        endpointGroups.map((group) => (
                          <Option value={group.groupName}>{group.groupName}</Option>
                        ))}
                    </Select>
                  </Col>
                )
              )}
              {(type2 == "option3" || type2 == "option4") && (
                <Col span={12}>
                  <p className="search-label">Wild Card</p>
                  <Input
                    placeholder="prefix*"
                    onSearch={(value) => console.log(value)}
                    onChange={(e) => setEndpointWildCard(e.target.value)}
                  />
                </Col>
              )}
            </Row>
            {/* <Row gutter={16}>
              <Col span={12}>
                <p className="search-label">OS</p>
                <Select placeholder="Select OS" onChange={(e) => setOs(e)} defaultValue={os}>
                  <Option value="Windows">Windows</Option>
                  <Option value="Linux">Linux</Option>
                </Select>
              </Col>
              <Col span={12}>
                <p className="search-label">Protocol</p>
                <Select
                  placeholder="Select Protocol"
                  onChange={(e) => setProtocol(e)}
                  defaultValue={protocol}
                >
                  <Option value="SSH">SSH</Option>
                  <Option value="RDP">RDP</Option>
                  <Option value="VNC">VNC</Option>
                  <Option value="Password">Password</Option>
                </Select>
              </Col>
            </Row> */}
            {policyType === "local" && (
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={12}>
                  <p className="search-label">Endpoints Users</p>
                  <Select
                    placeholder="Select Endpoint Users"
                    onChange={(e) => setType3(e)}
                    defaultValue={type3}
                  >
                    <Option value="option1">Endpoint Users</Option>

                    <Option value="option3">Endpoint Users Matching Wild Card</Option>

                    <Option value="option5">Apply To Whole Tenant</Option>
                  </Select>
                </Col>
                {type3 === "option1" ? (
                  <Col span={12}>
                    <p className="search-label">Endpoint User List</p>
                    <Select
                      mode="multiple"
                      onChange={(e) => setEndpointUser(e)}
                      defaultValue={endpointUser}
                    >
                      {epmUsers &&
                        epmUsers.length > 0 &&
                        epmUsers.map((user) => <Option value={user.user}>{user.user}</Option>)}
                    </Select>
                  </Col>
                ) : (
                  type3 === "option2" && (
                    <Col span={12}>
                      <p className="search-label">Endpoints Groups List</p>
                      <Select mode="multiple" onChange={(e) => setEpmGroupVal(e)}>
                        {epmGroups &&
                          epmGroups.length > 0 &&
                          epmGroups.map((group) => (
                            <Option value={group.groupName}>{group.groupName}</Option>
                          ))}
                      </Select>
                    </Col>
                  )
                )}
                {(type3 == "option3" || type3 == "option4") && (
                  <Col span={12}>
                    <p className="search-label">Wild Card</p>
                    <Input
                      placeholder="prefix*"
                      onSearch={(value) => console.log(value)}
                      onChange={(e) => setEndpointUserWildCard(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            )}
            {policyType === "ad" && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <p className="search-label">Domain</p>
                    <Select
                      showSearch
                      placeholder="Search Domain"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      defaultValue={domainVal}
                      onChange={(e, val) => {
                        setDomainVal(val?.children);
                        const selectedDomain = domainData.find((domain) => domain.id === e);
                        if (selectedDomain) {
                          setOuData(
                            selectedDomain?.ous?.map((data) => {
                              let ouVal = data.OuName.split(",")[0];
                              let nameOu = ouVal.substring(3);
                              return data;
                            })
                          );
                          fetchAdUser({ adDomainId: parseInt(e) });
                        } else {
                          setOuData([]);
                        }
                        // console.log(e, val);
                      }}
                    >
                      {domainData?.map((dom) => (
                        <Option key={dom.id} value={dom.id}>
                          {dom.directoryName}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <p className="search-label">Ou</p>
                    <Select
                      showSearch
                      placeholder="Search OU "
                      optionFilterProp="children"
                      onChange={(e, val) => {
                        setOu(val.children);
                        console.log(val.children);
                        let ouDn = {
                          adDomainId: parseInt(val.key),
                          adOuId: e,
                        };
                        fetchAdUser(ouDn);
                      }}
                      defaultValue={ou}
                    >
                      {ouData?.map((ou) => (
                        <Option key={ou.AdId} value={ou.Id}>
                          {ou.OuName}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <p className="search-label">
                      {policyType === "local" ? "Directory Users" : "Directory Users and Groups"}
                    </p>
                    {policyType !== "local" ? (
                      <Select
                        placeholder="Select User And Groups"
                        onChange={(e) => setType(e)}
                        defaultValue={type}
                      >
                        <Option value="option1">Directory Users </Option>
                        <Option value="option2">Directory Groups</Option>
                        <Option value="option3">Groups Matching Wild Card</Option>
                        <Option value="option4">Users Matching Wild Card</Option>
                        <Option value="option5">Apply To Whole Tenant</Option>
                      </Select>
                    ) : (
                      <Select
                        placeholder="Select User"
                        onChange={(e) => setType(e)}
                        defaultValue={type}
                      >
                        <Option value="option1">Directory Users </Option>
                        <Option value="option4">Users Matching Wild Card</Option>
                      </Select>
                    )}
                  </Col>
                  {type === "option1" ? (
                    <Col span={12}>
                      <p className="search-label">Directory User List</p>
                      {policyType === "local" ? (
                        <Select
                          placeholder="Select directory user"
                          onChange={(e) => setAdUsersVal(e)}
                          defaultValue={adUsersVal}
                          showSearch
                        >
                          {adUser && adUser.length > 0 && (
                            <>
                              {adUser.map((user) => (
                                <Option key={user.emailAddress} value={user.emailAddress}>
                                  {user.emailAddress}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      ) : (
                        <Select
                          placeholder="Select directory user"
                          mode="multiple"
                          onChange={handleADUser}
                          defaultValue={directoryUser}
                        >
                          {adUser && adUser.length > 0 && (
                            <>
                              <Option value="all">Select All</Option>
                              {adUser.map((user) => (
                                <Option key={user.emailAddress} value={user.emailAddress}>
                                  {user.emailAddress}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      )}
                    </Col>
                  ) : (
                    type === "option2" && (
                      <Col span={12}>
                        <p className="search-label"> Directory Groups List</p>
                        <Select onChange={(e) => setDirectoryGroups(e)} mode="multiple">
                          {adGroups &&
                            adGroups.length > 0 &&
                            adGroups.map((user) => <Option value={user.name}>{user.name}</Option>)}
                        </Select>
                      </Col>
                    )
                  )}
                  {(type == "option3" || type == "option4") && (
                    <Col span={12}>
                      <p className="search-label">Wild Card</p>
                      <Input
                        placeholder="prefix*"
                        onSearch={(value) => console.log(value)}
                        onChange={(e) => setUserWildCard(e.target.value)}
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}

            <h3 style={{ color: "blue", marginTop: "2rem" }}>Policy applicable to</h3>

            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={8}>
                {/* <p className="search-label">Wallet Users</p> */}
                <Select
                  mode="multiple"
                  onChange={(e) => setWalletUserVal(e)}
                  placeholder="Select wallet users"
                  defaultValue={walletUserVal}
                >
                  {walletData?.map((user) => (
                    <Option value={user.emailAddress}>{user.emailAddress}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <h3 style={{ color: "blue", marginTop: "2rem" }}>Select the policy action</h3>

            <Row>
              <Col span={8}>
                {/* <p className="search-label">Policy Action</p> */}
                <Select onChange={(e) => setActionVal(e)} defaultValue={actionVal}>
                  <Option value="Allow">Allow</Option>
                  <Option value="Deny">Deny</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
                span={2}
                offset={22}
              >
                <Button type="primary" onClick={() => checkOldwithCurrentData()}>
                  Update Policy
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UpdatePolicy;
