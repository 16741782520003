import { Col, Input, Row, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import { logOutUser } from "../../common";
import moment from "moment-timezone";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const AssignedVc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [endpointData, setEndpointData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const [sortModel, setSortModel] = useState();

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchEndpoints();
  }, [debouncedValue]);

  const columns = [
    {
      headerName: "Credential Name",
      field: "credentialName",
      key: "credentialName",
      flex: 2,
    },
    {
      headerName: "Endpoint User",
      field: "epmUsername",
      key: "epmUsername",
      flex: 1.5,
    },
    {
      headerName: "Endpoint",
      field: "hostname",
      key: "hostname",
      flex: 2,
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      key: "createdAt",
      flex: 1.5,
    },
    {
      headerName: "Expiry Date",
      field: "expirationDate",
      key: "expirationDate",
      flex: 1.5,
    },
    {
      headerName: "Issuer",
      field: "issuerName",
      key: "issuerName",
      flex: 1.5,
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      flex: 1,
      renderCell: (val) => (val?.row?.status?.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
  ];

  const fetchEndpoints = () => {
    const userId = localStorage.getItem("userId");

    let data = {
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      filter: {
        filterBy: "",
        value: "",
      },
      search,
      userId: parseInt(userId),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAssignCredential`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(
          res?.data?.credentials?.map((cred, i) => ({
            id: i + 1,
            ...cred,
            createdAt:
              cred.createdAt &&
              moment(cred.createdAt).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm [PST]"),
            expirationDate:
              cred.expirationDate &&
              moment(cred.expirationDate)
                .tz("America/Los_Angeles")
                .format("DD-MM-YYYY HH:mm [PST]"),
          }))
        );
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Verifiable Credentials</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (currentPageModel.current !== 1) {
                          setCurrentPageModel({ page: 0, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={endpointData}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              /> */}
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={endpointData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                sortModel={sortModel}
                onSortModelChange={(val) => setSortModel(val)}
                style={{ border: "none" }}
                paginationModel={currentPageModel}
                page={currentPage}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default AssignedVc;
