import { Col, Row, Select, Card } from "antd";
import { Link } from "react-router-dom";
const { Option } = Select;
const Tour = () => {
  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Tours</h2>
          </Col>
          <Col>
            {/* <Link to={"/linuxMachines/addJumpServer"}>
              <Button type="primary">Add Jump Server</Button>
            </Link> */}
          </Col>
        </Row>

        <Row gutter={24} style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <Card style={{ cursor: "pointer" }}>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  color:'blue'
                }}
              >
                Configure <br/>Endpoint Users
              </h3>
            </Card>
          </Col>
          <Col span={8}>
            <Card style={{ cursor: "pointer" }}>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  color:'blue'
                }}
              >
            Configure <br/>Endpoint Groups
              </h3>
            </Card>
          </Col>
          <Col span={8}>
          <Card style={{ cursor: "pointer" }}>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  color:'blue'
                }}
              >
                Configure <br/>Endpoints
              </h3>
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
          <Card style={{ cursor: "pointer" }}>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  color:'blue'
                }}
              >
                Configure <br/>Jump Servers
              </h3>
            </Card>
          </Col>
          <Col span={8}>
          <Card style={{ cursor: "pointer" }}>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  color:'blue'
                }}
              >
                Configure <br/>Connections
              </h3>
            </Card>
          </Col>
          <Col span={8}>
          <Card style={{ cursor: "pointer" }}>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                  color:'blue'
                }}
              >
                Configure <br/>Credential Policies
              </h3>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Tour;
