import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LOGO } from "../../../assets/logo.svg";

const TwoFactorLogin = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    console.log("Received values of form: ", values);
    const loginData = {
      ...values,
    };
    setIsLoading(true);
  };

  return (
    <>
      {setNotification}
      <div className="login-container">
        <div className="login-box">
          <h2 className="title">
            {" "}
            <LOGO />
          </h2>

          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password",
                },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <div className="login-form-button">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={IsLoading}
                >
                  Login
                </Button>
                <br />
              </Form.Item>
              <span>Or</span> <br />
              <br />
              <a href="">Forgot password ?</a>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default TwoFactorLogin;
