import { Cascader, Col, Input, Row, Table, Tag, message } from "antd";
import React, { useState } from "react";
import { useLocation,Link } from "react-router-dom";


const ListworkloadGroups = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ishistory, setIshistory] = useState(true);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [endpoint, setEndpoint] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const epmUsername = queryParams.get("epmUser");
  

  const columns = [
    {
      title: "Workload Identity Name",
      dataIndex: "Groups",
      key: "Groups",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Workload Identities",
      dataIndex: "identities",
      key: "identities",
    },
  
    {
      title: "Created by",
      dataIndex: "Created",
      key: "Created",
     
    },
    {
        title: "Rules",
        dataIndex: "rule",
        key: "rule",
       
      },
      {
        title: "History",
        dataIndex: "history",
        key: "history",
       
      },
  ];

  const dataSource = [
    {
      key: '1',
      Groups: 'Authnull-admin',
      id: '3C12Y-QACR-9IOP',
      identities: <Tag color="blue">Endpoint Group #1</Tag>,
      access: <Tag color="blue">Endpoint Group #17</Tag>,
      Created: 'asif@authnull.com',
      rule: <Link to={`/workloadIdentity/addWorkloadIdentity?history=${true}`} onClick={()=>setIshistory(true)}>View history</Link>,
      history: <Link to={`/workloadIdentity/addWorkloadIdentity`}>View history</Link>,
      
    },
    {
        key: '2',
        Groups: 'Authnull-admin',
        id: '3C12Y-QACR-9IOP',
        identities: <Tag color="blue">Endpoint Group #1</Tag>,
        access: <Tag color="blue">Endpoint Group #17</Tag>,
        Created: 'asif@authnull.com',
        rule: <Link to={`/workloadIdentity/addWorkloadIdentity?history=${true}`} onClick={()=>setIshistory(true)}>View history</Link>,
        history: <Link to={`/workloadIdentity/addWorkloadIdentity`}>View history</Link>,
        
      },
      {
        key: '3',
        Groups: 'Authnull-admin',
        id: '3C12Y-QACR-9IOP',
        identities: <Tag color="blue">Endpoint Group #1</Tag>,
        access: <Tag color="blue">Endpoint Group #17</Tag>,
        Created: 'asif@authnull.com',
        rule: <Link to={`/workloadIdentity/addWorkloadIdentity?history=${true}`} onClick={()=>setIshistory(true)}>View history</Link>,
        history: <Link to={`/workloadIdentity/addWorkloadIdentity`}>View history</Link>,
        
      },
      {
        key: '4',
        Groups: 'Authnull-admin',
        id: '3C12Y-QACR-9IOP',
        identities: <Tag color="blue">Endpoint Group #1</Tag>,
        access: <Tag color="blue">Endpoint Group #17</Tag>,
        Created: 'asif@authnull.com',
        rule: <Link to={`/workloadIdentity/addWorkloadIdentity?history=${true}`} onClick={()=>setIshistory(true)}>View history</Link>,
        history: <Link to={`/workloadIdentity/addWorkloadIdentity`}>View history</Link>,
        
      },
      {
        key: '5',
        Groups: 'Authnull-admin',
        id: '3C12Y-QACR-9IOP',
        identities: <Tag color="blue">Endpoint Group #1</Tag>,
        access: <Tag color="blue">Endpoint Group #17</Tag>,
        Created: 'asif@authnull.com',
        rule: <Link to={`/workloadIdentity/addWorkloadIdentity?history=${true}`} onClick={()=>setIshistory(true)}>View history</Link>,
        history: <Link to={`/workloadIdentity/addWorkloadIdentity`}>View history</Link>,
        
      },
    
  ];

  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };
  const option = [
    {
      value: "identites",
      label: "Workload Identities",
      children: endpoint,
    },
  
  ];

 
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      currentPage: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      
    </>
  );
};

export default ListworkloadGroups;
