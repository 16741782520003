import { InboxOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Col, Row, Select, Tag, Button, message } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../../../constants";
import { CSVLink } from "react-csv";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

export default function EndpointRulesLogs() {
  const isInitialMount = useRef(true);
  const [notification, notificationHolder] = message.useMessage();

  const [endpointsData, setEndpointsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [sourceIp, setSourceIp] = useState([]);
  const [destinationIP, setDestinationIP] = useState([]);
  const [selectedRule, setSelectedRule] = useState("Endpoints");

  //pagination
  const PAGESIZE = 100;
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 100,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  useEffect(() => {
    fetchEndpointUsers();
    fetchSourceIP();
    fetchDestinationIP();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setAppliedFilters([]);
    }
  }, [selectedRule]);

  useEffect(() => {
    if (selectedRule === "AD") {
      fetchADGroup();
    } else {
      fetchEndpointsRules();
    }
  }, [appliedFilters, paginationParams, currentPage]);

  const headers = [
    { label: "username", key: "username" },
    { label: "source_ip", key: "source_ip" },
    { label: "destination_ip", key: "destination_ip" },
    { label: "domain_name", key: "ou" },
    { label: "protocols", key: "protocols" },
    { label: "authentication_status", key: "authentication_status" },
    { label: "status", key: "status" },
    { label: "created_at", key: "created_at" },
  ];

  const columns = [
    {
      headerName: "Username",
      field: "username",
      key: "username",
      flex: 1,
    },
    {
      headerName: "Source IP",
      field: "source_ip",
      key: "source_ip",
      flex: 1,
      renderCell: (val) => (
        <Tag
          style={{
            cursor: "pointer",
            whiteSpace: "break-spaces",
          }}
          onClick={() => {
            applyFilter("sourceIp", val?.row?.source_ip);
          }}
        >
          {val?.row?.source_ip}
        </Tag>
      ),
    },
    {
      headerName: "Destination IP",
      field: "destination_ip",
      key: "destination_ip",
      flex: 1,
      renderCell: (val) => (
        <Tag
          style={{
            cursor: "pointer",
            whiteSpace: "break-spaces",
          }}
          onClick={() => {
            applyFilter("destinationIp", val?.row?.destination_ip);
          }}
        >
          {val?.row?.destination_ip}
        </Tag>
      ),
    },
    selectedRule === "AD" && {
      headerName: "Domain Name",
      field: "ou",
      key: "ou",
      flex: 1,
    },
    {
      headerName: "Protocol",
      field: "protocols",
      key: "protocols",
      flex: 0.8,
    },
    {
      headerName: "Created At",
      field: "created_at",
      key: "created_at",
      flex: 1,
      renderCell: (val) =>
        moment(val?.row?.created_at).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },

    selectedRule === "AD"
      ? {
          headerName: "Authentication Status",
          field: "authentication_status",
          key: "authentication_status",
          renderCell: (val) => (
            <Tag
              color={val?.row?.authentication_status?.toLowerCase() === "success" ? "green" : "red"}
            >
              {val?.row?.authentication_status}
            </Tag>
          ),
          flex: 1,
        }
      : {
          headerName: "Authentication Status",
          field: "authentication_status",
          key: "authentication_status",
          renderCell: (val) => (
            <Tag
              color={val?.row?.endpoint_auth_status?.toLowerCase() === "success" ? "green" : "red"}
            >
              {val?.row?.endpoint_auth_status}
            </Tag>
          ),
          flex: 1,
        },
  ].filter(Boolean);

  const fetchADGroup = async () => {
    setIsLoading(true);
    let payload = {
      org_Id: ORG_ID(),
      tenant_Id: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      filters:
        Object.keys(appliedFilters).length === 0
          ? [{ filterParameter: "", filterValue: "" }]
          : Object.entries(appliedFilters).map(([key, value]) => ({
              filterParameter: key,
              filterValue: value,
            })),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listADLogGroup`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempdata = res?.data?.data;
      setEndpointsData(tempdata);
      setTotalCount(res?.data?.total_count);
      setCurrentPageModel({
        pageSize: 100,
        page: currentPage,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.open({
        type: "error",
        content: "Failed to load AD Group logs, try reloading the page",
      });
      console.log(error);
    }
  };

  const fetchEndpointUsers = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpointUser`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const tempData = res?.data?.data.map((user) => {
        return {
          label: user,
          value: user,
        };
      });

      setEndpointUsers(tempData);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load Endpoint Users, try reloading the page",
      });
      console.log(error);
    }
  };

  const fetchSourceIP = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/listSourceIP`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.data.map((ip) => {
        return {
          label: ip,
          value: ip,
        };
      });

      setSourceIp(tempData);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load Source IP, try reloading the page",
      });
      console.log(error);
    }
  };

  const fetchDestinationIP = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listDestinationIP`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const tempData = res?.data?.data.map((ip) => {
        return {
          label: ip,
          value: ip,
        };
      });

      setDestinationIP(tempData);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load Destination IP, try reloading the page",
      });
      console.log(error);
    }
  };

  const fetchEndpointsRules = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      filters:
        Object.keys(appliedFilters).length === 0
          ? [{ filterParameter: "", filterValue: "" }]
          : Object.entries(appliedFilters).map(([key, value]) => ({
              filterParameter: key,
              filterValue: value,
            })),
    };
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpointRule`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setEndpointsData(res?.data?.data);
      setTotalCount(res?.data?.total_count);
      setCurrentPageModel({
        pageSize: 100,
        page: currentPage,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      notification.open({
        type: "error",
        content: "Failed to load Endpoint Rules logs, try reloading the page",
      });
      setIsLoading(false);
    }
  };

  const applyFilter = (type, value) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  };

  const getAppliedFilterValue = (type) => {
    return appliedFilters[type] ?? null;
  };

  return (
    <Row className="content-container">
      {notificationHolder}
      <Col span={24}>
        <Row justify="space-between">
          <h1 className="title">Endpoint Logs</h1>

          <Button type="primary" ghost>
            {console.log({ endpointsData, headers })}
            <CSVLink
              data={endpointsData}
              headers={headers}
              filename={"endpoint-log.csv"}
              className="btn btn-primary"
            >
              Export to CSV
            </CSVLink>
          </Button>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={20} style={{ marginBottom: "1rem" }}>
          <Col span={6}>
            <h5 className="search-label">Filter By Username:</h5>
            <Select
              showSearch
              placeholder="Select User"
              style={{ width: "100%" }}
              options={endpointUsers}
              onChange={(val) => applyFilter("username", val)}
              value={getAppliedFilterValue("username")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Source IP:</h5>
            <Select
              showSearch
              placeholder="Select Source IP"
              style={{ width: "100%" }}
              options={sourceIp}
              onChange={(val) => applyFilter("sourceIp", val)}
              value={getAppliedFilterValue("sourceIp")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Destination IP:</h5>
            <Select
              showSearch
              placeholder="Select Destination IP"
              style={{ width: "100%" }}
              options={destinationIP}
              onChange={(val) => applyFilter("destinationIp", val)}
              value={getAppliedFilterValue("destinationIp")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label"> Authentication Status:</h5>
            <Select
              placeholder="Select Status"
              style={{ width: "100%" }}
              options={[
                { label: "Success", value: "Success" },
                { label: "Failure", value: "Failure" },
              ]}
              onChange={(val) => applyFilter("endpointAuthStatus", val)}
              value={getAppliedFilterValue("endpointAuthStatus")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Log Type:</h5>
            <Select
              style={{ width: "100%" }}
              options={[
                { label: "AD", value: "AD" },
                { label: "Endpoints", value: "Endpoints" },
              ]}
              onChange={(val) => {
                setSelectedRule(val);
              }}
              defaultValue={selectedRule}
            />
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: "2rem" }}>
          <Col>
            <p>{selectedRule && "Applied Filters:"}</p>
            <Tag>{`logType: ${selectedRule}`}</Tag>
            {Object.entries(appliedFilters).map(([key, value]) => {
              if (value) {
                return (
                  <Tag
                    key={key}
                    closable={true}
                    onClose={() => {
                      setAppliedFilters((prevFilters) => {
                        let tempFilters = { ...prevFilters };
                        delete tempFilters[key];
                        return tempFilters;
                      });
                    }}
                  >{`${key}: ${value}`}</Tag>
                );
              }
              return null;
            })}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <DataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          style={{ border: "none" }}
          columns={columns}
          rows={endpointsData}
          loading={isLoading}
          paginationMode="server"
          rowCount={totalCount}
          page={currentPage}
          paginationModel={currentPageModel}
          filterMode="server"
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100, page: 0 },
            },
          }}
          onPaginationModelChange={(params) => {
            setCurrentPage(params.page);
          }}
        />
      </Col>
    </Row>
  );
}
