import { Breadcrumb, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import AcceptedCredential from "./acceptedCredential";
import AssignedCredential from "./assignedCredential";
import IgnoredCredential from "./ignoredCredentials";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";

const Credential = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const location = useLocation();
  const { walletId, mailId } = location.state || {};
  const Storage = localStorage.getItem("details");
  const storedValue = JSON.parse(Storage);
  const checkGroupId = walletId ? walletId : storedValue.walletId;
  const navigate = useNavigate();
  const items = [
    {
      key: 1,
      label: "Accepted",
      children: <AcceptedCredential checkGroupId={checkGroupId} />,
    },
    {
      key: 2,
      label: "Assigned",
      children: <AssignedCredential checkGroupId={checkGroupId} />,
    },
    {
      key: 3,
      label: "Ignored",
      children: <IgnoredCredential checkGroupId={checkGroupId} />,
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/">
                      <HomeOutlined />
                    </Link>
                  ),
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Wallet Users</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Credentials",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">
                Credentials -{" "}
                <span
                  style={{ color: "rgb(22, 119, 255)", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >{`${mailId ? mailId : storedValue.mailId}`}</span>
              </h2>
            </Col>
          </Row>

          <Row className="tab-container">
            <Col span={24}>
              <Tabs
                items={items}
                onChange={(key) => {
                  setCurrentTab(key);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Credential;
