export const getOktaToken = () => {
  const params = new URLSearchParams(window.location.search);
  let token = params.get("session");
  if (token) {
    localStorage.setItem("token", token);
  }
  return token;
};

export const getRequestId = () => {
  const params = new URLSearchParams(window.location.search);
  let requestId = params.get("requestId");
  if (requestId) {
    localStorage.setItem("requestId", requestId);
  } else {
    localStorage.setItem("requestId", "");
  }
  return requestId;
};
