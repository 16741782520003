import { Button, Col, Row, Input, Select, message, Form } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { REACT_APP_API, ORG_ID, DOMAIN_ID, AUTH_TOKEN } from "../../../../constants.js";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

export default function GrantTab({
  prevTab,
  nextTab,
  isEdit,
  ruleId,
  selectedEndpoint,
  selectedOU,
  mfaFailCount,
  loginFailCount,
  trustedLocation,
  selectedBrowser,
  selectedCountry,
  endpointHostname,
  additionalConditions,
  selectedState,
  setLoginPermission,
  setAddGroup,
  setRemoveGroup,
  loginPermission,
  addGroup,
  removeGroup,
  allowedSudoCommands,
  deniedSudoCommands,
  setAllowedSudoCommands,
  setDeniedSudoCommands,
  endpointOSType,
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [ADLogs, setADLogs] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [sudoCommandsList, setSudoCommandsList] = useState([]);

  useEffect(() => {
    fetchSudoCommands();
  }, []);

  useEffect(() => {
    if (selectedAction) {
      fetchADGroups();
    }
  }, [selectedAction]);

  const fetchADGroups = async () => {
    setIsLoadingGroups(true);
    try {
      const response = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listADGroups`,
        {
          org_Id: ORG_ID(),
          tenant_Id: DOMAIN_ID(),
          domainName: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const adGroups = response.data.data || [];
      setADLogs(adGroups);
      setIsLoadingGroups(false);
    } catch (error) {
      setIsLoadingGroups(false);
      console.error("Error fetching AD Groups:", error);
      message.error("Failed to fetch AD groups.");
    }
  };

  const fetchSudoCommands = async () => {
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listLinuxCommands`,
        {
          orgId: ORG_ID(),
          tenantId: DOMAIN_ID(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const tempData = res?.data?.data.map((command) => {
        return {
          value: command.linux_command,
          label: command.linux_command,
        };
      });

      setSudoCommandsList(tempData);
    } catch (error) {
      console.error("Error fetching Sudo commands", error);
      message.error("Failed to fetch Sudo commands");
    }
  };

  const handleAddPolicy = async () => {
    const conditions = [
      {
        ad_user_group: selectedOU !== "N/A" ? selectedOU : undefined,
        trusted_location: trustedLocation !== "N/A" ? trustedLocation : undefined,
        country: selectedCountry !== "N/A" ? selectedCountry : undefined,
        state: selectedState !== "N/A" ? selectedState : undefined,
        auth_fail_count: loginFailCount !== "N/A" ? loginFailCount : undefined,
        mfa_fail_count: mfaFailCount !== "N/A" ? mfaFailCount : undefined,
        ...(selectedBrowser !== "N/A" && { browser: selectedBrowser }),
        ...Object.fromEntries(
          additionalConditions
            .filter((condition) => condition.conditionValue !== "N/A")
            .map((condition) => [condition.conditionName, condition.conditionValue])
        ),
      },
    ];

    const filteredConditions = conditions.map((condition) =>
      Object.fromEntries(Object.entries(condition).filter(([_, value]) => value !== undefined))
    );

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      rule_name: `Rule For Endpoint - ${endpointHostname}`,
      endpoint_name: selectedEndpoint,
      conditions: filteredConditions,
      permissions: [
        {
          login: loginPermission,
          add_group: addGroup,
          remove_group: removeGroup,
          ...(allowedSudoCommands && { allowed_commands: allowedSudoCommands }),
          ...(deniedSudoCommands && { denied_commands: deniedSudoCommands }),
        },
      ],
    };

    try {
      setIsLoading(true);
      await form.validateFields();
      await axios.post(`${REACT_APP_API}/api/v1/policyService/createManualEndpointRule`, payload, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      message.success("Policy created successfully.");
      setIsLoading(false);
      navigate("/dit/endpointRules/policies");
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating policy:", error);
      message.error("Failed to create policy.");
    }
  };

  const handleEditPolicy = async () => {
    const conditions = [
      {
        ad_user_group: selectedOU !== "N/A" ? selectedOU : undefined,
        trusted_location: trustedLocation !== "N/A" ? trustedLocation : undefined,
        country: selectedCountry !== "N/A" ? selectedCountry : undefined,
        state: selectedState !== "N/A" ? selectedState : undefined,
        auth_fail_count: loginFailCount !== "N/A" ? loginFailCount : undefined,
        mfa_fail_count: mfaFailCount !== "N/A" ? mfaFailCount : undefined,
        ...(selectedBrowser !== "N/A" && { browser: selectedBrowser }),
        ...Object.fromEntries(
          additionalConditions
            .filter((condition) => condition.conditionValue !== "N/A")
            .map((condition) => [condition.conditionName, condition.conditionValue])
        ),
      },
    ];

    const filteredConditions = conditions.map((condition) =>
      Object.fromEntries(Object.entries(condition).filter(([_, value]) => value !== undefined))
    );

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      ruleId: ruleId,
      rule_name: `Rule For Endpoint - ${endpointHostname}`,
      endpoint_name: selectedEndpoint,
      conditions: filteredConditions,
      permissions: [
        {
          login: loginPermission,
          add_group: addGroup,
          remove_group: removeGroup,
          ...(allowedSudoCommands && { allowed_commands: allowedSudoCommands }),
          ...(deniedSudoCommands && { denied_commands: deniedSudoCommands }),
        },
      ],
    };
    setIsLoading(true);
    try {
      await form.validateFields();
      await axios.post(`${REACT_APP_API}/api/v1/policyService/updateManualEndpointRule`, payload, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      localStorage.removeItem("policyData");
      message.success("Policy Updated successfully.");
      setIsLoading(false);
      navigate("/dit/endpointRules/policies");
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating policy:", error);
      message.error("Failed to update policy.");
    }
  };

  useEffect(() => {
    if (loginPermission !== "" || addGroup !== "" || removeGroup !== "") {
      form.setFieldsValue({
        loginPermission: loginPermission,
        addGroup: addGroup,
        removeGroup: removeGroup && removeGroup,
        addSudoCommands: allowedSudoCommands,
        removeSudoCommands: deniedSudoCommands,
      });
    }
  }, [loginPermission, addGroup, removeGroup, form]);

  return (
    <Row>
      <Col span={24} style={{ padding: "20px" }}>
        <Form form={form}>
          <Row style={{ marginTop: "1rem" }}>
            <Col
              span={18}
              style={{
                background: "rgb(219, 238, 255)",
                borderRadius: "8px",
                padding: "2rem",
              }}
            >
              <Row gutter={20}>
                <Col span={2}>THEN</Col>
                <Col span={11}>
                  <Input value={"Login"} placeholder="Login" readOnly />
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="loginPermission"
                    rules={[{ required: true, message: "Select an Login Permission" }]}
                  >
                    <Select
                      placeholder="Select Allow or Deny"
                      style={{ width: "100%" }}
                      onChange={(value) => setLoginPermission(value)}
                      options={[
                        { label: "Allow", value: "Allow" },
                        { label: "Deny", value: "Deny" },
                      ]}
                      defaultValue={loginPermission}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {endpointOSType === "linux" && (
                <>
                  <Row gutter={20}>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Input
                        value={"Allowed Sudo Commands"}
                        placeholder="Allowed Sudo Commands"
                        readOnly
                      />
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="addSudoCommands"
                        rules={[{ required: true, message: "Select commands to add" }]}
                      >
                        <Select
                          placeholder="Select Sudo Commands"
                          style={{ width: "100%" }}
                          mode="multiple"
                          onChange={(value) => setAllowedSudoCommands(value)}
                          defaultValue={allowedSudoCommands}
                        >
                          {sudoCommandsList.map((command) => (
                            <Option key={command.value} value={command.value}>
                              {command.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col span={2}></Col>
                    <Col span={11}>
                      <Input
                        value={"Denied Sudo Commands"}
                        placeholder="Denied Sudo Commands"
                        readOnly
                      />
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="removeSudoCommands"
                        rules={[{ required: true, message: "Select commands to remove" }]}
                      >
                        <Select
                          placeholder="Select Sudo Commands"
                          mode="multiple"
                          style={{ width: "100%" }}
                          onChange={(value) => setDeniedSudoCommands(value)}
                          defaultValue={deniedSudoCommands}
                        >
                          {sudoCommandsList.map((command) => (
                            <Option
                              key={command.value}
                              value={command.value}
                              disabled={allowedSudoCommands?.includes(command.label)}
                            >
                              {command.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Row gutter={20}>
                <Col span={2}></Col>
                <Col span={11}>
                  <Input value={"Add Group"} placeholder="Add Group" readOnly />
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="addGroup"
                    rules={[{ required: true, message: "Select group to add" }]}
                  >
                    <Select
                      placeholder="Select Action"
                      style={{ width: "100%" }}
                      onDropdownVisibleChange={() => setSelectedAction("Add Group")}
                      onChange={(value) => setAddGroup(value)}
                      defaultValue={addGroup}
                      loading={isLoadingGroups}
                    >
                      {ADLogs.map((ad) => (
                        <Option key={ad} value={ad}>
                          {ad}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={2}></Col>
                <Col span={11}>
                  <Input value={"Remove Group"} placeholder="Remove Group" readOnly />
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="removeGroup"
                    rules={[{ required: true, message: "Select group to remove" }]}
                  >
                    <Select
                      placeholder="Select Action"
                      style={{ width: "100%" }}
                      onDropdownVisibleChange={() => setSelectedAction("Add Group")}
                      onChange={(value) => setRemoveGroup(value)}
                      defaultValue={removeGroup}
                      loading={isLoadingGroups}
                    >
                      {ADLogs.map((ad) => (
                        <Option key={ad} value={ad} disabled={addGroup.includes(ad)}>
                          {ad}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Row style={{ marginTop: "1rem" }}>
          <Col span={18}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => prevTab()}>Back</Button>
              {isEdit ? (
                <Button type="primary" onClick={handleEditPolicy} loading={isLoading}>
                  Update Policy
                </Button>
              ) : (
                <Button type="primary" onClick={handleAddPolicy} loading={isLoading}>
                  Add Policy
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
