import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import { logOutUser } from "../../../common";
import { LoadingOutlined } from "@ant-design/icons";

function AssignCredentials({
  setIsAssignCredentials,
  isAssignCredentials,
  selectedUserData,
  fetchAdUser,
  notification,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [issuerData, setIssuerData] = useState([]);
  const [walletData, setwalletData] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [formData, setFormData] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    fetchIssue();
    fetchWalletUser();
    fetchDefaultIssuer();
    setFormData([
      { name: ["ou"], value: selectedUserData?.ou },
      { name: ["AdName"], value: selectedUserData?.activeDirectoryName },
      { name: ["logonName"], value: selectedUserData?.logoname },
      { name: ["email"], value: selectedUserData?.email },
    ]);
  }, []);

  const handleShareCredential = (payload) => {
    axios
      .post(`${REACT_APP_API_DID_URL}/credential/createSharedAdUserCredential`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        notification.open({
          type: "success",
          content: `Credential Assigned Successfully to ${defaultValue?.walletName}`,
        });
        setIsBtnLoading(false);
        setIsAssignCredentials(false);
        fetchAdUser();
      })
      .catch((err) => {
        setIsBtnLoading(false);
        notification.open({
          type: "error",
          content: "Unable to assign credentials",
        });
      });
  };
  const WALLETID = [...new Set(selectedUserData?.walletId)];

  const handleOk = (e) => {
    let payload = {
      walletId: e.walletId,
      issuerId: e.issuerId,
      tenantId: DOMAIN_ID(),
      userId: selectedUserData.id,
      orgId: ORG_ID(),
    };
    setIsBtnLoading(true);

    const selectedValue =
      WALLETID.length > 0
        ? e.walletId.filter((val) => !selectedUserData.walletId.includes(val))
        : e.walletId;

    if (!selectedValue.length) {
      setIsAssignCredentials(false);
    } else {
      handleShareCredential(payload);
    }
  };

  // const handleOk = (e) => {
  //   console.log(e);
  //   let payload = {
  //     ...e,
  //     domainId: DOMAIN_ID(),
  //     userId: selectedUserData.id,
  //     orgId: ORG_ID(),
  //   };
  //   setIsLoading(true);
  //   axios
  //     .post(`${REACT_APP_API_DID_URL}/credential/createADUserCredential`, payload, {
  //       headers: {
  //         withCredentials: true,
  //         "X-Authorization": AUTH_TOKEN(),
  //       },
  //     })
  //     .then((res) => {
  //       notification.open({
  //         type: "success",
  //         content: "Credential Assigned Successfully!",
  //       });
  //       setIsLoading(false);
  //       setIsAssignCredentials(false);
  //       fetchDirectoryUsers();
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       notification.open({
  //         type: "error",
  //         content: "Unable to assign credentials",
  //       });
  //     });
  // };

  const fetchDefaultIssuer = () => {
    const payloadIssuer = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/getDefaultIssuer`, payloadIssuer, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        setDefaultValue({ issuerId: data.data });
        form.setFieldsValue({
          issuerId: data.data,
          walletId: selectedUserData?.walletId,
        });

        console.log(data.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    setIsAssignCredentials(false);
  };

  const fetchIssue = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIssuerData(
          res?.data?.map((data) => ({
            value: data.IssuerId,
            label: data.Name,
          })) || []
        );
      });
  };

  const fetchWalletUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: 1,
      pageSize: 100,
      search: "",
      filter: "",
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setwalletData(
          res.data.users?.map((data) => ({
            value: data.walletId,
            label: data.emailAddress,
            disabled: selectedUserData?.walletId?.includes(data.walletId),
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch wallet",
          });
        }
      });
  };

  return (
    <>
      <Modal
        centered
        open={isAssignCredentials}
        title={<span style={{ fontSize: 18 }}>Assign Credentials</span>}
        okText="Submit"
        onOk={form.submit}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <div style={{ marginTop: "2rem" }}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                    }}
                    spin
                  />
                }
              />
            </div>
          ) : (
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleOk}
                  fields={formData}
                  onFieldsChange={(_, allFields) => {
                    setFormData(allFields);
                  }}
                >
                  <Row gutter={16}>
                    {/* <Col span={8}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        name="logonName"
                        label="Logon Name"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="ou"
                        label="OU"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="AdName"
                        label="Active Directory Name"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="issuerId"
                        label="Select Issuer"
                        rules={[
                          {
                            required: true,
                            message: "Select Issuer",
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => {
                            console.log(value);
                            form.setFieldsValue({
                              issuerId: value,
                            });
                            setDefaultValue({ ...defaultValue, issuerId: value });
                          }}
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={issuerData}
                          placeholder="Select Issuer"
                          value={defaultValue.issuerId}
                        />{" "}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="walletId"
                        label="Select Users to Assign this AD Credential"
                        rules={[
                          {
                            required: true,
                            message: "Select Users",
                          },
                        ]}
                      >
                        <Select
                          placement="topLeft"
                          mode="multiple"
                          onChange={(value, data) => {
                            let selectedValue;
                            const walletId = Array.isArray(selectedUserData?.walletId)
                              ? selectedUserData.walletId
                              : [];

                            console.log(walletId);

                            if (walletId.length > 0) {
                              selectedValue = value?.filter((val) => !walletId.includes(val));
                            } else {
                              selectedValue = value;
                            }

                            form.setFieldsValue({
                              walletId: selectedValue,
                            });

                            setDefaultValue({
                              ...defaultValue,
                              walletId: selectedValue,
                              walletName: data.map((val) => val.label),
                            });
                          }}
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={walletData}
                          placeholder="Select User"
                          defaultValue={WALLETID}
                        />{" "}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item style={{ textAlign: "right", marginTop: "2rem" }}>
                    <Space>
                      <Button
                        type="default"
                        htmlType="submit"
                        onClick={() => setIsAssignCredentials(false)}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" loading={isBtnLoading} htmlType="submit">
                        Assign
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
}

export default AssignCredentials;
