import { useState, useEffect } from "react";
import { Row, Col, Input, Button, Dropdown, notification, Typography } from "antd";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { MoreOutlined, InboxOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../../constants";
import { logOutUser } from "../../../../common";
import { useDebounce } from "../../../../common/debounce";
import EpmGroupAuthflow from "../EpmGroupAuthflow";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const EndpointGroupsDetailsEndpoints = ({ storedValue }) => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [IsGroupauthflow, setIsGroupauthflow] = useState(false);
  const [endpoints, setEndpoints] = useState([]);
  const [instaceDataById, setinstaceDataById] = useState([]);

  const debouncedSearch = useDebounce(search, 500);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const groupId = queryParams.get("groupId");
  const { groupId } = location.state || {};
  // pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const { Text } = Typography;

  useEffect(() => {
    fetchInstanceGroupDetail();
  }, [debouncedSearch, paginationParams, currentPage]);

  const fetchInstanceGroupDetail = async () => {
    let payload = {
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search: search,
      filter: {
        filterBy: "",
        value: "",
      },
      domainId: DOMAIN_ID(),
      groupId: Number(groupId ? groupId : storedValue.groupId),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    let config = {
      headers: {
        "X-Authorization": AUTH_TOKEN(),
        withCredentials: true,
      },
    };
    let res;
    try {
      setIsLoading(true);
      res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/fetchInstanceGroupDetail`,
        payload,
        
        config, {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      let insData = res.data.InstanceGroup.map((ins, i) => ({
        id: ins.instanceId,
        hostName: ins.hostName,
        instanceName: ins.instanceName,
        os: ins.os,
        privateIp: ins.privateIp,
        publicIp: ins.publicIp,
        state: ins.state,
      }));
      setIsLoading(false);
      setinstaceDataById(insData);
      setTotalCount(res?.data?.totalCount);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
    } catch (err) {
      setIsLoading(false);
      if (err.response.data.message == "Invalid credentials") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    }
  };

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        // fetchEPMUsers(res?.data?.instances.map((ins) => ins.instanceId));
        setEndpoints(
          res?.data?.instances.map((ins) => ({
            ...ins,
            id: ins.instanceId,
            instance: ins.instanceId,
            label: ins.hostName,
            ipAddress: ins.publicIpAddress,
          }))
        );
      });
  };

  const handleStatus = async (record) => {
    let data = {
      domainId: DOMAIN_ID(),
      status: record.state === "Active" ? "Inactive" : "Active",
      instanceIds: [Number(record.id)],
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    let url = `${REACT_APP_PAM_API}/instances/updateInstanceStatus`;
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchInstance();
      notification.open({
        type: "success",
        content: `${record.hostName} is ${
          record.state === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (err.response.data.message === "Invalid credentials") {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };

  const columns = [
    {
      headerName: "Host Name",
      field: "hostName",
      key: "hostName",
      flex: 1,
    },
    {
      headerName: "OS",
      field: "os",
      key: "os",
      flex: 1,
    },
    {
      headerName: "Private IP",
      field: "privateIp",
      key: "privateIp",
      flex: 1,
    },
    {
      headerName: "PublicIp",
      field: "publicIp",
      key: "publicIp",
      flex: 1,
    },
    {
      headerName: "State",
      field: "state",
      key: "state",
      flex: 1,
      renderCell: (val) => {
        let stateVal = val.row.state.toLowerCase() === "active" ? "Active" : "Inactive";
        return (
          <>
            <Text type={stateVal === "Active" ? "success" : "danger"}> {stateVal}</Text>
          </>
        );
      },
    },
    {
      headerName: "Action",
      key: "Action",
      flex: 1,
      renderCell: (value, record) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedRecords(value.row);
                      setIsGroupauthflow(true);
                    }}
                  >
                    Auth Flow
                  </Link>
                ),
              },
              {
                key: "status",
                label: (
                  <Link onClick={() => handleStatus(value.row)}>
                    {value.row.state === "Active" ? "Deactivate" : "Activate"}
                  </Link>
                ),
                danger: value.row.state === "Active" ? true : false,
              },
              {
                key: "Password Policies",
                label: (
                  <Link to={`/pam/endpoint/passwordPolicies?hostaddress=${value.row.hostName}`}>
                    Password Policies
                  </Link>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Row className="content-container">
        <Col span={24}>
          <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
            <Col span={6}>
              <p className="search-label">Search</p>

              <Input
                style={{ width: "100%" }}
                placeholder="Search"
                allowClear
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (paginationParams.current !== 1) {
                    setPaginationParams({ current: 1, pageSize: 10 });
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            rows={instaceDataById}
            columns={columns}
            style={{ border: "none" }}
            loading={isLoading}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPage}
            paginationModel={currentPageModel}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            onPaginationModelChange={(params) => {
              setCurrentPage(params.page);
            }}
          />
        </Col>
      </Row>

      {IsGroupauthflow && (
        <EpmGroupAuthflow
          IsGroupauthflow={IsGroupauthflow}
          setIsGroupauthflow={setIsGroupauthflow}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default EndpointGroupsDetailsEndpoints;
