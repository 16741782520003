import React, { useEffect } from "react";
import { Modal, Form, Row, Col, Input, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API } from "../../../constants";

const AddPermissionModal = ({
  visible,
  onOk,
  onCancel,
  isUpdating,
  selectedRowData,
  permissionType,
  setPermissionType,
  groupNames,
  form,
  handleUpdate,
  isLoading,
  selectedRule,
}) => {
  useEffect(() => {
    if (selectedRowData) {
      form.setFieldsValue({
        ...selectedRowData,
        permissionType: selectedRule === "AD" ? "AD" : "Login",
      });
      setPermissionType(selectedRule === "AD" ? "AD" : "Login");
    }
  }, [selectedRowData, selectedRule, form, setPermissionType]);

  const permissionTypeOptions =
    selectedRule === "AD" ? [{ label: "AD", value: "AD" }] : [{ label: "Login", value: "Login" }];

  return (
    <Modal
      title={<span style={{ fontSize: 18 }}>Update Endpoint Permissions</span>}
      open={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={onCancel}
      okText="Add"
      okButtonProps={{ loading: isUpdating }}
      width={900}
    >
      <div style={{ marginTop: "2rem" }}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              handleUpdate(values);
              form.resetFields();
            }}
          >
            <Row gutter={16}>
              <Col span={12} style={{ display: "none" }}>
                <Form.Item label="Rule ID" name="id">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Username is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Source IP"
                  name="source_ip"
                  rules={[
                    {
                      required: true,
                      message: "Source IP is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Destination IP"
                  name="destination_ip"
                  rules={[
                    {
                      required: true,
                      message: "Destination IP is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Permission Type"
                  name="permissionType"
                  rules={[
                    {
                      required: true,
                      message: "Select Permission Type",
                    },
                  ]}
                >
                  <Input
                    readOnly
                    placeholder="Select Permission Type"
                    style={{ width: "100%" }}
                    options={permissionTypeOptions}
                    onChange={(value) => setPermissionType(value)}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Action"
                  name="action"
                  rules={[
                    {
                      required: true,
                      message: "Select Action",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Action"
                    style={{ width: "100%" }}
                    options={[
                      { label: "Allow", value: "Allow" },
                      { label: "Deny", value: "Deny" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {permissionType === "AD" && (
                  <Form.Item
                    label="Group Name"
                    name="adGroup"
                    rules={[
                      {
                        required: true,
                        message: "Select Group Name",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Group"
                      style={{ width: "100%" }}
                      options={groupNames}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default AddPermissionModal;
