import { Modal, Row, Col } from "antd";
import React from "react";

const Instruction = ({ instruction, setInstruction }) => {
  const handleOk = () => {
    setTimeout(() => {
      setInstruction(false);
    }, 3000);
  };
  const handleCancel = () => {
    setInstruction(false);
  };
  return (
    <>
      <Modal
        open={instruction}
        title="Instructions to set up Jump Server"
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={null}
      >
        <Row>
          <Col>
            <p>
              Step 1:{" "}
              <span>
                Please “Add Jump Server” <br />
                a) Server Host Name -> The hostname where the Guacd needs to be installed
                <br />
                b) Server IP Address -> Public IP address of the Guacd agent <br />
                c) Server Name - > Jump server Name
                <br />
              </span>
            </p>
            <p>
              Step 2:{" "}
              <span>
                The above job will be added to the endpoint selected, and the agent should pick up
                the Guacd installation Job now. (Make sure, the agent is installed and running on
                the Endpoint . Refer -> Agent Installation Steps)
                <br />
              </span>
            </p>
            <p>
              Step 3:{" "}
              <span>
                Check the guacd status on the jump server VM <br />
                a) sudo systemctl status guacd
                <br />
                b) The Jump server Agent Status should be active on the UI
                <br />{" "}
              </span>
            </p>
            <p>
              Step 4:{" "}
              <span>
                Click on Create Connections:
                <br />
                a) Endpoints - >The machine to connect with from Jump server
                <br />
                b) Ip Address - > Jump Server IP Address
                <br />
                c)Jump Server -> Select the Jump Server Host
                <br />{" "}
              </span>
            </p>
            <p>
              Step 5:{" "}
              <span>
                Please click on “Connect Now “ from the Connections screen on the UI
                <br />{" "}
              </span>
            </p>
            <p>
              Step 6:{" "}
              <span>
                The session should not open and we should be able to see the VM terminal, and
                execute commands on it. Try exiting the machine once you are done with it.
                <br />{" "}
              </span>
            </p>
            <p>
              Step 7:{" "}
              <span>
                Now navigate to the -> Session Recording page in the UI, we should be able to see
                the all the session recordings including our latest session recorded (The agent will
                automatically synchronize the sessions in the backend, and make sure the agent is
                installed and running)
                <br />
              </span>
            </p>
            <p>
              Step 8:{" "}
              <span>
                Please click on “Download” to see the session recorded / View to stream the session
                recording
                <br />{" "}
              </span>
            </p>
            <p>
              Step 9:{" "}
              <span>
                The text recording can be viewed under “Text Recording “ Page.
                <br />{" "}
              </span>
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Instruction;
