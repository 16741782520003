import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Radio, Row, Upload } from "antd";
import React, { useState } from "react";

function AppCreate() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  return (
    <Row className="content-conatiner">
      <Col span={24}>
        <h2 className="title">Create App</h2>
      </Col>
      <Col span={12}>
        <Form
          form={form}
          layout="vertical"
          // onFinish={handleSubmit}
          initialValues={{}}
        >
          <Form.Item
            label="App Name"
            name="appName"
            rules={[{ required: true, message: "Enter app name" }]}
          >
            <Input type="text" placeholder="Enter app name" />
          </Form.Item>
          <Form.Item
            name="url"
            label="Redirect URL"
            rules={[
              {
                required: true,
                message: "Please input your redirect URL",
              },
            ]}
          >
            <Input placeholder="https://www.example.com/" />
          </Form.Item>
          <Form.Item
            label="Authentication Type"
            name="auth_type"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Select Authentication type",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="1fa"> One-Factor Authentication </Radio>
              <Radio value="2fa"> Two-Factor Authentication </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="upload"
            label="Upload Logo"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              console.log("Upload event:", e);
              if (Array.isArray(e)) {
                return e;
              }
              return e?.fileList;
            }}
            rules={[
              {
                required: true,
                message: "Please input your App name",
              },
            ]}
          >
            <Upload name="logo" listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginTop: "1rem" }}>
              Create App
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default AppCreate;
