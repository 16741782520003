import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import DevicesTab from "./createPolicyTabs/DevicesTab";
import UsersTab from "./createPolicyTabs/UserTab";
import AppsTab from "./createPolicyTabs/AppsTab";
import NetworksTab from "./createPolicyTabs/NetworksTab";
import ConditionsTab from "./createPolicyTabs/ConditionTab";
import GrantTab from "./createPolicyTabs/GrantTab";
import { useLocation } from "react-router-dom";

export default function CreatePolicyDIT() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [currentTab, setCurrentTab] = useState("1");

  const [selectedType, setSelectedType] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [selectedDeviceType, setSelectedDeviceType] = useState(null);
  const [selectedBrowserType, setSelectedBrowserType] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const [selectedCountry, setselectedCountry] = useState("USA");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedOU, setSelectedOU] = useState(null);
  const [endpointHostname, setEndpointHostname] = useState("");

  const [mfaFailCount, setMfaFailCount] = useState("N/A");
  const [loginFailCount, setLoginFailCount] = useState("N/A");
  const [trustedLocation, setTrustedLocation] = useState("N/A");
  const [ruleId, setRuleId] = useState(null);
  const [endpointOSType, setEndpointOSType] = useState("");

  const [additionalConditions, setAdditionalConditions] = useState([
    {
      id: 1,
      conditionName: "persona_value",
      conditionLabel: "Persona Value",
      conditionValue: "N/A",
    },
    {
      id: 2,
      conditionName: "trusted_device",
      conditionLabel: "Trusted Device",
      conditionValue: "N/A",
    },
    {
      id: 3,
      conditionName: "confidence_value",
      conditionLabel: "Confidence Score",
      conditionValue: "N/A",
    },
    {
      id: 4,
      conditionName: "trusted_network",
      conditionLabel: "Trusted Network",
      conditionValue: "N/A",
    },
    {
      id: 5,
      conditionName: "risk_score",
      conditionLabel: "Risk Score",
      conditionValue: "N/A",
    },
    {
      id: 6,
      conditionName: "browser",
      conditionLabel: "Browser",
      conditionValue: "N/A",
    },
  ]);
  const [loginPermission, setLoginPermission] = useState("");
  const [addGroup, setAddGroup] = useState("");
  const [removeGroup, setRemoveGroup] = useState("");
  const [allowedSudoCommands, setAllowedSudoCommands] = useState(null);
  const [deniedSudoCommands, setDeniedSudoCommands] = useState(null);

  const isEdit = queryParams.get("edit");

  useEffect(() => {
    if (isEdit) {
      const policyData = JSON.parse(localStorage.getItem("policyData"));

      setRuleId(policyData?.rule_id);
      setSelectedOU(policyData?.rule_conditions?.ad_user_group);
      setSelectedEndpoint(policyData?.endpoint_name);
      setEndpointHostname(
        policyData?.rule_name.substring(policyData?.rule_name.indexOf("-") + 1).trim()
      );
      setselectedCountry(policyData?.rule_conditions?.country);

      // network tab
      setSelectedState(policyData?.rule_conditions?.state);
      setselectedCountry(policyData?.rule_conditions?.country);

      // conditions tab
      setMfaFailCount(policyData?.rule_conditions?.mfa_fail_count);
      setLoginFailCount(policyData?.rule_conditions?.auth_fail_count);
      setTrustedLocation(policyData?.rule_conditions?.trusted_location);
      updateAllConditions(policyData);

      // grant tab
      setLoginPermission(policyData?.rule_permission?.login);
      setAddGroup(policyData?.rule_permission?.add_group);
      setRemoveGroup(policyData?.rule_permission?.remove_group);
      setAllowedSudoCommands(policyData?.rule_permission?.allowed_commands);
      setDeniedSudoCommands(policyData?.rule_permission?.denied_commands);
    }
  }, []);

  const nextTab = () => {
    const nextTab = Number(currentTab) + 1;
    setCurrentTab(nextTab.toString());
  };

  const prevTab = () => {
    const prevTab = Number(currentTab) - 1;
    setCurrentTab(prevTab.toString());
  };

  const updateAllConditions = (policyData) => {
    const updatedConditions = additionalConditions.map((condition) => {
      const newValue = policyData?.rule_conditions?.[condition.conditionName] || "N/A";

      return {
        ...condition,
        conditionValue: newValue,
      };
    });

    setAdditionalConditions(updatedConditions);
  };

  const tabs = [
    {
      key: "1",
      label: "Apps and Endpoints",
      children: (
        <AppsTab
          setCurrentTab={setCurrentTab}
          nextTab={nextTab}
          setSelectedType={setSelectedType}
          setSelectedApp={setSelectedApp}
          setSelectedEndpoint={setSelectedEndpoint}
          setEndpointHostname={setEndpointHostname}
          setSelectedOU={setSelectedOU}
          selectedOU={selectedOU}
          selectedEndpoint={selectedEndpoint}
          endpointHostname={endpointHostname}
          setEndpointOSType={setEndpointOSType}
        />
      ),
    },
    {
      key: "2",
      label: "Devices and Browsers",
      children: (
        <DevicesTab
          prevTab={prevTab}
          nextTab={nextTab}
          additionalConditions={additionalConditions}
          setAdditionalConditions={setAdditionalConditions}
          setSelectedDeviceType={setSelectedDeviceType}
          setSelectedBrowserType={setSelectedBrowserType}
          setSelectedDevice={setSelectedDevice}
          setSelectedBrowser={setSelectedBrowser}
        />
      ),
    },

    {
      key: "3",
      label: "Networks",
      children: (
        <NetworksTab
          prevTab={prevTab}
          nextTab={nextTab}
          setselectedCountry={setselectedCountry}
          setSelectedState={setSelectedState}
          selectedCountry={selectedCountry}
          selectedState={selectedState}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Users and Groups",
    //   children: (
    //     <UsersTab
    //       prevTab={prevTab}
    //       nextTab={nextTab}
    //       selectedType={selectedType}
    //       setSelectedOU={setSelectedOU}
    //     />
    //   ),
    // },
    {
      key: "4",
      label: "Conditions",
      children: (
        <ConditionsTab
          prevTab={prevTab}
          nextTab={nextTab}
          selectedType={selectedType}
          selectedApp={selectedApp}
          selectedEndpoint={selectedEndpoint}
          selectedDeviceType={selectedDeviceType}
          selectedBrowserType={selectedBrowserType}
          selectedDevice={selectedDevice}
          selectedBrowser={selectedBrowser}
          selectedCountry={selectedCountry}
          selectedState={selectedState}
          selectedOU={selectedOU}
          mfaFailCount={mfaFailCount}
          setMfaFailCount={setMfaFailCount}
          loginFailCount={loginFailCount}
          setLoginFailCount={setLoginFailCount}
          trustedLocation={trustedLocation}
          setTrustedLocation={setTrustedLocation}
          additionalConditions={additionalConditions}
          setAdditionalConditions={setAdditionalConditions}
        />
      ),
    },
    {
      key: "5",
      label: "Grant Permissions",
      children: (
        <GrantTab
          prevTab={prevTab}
          nextTab={nextTab}
          isEdit={isEdit}
          ruleId={ruleId}
          selectedEndpoint={selectedEndpoint}
          selectedOU={selectedOU}
          mfaFailCount={mfaFailCount}
          loginFailCount={loginFailCount}
          trustedLocation={trustedLocation}
          selectedBrowser={selectedBrowser}
          selectedCountry={selectedCountry}
          setSelectedBrowser={setSelectedBrowser}
          endpointHostname={endpointHostname}
          additionalConditions={additionalConditions}
          selectedState={selectedState}
          setLoginPermission={setLoginPermission}
          setAddGroup={setAddGroup}
          setRemoveGroup={setRemoveGroup}
          loginPermission={loginPermission}
          addGroup={addGroup}
          removeGroup={removeGroup}
          endpointOSType={endpointOSType}
          allowedSudoCommands={allowedSudoCommands}
          deniedSudoCommands={deniedSudoCommands}
          setAllowedSudoCommands={setAllowedSudoCommands}
          setDeniedSudoCommands={setDeniedSudoCommands}
        />
      ),
    },
  ];

  return (
    <Row className="content-container">
      <Col span={24}>
        <Row>
          <h1 className="title">{isEdit ? "Edit" : "Create"} Policy</h1>
        </Row>
      </Col>

      <Col span={24}>
        <Tabs activeKey={currentTab} type="card" items={tabs} />
      </Col>
    </Row>
  );
}
