import React, { useEffect, useState } from "react";
import { Col, Row, Button, Input, Form, message, Space } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../constants";
import { logOutUser } from "../../common";

const AddUserDID = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [notification, setNotification] = message.useMessage();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [formData, setFormData] = useState({});
  const [name, setName] = useState("");
  const [Discp, setDiscp] = useState("");

  useEffect(() => {
    const initialValues = {
      userType: "Issuer DID",
    };
    form.setFieldsValue(initialValues);
    setFormData({
      userType: "Issuer DID",
    });
  }, [console.log()]);

  const handleUserType = (value, data) => {
    form.setFieldsValue({
      userType: value,
    });
    setFormData({ ...formData, userType: value });
  };

  const createIssure = () => {
    let url = `${REACT_APP_API_DID_URL}/did/createIssuerDid`;
    let data = {
      method: "brcm",
      domainId: DOMAIN_ID(),
      name: name,
      description: Discp,
      orgId: ORG_ID(),
    };
    axios
      .post(url, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          logOutUser();
        }
      });
  };

  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">Create DID</h2>
          </Col>
        </Row>
        <Form form={form} layout="vertical" onFinish={() => createIssure()}>
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={6}>
                  <Form.Item name="userType" label="User Type">
                    <Input disabled onChange={handleUserType} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Name required",
                      },
                    ]}
                  >
                    <Input onChange={(e) => setName(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={16}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: false,
                        message: "Name Required!",
                      },
                    ]}
                  >
                    <TextArea rows={8} onChange={(e) => setDiscp(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "1rem", marginRight: "4rem" }}>
            <Col span={11}>
              <Space>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Create DID
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default AddUserDID;
