import React, { useState, useEffect } from "react";
import { Row, Col, Select, Typography,Cascader } from "antd";
import "../../styles/WorkloadConditionalaccess.scss"

const { Title } = Typography;
const { Option } = Select;

const WorkloadConditionalAccess = () => {
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");


  useEffect(() => {
    
  }, []);
  const appList = [
    "Google Workspace",
    "Incorta",
    "JIRA",
    "Box",
    "Workato",
    "Wolken",
  ];
  const option = [
    {
      value: "IDENTITYNAME",
      label: "Identity Name",
      children: "",
    },
    {
      value: "IDENTITYGROUP",
      label: "Identity Group",
      children: "",
    },
  ];
  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.label : "");
  };
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  

  return (
    <div className="rule-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 className="title">Manage Conditional Access Policies</h1>
       
      </div>
      <Row>
        <Col span={24}>
          <Row className="search-box-container">
          <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </div>
                </Col>
            
          </Row>
        </Col>
      </Row>

      <Row className="commit-container">
        <Col span={24} className="version-history">
          {/* Replace this part with your dynamic version history data */}
          
          <Row>
            
              <Col span={24}>
                
                  <div className="github-commit-card">
                    <div className="commit-header">
                      <p className="commit-info">
                        Last updated by {"Asif Ali "}
                        
                      </p>
                    </div>
                    <div className="commit-details">
                      <p className="commit-message"></p>
                      <div className="commit-code">
                        <pre className="code-snippet">
                         
                                  <div
                                    className="code-line">
                                    If{" "}
                                    <span className="code-keyword">
                                      
                                    </span>
                                    <span className="code-operator">
                                      
                                    </span>
                                    <span className="code-value">
                                      
                                    </span>
                                  </div>
                             
                          <div
                            className="code-line"
                            style={{ marginTop: "1rem" }}
                          >
                            Then permission{" "}
                            <h5
                              style={{ marginTop: "0.5rem" }}
                              className="code-line"
                            >
                              App :{" "}
                              
                            </h5>
                            <h5
                              style={{ marginTop: "0.5rem" }}
                              className="code-line"
                            >
                              Groups Allowed :{" "}
                              
                            </h5>
                            <h5
                              style={{ marginTop: "0.5rem" }}
                              className="code-line"
                            >
                              Groups Denied :{" "}
                              
                            </h5>
                          </div>
                        </pre>
                      </div>
                    </div>
                  </div>
              </Col>
            
          </Row>

          {/* Repeat the version history and code snippet sections for each entry */}
          {/* ... */}
        </Col>
      </Row>
    </div>
  );
};

export default WorkloadConditionalAccess;
