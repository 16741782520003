import { Button, Modal, Row, Col, Input, Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

const AddGroups = ({ isAddGrp, setIsAddGrp, notification, fetchGrp }) => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  useEffect(() => {
    fetchUser();
  }, []);
  const handleOk = (e) => {
    let payload = {
      ...e,
      domainId: DOMAIN_ID(),
      applications: [],
      otpMethod: "1",
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/addGroup`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        notification?.open({
          type: "success",
          content: "User onboarded Successful!",
        });
        setIsAddGrp(false);
        fetchGrp();
      })
      .catch((err) => {
        setIsLoading(false);
        let errMgs = err.response.data.message;
        notification.open({
          type: "error",
          content:
            errMgs === "User already exists"
              ? "User already exists"
              : errMgs === "Invalid credentials"
              ? "Credentials are invalid"
              : "Unable to unboard User",
        });
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const handleCancel = () => {
    setIsAddGrp(false);
  };

  const fetchUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 10,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        setIssuerData(
          res?.data?.users?.map((data) => ({
            value: data.id,
            label: data.emailAddress,
          }))
        );
      });
  };

  const handleRole = (val) => {
    form.setFieldsValue({
      roles: val === "1" ? "3" : "1",
    });
  };
  const handleUser = (value) => {
    form.setFieldsValue({
      users: value,
    });
  };

  return (
    <Modal
      open={isAddGrp}
      title="Add Group"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={900}
    >
      <div style={{ marginTop: "2rem" }}>
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical" onFinish={handleOk}>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="groupName"
                        label="Group Name"
                        rules={[
                          {
                            required: true,
                            message: "Group name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter group name" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="roles"
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: "Select role",
                          },
                        ]}
                        initialValue="1"
                      >
                        <Select
                          onChange={handleRole}
                          placeholder="Select role"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                        >
                          <Option value="1">Enduser </Option>
                          <Option value="2">Admin</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="users"
                        label="Users"
                        rules={[
                          {
                            required: true,
                            message: "Select User",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          onChange={handleUser}
                          placeholder="Select User"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={issuerData}
                        />{" "}
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="cn"
                        label="Common Name"
                        rules={[
                          {
                            required: true,
                            message: "Common Name is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:cn" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row align="middle" justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name="baseDn"
                        label="Distinguish Name"
                        rules={[
                          {
                            required: true,
                            message: "Distinguish Name is required",
                          },
                          {
                            pattern: /^(dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message: "Distinguish Name must be dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="ou"
                        label="Group Format"
                        rules={[
                          {
                            required: true,
                            message: "Group Format is required",
                          },
                        ]}
                      >
                        <Input placeholder="eg:ou" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="metadata"
                        label="Other Metadata"
                        rules={[
                          {
                            required: true,
                            message: "Metadata is required",
                          },
                        ]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "end" }}>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Add
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AddGroups;
